import { Grid, useTheme, useMediaQuery, Box, Avatar, Card, Tooltip, ButtonBase, CardContent, Typography } from "@mui/material"
import { useTranslate } from 'react-admin'
import { useNavigate } from "react-router-dom";
import DashboardCard, { InfoLine, InfoTable2Col } from "../../ui/card/DashboardCard";
import { useState } from "react";





const Icon = ({ color }) => {
    return (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_7_569007)">
            <path d="M16.667 3.3335H3.33366C2.40866 3.3335 1.67533 4.07516 1.67533 5.00016L1.66699 15.0002C1.66699 15.9252 2.40866 16.6668 3.33366 16.6668H16.667C17.592 16.6668 18.3337 15.9252 18.3337 15.0002V5.00016C18.3337 4.07516 17.592 3.3335 16.667 3.3335ZM16.667 15.0002H3.33366V10.0002H16.667V15.0002ZM16.667 6.66683H3.33366V5.00016H16.667V6.66683Z" fill={color} />
        </g>
        <defs>
            <clipPath id="clip0_7_569007">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>

    )
}


const DeviceSubscriptionsSummaryBodyComponent = ({
    isSmall,
    nbrOfActiveSub,
    nbrOfUnpaidSub,
}) => {

    const translate = useTranslate();

    let rows = []

    if (nbrOfActiveSub) {
        rows.push({ label: translate("resources.dashboard.active"), info: nbrOfActiveSub })
    }
    if (nbrOfUnpaidSub) {
        rows.push({ label: translate("resources.dashboard.unpaid"), info: nbrOfUnpaidSub })
    }

    return (
        <InfoTable2Col rows={rows} />
    )


}



const DeviceSubscriptionsSummary = ({ }) => {

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const translate = useTranslate();
    const [nbrOfActiveSub, setNbrOfActiveSub] = useState(10)
    const [nbrOfUnpaidSub, setNbrOfUnpaidSub] = useState(10)

    return null;

    /*
    return (

        <Grid
            item
            sm={12}
            md={6}
            sx={{
                width: "100%",
                margin: isSmall ? "8px 0px 0px 0px" : '0px',
            }} >
            <DashboardCard
                HeaderIcon={() => <Icon color={theme.palette.primary.dark} />}
                title={translate("resources.dashboard.titles.device_subscriptions_summary")}
                BodyComponent={() => <DeviceSubscriptionsSummaryBodyComponent nbrOfActiveSub={nbrOfActiveSub} nbrOfUnpaidSub={nbrOfUnpaidSub} />}
                />
        </Grid>
    )
    */
}

export default DeviceSubscriptionsSummary;