import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { Button, CircularProgress, useTheme } from '@mui/material'
import withStyles from '@mui/styles/withStyles';
import classnames from 'classnames'
import { useNotify, } from 'react-admin'

const styles = {
    button: {
        margin: '10px 24px',
        position: 'relative',
    },
    iconPaddingStyle: {
        paddingRight: '0.5em',
    },
}

const sanitizeRestProps = ({

    saving,
    label,
    invalid,
    variant,
    handleSubmitWithRedirect,
    submitOnEnter,
    redirect,
    locale,
    ...rest
}) => rest



const NextButton = (props) => {
    const [saving, setSaving] = useState(false);
    const notify = useNotify();

    const handleClick = (e) => {

        const { handleSubmitWithRedirect, invalid, redirect } = props;

        if (saving) {
            // Prevent double submission
            e.preventDefault();
        } else {
            if (invalid) {
                notify('ra.message.invalid_form', 'warning');
            }

            // Always submit form explicitly regardless of button type
            if (e) {
                e.preventDefault();
            }

            handleSubmitWithRedirect(redirect);
        }
    };

    const {
        className,
        classes = {},
        disabled,
        invalid,
        label = 'ra.action.save',
        pristine,
        submitOnEnter,
        variant = 'contained',
        ...rest
    } = props;

    const type = submitOnEnter ? 'submit' : 'button';
    return (
        <Button
            sx={{ margin: '10px 24px', position: 'relative' }}
            className={className}
            variant={variant}
            type={type}
            disabled={disabled}
            onClick={(e) => handleClick(e)}
            color={saving ? 'default' : 'primary'}
            {...sanitizeRestProps(rest)}
        >
            {saving && saving.redirect === props.redirect ? (
                <CircularProgress size={25} thickness={2} sx={{ paddingRight: '0.5em' }} />
            ) : null}
            {label}
        </Button>
    );
};

NextButton.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    handleSubmitWithRedirect: PropTypes.func,
    invalid: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    pristine: PropTypes.bool,
    redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    submitOnEnter: PropTypes.bool,
    variant: PropTypes.oneOf(['contained', 'text', 'fab']),
};

NextButton.defaultProps = {
    handleSubmitWithRedirect: () => { },
    disabled: false,
};

export default NextButton;




/*
class NextButton extends Component {
    handleClick = e => {
        const { handleSubmitWithRedirect, invalid, redirect } = this.props
        const [saving, setSaving] = useState(false);
        const notify = useNotify();
        if (saving) {
            // prevent double submission
            e.preventDefault()
        } else {
            if (invalid) {
                notifyotify('ra.message.invalid_form', 'warning')
            }
            // always submit form explicitly regardless of button type
            if (e) {
                e.preventDefault()
            }

            //handleSubmitWithRedirect(redirect)()

            handleSubmitWithRedirect(redirect) // 13/01/22
        }
    }
    render() {
        const {
            className,
            classes = {},
            disabled,
            invalid,
            label = 'ra.action.save',
            pristine,
            redirect,
            submitOnEnter,
            variant = 'contained',
            ...rest
        } = this.props
        const [saving, setSaving] = useState(false);
        const type = submitOnEnter ? 'submit' : 'button'
        return (
            <Button
                sx={{margin: '10px 24px',
                position: 'relative'}}
                className={ className}
                variant={variant}
                type={type}
                disabled={disabled}
                onClick={e => this.handleClick(e)}
                color={saving ? 'default' : 'primary'}
                {...sanitizeRestProps(rest)}
            >
                {saving && saving.redirect === redirect ? (
                    <CircularProgress size={25} thickness={2} sx={{paddingRight: '0.5em'}} />
                ) : null}
                {label}
            </Button>
        )
    }
}

NextButton.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    handleSubmitWithRedirect: PropTypes.func,
    invalid: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    pristine: PropTypes.bool,
    redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    saving: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    submitOnEnter: PropTypes.bool,
    variant: PropTypes.oneOf(['contained', 'text', 'fab']),
}

NextButton.defaultProps = {
    handleSubmitWithRedirect: () => () => { },
    disabled: false,
}
const mapStateToProps = state => ({
    saving: state.admin.saving,
})

const enhance = compose(
    connect(
        mapStateToProps,
    ),
)
  
export default enhance(NextButton)
export default NextButton
*/