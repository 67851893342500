import React from 'react'
import Chip from '@mui/material/Chip'
import { useTheme } from '@mui/material'






const getVariantFromValue = (value) => {
    const successValues = ["validated", "good", "paid", "success", "public"]
    const warningValues = ["warning", "pending", "waiting", "waiting_payment", "private"]
    const errorValues = ["error", "failed", "rejected"]
    if (successValues.includes(value)) {
        return 'success'
    }
    if (errorValues.includes(value)) {
        return 'error'
    }
    if (warningValues.includes(value)) {
        return 'warning'
    }
    return null
}


const CustomChip = ({ label, value, variant: variantParam }) => {

    const theme = useTheme();

    let variant = variantParam ? variantParam : getVariantFromValue(value);

    if (variant === 'success') {
        return (<Chip
            label={label}
            size="small"
            sx={{
                height: "24px",
                width: "55px",
                fontSize: "10px",
                fontWeight: 500,
                bgcolor: 'success.light',
                color: 'success.dark'
            }}
        />
        )

    }

    if (variant === 'error') {
        return (<Chip
            label={label}
            size="small"
            sx={{
                height: "24px",
                width: "55px",
                fontSize: "10px",
                fontWeight: 500,
                bgcolor: 'error.light',
                color: 'error.dark'
            }}
        />
        )

    }

    if (variant === 'warning') {
        return (
            <Chip
                label={label}
                size="small"
                sx={{
                    height: "24px",
                    width: "55px",
                    fontSize: "10px",
                    fontWeight: 500,
                    bgcolor: 'warning.light',
                    color: theme.palette.mode === 'light' ? theme.palette.grey[900] : theme.palette.common.black

                }}
            />
        )

    }

    return (
        <Chip
            label={label}
            size="small"
            sx={{
                height: "24px",
                width: "55px",
                fontSize: "10px",
                fontWeight: 500,
                bgcolor: theme.palette.mode === 'light' ? 'background.default' : 'dark.main',
                color: 'text.dark'
            }}
        />
    )


    return null

}


export default CustomChip

