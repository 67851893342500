import React, { useEffect, useState } from 'react'
import { Card, Typography } from '@mui/material'
import style from 'jss-material-ui'
import { Layout as RaLayout } from 'react-admin'
import { useTheme } from '@mui/material/styles';
import AppBar from './AppBar';
import {  useMediaQuery } from '@mui/material';
import Menu from './Menu'
import { useLocation } from 'react-router-dom';
import { getFirebaseAnalytics } from '../analytics/firebaseAnalytics';




export const Title = style(Typography)((theme, { fontSize, warning }) => ({
    root: {
        margin: 0,
        padding: 0,
        fontSize: [12, "!important"],
        color: '#2196f3'
    },
}))

export const TitlePlan = style(Card)((theme, { fontSize, warning }) => ({
    root: {
        fontWeight: 400,
        margin: 0,
        padding: 0,
        boxShadow: `0 3px 2px -2px ${theme.palette.secondary.light} !important`,
        color: warning === null ? orange['500'] : '#222',
        fontSize: fontSize !== undefined ? fontSize : 12,
        marginTop: 10,
        marginBottom: 10,
    },
}))

const customStyles = ({ theme, windowSize, isSmall }) => ({
    raLayout: {
        maxWidth: "100%",
        minWidth: "0px",
        backgroundColor: theme.palette.background.appFrame,
        'header': {
            backgroundColor: theme.palette.background.appFrame,
            backgroundImage: "none",
            margin: "0px",
            padding: "0px",
            height: "69px",
        },
        '& .RaAppBar-toolbar': {
            margin: "16px 20px 0px 20px",
            padding: "0px !important",
            height: "53px",
        },
        '& .MuiAppBar-positionFixed': {
            width: "100%",
            maringX: 0,
            paddingX: 0,
        },
        '& .RaLayout-appFrame': {
            marginTop: "69px",
            maxWidth: "100%",
            backgroundColor: theme.palette.background.appFrame,
        },
        "& .RaLayout-content": {
            backgroundColor: theme.palette.background.default,
            width: `${windowSize.innerWidth - 224}px`,
            padding: isSmall ?"15px":"20px",
            margin: isSmall ?"0px" : "20px 20px 20px 0px",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "0px",
            borderBottomLeftRadius: "0px",

        },
        "& .MuiDrawer-root.MuiDrawer-docked.RaSidebar-docked": {
            margin: "20px",
        }
    }
})

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

const Layout = (props) => {
    const location = useLocation();
    const theme = useTheme()
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const styles = customStyles({ theme, windowSize,isSmall })
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);



    useEffect(() => {
        const logPageView = () => {
          const analytics = getFirebaseAnalytics();
          if (analytics) {
            window.gtag('event', 'page_view', {
              page_path: location.pathname,
            });
          }
        };
        logPageView();
      }, [location]);

    return (
        <RaLayout sx={styles.raLayout} appBar={AppBar}  {...props} menu={Menu} />);
}



export default Layout;



