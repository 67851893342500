import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import { Tooltip, useTheme } from '@mui/material';



export const AddBtnWithOptionalMenu = ({
    menuItems, width, height, isMobile
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const theme = useTheme();
    const navigate = useNavigate();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const defaultWidth = isMobile ? "64" : "32";
    const defaultHeight = isMobile ? "64" : "32";
    const widthP = width ? width : defaultWidth;
    const heightP = height ? height : defaultHeight;

    if (!Array.isArray(menuItems) || menuItems.length === 0) {
        return null
    }
    if (menuItems.length === 1) {
        return (<AddBtn path={menuItems[0].path} label={menuItems[0].label} widthP={widthP} heightP={heightP} isMobile={isMobile} />)
    }

    return (
        <Box sx={{
            width: `${widthP}px`,
            height: `${heightP}px`,
            padding: '0px',
            margin: '0px',
            position: isMobile ? 'fixed' : null,
            bottom: isMobile ? '10px' : null,
            right: isMobile ? '10px' : null,
            zIndex: isMobile ? 100 : null,

        }}>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{
                    width: `${widthP}px`,
                    height: `${heightP}px`,
                    padding: '0px',
                    margin: '0px',
                    '&:hover': {
                        backgroundColor: 'transparent'
                    }
                    ,
                    '&:focus': {
                        backgroundColor: 'transparent'
                    }

                }}
            >
                <AddBtnIcon width={widthP} height={heightP} color={theme.palette.primary.main} />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}

            >

                {
                    menuItems.map((item, index) => {
                        return (
                            <MenuItem key={index} onClick={() => {
                                navigate(item.path)
                            }}>
                                {item.label}
                            </MenuItem>
                        )
                    })
                }

            </Menu>
        </Box>
    );
}





const AddBtnIcon = ({ width, height, color }) => {
    const widthP = width ? width : "32";
    const heightP = height ? height : "32";
    return (
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 32" width={widthP} height={heightP}>
            <path fill={color} cx="25" cy="25" r="25" d="M32 16A16 16 0 0 1 16 32A16 16 0 0 1 0 16A16 16 0 0 1 32 16z" />
            <path stroke="#FFFFFF" strokeWidth="2" fill="#FFFFFF" x1="25" y1="13" x2="25" y2="38" d="M16 12.32L16 20.32" />
            <path stroke="#FFFFFF" strokeWidth="2" x1="37.5" y1="25" x2="12.5" y2="25" d="M20 16L12 16" />
        </svg>
    )
}





const AddBtn = ({
    path,
    isMobile,
    widthP,
    heightP,
    label
}) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const handleClick = (event) => {
        event.preventDefault();
        navigate(path)
    };

    return (
        <Tooltip title={label} disableInteractive >
            <Box sx={{
                position: isMobile ? 'fixed' : null,
                bottom: isMobile ? '10px' : null,
                right: isMobile ? '10px' : null,
                zIndex: isMobile ? 100 : null,
                width: widthP? `${widthP}px`:'32px',
                height:heightP? `${heightP}px`: '32px',
                padding: '0px',
                margin: '0px',

            }}>
                <IconButton
                    label={label}
                    onClick={handleClick}
                    sx={{
                        width: widthP? `${widthP}px`:'32px',
                height:heightP? `${heightP}px`: '32px',
                        padding: '0px',
                        margin: '0px',
                        '&:hover': {
                            backgroundColor: 'transparent'
                        }
                        ,
                        '&:focus': {
                            backgroundColor: 'transparent'
                        }

                    }}
                >
                    <AddBtnIcon width={widthP} height={heightP} color={theme.palette.primary.main} />
                </IconButton>

            </Box>
        </Tooltip>
    );
}






