import { Grid, useTheme, useMediaQuery, Box, Avatar, Card, Tooltip, ButtonBase, CardContent, Typography } from "@mui/material"
import { useDataProvider, useTranslate } from 'react-admin'
import { useNavigate } from "react-router-dom";
import DashboardCard, { InfoTable2Col, InfoTable2ColWithTitle } from "../../ui/card/DashboardCard";
import { useEffect, useState } from "react";
import { getDevicesSummary } from "../../rest/restClient";






const Icon = ({ color }) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-devices">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M13 9a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-6a1 1 0 0 1 -1 -1v-10z" />
            <path d="M18 8v-3a1 1 0 0 0 -1 -1h-13a1 1 0 0 0 -1 1v12a1 1 0 0 0 1 1h9" />
            <path d="M16 9h2" />
        </svg>
    )

}



const NumberOfDevicesByProductTable = ({ caption,summary }) => {
    const translate = useTranslate();
    let rows = []
    if (summary.total_devices) {
        rows.push({ label: translate("resources.dashboard.number_of_devices_in_account.total_devices"), info: summary.total_devices })
    }
    if (summary.total_active_devices) {
        rows.push({ label: translate("resources.dashboard.number_of_devices_in_account.total_active_devices"), info: summary.total_active_devices })
    }
    if (summary.total_inactive_devices) {
        rows.push({ label: translate("resources.dashboard.number_of_devices_in_account.total_inactive_devices"), info: summary.total_inactive_devices })
    }
    
    
    return (<InfoTable2Col  rows={rows} lastCaption={caption} />   )
}


const numberOfDevicesByProducts = (devicesSummary ) => {
    const translate = useTranslate();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    let cards = []
    let caption = null

    

    if (devicesSummary &&
        devicesSummary.devices_by_products &&
        devicesSummary.devices_by_products.devices_by_product_summary &&
        Array.isArray(devicesSummary.devices_by_products.devices_by_product_summary)
    ) {
        if (devicesSummary.created_at) {
            caption = `${translate("resources.dashboard.number_of_devices_in_account.created_at")} ${new Date(devicesSummary.created_at).toLocaleString()}`
        }
        
        for (let index = 0; index < devicesSummary.devices_by_products.devices_by_product_summary.length; index++) {
            
            const element = devicesSummary.devices_by_products.devices_by_product_summary[index];
           
            cards.push(
                <Grid
                item
                sm={12}
                md={4}
                lg={3}
                sx={{
                    width: "100%",
                    margin: isSmall ? "8px 0px 0px 0px" : '0px',
                }} >
                <DashboardCard HeaderIcon={() => <Icon color={theme.palette.primary.dark} />} title={`${translate("resources.dashboard.titles.number_of_devices_in_account")} ${element.marketing_name}`} BodyComponent={() => <NumberOfDevicesByProductTable summary={element} caption={caption} />}  />
            </Grid>
            
            )
        }
    }



    return (
        cards
        
    )
}

export default numberOfDevicesByProducts;