import React, { useEffect } from 'react'

import {
    Create,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
    useNotify,
    useRedirect,
    useTranslate,
} from 'react-admin'
import { Typography, useTheme } from '@mui/material';
import { RO_ID } from '../../configuration/actions';
import styledFormInputs from '../../../ui/inputs/styledFormInputs';
import { isSuperAdmin } from '../../../helper/resourceHelper';
import { useFormContext } from 'react-hook-form';
import NavigateBackBtn from '../../../ui/button/NavigateBackBtn';



const validateRequired = [required('Champs obligatoire')]

const InputDefaultValuesHelper = () => {
    const { getValues, setValue } = useFormContext();

    useEffect(() => {

        setTimeout(() => {
            setValue('root_organization_id', localStorage.getItem(RO_ID));
        }, 1000);

    }, [getValues, setValue]);

    return (
        <>
        </>
    );
};



const OrganizationCreate = ({ classes, ...props }) => {
    const translate = useTranslate();
    const theme = useTheme();
    const notify = useNotify();
    const redirect = useRedirect();
    const onSuccess = () => {
        notify('resources.organizations.creation_success');
        redirect('/organizations');
    }



    const inputsData = [
        { name: 'name', label: translate('resources.organization.name'), validate: validateRequired, type: "text" },
        { name: 'parent_organization_id', label: translate('resources.organization.parent'), validate: validateRequired, type: "reference", reference: "organizations", optionText: "name" },
        { name: 'description', label: translate('resources.organization.description'), type: "text" },
    ]

    if (isSuperAdmin()) {
        inputsData.push(
            { name: 'root_organization_id', label: translate('resources.organization.root'), validate: validateRequired, type: "reference", reference: "root_organizations", optionText: "name" },
        )
    } else {
        inputsData.push(
            { name: 'root_organization_id', label: translate('resources.organization.root'), type: "text", disabled: true },
        )
    }


    return (
        <>
            <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "24px", lineHeight: "42px", margin: "0px 0px 16px 0px" }}>
                <NavigateBackBtn /> {translate('resources.organizations.create')}
            </Typography>
            <Create
                title={'resources.organizations.create'}
                mutationOptions={{ onSuccess }}
                sx={{
                    "& .MuiPaper-root": {
                        backgroundColor: theme.palette.background.paper,
                        color: theme.palette.text.primary,
                    }
                }}
            >
                <SimpleForm >

                    {styledFormInputs(inputsData)}
                    <InputDefaultValuesHelper />

                </SimpleForm>
            </Create>
        </>
    )
}

export default OrganizationCreate
