import {
  RO_THEME_DARK_BACKGROUND_DEFAULT,
  RO_THEME_DARK_PRIMARY_DARK,
  RO_THEME_DARK_PRIMARY_LIGHT,
  RO_THEME_DARK_PRIMARY_MAIN,
  RO_THEME_LIGHT_BACKGROUND_DEFAULT,
  RO_THEME_LIGHT_PRIMARY_DARK,
  RO_THEME_LIGHT_PRIMARY_LIGHT,
  RO_THEME_LIGHT_PRIMARY_MAIN,
} from "../pages/configuration/actions";

// material-ui
import { alpha, createTheme } from "@mui/material/styles";

// project imports
import { ThemeMode } from "../config";

// assets
import defaultColor from "../assets/scss/_themes-vars.module.scss";

//merge the properties from obj2 into obj1 if the key exists in obj1.
function mergeDeep(obj1, obj2) {
  for (const key in obj1) {
    if (obj2.hasOwnProperty(key) && obj2[key] !== null) {
      if (typeof obj1[key] === "object" && typeof obj2[key] === "object") {
        obj1[key] = mergeDeep(obj1[key], obj2[key]);
      } else {
        obj1[key] = obj2[key];
      }
    }
  }
  return obj1;
}

const Palette = (mode) => {
  const defaultPalette = {
    mode,
    common: {
      black: defaultColor.darkPaper,
    },
    primary: {
      light:
        mode === ThemeMode.DARK
          ? defaultColor.darkPrimaryLight
          : defaultColor.primaryLight,
      main:
        mode === ThemeMode.DARK
          ? defaultColor.darkPrimaryMain
          : defaultColor.primaryMain,
      dark:
        mode === ThemeMode.DARK
          ? defaultColor.darkPrimaryDark
          : defaultColor.primaryDark,
      200:
        mode === ThemeMode.DARK
          ? defaultColor.darkPrimary200
          : defaultColor.primary200,
      800:
        mode === ThemeMode.DARK
          ? defaultColor.darkPrimary800
          : defaultColor.primary800,
    },
    secondary: {
      light:
        mode === ThemeMode.DARK
          ? defaultColor.darkSecondaryLight
          : defaultColor.secondaryLight,
      main:
        mode === ThemeMode.DARK
          ? defaultColor.darkSecondaryMain
          : defaultColor.secondaryMain,
      dark:
        mode === ThemeMode.DARK
          ? defaultColor.darkSecondaryDark
          : defaultColor.secondaryDark,
      200:
        mode === ThemeMode.DARK
          ? defaultColor.darkSecondary200
          : defaultColor.secondary200,
      800:
        mode === ThemeMode.DARK
          ? defaultColor.darkSecondary800
          : defaultColor.secondary800,
    },
    error: {
      light: defaultColor.errorLight,
      main: defaultColor.errorMain,
      dark: defaultColor.errorDark,
    },
    orange: {
      light: defaultColor.orangeLight,
      main: defaultColor.orangeMain,
      dark: defaultColor.orangeDark,
    },
    warning: {
      light: defaultColor.warningLight,
      main: defaultColor.warningMain,
      dark: defaultColor.warningDark,
    },
    success: {
      light: defaultColor.successLight,
      200: defaultColor.success200,
      main: defaultColor.successMain,
      dark: defaultColor.successDark,
    },
    grey: {
      50: defaultColor.grey50,
      100: defaultColor.grey100,
      500:
        mode === ThemeMode.DARK
          ? defaultColor.darkTextSecondary
          : defaultColor.grey500,
      600:
        mode === ThemeMode.DARK
          ? defaultColor.darkTextTitle
          : defaultColor.grey600,
      700:
        mode === ThemeMode.DARK
          ? defaultColor.darkTextPrimary
          : defaultColor.grey700,
      900:
        mode === ThemeMode.DARK
          ? defaultColor.darkTextPrimary
          : defaultColor.grey900,
    },
    dark: {
      light: defaultColor.darkTextPrimary,
      main: defaultColor.darkLevel1,
      dark: defaultColor.darkLevel2,
      800: defaultColor.darkBackground,
      900: defaultColor.darkPaper,
    },
    text: {
      primary:
        mode === ThemeMode.DARK
          ? defaultColor.darkTextPrimary
          : defaultColor.grey700,
      secondary:
        mode === ThemeMode.DARK
          ? defaultColor.darkTextSecondary
          : defaultColor.grey500,
      dark:
        mode === ThemeMode.DARK
          ? defaultColor.darkTextPrimary
          : defaultColor.grey900,
      hint: defaultColor.grey100,
    },
    divider:
      mode === ThemeMode.DARK
        ? alpha(defaultColor.grey200, 0.2)
        : defaultColor.grey200,
    background: {
      default: mode === ThemeMode.DARK ? "#1a223f" : "#eff4fa",
      paper: mode === ThemeMode.DARK ? "#212946" : "#fff",
      appFrame: mode === ThemeMode.DARK ? "#111936" : "#fff",
    },
  };

  let roPalette = {
    mode,
    primary: {
      dark:
        mode === ThemeMode.DARK
          ? localStorage.getItem(RO_THEME_DARK_PRIMARY_DARK)
          : localStorage.getItem(RO_THEME_LIGHT_PRIMARY_DARK),
      main:
        mode === ThemeMode.DARK
          ? localStorage.getItem(RO_THEME_DARK_PRIMARY_MAIN)
          : localStorage.getItem(RO_THEME_LIGHT_PRIMARY_MAIN),
      light:
        mode === ThemeMode.DARK
          ? localStorage.getItem(RO_THEME_DARK_PRIMARY_LIGHT)
          : localStorage.getItem(RO_THEME_LIGHT_PRIMARY_LIGHT),
    },

    background: {
      default:
        mode === ThemeMode.DARK
          ? null
          : localStorage.getItem(RO_THEME_LIGHT_BACKGROUND_DEFAULT),
    },
  };


  const mergedPalette = mergeDeep(defaultPalette, roPalette);

  return mergedPalette;
};

export default Palette;

/*
const roColors =  {
    // paper & background
    paper: $paper;

    // primary
    primaryLight: $primaryLight;
    primary200: $primary200;
    primaryMain: $primaryMain;
    primaryDark: $primaryDark;
    primary800: $primary800;

    // secondary
    secondaryLight: $secondaryLight;
    secondary200: $secondary200;
    secondaryMain: $secondaryMain;
    secondaryDark: $secondaryDark;
    secondary800: $secondary800;

    // success
    successLight: $successLight;
    success200: $success200;
    successMain: $successMain;
    successDark: $successDark;

    // error
    errorLight: $errorLight;
    errorMain: $errorMain;
    errorDark: $errorDark;

    // orange
    orangeLight: $orangeLight;
    orangeMain: $orangeMain;
    orangeDark: $orangeDark;

    // warning
    warningLight: $warningLight;
    warningMain: $warningMain;
    warningDark: $warningDark;

    // grey
    grey50: $grey50;
    grey100: $grey100;
    grey200: $grey200;
    grey300: $grey300;
    grey500: $grey500;
    grey600: $grey600;
    grey700: $grey700;
    grey900: $grey900;

    // ==============================|| DARK THEME VARIANTS ||============================== //

    // paper & background
    darkPaper: $darkPaper;
    darkBackground: $darkBackground;

    // dark 800 & 900
    darkLevel1: $darkLevel1;
    darkLevel2: $darkLevel2;

    // text variants
    darkTextTitle: $darkTextTitle;
    darkTextPrimary: $darkTextPrimary;
    darkTextSecondary: $darkTextSecondary;

    // primary dark
    darkPrimaryLight: $darkPrimaryLight;
    darkPrimaryMain: $darkPrimaryMain;
    darkPrimaryDark: $darkPrimaryDark;
    darkPrimary200: $darkPrimary200;
    darkPrimary800: $darkPrimary800;

    // secondary dark
    darkSecondaryLight: $darkSecondaryLight;
    darkSecondaryMain: $darkSecondaryMain;
    darkSecondaryDark: $darkSecondaryDark;
    darkSecondary200: $darkSecondary200;
    darkSecondary800: $darkSecondary800;
}






const palette= {
    mode: 'light',

    primary: {
      dark: localStorage.getItem(RO_THEME_LIGHT_PRIMARY_DARK) || "#268fe5",
      main: localStorage.getItem(RO_THEME_LIGHT_PRIMARY_MAIN) || "#56b2ff",
      light1: localStorage.getItem(RO_THEME_LIGHT_PRIMARY_LIGHT1) || "#9cd2ff",
      light2: localStorage.getItem(RO_THEME_LIGHT_PRIMARY_LIGHT2) || "#d2eaff",
      light3: localStorage.getItem(RO_THEME_LIGHT_PRIMARY_LIGHT3) || "#f3faff",

    },

    background: {
      default: localStorage.getItem(RO_THEME_LIGHT_BACKGROUND_DEFAULT) || "#eff4fa",
      paper: localStorage.getItem(RO_THEME_LIGHT_BACKGROUND_PAPER) || "#fff",
      appFrame: localStorage.getItem(RO_THEME_LIGHT_BACKGROUND_APPFRAME) || "#fff"
    }

}


*/
