import React from 'react'
import {
    Create,
    email,
    required,
    SimpleForm,
    useNotify,
    useRedirect,
    useTranslate,
} from 'react-admin'
import { ROLE_ORGA_ADMIN, ROLE_ORGA_USER, ROLE_USER, USER_ORGANIZATION_ID } from '../../configuration/actions'
import { Typography, useTheme } from '@mui/material';
import styledFormInputs from '../../../ui/inputs/styledFormInputs';
import NavigateBackBtn from '../../../ui/button/NavigateBackBtn';


const validateRequired = [required('Champs obligatoire')]
const validateEmail = [required('Champs obligatoire'), email('Syntaxe incorrecte')]

const UserInvite = () => {

    const translate = useTranslate();
    const theme = useTheme();
    const notify = useNotify();
    const redirect = useRedirect();
    const onSuccess = () => {
        notify('resources.users.invite_success');
        redirect('/users');
    }

    const inputsData = [
        { name: 'email', label: translate('resources.users.email'), validate: validateEmail, type: "text" },
        { name: 'role', label: translate('resources.users.role'), validate: validateRequired, type: "select", choices: roleChoices, },
        { name: 'organization_id', label: translate('resources.organization.organization'), validate: validateRequired, type: "reference", reference: "organizations", optionText: "name" },
    ]



    return (
        <>
            <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "24px", lineHeight: "42px", margin: "0px 0px 16px 0px" }}>
                <NavigateBackBtn /> {translate('resources.users.invite_action')}
            </Typography>
        <Create
            title={'resources.users.invite_action'}
            resource="users_invite"
            mutationOptions={{ onSuccess }}
            sx={{
                backgroundColor: theme.palette.background.paper,
            }}

        >
            <SimpleForm >


                {styledFormInputs(inputsData)}


            </SimpleForm>
        </Create>
       </>
    )
}

const roleChoices = [
    { id: ROLE_ORGA_USER, name: 'resources.role.member' },
    { id: ROLE_ORGA_ADMIN, name: 'resources.role.admin' },
    { id: ROLE_USER, name: 'resources.role.owner' },
]

export default UserInvite