import React from 'react'
//react-admin
import { useTranslate, List, GET_LIST } from 'react-admin'
import SensorsHistoryActions from './actions'
import { deviceName } from '../../rest/restClient'
import { getDeviceIdFromUrl } from '../../utils/utils'
import { useEffect, useState } from 'react'
import { Box, Paper, Typography } from '@mui/material'
import SensorCharts from './sensorCharts'
import SensorTable from './sensorTable'
import { AqiHelpLegendComponent } from './helpLegendComponents'
import { useParams } from 'react-router-dom';
import { useTheme, Grid, useMediaQuery } from '@mui/material';
import PageHeader from '../../ui/layout/PageHeader'
import NavigateBackBtn from '../../ui/button/NavigateBackBtn'
import dataProviderFactory from '../../dataProvider'





const customStyles = ({ theme, isSmall }) => ({
    containerGridWithoutBorder: {
        backgroundColor: theme.palette.background.default,
        boxShadow: "none",
        width: "100%",
        margin: '0px',
    },
    itemGrid: {
        width: "100%",
        padding: isSmall ? "0px 0px 8px 0px" : "0px 8px 8px 0px"
    },

    list: {
        width: "100%",
        '& .RaList-main': {
            width: "100%",
        },
        '& .MuiToolbar-root': {
            backgroundColor: theme.palette.background.default
        },
        '& .RaList-content': {
            backgroundColor: theme.palette.background.default,
            boxShadow: "none !important"
        }
    },

    chartList: {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
        },
        "& .MuiPaper-elevation1": {
            "box-shadow": "none",
        },
        "& .MuiToolbar-root": {
            "display": "none",
        },
        margin: 0,
        height: "100%"


    },
    icons: { marginRight: '1rem' }
})


const Empty = ({ label, setEmptyList }) => {
    const theme = useTheme();
    const translate = useTranslate();
    useEffect(() => {
        setEmptyList(true)
    }, [])
    return (
        <Paper elevation={0} sx={{
            backgroundColor: `${theme.palette.background.paper} !important`,
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            margin: 0,
            borderRadius: "12px",
            padding: "20px",
            width: '100%',
            height: "100%"
        }}>
            <Box sx={{ width: "100%", height: "100%" }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', margin: "0px 0px 20px 0px" }}>
                    <Typography variant='body2' fontWeight="800" pt={"2px"} mb={"2px"} ml={"10px"}> {label}</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: "center", height: "100%", margin: "0px 0px 20px 0px" }}>
                    <Typography fontSize={"14px"} color={theme.palette.text.disabled} fontWeight={400} p={"0px 20px 40px 20px"} textAlign={"center"}> {translate("resources.measures.no_measures_of_this_type")}</Typography>
                </Box>
            </Box>

        </Paper>
    );
}





const SensorChartList = (props) => {
    const { device, unitToShow, showHelpLegend, HelpLegendComponent, type, yDomain, tickCount, referenceAreas, unit, label, key, show, showDangerThreshold, chartStyleLineColor, chartStyleUnitStr, chartStyleAvgColor, chartStyleDangerColor, chartStyleMaxColor, chartStyleMinColor, eventName, resource, allListsFilters } = props
    const theme = useTheme();
    const [emptyList, setEmptyList] = useState(false)
    const styles = customStyles({ theme });
    const chartOptions = {
        eventName: eventName,
        type: type,
        referenceAreas: referenceAreas,
        yDomain: yDomain,
        showHelpLegend: showHelpLegend,
        HelpLegendComponent: HelpLegendComponent,
        tickCount: tickCount,
        unitToShow: unitToShow,
        unit: unit,
        label: label,
        key: key,
        show: show,
        showDangerThreshold: showDangerThreshold,
        style: {
            lineColor: chartStyleLineColor,
            dangerColor: chartStyleDangerColor,
            maxColor: chartStyleMaxColor,
            minColor: chartStyleMinColor,
            avgColor: chartStyleAvgColor,
            unitStr: chartStyleUnitStr
        }
    }



    if (!show) return null
    if (emptyList) return null
    return (
        <Grid item xs={12} lg={6} sx={styles.itemGrid}>
            <List
                basePath={`/measures/${device.device_id}`}
                title={" "}
                filters={<div />}
                empty={<Empty label={label} setEmptyList={setEmptyList} />}
                filter={{
                    names: [eventName],
                    device_ids: device.device_id,
                    ...allListsFilters
                }}
                actions={<div></div>}
                sx={styles.chartList}
                resource={resource}
                pagination={false}
                perPage={25}
                sort={{
                    field: 'timestamp',
                    order: 'DESC',
                }}
                {...props}
            >
                <SensorCharts {...props} device={device} chartOptions={chartOptions} />

            </List>
        </Grid>
    )
}


const SensorTableList = (props) => {
    const theme = useTheme();
    const styles = customStyles({ theme });
    const [emptyList, setEmptyList] = useState(false)
    const { device, label, name, resource, allListsFilters, show } = props


    if (!show) return null
    if (emptyList) return null

    return (<Grid item xs={12} md={6} sx={styles.itemGrid}>

        <List
            basePath={`/measures/${device.device_id}`}
            title={" "}
            filters={<div />}
            empty={<Empty label={label} setEmptyList={setEmptyList}/>}
            filter={{
                names: [name],
                device_ids: device.device_id,
                ...allListsFilters
            }}
            actions={<div></div>}
            sx={styles.chartList}
            resource={resource}
            pagination={false}
            perPage={25}
            sort={{
                field: 'timestamp',
                order: 'DESC',
            }}
            {...props}
        >

            <SensorTable {...props} device={device} label={label} name={name} />

        </List>
    </Grid>
    )
}


const _SensorHistoryList = (props) => {
    const theme = useTheme();
    const [allListsFilters, setAllListsFilters] = useState({ size: 5 });
    const [device, setDevice] = useState(null);
    const translate = useTranslate();
    const { id } = useParams()
    const sensorsNames = ["humidity", "temperature", "co2", "battery", "pressure", "luminosity", "formaldehyde", "tvoc", "ammonia", "noise", "mox", "aqi", "pm1", "pm2p5", "pm10", "benzen", "presence", "door", "water_leak", "shock", "button", "motion"];
    const [sensorsToShowNames, setSensorsToShowNames] = useState(sensorsNames);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = customStyles({ theme, isSmall: isMobile });


    useEffect(async () => {

        const dataProvider = await dataProviderFactory('rest')
        dataProvider(GET_LIST, 'devices', {
            filter: { device_ids: getDeviceIdFromUrl(), includes: 'device_id,mac_address,name' },
        })
            .then(devices => {
                setDevice(devices.data[0])
            })

        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop limit exceeded') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });

    }, {})



    if (!device) return null;
    return (
        <div>
            <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "24px", lineHeight: "42px", margin: "0px 0px 16px 0px" }}>
                <NavigateBackBtn /> {translate('resources.title.measures.history')}
            </Typography>
            <PageHeader
                rows={[
                    [{ title: translate('resources.heading_messages.device_name'), message: device.name }, { title: translate('resources.heading_messages.device_id'), message: device.mac_address ? device.mac_address : device.serial_number }]
                ]}
                isSmall={isMobile}
            />
            <SensorsHistoryActions
                recordId={id}
                setAllListsFilters={setAllListsFilters}
                sensorsToShowNames={sensorsToShowNames}
                setSensorsToShowNames={setSensorsToShowNames}
                sensorsNames={sensorsNames}
                isMobile={isMobile}

            />
            <Grid container spacing={0} sx={styles.containerGridWithoutBorder} >

                <SensorChartList
                    {...props}
                    device={device}
                    type="AreaChart"
                    eventName="humidity"
                    resource="humidity_events"
                    label={translate('resources.humidity.head')}
                    key="humidity"
                    show={sensorsToShowNames.indexOf("humidity") > -1}
                    showDangerThreshold={false}
                    chartStyleLineColor="#2e6cc6"
                    chartStyleAvgColor="gray"
                    chartStyleMaxColor="red"
                    chartStyleMinColor="green"
                    allListsFilters={allListsFilters}


                />
                <SensorChartList
                    {...props}
                    device={device}
                    type="AreaChart"
                    eventName="temperature"
                    resource="temperature_events"
                    label={translate('resources.temperature.head')}
                    key={"temperature"}
                    show={sensorsToShowNames.indexOf("temperature") > -1}
                    showDangerThreshold={false}
                    chartStyleLineColor="#e54f48"
                    chartStyleAvgColor="gray"
                    chartStyleMaxColor="red"
                    chartStyleMinColor="green"
                    allListsFilters={allListsFilters}


                />
                <SensorChartList
                    {...props}
                    device={device}
                    type="AreaChart"
                    eventName="co2"
                    resource="co2_events"
                    label={translate('resources.co2.head')}
                    key="co2"
                    show={sensorsToShowNames.indexOf("co2") > -1}
                    showDangerThreshold={true}
                    chartStyleLineColor="#883a0f"
                    chartStyleAvgColor="gray"
                    chartStyleDangerColor="#f30000"
                    chartStyleMaxColor="red"
                    chartStyleMinColor="green"
                    allListsFilters={allListsFilters}


                />

                <SensorChartList
                    {...props}
                    device={device}
                    type="AreaChart"
                    eventName="battery"
                    resource="battery_events"
                    label={translate('resources.battery.head')}
                    key="battery"
                    yDomain={[0, 100]}
                    show={sensorsToShowNames.indexOf("battery") > -1}
                    showDangerThreshold={false}
                    chartStyleLineColor="#348b07"
                    chartStyleAvgColor="gray"
                    chartStyleMaxColor="red"
                    chartStyleMinColor="green"
                    allListsFilters={{...allListsFilters, unit:"PERCENT"}}
                    unitToShow="percent"


                />

                <SensorChartList
                    {...props}
                    device={device}
                    type="AreaChart"
                    eventName="battery"
                    resource="battery_events"
                    label={translate('resources.battery.head')}
                    key="battery"
                    show={sensorsToShowNames.indexOf("battery") > -1}
                    showDangerThreshold={false}
                    chartStyleLineColor="#348b07"
                    chartStyleAvgColor="gray"
                    chartStyleMaxColor="red"
                    chartStyleMinColor="green"
                    allListsFilters={{...allListsFilters, unit:"MILLIVOLT"}}
                    unitToShow="millivolt"

                />
                <SensorChartList
                    {...props}
                    device={device}
                    type="AreaChart"
                    eventName="pressure"
                    resource="pressure_events"
                    yDomain={[800, 1200]}
                    label={translate('resources.pressure.head')}
                    key="pressure"
                    show={sensorsToShowNames.indexOf("pressure") > -1}
                    showDangerThreshold={false}
                    chartStyleLineColor="#315169"
                    chartStyleAvgColor="gray"
                    chartStyleMaxColor="red"
                    chartStyleMinColor="green"
                    allListsFilters={allListsFilters}


                />
                <SensorChartList
                    {...props}
                    device={device}
                    type="AreaChart"
                    eventName="luminosity"
                    resource="luminosity_events"
                    label={translate('resources.luminosity.head')}
                    key="luminosity"
                    show={sensorsToShowNames.indexOf("luminosity") > -1}
                    showDangerThreshold={false}
                    chartStyleLineColor="#e69138"
                    chartStyleAvgColor="gray"
                    chartStyleMaxColor="red"
                    chartStyleMinColor="green"
                    allListsFilters={allListsFilters}


                />
                <SensorChartList
                    {...props}
                    device={device}
                    type="AreaChart"
                    eventName="formaldehyde"
                    resource="formaldehyde_events"
                    label={translate('resources.formaldehyde.head')}
                    key="formaldehyde"
                    show={sensorsToShowNames.indexOf("formaldehyde") > -1}
                    showDangerThreshold={false}
                    chartStyleLineColor="#0e2057"
                    chartStyleAvgColor="gray"
                    chartStyleMaxColor="red"
                    chartStyleMinColor="green"
                    allListsFilters={allListsFilters}

                />
                <SensorChartList
                    {...props}
                    device={device}
                    type="AreaChart"
                    eventName="tvoc"
                    resource="tvoc_events"
                    label={translate('resources.tvoc.head')}
                    key="tvoc"
                    show={sensorsToShowNames.indexOf("tvoc") > -1}
                    showDangerThreshold={false}
                    chartStyleLineColor="#9ca564"
                    chartStyleAvgColor="gray"
                    chartStyleMaxColor="red"
                    chartStyleMinColor="green"
                    allListsFilters={allListsFilters}

                />

                <SensorChartList
                    {...props}
                    device={device}
                    type="AreaChart"
                    eventName="ammonia"
                    resource="ammonia_events"
                    label={translate('resources.ammonia.head')}
                    key="ammonia"
                    show={sensorsToShowNames.indexOf("ammonia") > -1}
                    showDangerThreshold={false}
                    chartStyleLineColor="#1e2429"
                    chartStyleAvgColor="gray"
                    chartStyleMaxColor="red"
                    chartStyleMinColor="green"
                    allListsFilters={allListsFilters}

                />
                <SensorChartList
                    {...props}
                    device={device}
                    type="AreaChart"
                    eventName="noise"
                    resource="noise_events"
                    label={translate('resources.noise.head')}
                    key="noise"
                    show={sensorsToShowNames.indexOf("noise") > -1}
                    showDangerThreshold={false}
                    chartStyleLineColor="#57595b"
                    chartStyleAvgColor="gray"
                    chartStyleMaxColor="red"
                    chartStyleMinColor="green"
                    allListsFilters={allListsFilters}

                />
                <SensorChartList
                    {...props}
                    device={device}
                    type="AreaChart"
                    eventName="mox"
                    resource="mox_events"
                    label={translate('resources.mox.head')}
                    key="mox"
                    show={sensorsToShowNames.indexOf("mox") > -1}
                    showDangerThreshold={false}
                    chartStyleLineColor="#008080"
                    chartStyleAvgColor="gray"
                    chartStyleMaxColor="red"
                    chartStyleMinColor="green"
                    allListsFilters={allListsFilters}

                />
                <SensorChartList
                    {...props}
                    device={device}
                    type="BarChart"
                    referenceAreas={[
                        { yMin: 0, yMax: 2, color: "#008000" },
                        { yMin: 2, yMax: 3, color: "#66b266" },
                        { yMin: 3, yMax: 4, color: "#ffff00" },
                        { yMin: 4, yMax: 5, color: "#ffa500" },
                        { yMin: 5, yMax: 6, color: "#ff0000" },
                        { yMin: 6, yMax: 7, color: "#ff0000" }
                    ]}

                    yDomain={[0, 6]}
                    showHelpLegend={true}
                    HelpLegendComponent={<AqiHelpLegendComponent translate={translate} />}
                    tickCount={6}
                    eventName="aqi"
                    resource="aqi_events"
                    label={translate('resources.aqi.head')}
                    key="aqi"
                    show={sensorsToShowNames.indexOf("aqi") > -1}
                    showDangerThreshold={false}
                    chartStyleLineColor="#348b07"
                    chartStyleAvgColor="gray"
                    chartStyleMaxColor="red"
                    chartStyleMinColor="green"
                    allListsFilters={allListsFilters}

                />
                <SensorChartList
                    {...props}
                    device={device}
                    type="AreaChart"
                    eventName="pm1"
                    resource="pm1_events"
                    label={translate('resources.pm1.head')}
                    key="pm1"
                    show={sensorsToShowNames.indexOf("pm1") > -1}
                    showDangerThreshold={false}
                    chartStyleLineColor="#1a0000"
                    chartStyleAvgColor="gray"
                    chartStyleMaxColor="red"
                    chartStyleMinColor="green"
                    allListsFilters={allListsFilters}

                />
                <SensorChartList
                    {...props}
                    device={device}
                    type="AreaChart"
                    eventName="pm2p5"
                    resource="pm2p5_events"
                    label={translate('resources.pm2p5.head')}
                    key="pm2p5"
                    show={sensorsToShowNames.indexOf("pm2p5") > -1}
                    showDangerThreshold={false}
                    chartStyleLineColor="#1a0000"
                    chartStyleAvgColor="gray"
                    chartStyleMaxColor="red"
                    chartStyleMinColor="green"
                    allListsFilters={allListsFilters}

                />
                <SensorChartList
                    {...props}
                    device={device}
                    type="AreaChart"
                    eventName="pm10"
                    resource="pm10_events"
                    label={translate('resources.pm10.head')}
                    key="pm10"
                    show={sensorsToShowNames.indexOf("pm10") > -1}
                    showDangerThreshold={false}
                    chartStyleLineColor="#1a0000"
                    chartStyleAvgColor="gray"
                    chartStyleMaxColor="red"
                    chartStyleMinColor="green"
                    allListsFilters={allListsFilters}

                />

                <SensorChartList
                    {...props}
                    device={device}
                    type="AreaChart"
                    eventName="benzen"
                    resource="benzen_events"
                    label={translate('resources.benzen.head')}
                    key="benzen"
                    show={sensorsToShowNames.indexOf("benzen") > -1}
                    showDangerThreshold={false}
                    chartStyleLineColor="#000f5c"
                    chartStyleAvgColor="gray"
                    chartStyleMaxColor="red"
                    chartStyleMinColor="green"
                    allListsFilters={allListsFilters}

                />

                <SensorTableList
                    {...props}
                    device={device}
                    show={sensorsToShowNames.indexOf("presence") > -1}
                    label={translate('resources.presence.head')}
                    name="presence"
                    resource="presence_events"
                    allListsFilters={allListsFilters}
                />
                <SensorTableList
                    {...props}
                    device={device}
                    show={sensorsToShowNames.indexOf("door") > -1}
                    label={translate('resources.door.head')}
                    name="door"
                    resource="door_events"
                    allListsFilters={allListsFilters}
                />
                <SensorTableList
                    {...props}
                    device={device}
                    show={sensorsToShowNames.indexOf("water_leak") > -1}
                    label={translate('resources.water_leak.head')}
                    name="water_leak"
                    resource="water_leak_events"
                    allListsFilters={allListsFilters}
                />
                <SensorTableList
                    {...props}
                    device={device}
                    show={sensorsToShowNames.indexOf("shock") > -1}
                    label={translate('resources.shock.head')}
                    name="shock"
                    resource="shock_events"
                    allListsFilters={allListsFilters}
                />
                <SensorTableList
                    {...props}
                    device={device}
                    show={sensorsToShowNames.indexOf("battery") > -1}
                    label={translate('resources.battery_status.head')}
                    name="battery_status"
                    resource="battery_status_events"
                    allListsFilters={allListsFilters}
                />
                <SensorTableList
                    {...props}
                    device={device}
                    show={sensorsToShowNames.indexOf("button") > -1}
                    label={translate('resources.button.head')}
                    name="button"
                    resource="button_events"
                    allListsFilters={allListsFilters}
                />
                <SensorTableList
                    {...props}
                    device={device}
                    show={sensorsToShowNames.indexOf("motion") > -1}
                    label={translate('resources.motion.head')}
                    name="motion"
                    resource="motion_events"
                    allListsFilters={allListsFilters}
                />

            </Grid>


        </div >

    )

}

export default _SensorHistoryList
