import { useEffect, useState } from 'react'
import axios from 'axios'
import { Buffer } from "buffer";
import { generateCodeVerifier, generateCodeChallenge } from '../../utils/pkce';
import ConnectingDialog from '../../ui/dialog/ConnectingDialog';
import jwt_decode from 'jwt-decode'
import { AUTHORITIES, ROLE_SUPER_ADMIN, RO_APP_TITLE, RO_ID, USER_FAV_LANGUAGE, USER_FAV_LANGUAGE_IS_SET, USER_ID, USER_NAME, USER_ORGANIZATION_ID, USER_ORGANIZATION_NAME, USER_ACCESS_TOKEN, VERSION_CHECK_DATE, USER_ACCESS_TOKEN_EXPIRY_DATE, USER_REFRESH_TOKEN_EXPIRY_DATE, USER_REFRESH_TOKEN } from '../configuration/actions'
import { organizationName, rootOrganization } from '../../rest/restClient'
import { supportedLanguage } from '../../i18nProvider'



export const Login = () => {

    // 1 : generate codes  + request authorization code 
    useEffect(() => {
        const verifier = generateCodeVerifier();
        const codeChallenge = generateCodeChallenge(verifier);
        localStorage.setItem('codeVerifier', verifier);
        localStorage.setItem('codeChallenge', codeChallenge);
        const authServerUrl = process.env.REACT_APP_AUTH_URL;
        const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
        const redirectUri = encodeURIComponent(`${window.location.origin}/redirect`);
        const link = `${authServerUrl}/oauth2/authorize?response_type=code&client_id=${clientId}&scope=openid&redirect_uri=${redirectUri}&code_challenge=${codeChallenge}&code_challenge_method=S256&root_organization=${localStorage.getItem(RO_ID)}`;
        window.location.href = link;
    }, [])

    return (<ConnectingDialog open={true} />);
}

export const LoginRedirect = () => {

    const [dialogAlertSeverity, setDialogAlertSeverity] = useState("info");
    const [dialogAlertText, setDialogAlertText] = useState("Loading");
    const queryParameters = new URLSearchParams(window.location.search)


    // 2 : request token  
    useEffect(() => {
        if (queryParameters?.get('code')) {
            const code = queryParameters?.get('code');
            const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
            const clientSecret = process.env.REACT_APP_AUTH_CLIENT_SECRET;
            const authServerUrl = process.env.REACT_APP_AUTH_URL;
            const redirectUri = encodeURIComponent(`${window.location.origin}/redirect`);
            const headers = {
                'Content-type': 'application/x-www-form-urlencoded',
                'Authorization': `Basic ${Buffer.from(`${clientId}:${clientSecret}`).toString('base64')}`
            };
            const verifier = localStorage.getItem('codeVerifier');
            const url = `${authServerUrl}/oauth2/token?client_id=${clientId}&scope=openid&redirect_uri=${redirectUri}&grant_type=authorization_code&code=${code}&code_verifier=${verifier}`;

            axios.post(url, {}, { headers })
                .then(async (response) => {

                    if (response?.status === 200) {
                        const token = response.data;
                        loginSuccessWrapper({ accessToken: token.access_token, refreshToken: token.refresh_token, setDialogAlertText, setDialogAlertSeverity })

                    } else {
                        setDialogAlertSeverity("error");
                        setDialogAlertText("Error : token error !")
                        window.location.href = "/login"
                    }
                }).catch((err) => {
                    setDialogAlertSeverity("error");
                    setDialogAlertText("Error : token error !")
                    console.log(err);
                })


        } else {
            setDialogAlertSeverity("error");
            setDialogAlertText("Error : authorization code not found !")
        }
    }, []);


    return (<ConnectingDialog open={true} />);



}

export const LoginPage = () => {

    // redirect to login 
    useEffect(() => {

        window.location.pathname = "/init-login"

    }, [])

    return (<ConnectingDialog open={true} />);
}




const loginSuccessWrapper = ({ accessToken, refreshToken, setDialogAlertText, setDialogAlertSeverity }) => {

    const decodedAccessToken = jwt_decode(accessToken)
    const userName = decodedAccessToken.user_name
    const lang = decodedAccessToken.language
    const accessTokenExpiryDate = decodedAccessToken.exp
    const userId = decodedAccessToken.userId
    const organizationId = decodedAccessToken.organizationId
    const authorities = decodedAccessToken.authorities
    const userRootOrganization = decodedAccessToken.rootOrganizationId

    if (
        (authorities[0].toLowerCase() === ROLE_SUPER_ADMIN) ||

        (localStorage.getItem(RO_ID) &&
            (userRootOrganization === localStorage.getItem(RO_ID))
        )
    ) {
        // user allowed on root organization


        setDialogAlertSeverity("success");
        setDialogAlertText("Auth success...")

        localStorage.setItem(USER_ACCESS_TOKEN, accessToken)
        localStorage.setItem(USER_ACCESS_TOKEN_EXPIRY_DATE, accessTokenExpiryDate)
        localStorage.setItem(USER_REFRESH_TOKEN, refreshToken)
        localStorage.setItem(USER_ID, userId)
        localStorage.setItem(USER_ORGANIZATION_ID, organizationId)
        localStorage.setItem(USER_NAME, userName)
        localStorage.setItem(AUTHORITIES, authorities[0].toLowerCase())


        if (lang && supportedLanguage(lang) != null) {
            localStorage.setItem(USER_FAV_LANGUAGE, supportedLanguage(lang))
            localStorage.setItem(USER_FAV_LANGUAGE_IS_SET, false)
        }


        organizationName(organizationId)
            .then(organization => {
                localStorage.setItem(USER_ORGANIZATION_NAME, organization.json.name)
            })
            .catch(err => console.log(JSON.stringify(err)))


        setTimeout(() => {
            window.location.href = "/dashboard"
        }, 100)
    } else {
        // user not allowed on root organization

        setDialogAlertSeverity("error");
        setDialogAlertText(`Your account cannot be logged in ${localStorage.getItem(RO_APP_TITLE)} ! <br/> Please create a new account ...`)
        setTimeout(() => {
            logout({ token: accessToken, setDialogAlertSeverity, setDialogAlertText })
        }, 5000)
    }







}



const logout = ({ token, setDialogAlertText, setDialogAlertSeverity }) => {


    const authServerUrl = process.env.REACT_APP_AUTH_URL;
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    const url = `${authServerUrl}/logout_sso`;

    axios.get(url, { headers })
        .then(async (response) => {

            if (response?.status === 202) {
                window.location.href = "/login"


            } else {
                setDialogAlertSeverity("error");
                setDialogAlertText("22 Logout error. Please refresh page or contact ...")

            }
        }).catch((err) => {
            setDialogAlertSeverity("error");
            setDialogAlertText("22 Logout error. Please refresh page or contact ...")
        })

}
