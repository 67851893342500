
import { Divider, Box, useTheme, useMediaQuery,CardContent, Card, Typography, ButtonBase } from '@mui/material'
import { useTranslate } from 'react-admin';
import { getFormattedPrice } from '../../../helper/resourceHelper';




const CardSmsCreditPlan = ({
    plans,
    selectedPlan,
    setSelectedPlan
}) => {

    const translate = useTranslate();
    const theme = useTheme();
    const isSmall = useMediaQuery(
        theme => theme.breakpoints.down('sm'),
        { noSsr: true }
    );

    const selectedBg = theme.palette.mode === "light" ? ["linear-gradient(0deg,#FFFFFF,#FFFFFF)", `linear-gradient(180deg, rgba(33, 150, 243,0.2) 0%, rgba(255, 255, 255, 0.2) 91.89%)`] : ["linear-gradient(0deg, #111936, #111936)", `linear-gradient(180deg, rgba(33, 150, 243,0.2) 0%, rgba(33, 41, 70, 0.2) 91.89%);`]


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', margin: "0px 0px 0px 0px", padding: "20px" }}>
            <Typography
                color="text.main"
                sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "29.3px",
                    textAlign: "center",
                    margin: "0px 0px 10px 0px"
                }}
            >
                {translate('resources.devices.select_offer')}
            </Typography>
            


            <Box sx={{ display: 'flex', flexDirection: isSmall ? 'column' : 'row', alignItems: 'center', justifyContent: 'center', margin: "0px 0px 20px 0px", width: "100%" }}>
                {plans.sort((a, b) => a.amount - b.amount).map(function (plan, index) {
                    const translate = useTranslate();
                    const quantity = plan.sms_quantity
                    const price = getFormattedPrice(plan)
                    const isSelected = selectedPlan && selectedPlan.plan_id === plan.plan_id
                    return (
                        <ButtonBase
                            onClick={() => { setSelectedPlan(plan) }}
                        >
                            <Card sx={{
                                display: 'flex',
                                width: "200px",
                                height: "200px",
                                margin:isSmall ?"5px 0px 5px 0px" :  (index===0?"0px 0px 0px 0px":"0px 0px 0px 20px"),
                                border: (isSelected) ? 2 : 1,
                                borderRadius: "12px",
                                borderColor: (isSelected) ? 'primary.main' : 'text.secondary',
                                background: {xs: (isSelected) ? selectedBg : "none"}
                            }}>
                                <CardContent sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    margin: "0px",
                                    padding: "0px"
                                }}>
                                    <Typography
                                        color="text.main"
                                        sx={{
                                            fontFamily: "Roboto",
                                            fontSize: "14px",
                                            fontWeight: 500,
                                            lineHeight: "29.3px",
                                            textAlign: "center",
                                            margin: "0px 5px 3px 5px"


                                        }}
                                    >
                                        {plan.title}
                                    </Typography>
                                    <Divider sx={{
                                        width: "50px", borderWidth: "1px", borderColor: theme.palette.primary.main,
                                        margin: "0px 20px 3px 20px"
                                    }} />
                                    <Typography
                                        color="text.secondary"
                                        sx={{
                                            fontFamily: "Roboto",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            lineHeight: "20px",
                                            textAlign: "center",
                                            margin: "0px 0px 15px 0px"
                                        }}
                                    >
                                        {plan.description}
                                    </Typography>
                                    
                                    

                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'flex-end',
                                        justifyContent: 'center',
                                        margin: "0px",
                                        padding: "0px",
                                        width: "100%"
                                    }}>

                                        <Typography
                                            color={(isSelected) ? 'primary.dark' : 'text.secondary'}
                                            sx={{
                                                fontFamily: "Roboto",
                                                fontSize: "35px",
                                                fontWeight: 700,
                                                lineHeight: "62px",
                                                textAlign: 'end',
                                                verticalAlign: 'text-bottom',
                                                margin: "0px 2px 2px 0px"
                                            }}
                                        >
                                            {price}
                                        </Typography>
                                      
                                    </Box>
                                    <Typography
                                        color="text.secondary"
                                        sx={{
                                            fontFamily: "Roboto",
                                            fontSize: "12px",
                                            fontWeight: 400,
                                            lineHeight: "20px",
                                            textAlign: "center",
                                            margin: "0px 0px 15px 0px"
                                        }}
                                    >
                                        {"Inc.TAX"}
                                    </Typography>

                                </CardContent>
                            </Card >

                        </ButtonBase>)
                })
                }

            </Box >

        </Box>

    )


}



export default CardSmsCreditPlan
