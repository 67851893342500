import React, { cloneElement } from 'react'
import { Filter, Datagrid, useTranslate, FunctionField, List, TextInput, NumberField, Pagination, ReferenceField, TextField, useListContext, usePermissions, useLocaleState, DateField } from 'react-admin'
import { datagridCommonStyle, listCommonStyle, searchInputCommonStyle } from '../../../themes/commonStyles'
import { LIST_TYPE_TABLE } from '../../../ui/button/ListTypeButton';
import { useTheme, useMediaQuery, Box, Typography } from '@mui/material';
import CustomChip from '../../../ui/CustomChip';
import SearchInputLabelComponent from '../../../ui/inputs/SearchInputLabelComponent';
import AppOwnerInvoiceDownloadButton from '../../../ui/button/AppOwnerInvoiceDownloadButton';
import AppOwnerInvoicePayButton from '../../../ui/button/AppOwnerInvoicePayButton';
import AppOwnerInvoiceValidateButton from '../../../ui/button/AppOwnerInvoiceValidateButton';
import { isSuperAdmin } from '../../../helper/resourceHelper';
import LoadingDialog from '../../../ui/dialog/LoadingDialog';
import NavigateBackBtn from '../../../ui/button/NavigateBackBtn';


const AppOwnerInvoiceListActions = ({
    displayedFilters,
    filters,
    filterValues,
    hideFilter,
    showFilter
}) => {
    const { resource } = useListContext();

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row"

        }}>
            {filters &&
                cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    hideFilter,
                    filterValues,
                    context: 'button',
                })}


        </Box>
    )
}



const AppOwnerInvoiceListPagination = ({ }) => {
    const theme = useTheme()


    return (<Pagination sx={{
        '& .MuiToolbar-root': {
            borderTopRightRadius: "0px",
            borderTopLeftRadius: "0px",
            borderBottomRightRadius: "12px",
            borderBottomLeftRadius: "12px",
            marginTop: "0px",

        }

    }} rowsPerPageOptions={[10, 25, 50, 100, 500]} />)
};

const LoadingPopUp = ({ }) => {
    const { isPending, isFetching, isLoading } = useListContext();
    return (
        <LoadingDialog
            isPending={isPending}
            isFetching={isFetching}
            isLoading={isLoading}
        />
    )

};

const AppOwnerInvoiceList = props => {
    const [locale, setLocale] = useLocaleState()
    const theme = useTheme()
    const translate = useTranslate()
    return (
        <>
            <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "24px", lineHeight: "42px", margin: "0px 0px 16px 0px" }}>
                <NavigateBackBtn /> {translate('resources.app_owner_invoices.name', { smart_count: 2 })}
            </Typography>

            <List
                actions={<AppOwnerInvoiceListActions />}
                filter={{}}
                filters={
                    <Filter  {...props}>
                        <TextInput
                            id="outlined-basic-size-small"
                            label={<SearchInputLabelComponent />}
                            source="q"
                            alwaysOn
                            sx={searchInputCommonStyle({ theme })}
                        />
                    </Filter>
                }
                sx={listCommonStyle({ isLargeOrLess: true, listFormat: LIST_TYPE_TABLE, theme })}
                perPage={25}
                pagination={false}
            >
                <Datagrid
                    bulkActionButtons={false}
                    size='small'
                    sx={datagridCommonStyle({ theme })}
                >
                    <TextField source="invoice_number" />
                    <DateField source="creation_date" />


                    <FunctionField
                        label={translate('resources.app_owner_invoices.fields.invoices_details_total_amount')}
                        render={record => record.invoices_details_total_amount && record.currency ? `${record.invoices_details_total_amount} ${record.currency}` : null}
                        sortable={false}
                    />
                    <FunctionField
                        label={translate('resources.app_owner_invoices.fields.invoices_details_total_amount_without_tax')}
                        render={record => record.invoices_details_total_amount_without_tax && record.currency ? `${record.invoices_details_total_amount_without_tax} ${record.currency}` : null}
                        sortable={false}
                    /><FunctionField
                        label={translate('resources.app_owner_invoices.fields.invoices_details_total_net_root_org')}
                        render={record => record.invoices_details_total_net_root_org && record.currency ? `${record.invoices_details_total_net_root_org} ${record.currency}` : null}
                        sortable={false}
                    /><FunctionField
                        label={translate('resources.app_owner_invoices.fields.invoices_details_total_fee_amount_without_tax')}
                        render={record => record.invoices_details_total_fee_amount_without_tax && record.currency ? `${record.invoices_details_total_fee_amount_without_tax} ${record.currency}` : null}
                        sortable={false}
                    />




                    <TextField source="period" />

                    <FunctionField
                        label={translate('resources.app_owner_invoices.fields.status')}
                        render={record => record.status ? <CustomChip label={translate(`resources.app_owner_invoices.status.${record.status}`)} value={record.status} /> : null}
                        sortable={false}
                    />

                    <DateField source="payment_date" />

                    <ReferenceField source="root_organization_id" reference="root_organizations" allowEmpty>
                        <FunctionField
                            render={record => record.name ? record.name.toUpperCase() : null}
                            sortable={false}
                        />
                    </ReferenceField>

                    <FunctionField
                        label={translate('resources.common.actions', { smart_count: 1 })}
                        render={record =>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center'
                                }}>

                                <AppOwnerInvoiceDownloadButton disabled={false} record={record} />
                                {isSuperAdmin() &&
                                    <AppOwnerInvoiceValidateButton disabled={false} record={record} />}
                                {isSuperAdmin() &&
                                    <AppOwnerInvoicePayButton disabled={false} record={record} />}
                            </Box>


                        }
                    />

                </Datagrid>
                <AppOwnerInvoiceListPagination />
                <LoadingDialog />
            </List>
        </>
    )

}

export default AppOwnerInvoiceList

