import React from 'react';
import { Link } from 'react-router-dom';
import { useListContext, useRecordContext, useTranslate } from 'react-admin';
import { Tooltip, useTheme } from '@mui/material'
import { listActionsColumnIconCommonStyle } from '../../themes/commonStyles';



const Icon = ({ color }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_73_129339)">
                <path d="M17.2583 5.86667C17.5833 5.54167 17.5833 5.01667 17.2583 4.69167L15.3083 2.74167C15.1417 2.575 14.9333 2.5 14.7167 2.5C14.5 2.5 14.2917 2.58333 14.1333 2.74167L12.6083 4.26667L15.7333 7.39167L17.2583 5.86667ZM2.5 14.375V17.5H5.625L14.8417 8.28333L11.7167 5.15833L2.5 14.375ZM4.93333 15.8333H4.16667V15.0667L11.7167 7.51667L12.4833 8.28333L4.93333 15.8333Z" fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_73_129339">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}
const EditButton = ({
    basePath = '',
    disabled,
    ...rest
}) => {
    const translate = useTranslate();
    const { resource } = useListContext();
    const record = useRecordContext();
    const theme = useTheme();
    const isLightMode = theme.palette.mode === 'light';
    const disabledColor = isLightMode ? theme.palette.grey[400] : theme.palette.grey[500];

    return (

        <Tooltip title={disabled ? "" : translate(`ra.action.edit`)} disableInteractive >

            <Link
                disabled
                to={disabled ? `/${resource}` : `/${resource}/${record.id}`}
                style={listActionsColumnIconCommonStyle({ isLastItem: false })}>
                <Icon color={disabled ? disabledColor : theme.palette.primary.main} />
            </Link>
        </Tooltip>
    );
}



export default EditButton;













