// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { RO_FIREBASE_API_KEY,
    RO_FIREBASE_AUTH_DOMAIN,
    RO_FIREBASE_PROJECT_ID,
    RO_FIREBASE_STORAGE_BUCKET,
    RO_FIREBASE_MESSAGING_SENDER_ID,
    RO_FIREBASE_APP_ID,
    RO_FIREBASE_MEASUREMENT_ID

 } from "../pages/configuration/actions";



let analytics = null



export const getFirebaseAnalytics = () => {


    if(!analytics && localStorage.getItem(RO_FIREBASE_APP_ID)){
        const firebaseConfig = {
            apiKey: localStorage.getItem(RO_FIREBASE_API_KEY),
            authDomain: localStorage.getItem(RO_FIREBASE_AUTH_DOMAIN),
            projectId: localStorage.getItem(RO_FIREBASE_PROJECT_ID),
            storageBucket: localStorage.getItem(RO_FIREBASE_STORAGE_BUCKET),
            messagingSenderId: localStorage.getItem(RO_FIREBASE_MESSAGING_SENDER_ID),
            appId: localStorage.getItem(RO_FIREBASE_APP_ID),
            measurementId: localStorage.getItem(RO_FIREBASE_MEASUREMENT_ID)
        };

        // Initialize Firebase and Analytics
        const app = initializeApp(firebaseConfig);
        analytics = getAnalytics(app);
        return analytics;
    }

    return analytics
   
   
}


