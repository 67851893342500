import 'babel-polyfill';
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { StyledEngineProvider } from '@mui/material/styles';


ReactDOM.render(<StyledEngineProvider injectFirst>
  <App />
</StyledEngineProvider>, document.getElementById('root'));
registerServiceWorker();
