import React, { useEffect, useRef, useState } from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import Autocomplete from '@mui/material/Autocomplete';
import {
    BooleanInput,
    TopToolbar,
    DateTimeInput,
    DeleteButton,
    SaveButton,
    Edit,
    FormDataConsumer,
    FormTab,
    SimpleForm,
    ListButton,
    number,
    maxValue,
    minValue,
    NumberInput,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    SelectInput,
    TabbedForm,
    TextInput,
    Toolbar,
    useTranslate,
    useLocale,
    minLength,
    maxLength,
    TextField,
    useEditContext,
    AutocompleteInput,
    useEditController,
    useRecordContext,
    useRefresh,
    useNotify,
    useLocaleState,
    useDataProvider
} from 'react-admin'
import { hasWriteAccess, isAdminPermissions, isSuperAdmin, isXPressOrXTrakR, isXTrak, isXTrakS, isCo2Sensor, isYabby, isTiLocker, isEgIot, isQueclink, isOyster, isDeviceWithConfigurationPlan, hasDeviceConfiguration, isUser } from '../../../helper/resourceHelper'
import { Avatar } from '../../../ui/Avatar'
import ConfigProfilCard from '../../../ui/card/ConfigProfilCard'
import { formFetch, getImage, deviceWithProduct } from '../../../rest/restClient'
import withStyles from '@mui/styles/withStyles';
import { SaveDeviceButton } from './SaveDeviceButton'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import styledFormInputs from '../../../ui/inputs/styledFormInputs';
import { SaveDeviceConfigButton } from './SaveDeviceConfigButton';
import NavigateBackBtn from '../../../ui/button/NavigateBackBtn';
import PageHeader from '../../../ui/layout/PageHeader';




const styles = {
    geolocation_period: { width: 290, marginRight: '1rem' },
    retry_period: { display: 'inline-block', width: 290 },
    bluetooth_advertising: { display: 'inline-flex', marginRight: '1rem' },
    bluetooth_advertising_frequency: { display: 'inline-flex', width: 330 },
    send_message_in_motion: { display: 'inline-flex', width: 310 },
    temperature_low_limit: { display: 'inline-block', marginRight: '1rem' },
    temperature_up_limit: { display: 'inline-flex', marginRight: '1rem' },
    humidity_low_limit: { display: 'inline-block', marginRight: '1rem' },
    humidity_up_limit: { display: 'inline-flex', marginRight: '1rem' },
    yabby_configuration_profil: { alignItems: 'center', display: 'flex', justifyContent: 'flex-start', marginRight: '1rem', marginTop: '0.5em' },
    //yabby_configuration_select: { verticalAlign: 'middle', display: 'inline-flex', marginRight: '1rem' },
    co2_alert_treshold: { display: 'inline-flex', marginRight: '1rem', marginLeft: '2rem', width: 320 },
    co2_offset: { display: 'inline-flex', marginRight: '1rem', marginLeft: '2rem', width: 320 },
    co2_send_in_interval: { display: 'inline-flex', marginRight: '1rem', marginLeft: '2rem', width: 320 },
    co2_extrameasurementcount: { display: 'inline-flex', marginRight: '1rem', marginLeft: '2rem', width: 320 },
    margin: { display: 'inline-flex', marginRight: '1rem' },
    marginTop: { marginTop: '1rem' },
    unlock_requested: { display: 'inline-flex', marginRight: '1rem', marginLeft: '2rem', width: 320 },
    unlock_request_date_time: { display: 'inline-flex', marginRight: '1rem', marginLeft: '2rem', width: 320 },
    tilockerPatchBox: { display: 'flex', flex: 1, flexDirection: 'column', marginRight: '1rem', marginLeft: '2rem' },
    tilockerInfoBox: { display: 'flex', flex: 1, flexDirection: 'column', marginRight: '1rem', marginLeft: '2rem' },
    tilockerBox: { display: 'flex', flexDirection: 'row' },
}






const validateRequired = [required('Champs obligatoire')]




const DeviceEditToolbar = ({ record, ...props }) => {

    const translate = useTranslate();

    return (
        <Toolbar  {...props} >
            <SaveButton />
            <DeleteButton resource={"devices"} label={translate("resources.devices.delete")} />
        </Toolbar>
    )

};


const DeviceConfigurationEditToolbar = ({ record, ...props }) => (
    <Toolbar  {...props} >
        <SaveDeviceConfigButton   {...props} />
    </Toolbar>
);


const co2SensorFreqValidate = (value, allValues) => {

    if (!value) {
        return 'Fréquence de capteur est obligatoire';
    }
    if ((allValues.configuration.send_interval / (value + 1)) < 1) {
        return 'La fréquence capteur doit être inférieur à celle de la plateforme ';
    }
    return undefined;
};








const DeviceEdit = () => {
    const translate = useTranslate();
    const [device, setDevice] = useState(null);
    const [locale, setLocale] = useLocaleState()
    const dataProvider = useDataProvider();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const urlParams = useParams();
    const deviceId = urlParams['id']

    useEffect(() => {
        deviceWithProduct(deviceId).then((res) => {
            if (res && res.status === 200) {
                setDevice(res.json)
            }
        })
    }, []);


    const xPressInputs = () => {
        let inputs = [];
        inputs.push({ name: 'configuration.geolocation_period', label: translate('resources.devices.config.geolocation_period'), validate: [required(), number(), minValue(0), maxValue(4095)], type: "number" })
        inputs.push({ name: 'configuration.wifi_sniffing', label: translate('resources.devices.config.wifi_sniffing'), validateRequired, type: "boolean" })
        if (isSuperAdmin()) {
            inputs.push({ name: 'configuration.bluetooth_advertising', label: translate('resources.devices.config.bluetooth_advertising'), validateRequired, type: "boolean" })
            inputs.push({ name: 'configuration.bluetooth_advertising_frequency', label: translate('resources.devices.config.bluetooth_advertising_frequency'), validate: [required(), number(), minValue(0.1), maxValue(25.5)], type: "number" })
        }
        return inputs;
    }

    const xTrakInputs = () => {
        let inputs = [];
        inputs.push({ name: 'configuration.geolocation_period', label: translate('resources.devices.config.geolocation_period'), validate: [required(), number(), minValue(5), maxValue(60)], type: "number" })
        if (isSuperAdmin()) {
            inputs.push({ name: 'configuration.send_message_in_motion', label: translate('resources.devices.config.send_message_in_motion'), validateRequired, type: "boolean" })
        }
        return inputs;
    }


    const xTrakSInputs = [
        { name: 'configuration.temperature_low_limit', label: translate('resources.devices.config.temperature_low_limit'), validate: [required(), number(), minValue(-40), maxValue(85)], type: "number" },
        { name: 'configuration.temperature_up_limit', label: translate('resources.devices.config.temperature_up_limit'), validate: [required(), number(), minValue(-40), maxValue(85)], type: "number" },
        { name: 'configuration.humidity_low_limit', label: translate('resources.devices.config.humidity_low_limit'), validate: [required(), number(), minValue(0), maxValue(100)], type: "number" },
        { name: 'configuration.humidity_up_limit', label: translate('resources.devices.config.humidity_up_limit'), validate: [required(), number(), minValue(0), maxValue(100)], type: "number" },
    ]

    const renderConfigurationPlans = () => {
        const { record } = useEditContext();
        if (record && record.configuration) {
            return (
                <div sx={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-start', marginRight: '1rem', marginTop: '0.5em' }}>
                    <ReferenceInput step={1} label="Profil" source="configuration.subscription_plan_id" reference="device_sub_plans" filter={{ deviceId: record.id, organizationId: device ? device.organization.organization_id : null, language: locale.toUpperCase() }} sort={{ field: null, order: null }} >
                        <ConfigProfilCard record={record} />
                    </ReferenceInput>
                </div >
            )
        }
        return null

    }


    const renderTiLocker = () => {
        const translate = useTranslate();
        let inputs = [];
        const { record } = useEditContext();
        const lastUnlockRequest = record && record.configuration && record.configuration.unlock_request_date_time ? record.configuration.unlock_request_date_time : null;
        const lastGeolocationRequest = record && record.configuration && record.configuration.geolocation_upload_period_request_date_time ? record.configuration.geolocation_upload_period_request_date_time : null;
        const lastBikeSearchRequest = record && record.configuration && record.configuration.bike_search_request_date_time ? record.configuration.bike_search_request_date_time : null;

        inputs.push({
            type: 'captionMessage', label: translate('resources.devices.config.tilocker.history'),
            captions: [
                `${translate('resources.devices.config.tilocker.unlock_request_date_time')} : ${new Date(lastUnlockRequest).toLocaleString()}`,
                `${translate('resources.devices.config.tilocker.geolocation_upload_period_request_date_time')} : ${new Date(lastGeolocationRequest).toLocaleString()}`,
                `${translate('resources.devices.config.tilocker.bike_search_request_date_time')} : ${new Date(lastBikeSearchRequest).toLocaleString()}`
            ]
        })
        inputs.push({ name: 'configuration.unlock_requested', label: translate('resources.devices.config.tilocker.unlock_requested'), validateRequired, type: "boolean" })
        inputs.push({ name: 'configuration.geolocation_upload_period', label: translate('resources.devices.config.tilocker.geolocation_upload_period'), validate: [required(), number(), minValue(0), maxValue(100000)], type: "number" })
        inputs.push({ name: 'configuration.biked_number', label: translate('resources.devices.config.tilocker.biked_number'), validate: [required(), minLength(16, translate("resources.devices.config.tilocker.bike_number_min")), maxLength(16, translate("resources.devices.config.tilocker.bike_number_max"))], type: "text" })
        inputs.push({ name: 'configuration.bike_search_requested', label: translate('resources.devices.config.tilocker.bike_search_requested'), validateRequired, type: "boolean" })

        return inputs
    }

    const egIotInputs = () => {
        let inputs = [];
        const { record } = useEditContext();
        inputs.push({ name: 'configuration.battery_init_request', label: translate('resources.devices.config.egiot.init_battery'), validateRequired, type: "boolean", disabled: record && record.configuration && record.configuration.battery_init_request === true })
        return inputs;
    }

    const co2SensorInputs = [
        { name: 'configuration.send_interval', label: translate('resources.devices.config.co2.plateformFrequency'), validate: [required(), number(), minValue(10), maxValue(1440)], type: "number" },
        { name: 'configuration.extra_measurement_count', label: translate('resources.devices.config.co2.extra_measurement_count'), validate: [required(), number(), minValue(1), co2SensorFreqValidate], type: "number" },
        { name: 'configuration.co2_alert_threshold', label: translate('resources.devices.config.co2.threshold'), validate: [required(), number(), minValue(0), maxValue(9999)], type: "number" },
        { name: 'configuration.co2_offset', label: translate('resources.devices.config.co2.offset'), validate: [required(), number(), minValue(0), maxValue(9999)], type: "number" },
    ]




    let inputsData = [
        { name: 'name', label: translate('resources.devices.fields.name'), validate: validateRequired, type: "text" },
    ]
    if (isAdminPermissions()) {
        inputsData.push({ name: 'organization_id', label: translate('resources.devices.fields.organization'), validate: validateRequired, type: "reference", reference: "organizations", optionText: "name" })
        inputsData.push({ name: 'owner_id', label: translate('resources.devices.fields.owner'), type: "reference", reference: "users", optionText: (u) => (u.first_name && u.last_name ? u.first_name.toUpperCase() + ' ' + u.last_name.toUpperCase() : u.email) })

    }
    if (isSuperAdmin()) {
        inputsData.push(
            { name: 'product_id', label: translate('resources.devices.product'), validate: validateRequired, type: "reference", reference: "products", optionText: "marketing_name" }
        )
        inputsData.push({ name: 'tags', label: translate('resources.devices.tags'), width: isSmall ? "300px" : "480px", type: "text", disabled: true })
        inputsData.push({ name: 'plan_ids', label: translate('resources.devices.device_sub_plans'), width: isSmall ? "300px" : "480px", height: "250px", type: "referenceAutocompleteArrayInput", reference: "device_sub_plans", optionText: "title", filter: { language: locale } })

    }

    inputsData.push({ name: 'software_version', label: translate('resources.devices.fields.software_version'), type: "text" })
    inputsData.push({ name: 'image', label: translate('resources.devices.fields.image'), type: "file", width: isSmall ? "300px" : "480px", accept: "image/png", subLabel: "type: png , size 250px*250px , max 1M", maxSize: 1000000 })



    const transform = async (data) => {
        if (data.plan_ids && Array.isArray(data.plan_ids)) {
            const { data: plans } = await dataProvider.getMany('device_sub_plans', { filter: { device_sub_plan_ids: data.plan_ids } });
            if (plans && Array.isArray(plans) && plans.length > 0) {
                const filtredTags = plans.filter(p => data.plan_ids.includes(p.plan_id) && p.tag && p.tag.length > 0)
                const tags = filtredTags && filtredTags.length > 0 ? filtredTags.map(p => p.tag) : []
                data.tags = tags;
            }
            delete data.plan_ids;
            return data
        } else {
            return data
        }
    };



    return (

        <Box >

            <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "24px", lineHeight: "42px", margin: "0px 0px 16px 0px" }}>
                <NavigateBackBtn /> {translate('resources.devices.page_titles.edit_device')}
            </Typography>
            <PageHeader
                rows={[
                    [{ title: translate('resources.heading_messages.device_name'), message: device && device.name ? device.name : "UNKNOWN" }, { title: translate('resources.heading_messages.device_id'), message: device && device.serial_number ? device.serial_number : (device && device.mac_address ? device.mac_address : "UNKNOWN") }]
                ]}
                isSmall={isSmall}
            />

            <Typography color="text.main" sx={{
                fontFamily: "Roboto",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "25px",
                textAlign: "start",
                margin: "16px 0px 10px 0px"
            }}
            >
                {translate('resources.devices.edit_sections.general')}
            </Typography>
            <Edit transform={transform}>


                <SimpleForm toolbar={<DeviceEditToolbar />}  >

                    {styledFormInputs(inputsData)}

                </SimpleForm>

            </Edit>
            <Typography color="text.main" sx={{
                fontFamily: "Roboto",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "25px",
                textAlign: "start",
                margin: "30px 0px 10px 0px"
            }}
            >
                {translate('resources.devices.edit_sections.configuration')}
            </Typography>


            <Edit >
                <SimpleForm toolbar={<DeviceConfigurationEditToolbar />} >

                    <FormDataConsumer>
                        {({ formData }) => isXPressOrXTrakR(formData) && styledFormInputs(xPressInputs())
                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData }) => isCo2Sensor(formData) && styledFormInputs(co2SensorInputs)
                        }
                    </FormDataConsumer>


                    <FormDataConsumer>
                        {({ formData }) => isTiLocker(formData) && styledFormInputs(renderTiLocker())
                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData }) => isEgIot(formData) && styledFormInputs(egIotInputs())
                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData }) => isXTrak(formData) && styledFormInputs(xTrakInputs())
                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData }) => isXTrakS(formData) && styledFormInputs(xTrakSInputs)
                        }
                    </FormDataConsumer>

                </SimpleForm>
            </Edit>
        </Box>
    )





}






export default DeviceEdit;


/*

<FormDataConsumer>
    {({ formData }) => isDeviceWithConfigurationPlan(formData) && renderConfigurationPlans()
    }
</FormDataConsumer>

*/