
import React from 'react'
import MobileGrid from './MobileGrid'
import { Box, Typography } from '@mui/material'
import {
    Filter,
    List,
    TextInput,
    useTranslate,
    usePermissions,
    Pagination,
    useListContext
} from 'react-admin'
import { hasWriteAccess, PER_PAGE } from '../../../helper/resourceHelper'
import { useTheme, useMediaQuery } from '@mui/material'
import SearchInputLabelComponent from '../../../ui/inputs/SearchInputLabelComponent'
import { listCommonStyle, searchInputCommonStyle } from '../../../themes/commonStyles'
import { AddBtnWithOptionalMenu } from '../../../ui/button/AddBtn'
import Empty from './Empty'
import NavigateBackBtn from '../../../ui/button/NavigateBackBtn'




const IntegrationJohnDeereActions = ({
    isMobile
}) => {
    const { resource } = useListContext();
    const { isLoading, permissions } = usePermissions();
    const theme = useTheme();
    const translate = useTranslate();
    const addBtnMenuItems = []


    if (hasWriteAccess(permissions)) {
        addBtnMenuItems.push({
            label: translate('resources.hooks.create'),
            path: `/${resource}/create`
        })
    }


    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row"

        }}>

            <AddBtnWithOptionalMenu menuItems={addBtnMenuItems} isMobile={isMobile} />
        </Box>
    )



}




const IntegrationJohnDeerePagination = ({ cardMode }) => {
    const theme = useTheme()

    return (<Pagination sx={{
        '& .MuiToolbar-root': {
            borderTopRightRadius: cardMode ? "12px" : "0px",
            borderTopLeftRadius: cardMode ? "12px" : "0px",
            borderBottomRightRadius: "12px",
            borderBottomLeftRadius: "12px",
            marginTop: cardMode ? "25px" : "0px",

        }

    }} rowsPerPageOptions={[10, 25, 50, 100, 500]} />)
};



const IntegrationJohnDeereList = (props) => {
    const translate = useTranslate();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    return (

        <>
            <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "24px", lineHeight: "42px", margin: "0px 0px 16px 0px" }}>
                <NavigateBackBtn />
                {translate('resources.integrations.list_sections.johndeere')}
            </Typography>
            <List

                title={translate('resources.integrations.name', { smart_count: 2 })}
                actions={<IntegrationJohnDeereActions isMobile={isSmall} />}
                empty={<Empty
                    btnLabel={translate('resources.integrations.create_new_jd_integration')}
                    message={translate('resources.integrations.empty_jd_integrations')}
                    subMessage={translate('resources.integrations.click_below_to_create_new_jd_integration')}
                />}
                filters={!isSmall ?
                    <Filter {...props}>

                        <TextInput
                            id="outlined-basic-size-small"
                            label={<SearchInputLabelComponent />}
                            source="q"
                            alwaysOn
                            sx={searchInputCommonStyle({ theme, isSmall: isSmall })}
                        />
                    </Filter> : null
                }
                sort={{ field: 'name', order: 'ASC' }}
                perPage={PER_PAGE}
                pagination={false}
                sx={listCommonStyle({ isCardMode: true, theme, isSmall })}
                resource='integrations_john_deere'
            >
                <MobileGrid isSmall={isSmall} />

                <IntegrationJohnDeerePagination cardMode={true} />

            </List>

        </>
    )
}


export default IntegrationJohnDeereList

