import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconSatellite, IconGps, IconDevicesPin, IconHourglassHigh, IconHourglassLow, IconHourglass } from '@tabler/icons-react';
import { useTranslate } from 'react-admin';

const LoadingDialog = ({
  isPending,
  isFetching,
  isLoading,
  title,
  content
}) => {

  const theme = useTheme();
  const translate = useTranslate();
  const [showDialog, setShowDialog] = useState(false);
  const [visibleIcon, setVisibleIcon] = useState(1);

  useEffect(() => {
    const cycleDurations = [1000, 2000, 1000]; // Durations for each icon in ms
    let currentIndex = 0; // Tracks which icon is currently active

    const intervalId = setInterval(() => {
      // Move to the next icon in the sequence
      currentIndex = (currentIndex + 1) % 3; // Cycle through 0 → 1 → 2 → 0
      setVisibleIcon(currentIndex + 1);
    }, cycleDurations[currentIndex]);

    return () => clearInterval(intervalId); // Clear interval on unmount
  }, []);


  useEffect(() => {
    let timeoutId;
    if (isPending || isFetching || isLoading) {
      timeoutId = setTimeout(() => {
        setShowDialog(true);
      }, 750);
    } else {
      setShowDialog(false);
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [isPending, isFetching, isLoading]);

  if (!showDialog) return null;

  return (
    <Dialog
      open={isPending || isFetching || isLoading}
      onClose={() => { }}
      sx={{
        "& .MuiPaper-root": {
          width: "80%",
          height: "50%",
          maxWidth: "500px",
          maxHeight: "500px",
          margin: "0px",
          padding: "0px",
          borderRadius: "12px"
        }
      }}
    >

      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          padding: '24px',
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.contrastText
        }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 3
        }}>
          {(isPending || isFetching || isLoading) && (
            <>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                color: theme.palette.primary.main
              }}>
                {(visibleIcon === 1 || visibleIcon === null) && (
                  <Box
                    sx={{
                      animation: `shine12416 4s infinite`,
                      animationDelay: '0s',
                      '@keyframes shine12416': {
                        '0%, 100%': { opacity: 0.5 },
                        '50%': { opacity: 1 },
                      }
                    }}
                  >
                    <IconHourglassHigh
                      size={48}
                      stroke={1.5}

                    />
                  </Box>
                )}
                {(visibleIcon === 2 || visibleIcon === null) && (
                  <Box
                    sx={{
                      animation: `shine12417 3s infinite`,
                      animationDelay: '0s',
                      '@keyframes shine12417': {
                        '0%, 100%': { opacity: 0.5 },
                        '50%': { opacity: 1 },
                      }
                    }}
                  >
                    <IconHourglass
                      size={48}
                      stroke={1.5}
                    />
                  </Box>
                )}
                {(visibleIcon === 3 || visibleIcon === null) && (
                  <Box
                    sx={{
                      animation: `shine12418 2s infinite`,
                      animationDelay: '1.5s',
                      '@keyframes shine12418': {
                        '0%, 100%': { opacity: 0.5 },
                        '50%': { opacity: 1 },
                      }
                    }}
                  >

                    <IconHourglassLow
                      size={48}
                      stroke={1.5}
                    />
                  </Box>
                )}



              </Box>

              <Typography variant="h5" gutterBottom sx={{ color: theme.palette.text.primary }}>
                {title ? title : translate("resources.common.loading_dialog_title")}
              </Typography>

              <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>

                {content ? content : translate("resources.common.loading_dialog_content")}
              </Typography>
            </>
          )}
        </Box>
      </DialogContent>

    </Dialog>
  );
};

export default LoadingDialog;