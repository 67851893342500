


import { Chip } from '@mui/material';
import { useState } from 'react';
import { useTranslate } from 'react-admin';



export const StatusChip = ({ record }) => {
    const translate = useTranslate();
    
    if (record.status === 'active') {
        return (
            <Chip
                label={translate('resources.subscriptions.statuses.active')}
                size="small"
                sx={{
                    height: "24px",
                    width: "55px",
                    fontSize: "10px",
                    fontWeight: 500,
                    bgcolor: 'success.light',
                    color: 'success.dark'
                }}
            />
        )
    } else if (record.status === 'unpaid') {
        return (
            <Chip
                label={translate('resources.subscriptions.statuses.unpaid')}
                size="small"
                sx={{
                    height: "24px",
                    width: "55px",
                    fontSize: "10px",
                    fontWeight: 500,
                    bgcolor: "warning.light",
                    color: "warning.dark" 
                }}
            />
        )
    } else if (record.status === 'canceled') {
        return (
            <Chip
                label={translate('resources.subscriptions.statuses.canceled')}
                size="small"
                sx={{
                    height: "24px",
                    width: "55px",
                    fontSize: "10px",
                    fontWeight: 500,
                    bgcolor: 'grey.light',
                    color: 'grey.dark'
                   
                }}
            />
        )
    }  else if (record.status === 'past') {
        return (
            <Chip
                label={translate('resources.subscriptions.statuses.past')}
                size="small"
                sx={{
                    height: "24px",
                    width: "55px",
                    fontSize: "10px",
                    fontWeight: 500,
                    bgcolor: 'error.light',
                    color: 'error.dark'
                }}
            />
        )
    }
    
    return (
        <Chip
            label={translate('resources.subscriptions.statuses.unknown')}
            size="small"
            sx={{
                height: "24px",
                width: "55px",
                fontSize: "10px",
                fontWeight: 500,
                bgcolor: 'grey.light',
                color: 'grey.dark'
            }}
        />
    )
}