import React from 'react';
import { hasWriteAccess } from '../../../helper/resourceHelper'
import { BooleanField, EditButton, ReferenceField, TextField, useTranslate, useListContext, TopToolbar, usePermissions, ChipField } from 'react-admin';
import { useTheme, Grid, TableBody, CardContent, Card, Typography, Avatar, Box, Chip, ButtonBase, Tooltip } from '@mui/material'
import { CardDetailRow } from '../../../ui/Common';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import  { IntegrationJohnDeereIcon, IntegrationTibibIcon } from '../icon'





const IntegrationCardHeader = ({ Icon, width, height }) => {

    return (
        <Box sx={{
            marginX: "12px",
            marginTop: "12px",
            marginBottom: "16px",
            margin: "12px 0px 16px 5px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start"

        }}>
            <Box
                sx={{
                    width: width,
                    height: height  

                }}
            >
                <Icon />
            </Box>
        </Box>

    );
}


const IntegrationCardContent = ({ integration }) => {
    const translate = useTranslate();
    return (
        <TableBody>
            {integration.name && (
                <CardDetailRow label={translate('resources.integrations.fields.name', { smart_count: 1 })} ValueComponent={() => <TextField source="name" record={integration} />} />
            )}

            {integration.description && (
                <CardDetailRow label={translate('resources.integrations.fields.description', { smart_count: 1 })} ValueComponent={() => <TextField source="description" record={integration} />} />
            )}
        </TableBody>

    );
}



const IntegrationList = (props) => {
    const translate = useTranslate();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate()
    const data = [
        {
            tooltip_title: translate(`resources.integrations.browse_jd_integrations`),
            button_onclick: () => { navigate(`/integrations_john_deere`) },
            name: translate(`resources.integrations.list_sections.johndeere`),
            description: "John Deere",
            Icon: () => <IntegrationJohnDeereIcon />,
            icon_width:"150px",
            icon_height: "28.914px",
        },
        {
            tooltip_title: translate(`resources.integrations.browse_tibib_integrations`),
            button_onclick: () => { navigate(`/integrations_tibib`) },
            name: translate(`resources.integrations.list_sections.tibib`),
            description: "vv",
            Icon: () => <IntegrationTibibIcon />,
            icon_width:"87.734px",
            icon_height: "29px",
        }
    ]
    return (

        <Grid
            container
            spacing={isSmall ? 0 : 1}
            sx={{
                margin: '0px',
                width: "100%",
                backgroundColor: theme.palette.background.default,
            }}
        >
            {data && data.map((integration, index) => (
                <Grid item sm={12} md={4} lg={3}sx={{
                    width: "100%",
                    margin: isSmall ? "8px 0px 0px 0px" : '0px',
                }} >
                    <Card key={index}
                        sx={{
                            height: '100%',
                            display: 'flex',
                            borderRadius: "12px",
                            flexDirection: 'column',
                            margin: '0px',
                            justifyContent: "space-between",
                        }}

                    >
                        <Tooltip title={integration.tooltip_title}>
                            <ButtonBase
                                onClick={integration.button_onclick}
                                sx={{ display: 'flex', width: "100%" }}
                            >

                                <CardContent sx={{
                                    ...theme.typography.body1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: "100%",
                                    justifyContent: "space-between",
                                    padding: "8px"
                                }}>

                                    <IntegrationCardHeader 
                                    Icon={integration.Icon} 
                                    width={integration.icon_width}
                                    height={integration.icon_height}
                                     />
                                    <IntegrationCardContent integration={integration} />
                                </CardContent>
                            </ButtonBase>
                        </Tooltip>

                    </Card>
                </Grid>
            ))}
        </Grid>
    )
}


export default IntegrationList

