import React, { useState } from 'react'
import {  changeConfig, USER_FAV_LANGUAGE } from '../pages/configuration/actions'
import { useTranslate, useLocale, useSetLocale } from 'react-admin'

import { Fade, MenuItem, Menu, IconButton, useTheme ,Tooltip} from '@mui/material';
import Flag from 'react-world-flags'



const LanguageIcon = () => {
    const theme = useTheme();
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" stroke={theme.palette.primary.main} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-world">
            <rect width="32" height="32" rx="8" fill={theme.palette.mode === 'light' ? theme.palette.primary.light : theme.palette.dark.dark} stroke="none" />
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path transform="translate(4, 4)" d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
            <path transform="translate(4, 4)" d="M3.6 9h16.8" />
            <path transform="translate(4, 4)" d="M3.6 15h16.8" />
            <path transform="translate(4, 4)" d="M11.5 3a17 17 0 0 0 0 18" />
            <path transform="translate(4, 4)" d="M12.5 3a17 17 0 0 1 0 18" />
        </svg>
    )
}


const LanguageButton=()=> {
    const [anchorEl, setAnchorEl] = useState(null);
    const setLocale = useSetLocale();
    const translate = useTranslate();
    const theme = useTheme()
    const locale = useLocale();
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    return (
        <Tooltip enterDelay={300} title={translate('resources.common.language')} >
            <>

            <IconButton
                id="fade-button"
                variant="text"
                aria-controls={open ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                size="large">

                <LanguageIcon />
            </IconButton>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}

                TransitionComponent={Fade}
            >
                <MenuItem onClick={() => { setLocale('en'); changeConfig(USER_FAV_LANGUAGE, 'en') }} sx={{ fontWeight: locale === 'en' ? 600: 400 }}> <Flag code="gb" height="20" width="20" />&nbsp; EN </MenuItem>
                <MenuItem onClick={() => { setLocale('fr'); changeConfig(USER_FAV_LANGUAGE, 'fr') }} sx={{ fontWeight: locale === 'fr' ? 600: 400}}><Flag code="fr" height="20" width="20" />&nbsp; FR </MenuItem>

            </Menu>
            </>
            </Tooltip>
    );
}



export default LanguageButton;


