import React from 'react';

import {
    useTranslate,
    MenuItemLink,
    WithPermissions,
    useSidebarState,
    Menu

} from 'react-admin';

import DocumentationIcon from './documentation-icon';
import { useTheme } from '@mui/material/styles';
import { DeviceIcon } from '../pages/device'
import ContactIcon from './contact-icon';
import { LocationIcon } from '../pages/location'
import { MeasureIcon } from '../pages/measure'
import { NotificationIcon } from '../pages/notification'
import { IconHelp } from '@tabler/icons-react';
import { IconInfoCircle } from '@tabler/icons-react';
import { IconCalendarTime } from '@tabler/icons-react';
import { OrganizationIcon } from '../pages/organization'
import { UserIcon } from '../pages/user'
import { ProductIcon } from '../pages/product'
import { HookIcon } from '../pages/hook'
import { IconHome, IconX } from '@tabler/icons-react';
import { isAdminPermissions, isROAdmin, isSuperAdmin } from '../helper/resourceHelper'
import { Box, Button, Divider, IconButton, Typography } from '@mui/material';
import RefreshVersionButton from '../ui/button/RefreshVersionButton';
import { useMediaQuery } from '@mui/material';
import { AccountIcon } from '../pages/account';
import { FopIcon } from '../pages/fop';
import { InvoiceIcon } from '../pages/invoice';
import { RO_APP_TITLE, RO_CONTACT_PAGE_URL, USER_ID } from '../pages/configuration/actions';
import { DeviceSubPlanIcon } from '../pages/deviceSubPlan';
import { RootOrganizationIcon } from '../pages/rootOrganization';
import { AppOwnerInvoiceIcon } from '../pages/appOwnerInvoice';
import { NotificationLogIcon } from '../pages/notificationLog';
import { ExportIcon } from '../pages/export';
import { SmsCreditPlanIcon } from '../pages/smsCreditPlan';
import { SubscriptionIcon } from '../pages/subscription';
import { IntegrationIcon } from '../pages/integration';
import { width } from '@mui/system';




const customStyles = ({ theme, sidebarOpen, isMobile }) => ({


    menuItemLink: {
        padding: sidebarOpen ? '14px 16px 14px 16px' : '10px',
        margin: isMobile ? "10px" : (sidebarOpen ? '0px' : '0px'),
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: "20px",
        width: isMobile ? '90% !important' : '224px',
        height: '52px',
        color: theme.palette.text.primary,
        '&:hover': { // styles survol
            backgroundColor: sidebarOpen ? (theme.palette.mode === 'light' ? theme.palette.primary.light : theme.palette.dark.dark) : theme.palette.background.appFrame,
            color: theme.palette.primary.main,
            fontWeight: '400',
            borderRadius: '12px',
            "& .RaMenuItemLink-icon": {
                color: theme.palette.primary.main,
            },
        },
        "&.RaMenuItemLink-active": {
            backgroundColor: sidebarOpen ? (theme.palette.mode === 'light' ? theme.palette.primary.light : theme.palette.dark.dark) : theme.palette.background.appFrame,
            color: theme.palette.primary.main,
            fontWeight: '400',
            borderRadius: '12px',
        },
        "&.RaMenuItemLink-active .RaMenuItemLink-icon": {
            color: theme.palette.primary.main,
            width: '24px !important',
            minWidth: '24px !important',
            height: '24px',
            margin: sidebarOpen ? '0px 12px 0px 0px' : '0px 12px 0px 10.5px'
        },
        "& .RaMenuItemLink-icon": {
            color: theme.palette.text.primary,
            width: '24px !important',
            minWidth: '24px !important',
            height: '24px',
            margin: sidebarOpen ? '0px 12px 0px 0px' : '0px 12px 0px 10.5px',
        },

    },
    menuSectionTypography: {
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: "20px",
        width: isMobile ? '90% !important' : '224px',
        height: '20px',
        margin: isMobile ? '16px 8px 8px 8px' : '16px 0px 8px 0px',

    },
    divider: { width: '100%', borderColor: theme.palette.divider, padding: "0px", margin: "16px 0px 0px 0px" },
    menuCloseBox: {
        width: "90%",
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: '20px',
        margin: '16px'

    }

})


function MyMenu({ onMenuClick }) {
    const theme = useTheme();
    const [sidebarOpen, setSidebarOpen] = useSidebarState();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = customStyles({ theme, sidebarOpen, isMobile });
    const translate = useTranslate();


    return (
        <Menu sx={{
            margin: '0px',
            padding: '0px',
            width: isMobile ? '100% !important' : '224px',
        }}>

            <div>
                {isMobile &&
                    <Box sx={styles.menuCloseBox}><IconButton onClick={() => setSidebarOpen(false)}><IconX color={theme.palette.text.primary} /></IconButton></Box>

                }

                {sidebarOpen && (
                    < Typography variant="subtitle1" sx={styles.menuSectionTypography}>{translate("resources.sidebar.sections.dashboard")}  </Typography>)}
                <MenuItemLink
                    key='dashboard'
                    to={`/dashboard`}
                    primaryText={sidebarOpen ? translate("resources.dashboard.name", { smart_count: 1, }) : null}
                    leftIcon={<IconHome />}
                    onClick={onMenuClick}
                    sx={styles.menuItemLink}
                />


                <Divider variant='middle' sx={styles.divider} ></Divider>
                {sidebarOpen && (
                    < Typography variant="subtitle1" sx={styles.menuSectionTypography}>{translate("resources.sidebar.sections.devices")}  </Typography>)}

                <MenuItemLink
                    key='devices'
                    to={`/devices`}
                    primaryText={sidebarOpen ? translate("resources.devices.name", { smart_count: 1, }) : null}
                    leftIcon={<DeviceIcon />}
                    onClick={onMenuClick}
                    sx={styles.menuItemLink}
                />

                <WithPermissions
                    render={({ permissions }) => (
                        isSuperAdmin(permissions) ? <MenuItemLink
                            key='products'
                            to={`/products`}
                            primaryText={sidebarOpen ? translate("resources.products.name", { smart_count: 1, }) : null}
                            leftIcon={<ProductIcon />}
                            onClick={onMenuClick}
                            sx={styles.menuItemLink}
                        /> : null
                    )}
                />

                <Divider variant='middle' sx={styles.divider} ></Divider>
                {sidebarOpen && (
                    <Typography variant="subtitle1" sx={styles.menuSectionTypography}>{translate("resources.sidebar.sections.data")}    </Typography>)}
                <MenuItemLink
                    key='locations'
                    to={`/locations`}
                    primaryText={sidebarOpen ? translate("resources.locations.name", { smart_count: 1, }) : null}
                    leftIcon={<LocationIcon />}
                    onClick={onMenuClick}
                    sx={styles.menuItemLink}

                />
                <MenuItemLink
                    key='measures'
                    to={`/measures`}
                    primaryText={sidebarOpen ? translate("resources.measures.name", { smart_count: 1, }) : null}
                    leftIcon={<MeasureIcon />}
                    onClick={onMenuClick}
                    sx={styles.menuItemLink}
                />
                <MenuItemLink
                    key='export'
                    to={`/export/create`}
                    primaryText={sidebarOpen ? translate("resources.export.create", { smart_count: 1, }) : null}
                    leftIcon={<ExportIcon />}
                    onClick={onMenuClick}
                    sx={styles.menuItemLink}
                />

                <Divider variant='middle' sx={styles.divider} ></Divider>
                {sidebarOpen && (
                    <Typography variant="subtitle1" sx={styles.menuSectionTypography} >{translate("resources.sidebar.sections.notifications")}   </Typography>)}

                <MenuItemLink
                    key='notifications'
                    to={`/notifications`}
                    primaryText={sidebarOpen ? translate("resources.notifications.name", { smart_count: 1, }) : null}
                    leftIcon={<NotificationIcon />}
                    onClick={onMenuClick}
                    sx={styles.menuItemLink}
                />
                <MenuItemLink
                    key='notification_logs'
                    to={`/notification_logs`}
                    primaryText={sidebarOpen ? translate("resources.notification_logs.name", { smart_count: 1, }) : null}
                    leftIcon={<NotificationLogIcon />}
                    onClick={onMenuClick}
                    sx={styles.menuItemLink}
                />


                <WithPermissions
                    render={({ permissions }) => (
                        (isAdminPermissions(permissions)) ?
                            <Divider variant='middle' sx={styles.divider}></Divider>
                            : null
                    )}
                />

                <WithPermissions
                    render={({ permissions }) => (
                        (isAdminPermissions(permissions) && sidebarOpen) ? <Typography
                            variant="subtitle1"
                            sx={styles.menuSectionTypography}
                        >
                            {translate("resources.sidebar.sections.members")}
                        </Typography> : null
                    )}
                />
                <WithPermissions
                    render={({ permissions }) => (
                        isAdminPermissions(permissions) ? <MenuItemLink
                            key='users'
                            to={`/users`}
                            primaryText={sidebarOpen ? translate("resources.users.name", { smart_count: 1, }) : null}
                            leftIcon={<UserIcon />}
                            onClick={onMenuClick}
                            sx={styles.menuItemLink}
                        /> : null
                    )}
                />
                <WithPermissions
                    render={({ permissions }) => (
                        isAdminPermissions(permissions) ? <MenuItemLink
                            key='organizations'
                            to={`/organizations`}
                            primaryText={sidebarOpen ? translate("resources.organizations.name", { smart_count: 1, }) : null}
                            leftIcon={<OrganizationIcon />}
                            onClick={onMenuClick}
                            sx={styles.menuItemLink}
                        /> : null
                    )}
                />

                <WithPermissions
                    render={({ permissions }) => (
                        isSuperAdmin(permissions) ? <MenuItemLink
                            key='root_organizations'
                            to={`/root_organizations`}
                            primaryText={sidebarOpen ? translate("resources.root_organizations.name", { smart_count: 1, }) : null}
                            leftIcon={<RootOrganizationIcon />}
                            onClick={onMenuClick}
                            sx={styles.menuItemLink}
                        /> : null
                    )}
                />

                <WithPermissions
                    render={({ permissions }) => (
                        (isSuperAdmin(permissions)) ?
                            <Divider variant='middle' sx={styles.divider}></Divider>
                            : null
                    )}
                />
                <WithPermissions
                    render={({ permissions }) => (
                        ((isSuperAdmin(permissions) || isROAdmin(permissions)) && sidebarOpen) ? <Typography
                            variant="subtitle1"
                            sx={styles.menuSectionTypography}
                        >
                            {translate("resources.sidebar.sections.subscriptions")}
                        </Typography> : null
                    )}
                />
                <WithPermissions
                    render={({ permissions }) => (
                        (isSuperAdmin(permissions) || isROAdmin(permissions)) ? <MenuItemLink
                            key='device_sub_plans'
                            to={`/device_sub_plans`}
                            primaryText={sidebarOpen ? translate("resources.sidebar.device_sub_plans", { smart_count: 1, }) : null}
                            leftIcon={<DeviceSubPlanIcon />}
                            onClick={onMenuClick}
                            sx={styles.menuItemLink}
                        /> : null
                    )}
                />

                <WithPermissions
                    render={({ permissions }) => (
                        (isSuperAdmin(permissions)) ? <MenuItemLink
                            key='sms_credit_plans'
                            to={`/sms_credit_plans`}
                            primaryText={sidebarOpen ? translate("resources.sidebar.sms_credit_plans", { smart_count: 1, }) : null}
                            leftIcon={<SmsCreditPlanIcon />}
                            onClick={onMenuClick}
                            sx={styles.menuItemLink}
                        /> : null
                    )}
                />

                <WithPermissions
                    render={({ permissions }) => (
                        isSuperAdmin(permissions) ? <MenuItemLink
                            key='subscriptions'
                            to={`/subscriptions`}
                            primaryText={sidebarOpen ? translate("resources.sidebar.device_sub", { smart_count: 1, }) : null}
                            leftIcon={<SubscriptionIcon />}
                            onClick={onMenuClick}
                            sx={styles.menuItemLink}
                        /> : null
                    )}
                />


                <Divider variant='middle' sx={styles.divider}></Divider>
                {sidebarOpen && (
                    <Typography variant="subtitle1" sx={styles.menuSectionTypography} > {translate("resources.sidebar.sections.developers")}   </Typography>
                )}

                <MenuItemLink
                    key='hooks'
                    to={`/hooks`}
                    primaryText={sidebarOpen ? translate("resources.hooks.name", { smart_count: 2, }) : null}
                    leftIcon={<HookIcon />}
                    onClick={onMenuClick}
                    sx={styles.menuItemLink}
                />
                <MenuItemLink
                    key='integrations'
                    to={`/integrations`}
                    primaryText={sidebarOpen ? translate("resources.integrations.name", { smart_count: 2, }) : null}
                    leftIcon={<IntegrationIcon />}
                    onClick={onMenuClick}
                    sx={styles.menuItemLink}
                />
                <MenuItemLink
                    key='documentation'
                    to={"https://developers.ticatag.com"}
                    target="_blank"
                    primaryText={sidebarOpen ? translate('resources.common.documentation', { smart_count: 1, },) : null}
                    leftIcon={<DocumentationIcon />}
                    onClick={onMenuClick}
                    sx={styles.menuItemLink}
                />

                <Divider variant='middle' sx={styles.divider}></Divider>
                {sidebarOpen && (
                    <Typography variant="subtitle1" sx={styles.menuSectionTypography} >{translate("resources.sidebar.sections.account")} </Typography>
                )}
                <MenuItemLink
                    key='account'
                    to={`/account/${localStorage.getItem(USER_ID)}`}
                    primaryText={sidebarOpen ? translate("resources.account.personal_information") : null}
                    leftIcon={<AccountIcon />}
                    onClick={onMenuClick}
                    sx={styles.menuItemLink}
                />
                <MenuItemLink
                    key='fops'
                    to={"/fops"}
                    primaryText={sidebarOpen ? translate('resources.fops.name', { smart_count: 1, },) : null}
                    leftIcon={<FopIcon />}
                    onClick={onMenuClick}
                    sx={styles.menuItemLink}
                />
                <MenuItemLink
                    key='invoices'
                    to={`/invoices`}
                    primaryText={sidebarOpen ? translate('resources.invoices.name', { smart_count: 2, }) : null}
                    leftIcon={<InvoiceIcon />}
                    onClick={onMenuClick}
                    sx={styles.menuItemLink}
                />

                <WithPermissions
                    render={({ permissions }) => (
                        isSuperAdmin(permissions) ? <MenuItemLink
                            key='app_owner_invoices'
                            to={`/app_owner_invoices`}
                            primaryText={sidebarOpen ? translate('resources.app_owner_invoices.name', { smart_count: 2, }) : null}
                            leftIcon={<AppOwnerInvoiceIcon />}
                            onClick={onMenuClick}
                            sx={styles.menuItemLink}
                        /> : null
                    )}
                />


                <Divider variant='middle' sx={styles.divider}></Divider>
                {sidebarOpen && (
                    <Typography variant="subtitle1" sx={styles.menuSectionTypography} >{translate("resources.sidebar.sections.assistance")} </Typography>
                )}
                <MenuItemLink
                    key='Help'
                    to={`/help`}
                    primaryText={sidebarOpen ? translate("resources.help.name", { smart_count: 1, }) : null}

                    leftIcon={<IconHelp />}
                    onClick={onMenuClick}
                    sx={styles.menuItemLink}
                />

                <MenuItemLink
                    key='contact'
                    to={localStorage.getItem(RO_CONTACT_PAGE_URL)}
                    target="_blank"
                    primaryText={sidebarOpen ? translate('resources.common.contact', { smart_count: 1, },) : null}
                    leftIcon={<ContactIcon />}
                    onClick={onMenuClick}
                    sx={styles.menuItemLink}
                />
                <Divider variant='middle' sx={styles.divider}></Divider>
                {sidebarOpen && (
                    <Typography variant="subtitle1" sx={styles.menuSectionTypography} >{translate("resources.sidebar.sections.website_info")} </Typography>
                )}
                <MenuItemLink
                    key='info-version'
                    disabled={true}
                    to={"/info-version"}
                    primaryText={sidebarOpen ? `${localStorage.getItem(RO_APP_TITLE)} @${process.env.REACT_APP_VERSION}` : null}
                    leftIcon={<IconInfoCircle />}
                    onClick={onMenuClick}
                    sx={styles.menuItemLink}
                />
                <MenuItemLink
                    key='info-update-date'
                    disabled={true}
                    to={"/info-update-date"}
                    primaryText={sidebarOpen ? process.env.REACT_APP_VERSION_DATE : null}
                    leftIcon={<IconCalendarTime />}
                    onClick={onMenuClick}
                    sx={styles.menuItemLink}
                />


            </div>
            <div style={{
                marginBottom: "1.5em",
                height: "50px",
                color: theme.palette.primary.dark
            }} >

                <RefreshVersionButton sx={{ display: "none" }} />
            </div>
        </Menu>

    )
}





export default MyMenu;





