import { stringify } from 'query-string'
import {
    CREATE,
    DELETE,
    DELETE_MANY,
    fetchUtils,
    GET_LIST,
    GET_MANY,
    GET_MANY_REFERENCE,
    GET_ONE,
    HttpError,
    UPDATE
} from 'react-admin'
import { PER_PAGE } from '../helper/resourceHelper'
import { USER_ACCESS_TOKEN } from '../pages/configuration/actions'


/**
 * Maps react-admin queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts/123
 * DELETE       => DELETE http://my.api.url/posts/123
 */


//        `http://www.mapquestapi.com/geocoding/v1/reverse?key=85mgMfFi9qyEFGyWasGrdo5eSroCrLZk&location=${lat},${lon}`,

const fetchFormData = (url, { method, headers, body }) => {

    return fetch(url, {
        method: method,
        headers: headers,
        body: body
    }).then(response =>
        response.text().then(text => ({
            status: response.status,
            statusText: response.statusText,
            headers: response.headers,
            body: text,
        }))
    )
        .then(({ status, statusText, headers, body }) => {

            if (status < 200 || status >= 300) {
                return Promise.reject(
                    new HttpError(
                        statusText,
                        status,
                        body
                    )
                );
            }
            return Promise.resolve({ status, headers, body });
        });
}

const httpClientLocal = ({ resource, url = '', options = {} }) => {
    const baseUrl = "http://127.0.0.1"
    var port = "8080";
    switch (resource) {
        case "devices": {
            url = `/v2/${url}`
            port = "8080";
            break;
        }


        case "devices_bulk": {
            url = `/v2/${url}`
            port = "8080";
            break;
        }


        case "products": {
            url = `/v2/${url}`
            port = "8080";
            break;
        }

        case "locations": {
            url = `/v2/${url}`
            port = "8080";
            break;
        }

        case "notification_logs": {
            url = `/v2/${url}`
            port = "8080";
            break;
        }

        case "notification_subjects": {
            url = `/v2/${url}`
            port = "8090";
            break;
        }


        case "hook_events": {
            url = `/v2/${url}`
            port = "8088";
            break;
        }




        case "ammonia_events":
        case "aqi_events":
        case "battery_events":
        case "battery_status_events":
        case "benzen_events":
        case "button_events":
        case "co2_events":
        case "computed_distance_events":
        case "computed_location_events":
        case "distance_events":
        case "formaldehyde_events":
        case "door_events":
        case "geofence_monitoring_events":
        case "high_g_events":
        case "humidity_events":
        case "level_events":
        case "location_events":
        case "luminosity_events":
        case "motion_events":
        case "mox_events":
        case "noise_events":
        case "pm1_events":
        case "pm2p5_events":
        case "pm10_events":
        case "presence_events":
        case "pressure_events":
        case "rollover_events":
        case "shock_events":
        case "temperature_events":
        case "total_odometer_events":
        case "tvoc_events":
        case "notification_log_events":
        case "water_leak_events": {
            url = `/v2/${url}`
            port = "8085";
            break;
        }


        case "measures": {
            url = `/v2/${url}`
            port = "8080";
            break;
        }

        case "configuration_plan": {
            url = url.slice(6)
            port = "8092";
            break;
        }
        case "device_sub_plans": {
            url = `/v2/${url}`
            port = "8084";
            break;
        }
        case "sms_credit_plans": {
            url = `/v2/${url}`
            port = "8084";
            break;
        }
        case "invoices": {
            url = `/v2/${url}`
            port = "8084";
            break;
        }
        case "app_owner_invoices": {
            url = `/v2/${url}`
            port = "8084";
            break;
        }

        case "account": {
            port = "9998";
            url = `/v2/${url}`
            break;
        }
        case "users": {
            port = "9998";
            url = `/v2/${url}`
            break;
        }
        case "organizations": {
            port = "9998";
            url = `/v2/${url}`
            break;
        } case "root_organizations": {
            port = "9998";
            url = `/v2/${url}`
            break;
        }
        case "users_invite": {
            port = "9998";
            url = `/v2/${url}`
            break;
        }
        case "change_password": {
            port = "9998";
            url = `/v2/${url}`
            break;
        }
        case "change_email": {
            port = "9998";
            url = `/${url}`
            break;
        }
        case "device_configurations": {
            url = url.slice(6)
            port = "8092";
            break;
        }

        case "subscriptions": {
            url = `/v2/${url}`
            port = "8084";
            break;
        }


    }


    if (options.formData) {
        const token = localStorage.getItem(USER_ACCESS_TOKEN)
        return fetchFormData(`${baseUrl}:${port}${url}`, {
            method: options.method,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: options.formData
        })
    }

    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' })
    }
    const token = localStorage.getItem(USER_ACCESS_TOKEN)
    options.headers.set('Authorization', `Bearer ${token}`)
    return fetchUtils.fetchJson(`${baseUrl}:${port}${url}`, options)

}

const httpClientLB = ({ url, options = {} }) => {

    if (options.formData) {
        const token = localStorage.getItem(USER_ACCESS_TOKEN)
        return fetchFormData(`${process.env.REACT_APP_API_URL}/${url}`, {
            method: options.method,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: options.formData
        })
    }


    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' })
    }
    const token = localStorage.getItem(USER_ACCESS_TOKEN)
    options.headers.set('Authorization', `Bearer ${token}`)

    return fetchUtils.fetchJson(`${process.env.REACT_APP_API_URL}/${url}`, options)
}


const httpClient = ({ resource, url, options = {} }) => {

    if (process.env.REACT_APP_API_BACKEND_TYPE === "local") return httpClientLocal({ resource, url, options })
    if (process.env.REACT_APP_API_BACKEND_TYPE === "lb") return httpClientLB({ url, options })

    return null

}



export default () => {

    const restMap = {
        account: {
            urn: 'uaa/account',
            resource: 'account',
            id: 'user_id',
            includes: ['user_id', 'organization_id', 'phone_number', 'company_name'],
        },
        change_password: {
            urn: 'uaa/account/change_password',
            resource: 'change_password',
        },
        change_email: {
            urn: 'uaa/account/change_email',
            resource: 'change_email',
        },
        attach: {
            urn: 'devices/attach',
            resource: 'devices',
            id: 'device_id',
        },

        devices: {
            urn: 'devices',
            resource: 'devices',
            deleteUrn: 'detach',
            id: 'device_id',
            includes: ['device_id'],
            includes_get_one: ['organization_id', 'software_version', 'image', 'product', 'product_id', 'last_subscription', 'configuration', 'owner_id', "tags"]
        },
        devices_erase: {
            urn: 'devices',
            resource: 'devices',
            deleteUrn: 'erase',
            id: 'device_id',
        },
        device_configurations: {
            urn: 'config/devices',
            resource: 'device_configurations',
        },
        devices_bulk: {
            urn: 'devices/batch',
            resource: 'devices_bulk',
        },
        devices_status: {
            urn: 'devices/serial_number',
            resource: 'devices',
            id: 'serial_number',
        },
        export: {
            urn: 'events/batch/export',
            resource: 'export',
        },
        geofences: {
            urn: 'geofences',
            resource: 'geofences',
            id: 'geofence_id',
            includes: ['device_id'],
            includes_get_one: ['geofence_id', 'device']
        },
        hooks: {
            urn: 'hooks',
            resource: 'hooks',
            id: 'hook_id',
            includes: ['hook_id'],
        },
        integrations_john_deere: {
            urn: 'integrations/johndeere',
            resource: 'integrations_john_deere',
            id: 'id',
            includes: ['id'],
        },
        integrations_tibib: {
            urn: 'integrations/tibib',
            resource: 'integrations_tibib',
            id: 'id',
            includes: ['id'],
        },
        locations: {
            urn: 'devices',
            resource: 'devices',
            id: 'device_id',
            includes: ['images', 'last_location', 'device_id'],
        },
        notification_logs: {
            urn: 'devices',
            resource: 'devices',
            id: 'device_id',
            includes: ['images', 'last_notification_logs', 'device_id'],
        },
        number_nack_notification_logs: {
            urn: 'devices',
            resource: 'devices',
            id: 'device_id',
            includes: ["last_nack_notification_log"],
        },
        ammonia_events: {
            urn: 'events',
            resource: 'ammonia_events',
            id: 'event_id',
        },
        aqi_events: {
            urn: 'events',
            resource: 'aqi_events',
            id: 'event_id',
        },
        battery_events: {
            urn: 'events',
            resource: 'battery_events',
            id: 'event_id',
        },
        battery_status_events: {
            urn: 'events',
            resource: 'battery_status_events',
            id: 'event_id',
        },
        benzen_events: {
            urn: 'events',
            resource: 'benzen_events',
            id: 'event_id',
        },
        button_events: {
            urn: 'events',
            resource: 'button_events',
            id: 'event_id',
        },
        co2_events: {
            urn: 'events',
            resource: 'co2_events',
            id: 'event_id',
        },
        computed_distance_events: {
            urn: 'events',
            resource: 'computed_distance_events',
            id: 'event_id',
        },
        computed_location_events: {
            urn: 'events',
            resource: 'computed_location_events',
            id: 'event_id',
        },
        distance_events: {
            urn: 'events',
            resource: 'distance_events',
            id: 'event_id',
        },
        formaldehyde_events: {
            urn: 'events',
            resource: 'formaldehyde_events',
            id: 'event_id',
        },
        door_events: {
            urn: 'events',
            resource: 'door_events',
            id: 'event_id',
        },
        geofence_monitoring_events: {
            urn: 'events',
            resource: 'geofence_monitoring_events',
            id: 'event_id',
        },
        high_g_events: {
            urn: 'events',
            resource: 'high_g_events',
            id: 'event_id',
        },
        humidity_events: {
            urn: 'events',
            resource: 'humidity_events',
            id: 'event_id',
        },
        level_events: {
            urn: 'events',
            resource: 'level_events',
            id: 'event_id',
        },
        location_events: {
            urn: 'events',
            resource: 'location_events',
            id: 'event_id',
        },
        luminosity_events: {
            urn: 'events',
            resource: 'luminosity_events',
            id: 'event_id',
        },
        motion_events: {
            urn: 'events',
            resource: 'motion_events',
            id: 'event_id',
        },
        mox_events: {
            urn: 'events',
            resource: 'mox_events',
            id: 'event_id',
        },
        noise_events: {
            urn: 'events',
            resource: 'noise_events',
            id: 'event_id',
        },
        pm1_events: {
            urn: 'events',
            resource: 'pm1_events',
            id: 'event_id',
        },
        pm2p5_events: {
            urn: 'events',
            resource: 'pm2p5_events',
            id: 'event_id',
        },
        pm10_events: {
            urn: 'events',
            resource: 'pm10_events',
            id: 'event_id',
        },
        presence_events: {
            urn: 'events',
            resource: 'presence_events',
            id: 'event_id',
        },
        pressure_events: {
            urn: 'events',
            resource: 'pressure_events',
            id: 'event_id',
        },
        rollover_events: {
            urn: 'events',
            resource: 'rollover_events',
            id: 'event_id',
        },
        shock_events: {
            urn: 'events',
            resource: 'shock_events',
            id: 'event_id',
        },
        temperature_events: {
            urn: 'events',
            resource: 'temperature_events',
            id: 'event_id',
        },
        total_odometer_events: {
            urn: 'events',
            resource: 'total_odometer_events',
            id: 'event_id',
        },
        tvoc_events: {
            urn: 'events',
            resource: 'tvoc_events',
            id: 'event_id',
        },
        water_leak_events: {
            urn: 'events',
            resource: 'water_leak_events',
            id: 'event_id',
        },
        notification_log_events: {
            urn: 'events/notification_logs',
            resource: 'notification_log_events',
            id: 'event_id',
        },
        notifications: {
            urn: 'notifications',
            resource: 'notifications',
            id: 'notification_id',
            includes: ['device_id'],
        },

        organizations: {
            urn: 'uaa/organizations',
            resource: 'organizations',
            id: 'organization_id',
            includes: ['parent_organization_id', 'root_organization_id'],
        },
        root_organizations: {
            urn: 'uaa/organizations/root',
            resource: 'root_organizations',
            id: 'root_organization_id',
            includes: ['root_organization_id'],
            includes_get_one: ["all"]
        },
        configuration_plan: {
            urn: 'config/configurationplan/device_id',
            resource: ' configuration_plan',
            id: 'device_id',
        },
        configuration_plans: {
            urn: 'config/configurationplan',
            resource: ' configuration_plans',
            id: 'plan_id',
        },
        measures: {
            urn: 'devices',
            resource: 'devices',
            id: 'device_id',
            includes: ['device_id'],
        },
        toDo: {
            urn: 'todos',
            resource: 'toDo',
            id: 'toDo_id'
        },
        help: {
            urn: 'help',
            resource: 'help',
            id: 'help_id'
        },
        subscriptions_create: {
            urn: 'subscriptions/create',
            resource: 'subscriptions_create',
            id: 'subscription_id',
        },
        subscriptions: {
            urn: 'subscriptions',
            resource: 'subscriptions',
            id: 'subscription_id',
        },
        device_sub_plans: {
            urn: 'subscriptions/device_sub_plans',
            resource: 'device_sub_plans',
            id: 'plan_id',
            includes: ['plan_id'],
            includes_get_one: ["all"]
        },
        sms_credit_plans: {
            urn: 'subscriptions/sms_credit_plans',
            resource: 'sms_credit_plans',
            id: 'plan_id',
            includes: ['plan_id'],
            includes_get_one: ["all"]
        },
        products: {
            urn: 'devices/products',
            resource: 'products',
            id: 'product_id',
            includes: ['product_id'],
        },
        notification_subjects: {
            urn: 'notifications/subjects',
            resource: 'notification_subjects'
        },
        hook_events: {
            urn: 'hooks/events',
            resource: 'hook_events'
        },
        invoices: {
            urn: 'subscriptions/invoices',
            resource: 'invoices',
            id: 'invoice_id',
        },
        app_owner_invoices: {
            urn: 'subscriptions/app-owner-invoices',
            resource: 'app_owner_invoices',
            id: 'app_owner_invoice_id',

        },
        subscriptions_manager: {
            urn: 'devices',
            resource: 'devices',
            id: 'device_id',
            includes: ['last_location'],

        },
        subscriptionByDevice: {
            urn: 'subscriptions',
            resource: 'subscriptionByDevice',
            id: 'device_id',
            includes_get_one: ['device_id', 'device', 'subscription_id', 'plan']
        },
        vouchers: {
            urn: 'vouchers',
            resource: 'vouchers',
            id: 'voucher_id',
        },
        users: {
            urn: 'uaa/users',
            resource: 'users',
            createUrn: 'uaa/register',
            deleteUrn: 'remove',
            id: 'user_id',
            includes: ['organization_id', 'company_name'],
        },
        users_invite: {
            urn: 'uaa/users/invite',
            resource: 'users_invite',
            id: 'user_id',
        }
    }

    /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The REST request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
    const convertRESTRequestToHTTP = (type, resource, params) => {
        let url = ''
        const options = {}
        const query = {}

        switch (type) {
            case GET_MANY:
            case GET_MANY_REFERENCE:
            case GET_LIST: {
                if (params.pagination) {
                    const { page, perPage } = params.pagination
                    if (page) {
                        query['page'] = page - 1
                    }
                    if (!perPage) {
                        query['size'] = PER_PAGE
                    } else {
                        query['size'] = perPage
                    }
                }

                if (type === 'GET_MANY' && restMap[resource].id) {
                    //dirty hack to disable pagination - a search by device ids must be implemented on back-end side
                    //query['size'] = 500

                    query[`${restMap[resource].id}s`] = params.ids

                    if (resource === 'devices') {
                        const restResource = restMap[resource]
                        const includes_get_one = restResource.includes_get_one
                        if (includes_get_one) {
                            query.includes = ''
                            includes_get_one.map(include => (query.includes += include + ','))
                        }
                    }
                }

                const filters = params.filter

                for (const filter in filters) {
                    query[filter] = filters[filter]
                }

                if (params.sort) {
                    const { field, order } = params.sort
                    if (field && order) {
                        query.sort = field + ',' + order
                    }
                }

                const id = restMap[resource].id
                if (!query.includes || !query.includes[id]) {
                    query.includes = query.includes ? query.includes + ',' + id : id
                }

                const aincludes = restMap[resource].includes
                if (aincludes) {
                    aincludes.map(include => (query.includes += ',' + include))
                }

                const urn = restMap[resource].urn
                url = `${urn}?${stringify(query)}`

                if (resource === 'yabby_configuration_plans' && params.filter && params.filter.device_id) {
                    url = `${urn}/device_id/${params.filter.device_id}`
                }
                break
            }
            case GET_ONE: {
                const query = {}
                const restResource = restMap[resource]
                const includes = restResource.includes
                if (includes) {
                    query.includes = ''
                    includes.map(include => (query.includes += include + ','))
                }
                const includes_get_one = restResource.includes_get_one
                if (includes_get_one) {
                    includes_get_one.map(include => (query.includes += include + ','))
                }
                const res = restMap[resource]
                if (resource === 'account') url = `${res.urn}?${stringify(query)}`
                else url = `${res.urn}/${params.id}?${stringify(query)}`

                break
            }
            case DELETE:
                const restResource = restMap[resource]
                url =
                    restResource && restResource.deleteUrn
                        ? `${restResource.urn}/${params.id}/${restResource.deleteUrn}`
                        : `${resource}/${params.id}`
                if (resource === 'location_events') {
                    url = `${restResource.urn}?device_ids=${params.id}`
                }
                options.method = 'DELETE'
                break
            case UPDATE: {
                const urn1 = restMap[resource].urn
                url = `${urn1}/${params.id}`
                options.method = 'PATCH'
                options.formData = getFormDataIfFilesExists(params.data, params.previousData)
                options.body = JSON.stringify(params.data)
                break
            }
            case CREATE: {
                url = restMap[resource].createUrn ? restMap[resource].createUrn : restMap[resource].urn;
                options.method = 'POST'
                options.formData = getFormDataIfFilesExists(params.data, params.previousData)
                options.body = JSON.stringify(params.data)
                break
            }
            default:
                throw new Error(`Unsupported fetch action type ${type}`)
        }
        return { url, options }
    }

    /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The REST request params, depending on the type
     * @returns {Object} REST response
     */
    const convertHTTPResponseToREST = (response, type, resource, params) => {
        const { json } = response

        switch (type) {
            case GET_LIST:
            case GET_MANY:
            case GET_MANY_REFERENCE: {
                const id = restMap[resource].id
                const resourceName = restMap[resource].resource
                if (json._embedded) {
                    const data = json._embedded[resourceName].map(resource => {
                        return { ...resource, id: resource[id] }
                    })
                    localStorage.setItem('lastResource', JSON.stringify(data))
                    return {
                        data: data,
                        total: json.page.total_elements,
                    }
                } else if (json.page && json.page.total_elements === 0) {
                    return {
                        data: [],
                        total: 0,
                    }
                } else if (json.page && json.page.total_elements > 0) {
                    return {
                        data: [],
                        total: json.page.total_elements,
                    }
                } else {
                    return {
                        data: json.map(resource => {
                            return { ...resource, id: resource[id] }
                        }),
                        total: json.length,
                    }
                }
            }
            case GET_ONE: {
                const resourceName = restMap[resource].resource

                if (json._embedded) {
                    const resource = json._embedded[resourceName][0]
                    if (resource) {
                        return {
                            ...resource,
                            id: params.id,
                        }
                    }
                }
                return { data: { ...json, id: params.id } }
            }
            case CREATE:
                if (json) {
                    if (json.id) {
                        return { data: { ...params.data, id: json.id } }
                    } else if (json[restMap[resource].id]) {
                        return { data: { ...params.data, id: json[restMap[resource].id] } }
                    } else {
                        return { data: json }
                    }
                } else {
                    return { data: { id: 'fake', ...params.data }, id: 'fake' }
                }

            case UPDATE:

                return { data: { ...params.data, id: params.id } }

            case DELETE:
                return { data: { ...params.data, id: params.id } }
            default:
                return { data: json }

        }
    }
    /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a REST response
     */
    return (type, resource, params) => {

        if (type === null) {
            return null
        }

        if (type === DELETE_MANY) {
            return Promise.all(
                params.ids.map(id =>
                    httpClient({
                        resource,
                        url: restMap[resource].deleteUrn
                            ? `/${restMap[resource].urn}/${id}/${restMap[resource].deleteUrn}`
                            : `/${restMap[resource].urn}/${id}`,
                        options: {
                            method: 'DELETE',
                        }
                    })
                )
            ).then(responses => ({
                data: responses.map(response => response.json),
            }))
        }
        if (resource === 'subscriptionByDevice') {

            return Promise.all(
                params.ids.map(id =>
                    getSubscriptions(id)
                )
            ).then(responses => {

                return (
                    ({
                        data: responses.map((response, index) => {
                            const device_id = params.ids[index]
                            return ({ ...response.json, id: device_id })
                        }),
                    })
                )
            }


            )
        }

        const { url, options } = convertRESTRequestToHTTP(type, resource, params)


        return httpClient({ resource, url, options })
            .then(response => {

                return convertHTTPResponseToREST(response, type, resource, params)
            })
            .catch(error => {

                return new Promise((_, reject) => reject(error))
            })
    }

}

const isSameAsPrevious = (key, value, previousData) => {
    // Check if the key exists in previousData
    if (previousData && previousData.hasOwnProperty(key)) {
        // Check if the value of the key is equal in current data and previousData
        return JSON.stringify(value) === JSON.stringify(previousData[key]);
    }
    // If the key doesn't exist in previousData, consider it as not the same
    return false;
};

const getFormDataIfFilesExists = (data, previousData) => {
    const formData = new FormData();
    let hasFile = false;
    for (const [key, value] of Object.entries(data)) {

        // Check if the value is an object and has a property called "title" and src ImageInput
        if (value && typeof value === 'object' && 'title' in value && 'src' in value) {
            if ('rawFile' in value && !isSameAsPrevious(key, value, previousData)) {
                const rawFile = value.rawFile;
                formData.append(`${key}_file`, rawFile);
            }
            hasFile = true;
        } else {
            if (!isSameAsPrevious(key, value, previousData) && value) formData.append(key, value);

        }
    }

    if (hasFile)
        return formData
    else
        return null

}




export const deviceToActivate = deviceSn => {
    return httpClient({
        resource: "devices",
        url: `devices/serial_number/${deviceSn}`,
        options: {
            method: 'GET',
        }
    })
}

export const rootOrganization = organizationId => {
    return httpClient({
        resource: "uaa",
        url: `uaa/organizations/${organizationId}?includes=root_organization_id`,
        options: {
            method: 'GET',
        }
    })


}

export const organizationName = organizationId => {

    return httpClient({
        resource: "uaa",
        url: `uaa/organizations/${organizationId}`,
        options: {
            method: 'GET',
        }
    })
}


export const deviceToSubscribe = deviceId => {
    return httpClient({
        resource: "devices",
        url: `devices/${deviceId}?includes=product,organization,device_id,last_subscription`,
        options: {
            method: 'GET',
        }
    })
}

export const deviceWithProduct = deviceId => {
    return httpClient({
        resource: "devices",
        url: `devices/${deviceId}?includes=product,organization,mac_address,device_id`,
        options: {
            method: 'GET',
        }
    })
}



export const deviceName = deviceId => {

    return httpClient({
        resource: "devices",
        url: `devices/${deviceId}?includes=mac_address`,
        options: {
            method: 'GET',
        }
    })
}

export const deviceEventName = (deviceId, name, filters) => {
    let params = '';
    if (filters)
        params = '&' + stringify(filters)

    return httpClient({
        resource: "events",
        url: `events?names=${name}&includes=event_id&device_ids=${deviceId}${params}`,
        options: {
            method: 'GET',
        }
    })
}


export const deviceAttach = ({ name, serial_number }) => {

    return httpClient({
        resource: "devices",
        url: 'devices/attach',
        options: {
            method: 'POST',
            body: JSON.stringify({
                type: 'unb',
                name,
                serial_number,
            }),
        }
    })
}




export const createIntent = subscription => {
    return httpClient({
        resource: "subscriptions",
        url: 'subscriptions/intent',
        options: {
            method: 'POST',
            body: JSON.stringify(subscription),
        }
    })
}


export const getPlans = (deviceId, language) => {

    return httpClient({
        resource: "subscriptions",
        url: `subscriptions/device_sub_plans/device_id/${deviceId}?language=${language.toUpperCase()}`,
        options: { method: 'GET' }
    })
}





export const checkoutSession = (subscription) => {
    //todo source connect
    return httpClient({
        resource: "subscriptions",
        url: 'subscriptions/checkout',
        options: {
            method: 'POST',
            body: JSON.stringify(subscription),
        }
    })
}



export const checkoutSuccess = (sessionId, planType, rootOrganizationId) => {
    return httpClient({
        resource: "subscriptions",
        url: "subscriptions/checkout-success?session_id=" + sessionId + "&root_organization_id=" + rootOrganizationId + '&plan_type=' + planType,
        options: { method: 'GET', }
    })
}

export const getSubscriptions = deviceId => {

    return httpClient({
        resource: "subscriptions",
        url: `subscriptions?device_id=${deviceId}&includes=plan,subscription_id`,
        options: { method: 'GET' }
    })
}


export const getLastSubscription = deviceId => {

    return httpClient({
        resource: "subscriptions",
        url: `subscriptions?device_id=${deviceId}&includes=plan,subscription_id&latest=true`,
        options: { method: 'GET' }
    })
}




export const downloadInvoice = ({ invoiceId, locale }) => {

    return httpClient({
        resource: "invoices",
        url: `subscriptions/invoices/${invoiceId}/download?language=${locale}`,
        options: { method: 'GET' }
    })
}



export const downloadAppOwnerInvoice = ({ invoiceId, locale }) => {

    return httpClient({
        resource: "app_owner_invoices",
        url: `subscriptions/app-owner-invoices/${invoiceId}/download?language=${locale}`,
        options: { method: 'GET' }
    })
}



export const ackNotificationLog = ({ eventId }) => {

    return httpClient({
        resource: "notification_log_events",
        url: `events/${eventId}/ack`,
        options: { method: 'PATCH' }
    })
}
export const nAckNotificationLog = ({ eventId }) => {

    return httpClient({
        resource: "notification_log_events",
        url: `events/${eventId}/unack`,
        options: { method: 'PATCH' }
    })
}


export const validateAppOwnerInvoice = ({ invoiceId }) => {

    return httpClient({
        resource: "app_owner_invoices",
        url: `subscriptions/app-owner-invoices/${invoiceId}/validate`,
        options: { method: 'POST' }
    })
}


export const payAppOwnerInvoice = ({ invoiceId }) => {

    return httpClient({
        resource: "app_owner_invoices",
        url: `subscriptions/app-owner-invoices/${invoiceId}/pay`,
        options: { method: 'POST' }
    })
}

export const activateDeviceSubPlan = ({ planId }) => {

    return httpClient({
        resource: "device_sub_plans",
        url: `subscriptions/device_sub_plans/${planId}/activate`,
        options: { method: 'POST' }
    })
}
export const archiveDeviceSubPlan = ({ planId }) => {

    return httpClient({
        resource: "device_sub_plans",
        url: `subscriptions/device_sub_plans/${planId}/archive`,
        options: { method: 'POST' }
    })
}


export const activateSmsCreditPlan = ({ planId }) => {

    return httpClient({
        resource: "sms_credit_plans",
        url: `subscriptions/sms_credit_plans/${planId}/activate`,
        options: { method: 'POST' }
    })
}
export const archiveSmsCreditPlan = ({ planId }) => {

    return httpClient({
        resource: "sms_credit_plans",
        url: `subscriptions/sms_credit_plans/${planId}/archive`,
        options: { method: 'POST' }
    })
}








export const getAppVersion = () => {

    return httpClient({
        resource: "config",
        url: `config/software_versions?software_name=connect-app`,
        options: { method: 'GET', }
    })
}


export const cancelSubscription = subscriptionId => {
    return httpClient({
        resource: "subscriptions",
        url: `subscriptions/${subscriptionId}/cancel`,
        options: { method: 'DELETE', }
    })
}



export const getMarkerIcons = () => {

    return httpClient({
        resource: "config",
        url: `config/icons/markers`,
        options: { method: 'GET', }
    })
}


export const updateDeviceStatus = ({ id, status }) => {
    return httpClient({
        resource: "devices",
        url: `devices/${id}`,
        options: {
            method: 'PATCH',
            body: JSON.stringify({
                status,
            }),
        }
    })
}

export const updateDeviceIcon = ({ id, device }) => {

    return httpClient({
        resource: "devices",
        url: `devices/${id}`,
        options: {
            method: 'PATCH',
            body: JSON.stringify({
                ...device,
            }),
        }
    })
}


export const updateDeviceNameOrgIdOwnerId = ({ id, device }) => {

    return httpClient({
        resource: "devices",
        url: `devices/${id}`,
        options: {
            method: 'PATCH',
            body: JSON.stringify({
                ...device,
            }),
        }
    })
}




export const updateDeviceAddress = ({ id, formatted_address }) => {

    return httpClient({
        resource: "devices",
        url: `devices/${id}`,
        options: {
            method: 'PATCH',
            body: JSON.stringify({
                formatted_address,
            }),
        }
    })
}

export const updateDeviceConfiguation = ({ device_configuration }) => {

    return httpClient({
        resource: "config",
        url: `config/devices/${device_configuration.id}`,
        options: {
            method: 'PATCH',
            body: JSON.stringify({
                ...device_configuration,
            }),
        }
    })
}



export const updateDeviceConfiguationAsSuperAdmin = ({ device_configuration }) => {

    return httpClient({
        resource: "config",
        url: `config/devices/super_admin/${device_configuration.id}`,
        options: {
            method: 'PATCH',
            body: JSON.stringify({
                ...device_configuration,
            }),
        }
    })
}


export const updateEventAddress = ({ id, formatted_address }) => {
    return httpClient({
        resource: "events",
        url: `events/${id}`,
        options: {
            method: 'PATCH',
            body: JSON.stringify({
                formatted_address,
            }),
        }
    })

}

export const updateAccountInfo = ({ id, account }) => {

    return httpClient({
        resource: "uaa",
        url: `uaa/account`,
        options: {
            method: 'PATCH',
            body: JSON.stringify({
                ...account,
            }),
        }
    })
}


export const addEvents = (events) => {

    return httpClient({
        resource: "events",
        url: `events`,
        options: {
            method: 'POST',
            body: JSON.stringify(events),
        }
    })
}


export const searchVoucher = code => {

    return httpClient({
        resource: "subscriptions",
        url: `subscriptions/vouchers/code/${code}`,
        options: { method: 'GET', }
    })
}


export const verifySmsDestination = (id, code) => {

    return httpClient({
        resource: "notifications",
        url: `notifications/sms_destination/${id}/verify?verification_code=${code}`,
        options: { method: 'POST', }
    })
}

export const verifyEmailDestination = (id, code) => {

    return httpClient({
        resource: "notifications",
        url: `notifications/email_destination/${id}/verify?verification_code=${code}`,
        options: { method: 'POST', }
    })
}

export const resendCodeForSmsDestination = (id) => {

    return httpClient({
        resource: "notifications",
        url: `notifications/sms_destination/${id}/resend_code`,
        options: { method: 'POST', }
    })
}

export const resendCodeForEmailDestination = (id) => {

    return httpClient({
        resource: "notifications",
        url: `notifications/email_destination/${id}/resend_code`,
        options: { method: 'POST', }
    })
}

export const addVoucherSubscription = subscription => {

    return httpClient({
        resource: "subscriptions",
        url: 'subscriptions/activate_voucher',
        options: {
            method: 'POST',
            body: JSON.stringify(subscription),
        }
    })
}


export const reverseGeocode = (lat, lon) => {
    return fetch(
        `https://eu1.locationiq.org/v1/reverse.php?key=9074bc8605599b&lat=${lat}&lon=${lon}&format=json&zoom=18`,
        {}
    )
}

export const getGeofenceFromDeviceId = deviceId => {
    return httpClient({
        resource: "geofences",
        url: `geofences?includes=geofence_id&device_id=${deviceId}`,
        options: { method: 'GET', }
    })
}

export const getNotificationFromDeviceId = deviceId => {

    return httpClient({
        resource: "notifications",
        url: `notifications?includes=notification_id&device_id=${deviceId}`,
        options: { method: 'GET', }
    })
}

export const getImage = fullPath => {
    const token = localStorage.getItem(USER_ACCESS_TOKEN)
    const opts = {
        method: 'GET',
        headers: new Headers({
            Authorization: `Bearer ${token}`,
        }),
    }

    return fetch(fullPath, opts)
}

export const setImage = (deviceId, payload) => {
    const fullPath = process.env.REACT_APP_API_URL + `/devices/${deviceId}/image`
    const token = localStorage.getItem(USER_ACCESS_TOKEN)
    const opts = {
        method: 'post',
        body: payload,
        headers: new Headers({
            Authorization: `Bearer ${token}`,
        }),
    }

    return fetch(fullPath, opts)
}

export const createGeofenceFromDeviceId = (
    deviceId,
    payload = {
        radius: 50,
        latitude: 48.810106,
        longitude: -3.501209,
        on_entry: false,
        on_exit: false,
        active: false,
    }
) => {
    return httpClient({
        resource: `geofences`,
        url: `geofences`,
        options: {
            method: 'POST',
            body: JSON.stringify({ device_id: deviceId, ...payload }),
        }
    })
}



export const createNotificationFromDeviceId = (
    deviceId,
    payload = {
        channel: 'sms',
        event: 'geofence_crossed',
        to: ['<phone number>'],
        message: '<Alert balise>',
        options: ['append_map_to_message'],
    }
) => {

    return httpClient(
        {
            resource: "notifications",
            url: `notifications`,
            options: {
                method: 'POST',
                body: JSON.stringify({ device_id: deviceId, ...payload }),
            }
        }
    )
}



export function formFetch(url, payload = undefined, method = 'post') {
    const fullPath = process.env.REACT_APP_API_URL + url
    const token = localStorage.getItem(USER_ACCESS_TOKEN)
    const opts = {
        method,
        headers: new Headers({
            mode: 'formdata',
            formdata: [
                {
                    key: 'image',
                    type: 'file',
                },
            ],
            Authorization: `Bearer ${token}`,
        }),
    }
    if (payload) {
        opts.body = getFormData(payload)
    }

    return fetch(fullPath, opts)
}


const getFormData = data => {
    const formData = new FormData()

    for (let name in data) {
        if (data[name]) {
            formData.append(name, data[name])
        }
    }
    return formData
}

export const getDevicesWithSubscriptions = async () => {
    const url = '/devices?includes=mac_address%2Cproduct%2Corganization_id%2Cstatus%2Ctype%2Cowner%2Cconfiguration%2Clast_subscription%2Cdevice_id%2Clast_location&page=0&size=25&sort=id%2CASC'

    const response = await httpClient({
        resource: "devices",
        url,
        options: {
            method: 'GET',
        }
    })
    return response
}





export const getUserRemaingSmsCredit = (userId) => {
    return httpClient({
        resource: "subscriptions",
        url: 'subscriptions/sms_credit/remaining_credit/user_id/' + userId,
        options: {
            method: 'GET',
        }
    })
}





export const getSmsCreditPlans = (userId, language) => {

    return httpClient({
        resource: "subscriptions",
        url: `subscriptions/sms_credit_plans/user_id/${userId}?language=${language.toUpperCase()}`,
        options: { method: 'GET' }
    })
}



export const smsCreditCheckoutSession = (smsCreditsPurchase) => {

    return httpClient({
        resource: "subscriptions",
        url: 'subscriptions/sms_credit/checkout',
        options: {
            method: 'POST',
            body: JSON.stringify(smsCreditsPurchase),
        }
    })
}


export const billingPortalSession = () => {

    return httpClient({
        resource: "subscriptions",
        url: 'subscriptions/fops/portal',
        options: {
            method: 'POST'
        }
    })
}


export const getDevicesSummary = () => {

    return httpClient({
        resource: "devices",
        url: 'devices/summary',
        options: {
            method: 'GET',
        }
    })
}


export const getUserUiSetup = (userId) => {

    return httpClient({
        resource: "users",
        url: `uaa/users/ui_setup/${userId}`,
        options: {
            method: 'GET',
        }
    })
}


export const hideUiMessage = (userId, patchUserUiSetup) => {

    return httpClient({
        resource: "users",
        url: `uaa/users/ui_setup/${userId}`,
        options: {
            method: 'PATCH',
            body: JSON.stringify(patchUserUiSetup),
        }
    })
}


export const getBroadcastMessages = () => {

    return httpClient({
        resource: "config",
        url: `config/broadcast_messages`,
        options: {
            method: 'GET',
        }
    })
}





