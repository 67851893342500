import React from 'react';
import compose from 'recompose/compose'
import PropTypes from 'prop-types';
import LocationIcon from '@mui/icons-material/Map';
import { Link } from 'react-router-dom';
import { linkToRecord, useTranslate } from 'react-admin';
import { Button, Tooltip, useTheme } from '@mui/material'
import { listActionsColumnIconCommonStyle } from '../../themes/commonStyles';



const Icon = ({ color }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_7_569009)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9 3.25C9.12598 3.25 9.24471 3.28106 9.34894 3.33594L15 6.16148L20.6646 3.32918C20.8971 3.21293 21.1732 3.22536 21.3943 3.36201C21.6154 3.49867 21.75 3.74007 21.75 4V17C21.75 17.2841 21.5895 17.5438 21.3354 17.6708L15.3489 20.6641C15.2506 20.7158 15.1395 20.7464 15.0215 20.7497C15.0144 20.7499 15.0072 20.75 15 20.75C14.9884 20.75 14.9768 20.7497 14.9652 20.7492C14.8522 20.744 14.7457 20.7139 14.6511 20.6641L9 17.8385L3.33541 20.6708C3.10292 20.7871 2.82681 20.7746 2.6057 20.638C2.38459 20.5013 2.25 20.2599 2.25 20V7C2.25 6.71592 2.4105 6.45622 2.66459 6.32918L8.65106 3.33594C8.75529 3.28106 8.87402 3.25 9 3.25ZM15.75 18.7865L20.25 16.5365V5.21353L15.75 7.46353V18.7865ZM14.25 7.46353V18.7865L9.75 16.5365V5.21353L14.25 7.46353ZM3.75 7.46353L8.25 5.21353V16.5365L3.75 18.7865V7.46353Z" fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_7_569009">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
const LocationButton = ({
    basePath = '',
    disabled,
    record = {},
    ...rest
}) => {
    const translate = useTranslate();
    const theme = useTheme();
    const isLightMode = theme.palette.mode === 'light';
    const disabledColor = isLightMode ? theme.palette.grey[400] : theme.palette.grey[500];
    return (

        <Tooltip title={disabled ? "" : translate(`resources.devices.browse_location`)} disableInteractive >
            <Button
                component={Link}
                disabled={disabled}
                to={`/locations/${record.id}/history`}
                {...rest}
                sx={listActionsColumnIconCommonStyle({ isLastItem: true })}
            >
                <Icon color={disabled ? disabledColor : theme.palette.primary.main} />
            </Button>
        </Tooltip>
    );
}

LocationButton.propTypes = {
    basePath: PropTypes.string,
    className: PropTypes.string,
    classes: PropTypes.object,
    record: PropTypes.object,
};


export default LocationButton