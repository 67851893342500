import * as React from 'react';
import { Children, isValidElement, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    useListContext,
    useResourceContext,
    Identifier,
    RaRecord,
    SortPayload,
    useTranslate,
} from 'ra-core';
import { Checkbox, TableCell, TableHead, TableRow } from '@mui/material';
import clsx from 'clsx';

import { DatagridHeaderCell, useDatagridContext, DatagridClasses } from 'ra-ui-materialui';
import { SelectColumnsButton } from '../../../ui/button/SelectColumnsButton';

/**
 * The default Datagrid Header component.
 *
 * Renders select all checkbox as well as column header buttons used for sorting.
 */
export const DatagridHeader = (props) => {
    const {
        children, columns,
        className,
        hasExpand = false,
        hasBulkActions = false,
        isRowSelectable,
    } = props;
    const resource = useResourceContext(props);
    const translate = useTranslate();
    const { sort, data, onSelect, selectedIds, setSort } = useListContext(
        props
    );
    const { expandSingle } = useDatagridContext();

    const updateSortCallback = useCallback(
        event => {
            event.stopPropagation();
            const newField = event.currentTarget.dataset.field;
            const newOrder =
                sort.field === newField
                    ? sort.order === 'ASC'
                        ? 'DESC'
                        : 'ASC'
                    : event.currentTarget.dataset.order;

            setSort({ field: newField, order: newOrder });
        },
        [sort.field, sort.order, setSort]
    );

    const updateSort = setSort ? updateSortCallback : null;

    const handleSelectAll = useCallback(
        event =>
            onSelect(
                event.target.checked
                    ? selectedIds.concat(
                        data
                            .filter(
                                record => !selectedIds.includes(record.id)
                            )
                            .filter(record =>
                                isRowSelectable
                                    ? isRowSelectable(record)
                                    : true
                            )
                            .map(record => record.id)
                    )
                    : []
            ),
        [data, onSelect, isRowSelectable, selectedIds]
    );

    const selectableIds = Array.isArray(data)
        ? isRowSelectable
            ? data
                .filter(record => isRowSelectable(record))
                .map(record => record.id)
            : data.map(record => record.id)
        : [];

    return (
        <TableHead className={clsx(className, DatagridClasses.thead)}>
            <TableRow
                className={clsx(DatagridClasses.row, DatagridClasses.headerRow)}
            >

                {hasBulkActions && selectedIds && (
                    <TableCell
                        padding="checkbox"
                        className={DatagridClasses.headerCell}
                    >
                        <Checkbox
                            aria-label={translate('ra.action.select_all', {
                                _: 'Select all',
                            })}
                            className="select-all"
                            color="primary"
                            checked={
                                selectedIds.length > 0 &&
                                selectableIds.length > 0 &&
                                selectableIds.every(id =>
                                    selectedIds.includes(id)
                                )
                            }
                            onChange={handleSelectAll}
                        />
                    </TableCell>
                )}
                {Children.map(children, (field, index) =>
                    isValidElement(field) ? (
                        <DatagridHeaderCell
                            className={clsx(
                                DatagridClasses.headerCell,
                                `column-${(field.props).source}`
                            )}
                            sort={sort}
                            field={field}
                            isSorting={
                                sort.field ===
                                ((field.props).sortBy ||
                                    (field.props).source)
                            }
                            key={(field.props).source || index}
                            resource={resource}
                            updateSort={updateSort}
                        />
                    ) : null
                )}
                <TableCell sx={{
                    padding: "6px 0px 6px 0px",
                }}>
                    <SelectColumnsButton columns={columns} />
                </TableCell>
            </TableRow>
        </TableHead>
    );
};

DatagridHeader.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    sort: PropTypes.exact({
        field: PropTypes.string,
        order: PropTypes.string,
    }),
    data: PropTypes.arrayOf(PropTypes.any),
    hasExpand: PropTypes.bool,
    hasBulkActions: PropTypes.bool,
    isRowSelectable: PropTypes.func,
    isRowExpandable: PropTypes.func,
    onSelect: PropTypes.func,
    onToggleItem: PropTypes.func,
    resource: PropTypes.string,
    selectedIds: PropTypes.arrayOf(PropTypes.any),
    setSort: PropTypes.func,
};



DatagridHeader.displayName = 'DatagridHeader';
