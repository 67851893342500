import { Box, Button, Typography, useTheme } from '@mui/material';
import { CreateButton, useTranslate, useListContext, usePermissions } from 'react-admin';
import { AddBtnWithOptionalMenu } from '../../../ui/button/AddBtn';
import { hasWriteAccess, isAdminPermissions, isSuperAdmin } from '../../../helper/resourceHelper';




const Empty = ({ btnLabel, message, subMessage }) => {
    const theme = useTheme();
    const { resource } = useListContext();
    const { isLoading, permissions } = usePermissions();
    const addBtnMenuItems = []


    if (isAdminPermissions(permissions)) {
        addBtnMenuItems.push({
            label: btnLabel,
            path: `/${resource}/create`
        })
    }
    return (<Box
        sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: "100px 0px 100px 0px"

        }}>
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '360px',
            width: '300px',
            padding: "60px 40px 60px 40px",
            backgroundColor: theme.palette.background.paper,
            borderRadius: "12px",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",

        }}>
            <Typography sx={{
                margin: "24px 0px 24px 0px",
                fontWeight: 700,
                fontSize: "20px",
                lineHeight: "28px",
                color: theme.palette.text.primary
            }}>
                {message}
            </Typography>
            <Typography sx={{
                margin: "0px 0px 24px 0px",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px",
                color: theme.palette.text.primary
            }}>
                {subMessage}
            </Typography>

            <AddBtnWithOptionalMenu menuItems={addBtnMenuItems} width="64" height="64" />
        </Box>

    </Box>)
}


export default Empty;