import React from 'react';
import Avatar from '@mui/material/Avatar';




const AvatarField = ({ avatarUrl, size }) => {
    return(

    <Avatar 
        src={`${avatarUrl}?size=${size}x${size}`}
        size={size}
        style={{ width: size, height: size }}
    />
)};

AvatarField.defaultProps = {
    size: 25,
};

export default AvatarField;