//https://codesandbox.io/p/sandbox/github/final-form/react-final-form/tree/master/examples/wizard?file=%2Findex.js%3A5%2C20

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import { useTheme, Button, Paper, Divider, useMediaQuery, Typography, Box } from '@mui/material'
import { Toolbar, useTranslate, TopToolbar } from 'react-admin'
import SettingsIcon from '@mui/icons-material/Settings';
import { fontWeight } from '@mui/system'
import NavigateBackBtn from '../button/NavigateBackBtn'

const customStyles = ({ theme }) => ({
    form: {
        alignItems: 'center',
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        height: 'inherit',
        justifyContent: 'space-between'
    },
})


const NextButton = ({ label }) => {
    const translate = useTranslate();
    return <Button variant='contained' type="submit" sx={{ marginX: "10px" }}>{label ? label : translate('resources.common.next')}</Button>

}
const PreviousButton = ({ label, onClick }) => {
    const translate = useTranslate();
    return <Button variant='outlined' type="button" onClick={onClick} sx={{ marginX: "10px" }}>{label ? label : translate('resources.common.back')}</Button>

}
const SubmitButton = ({ label, disabled }) => {
    const translate = useTranslate();
    return <Button variant='contained' type="submit" disabled={disabled} sx={{ marginX: "10px" }}>{label ? label : translate('ra.action.save')}</Button>

}

const PageIconByNumber = ({ number, bgcolor, color }) => {
    return (
        <Box sx={{
            display: "flex",
            padding: "4px",
            width: "22px",
            height: "22px",
            borderRadius: "100px",
            bgcolor: bgcolor,
            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "14.06px",
            alignItems: "center",
            justifyContent: "center",
            color: color,

        }}>
            {number < 10 ? "0" + (number) : (number)}
        </Box>
    )
}

const PreviousPageIcon = () => {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM16.2 10.3L11.4 15.1C11 15.5 10.4 15.5 10 15.1L7.8 12.9C7.4 12.5 7.4 11.9 7.8 11.5C8.2 11.1 8.8 11.1 9.2 11.5L10.7 13L14.8 8.9C15.2 8.5 15.8 8.5 16.2 8.9C16.6 9.3 16.6 9.9 16.2 10.3Z" fill="#00C853" />
    </svg>
    )
}

const ActivePageIcon = ({ number, theme }) => <PageIconByNumber number={number} color={"#ffffff"} bgcolor={theme.palette.primary.main} />
const NextPageIcon = ({ number, theme }) => <PageIconByNumber number={number} color={"#ffffff"} bgcolor={theme.palette.text.secondary} />





const CustomStepper = ({ pages, activePageIdx }) => {
    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    if (!pages || !Array.isArray(pages)) return null
    var steps = []
    const stepBoxWidth = (100 / (pages.length - 0.15))
    const lastStepBoxWidth = stepBoxWidth * 0.85
    pages.map((page, idx) => {
        const isActivePage = activePageIdx === idx
        const isFuturPage = activePageIdx < idx
        const isPreviousPage = !isActivePage && !isFuturPage;
        const showLabel = !isSmall || isActivePage

        steps.push(
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: !isSmall ? ((idx !== (pages.length - 1)) ? `${stepBoxWidth}%` : `${lastStepBoxWidth}%`) : "auto",
                    height: "42px",
                    padding: "10px, 10px, 10px, 7px",
                    margin: "0px !important"
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        width: `85%`,
                        height: "42px",
                    }}
                >

                    {isPreviousPage && <PreviousPageIcon />}
                    {isActivePage && <ActivePageIcon number={idx + 1} theme={theme} />}
                    {isFuturPage && <NextPageIcon number={idx + 1} theme={theme} />}

                    {showLabel &&
                        <Typography sx={{
                            lineHeight: "20px",
                            fontWeight: 400,
                            margin: "1px 10px 1px 10px",
                            color: theme.palette.text.secondary
                        }}>

                            {(page.props && page.props.label) ? (
                                page.props.label.length < 35 ?
                                    page.props.label :
                                    page.props.label.substring(0, 35) + "..."
                            )

                                : "..."
                            }
                        </Typography>}
                </Box>

                {(idx !== (pages.length - 1)) ? <Divider sx={{
                    width: `15%`,
                    margin: "1px 10px 1px 10px",
                    backgroundColor: (isFuturPage || isActivePage) ? theme.palette.grey[500] : theme.palette.primary.dark
                }} /> : null}
            </Box>




        )

    }
    )
    return (

        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: "20px 20px 20px 15px",
            margin: "0px !important",
            width: "100%",
            height: "82px",
        }}>
            {steps}
        </Box>
    )

}



export const Wizard = (props) => {
    const theme = useTheme()
    const [page, setPage] = useState(props.initialPage || 0);
    const [values, setValues] = useState(props.initialValues || {});

    const { children, onSubmit, previousBtnlabel, nextBtnlabel, submitBtnlabel, title } = props



    const isForbiddenPage = (pageIndex, v) => {

        if (v.forbiddenPages && Array.isArray(v.forbiddenPages)) {
            return v.forbiddenPages.includes(pageIndex);
        }

        return false
    }

    const getNextPageIndex = (pageIndex, v) => {
        let nextPage = pageIndex;
        const totalPages = children.length
        do {
            nextPage = (nextPage + 1) % totalPages;
        } while (isForbiddenPage(nextPage, v));

        return Math.min(nextPage, totalPages);
    }

    const getPreviousPageIndex = (pageIndex, v) => {
        let previousPage = pageIndex;
        const totalPages = children.length
        do {
            previousPage = (previousPage - 1 + totalPages) % totalPages;
        } while (isForbiddenPage(previousPage, v));

        return Math.max(previousPage, 0);
    }


    const next = async values => {
        handleNextStep(values).then(resp => {
            if (resp.success === true) {
                setValues(resp.values)
                setPage(getNextPageIndex(page, resp.values))
            }
        }).catch(err => console.log("err "))

    }

    const handleNextStep = (values) => {
        const activePage = React.Children.toArray(children)[
            page
        ]
        return activePage.props.next ? activePage.props.next(values) : {}
    }

    const previous = () => {
        setPage(getPreviousPageIndex(page, values))
    }


    const validate = values => {
        const activePage = React.Children.toArray(children)[
            page
        ]
        return activePage.props.validate ? activePage.props.validate(values) : {}
    }

    const handleSubmit = values => {
        const isLastPage = page === React.Children.count(children) - 1
        if (isLastPage) {
            return onSubmit(values)
        } else {
            next(values)
        }
    }

    const activePage = React.Children.toArray(children)[page]
    const isLastPage = page === React.Children.count(children) - 1



    return (
        <Box sx={{
            width: '100%',
            height: '100%',
            background: "none",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "0px 0px 0px 0px",
        }}
        >

            <Typography sx={{
                color: theme.palette.text.primary, textAlign: 'left', fontWeight: 700, fontSize: "24px", lineHeight: "42px", margin: "0px 0px 16px 0px",
                width: '100%',
            }}>
                <NavigateBackBtn /> {title}
            </Typography>

            <Paper elevation={0} sx={{
                width: '100%',
                backgroundColor: theme.palette.background.paper,
                borderRadius: "12px"
            }}>
                <CustomStepper pages={React.Children.toArray(children)} activePageIdx={page} />
                <Form
                    initialValues={values}
                    validate={validate}
                    onSubmit={handleSubmit}
                >
                    {({ handleSubmit, submitting, values }) => (
                        <form onSubmit={handleSubmit}>

                            {activePage}


                            <Toolbar sx={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', flexDirection: 'row', backgroundColor: theme.palette.background.paper, padding: "0px 20px 20px 20px" }}>

                                {page > 0 ? <PreviousButton onClick={previous} label={previousBtnlabel} /> : <div />
                                }
                                {!isLastPage && <NextButton label={nextBtnlabel} />}
                                {isLastPage && <SubmitButton disabled={submitting} label={submitBtnlabel} />
                                }

                            </Toolbar>
                        </form>
                    )}
                </Form>
            </Paper>

        </Box>
    )

}

Wizard.Page = ({ children }) => children


Wizard.propTypes = {
    onSubmit: PropTypes.func.isRequired
}



const WizardFormComponent = (props) => {

    const { styles, invalid, pristine, saving, submitOnEnter, toolbar, nextPage, previousPage, redirect, handleSubmit, children } = props
    const theme = useTheme();

    const handleSubmitWithRedirect = () => {
        if (redirect) {
            handleSubmit().then((e) => {
                redirect();
            })
        } else {
            handleSubmit();
        }

    }


    return (
        <form
            style={{ ...styles, ...customStyles({ theme }) }}
        >
            {children}
            {toolbar &&
                React.cloneElement(toolbar, {
                    handleSubmitWithRedirect: handleSubmitWithRedirect,
                    handleNextPage: nextPage,
                    handlePreviousPage: previousPage,
                    invalid,
                    pristine,
                    submitOnEnter,
                    saving,
                    styles: { display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap', backgroundColor: 'inherit' },

                })}
        </form>
    )

}

WizardFormComponent.propTypes = {

    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    handleSubmit: PropTypes.func, // passed by react-final-form
    invalid: PropTypes.bool,
    nextPage: PropTypes.func,
    previousPage: PropTypes.func,
    pristine: PropTypes.bool,
    record: PropTypes.object,
    resource: PropTypes.string,
    redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    submitOnEnter: PropTypes.bool,
    title: PropTypes.string,
    toolbar: PropTypes.element,
    validate: PropTypes.func,
    version: PropTypes.number,
}

WizardFormComponent.defaultProps = {
    nextPage: null,
    submitOnEnter: true,
    title: '',
    toolbar: <Toolbar />,
    redirect: false,
}

const WizardForm = (props => <Form {...props} component={WizardFormComponent} />)

export default WizardForm



