export const faqFR = [
    {
        title: "Abonnement",
        content: [
            {
                title: "Comment renouveler l'abonnement d'une balise ?",
                content: `
1) Selectionner le Menu **Balise** dans le bandeau de gauche

2) Sélectionnez l'icone "Abonnement" (image d'une carte de crédit)

3) Selectionnez "Ajouter un Abonnement"

4) Selectionnez la formule "Abonnement" et cliquez sur Suivant
            `
            },
            {
                title: "Comment annuler l'abonnement d'une balise ?",
                content: `
1) Selectionner le Menu ** Balise ** dans le bandeau de gauche
2) Sélectionnez l\'icone "Abonnement" (image d'une carte de crédit)
3) Selectionnez "ANNULER L ABONNEMENT"

            `
            },
            {
                title: "Comment ajouter une Carte Bancaire ?",
                content: `
1) Selectionner le Menu ** Moyen de paiement ** dans le bandeau de gauche

Vous pourrez alors ajouter votre nouvelle carte et supprimer l'ancienne.
                `
            },
            {
                title: "Comment recharger mon credit SMS ?",
                content: `
    
Sachez que tous les mois votre abonnement inclus **30 SMS gratuits**.
Si cela ne suffit pas, vous avez la possibilité d'acheter du crédit SMS supplémentaire.
Veuillez trouver ci dessous la marche à suivre,

1) ???
                
                `
            }

        ]
    },
    {
        title: "Factures",
        content: [
            {
                title: "Comment telecharger une Facture ?",
                content: `
                
Selectionnez le menu **Factures** sur la gauche
Vous pourrez alors afficher ou télécharger une facture de vos achats.
    
                `
            }
        ]
    },
    {
        title: "Données",
        content: [
            {
                title: "Combien de temps sont conservées les données sur la plateforme ?",
                content: `
    
La plateforme CONNECT conserve par défaut les données d'un utilisateur sur une période de **12 mois** glissant.
                `
            }

        ]
    },
    {
        title: "Réseau",
        content: [

            {
                title: "Comment fonctionne la balise sur SIGFOX ?",
                content: `

Ci dessous un petit résumé du fonctionnement de ce type de balise **basse consommation** sur le réseau **SIGFOX**:

Cette balise s'active dès qu'elle bouge, et elle fait une tentative de remontée de position toutes les 5 minutes par défaut (une fois par jour sinon).
Pour que le point remonte **il faut que les 2 conditions ci dessous soient remplies**:

1) Que la balise puisse prendre la position GPS ... plutôt à l'extérieur pour voir les satellites  (dans une voiture à l'extérieur ca marche )

2) Qu'il y ait du réseau sigfox (cf www.sigfox.com/coverage) ... et que l'objet ne soit pas trop en mobilité (càd que véhicule soit plutôt à l'arrêt)

Si une des conditions est **NOK** la balise refera une tentative 5 minutes plus tard.

Le nombre de points remontés dépend donc beaucoup des conditions, sachant que cette balise a pour objectif de rester Basse consommation pour préserver son autonomie.

Sachez qu'en mobilité ce type de réseau n'est pas très performant, les points seront donc majoritairement remontés en cas d'arrêt du véhicule (si la balise est dans un véhicule ...)

                `
            },
            {
                title: "Qelle différence entre **SIGFOX** et la **4G** ?",
                content: `
                
Les balises qui utilisent la **4G** vont pouvoir bénéficier de la couverture réseau des opérateurs mobile, vous aurez donc une meilleur chance de capter le réseau. En complément, à la différence du réseau **SIGFOX**, 
le réseau **4G** assure une Qualité de service qui permet de retransmettre une position si la balise n'était pas dans une zone couverte.
Enfin, avec les fonctionalités **LTE-M** et **NB-IOT** la balise obtient des performances Basse consommation équivalentes * **SIGFOX** ( ou **LoRa**) pour offrir des autonomie de plusieurs années avec de simple piles AA.
    
                `
            }
        ]
    },
    {
        title: "Application Mobile",
        content: [

            {
                title: "Ou puis-je trouver l'application mobile ?",
                content: `
    
Avec la nouvelle version de la plateforme (Connect V2) il n'est plus nécessaire de télécharger une application sur les stores IOS ou Android.
Ci dessous la marche à suivre sur votre mobile

1) A l'aide de votre navigateur (Safari ou Chrome) allez sur le site [www.ticatag.com](https://www.ticatag.com) et appuyer sur le bouton **CONNECT**
2) Entrez vos identifiants ( email et mot de passe)

** Si vous être sur un iPhone**
3) À droite de la barre d'adresse, appuyez sur Partager. Ajouter à l'écran d'accueil.
4) Modifiez les informations du site Web avec le nom « CONNECT V2 »
5) Appuyez sur Ajouter.
C'est fait, une icone "CONNECT V2" a été créé sur votre iPhone comme une application standard.

Utilisez cette icone pour vos prochaines connexion

    ** Si vous être sur un Android**
3) À droite de la barre d'adresse, appuyez sur Partager. Ajouter à l'écran d'accueil.
4) Modifiez les informations du site Web avec le nom « CONNECT V2 »
5) Appuyez sur Ajouter.
C'est fait, une icone "CONNECT V2" a été créé sur votre iPhone comme une application standard.

Utilisez cette icone pour vos prochaines connexion           
    
                `
            }

        ]
    },

]


