import React from 'react'

import {
    Edit,
    minLength,
    ReferenceInput,
    required,
    SelectInput,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    useTranslate,
    ImageInput,
    ImageField,
} from 'react-admin'
import { Typography, useTheme } from '@mui/material';
import { USER_ORGANIZATION_ID } from '../../configuration/actions';
import styledFormInputs from '../../../ui/inputs/styledFormInputs';
import NavigateBackBtn from '../../../ui/button/NavigateBackBtn';



const validateRequired = [required('Champs obligatoire')]
const validateMinLength = [
    required('Champs obligatoire'),
    minLength(5, 'La longueur de la données doit etre >= 5'),
]

const RootOrganizationEditToolBar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);



const RootOrganizationEdit = () => {
    const translate = useTranslate();
    const theme = useTheme();


    const inputsDataGeneral = [
        { name: 'name', label: translate('resources.root_organization.name'), validate: validateRequired, type: "text" },
        { name: 'app_title', label: translate('resources.root_organization.app_title'), validate: validateRequired, type: "text" },
        { name: 'company_url', label: translate('resources.root_organization.company_url'), validate: validateRequired, width: "480px", type: "text" },
        { name: 'contact_page_url', label: translate('resources.root_organization.contact_page_url'), validate: validateRequired, width: "480px", type: "text" },
        { name: 'admin_email', label: translate('resources.root_organization.admin_email'), validate: validateRequired, width: "480px", type: "text", disabled: true }
    ]

    const inputsDataAppConf = [
        { name: 'app_url1', label: translate('resources.root_organization.app_url1'), validate: validateRequired, width: "480px", type: "text" },
        { name: 'app_url2', label: translate('resources.root_organization.app_url2'), width: "480px", type: "text" },
        { name: 'app_url3', label: translate('resources.root_organization.app_url3'), width: "480px", type: "text" },
        { name: 'firebase_api_key', label: translate('resources.root_organization.firebase_api_key'), validate: validateRequired, width: "480px", type: "text" },
        { name: 'firebase_auth_domain', label: translate('resources.root_organization.firebase_auth_domain'), validate: validateRequired, width: "480px", type: "text" },
        { name: 'firebase_project_id', label: translate('resources.root_organization.firebase_project_id'), validate: validateRequired, width: "480px", type: "text" },
        { name: 'firebase_storage_bucket', label: translate('resources.root_organization.firebase_storage_bucket'), validate: validateRequired, width: "480px", type: "text" },
        { name: 'firebase_messaging_sender_id', label: translate('resources.root_organization.firebase_messaging_sender_id'), validate: validateRequired, width: "480px", type: "text" },
        { name: 'firebase_app_id', label: translate('resources.root_organization.firebase_app_id'), validate: validateRequired, width: "480px", type: "text" },
        { name: 'firebase_measurement_id', label: translate('resources.root_organization.firebase_measurement_id'), validate: validateRequired, width: "480px", type: "text" }
    ]

    const inputsDataTheme = [
        { name: 'connect_header_logo', label: translate('resources.root_organization.connect_header_logo'), validate: validateRequired, width: "480px", type: "file", accept: "image/svg+xml, image/png", subLabel: "type: svg or png , size 224px*50px , max 200KB", maxSize: 200000 },
        { name: 'connect_favicon_logo', label: translate('resources.root_organization.connect_favicon_logo'), validate: validateRequired, width: "480px", type: "file", accept: "image/png", subLabel: "type: png , size 64px*64px , max 200KB", maxSize: 200000 },
        { name: 'connect_icon96x96_png', label: translate('resources.root_organization.connect_icon96x96_png'), validate: validateRequired, width: "480px", type: "file", accept: "image/png", subLabel: "type: png , size 96px*96px , max 200KB", maxSize: 200000 },
        { name: 'connect_icon180x180_png', label: translate('resources.root_organization.connect_icon180x180_png'), validate: validateRequired, width: "480px", type: "file", accept: "image/png", subLabel: "type: png , size 180px*180px , max 200KB", maxSize: 200000 },
        { name: 'connect_icon192x192_png', label: translate('resources.root_organization.connect_icon192x192_png'), validate: validateRequired, width: "480px", type: "file", accept: "image/png", subLabel: "type: png , size 192px*192px , max 200KB", maxSize: 200000 },
        { name: 'connect_icon512x512_png', label: translate('resources.root_organization.connect_icon512x512_png'), validate: validateRequired, width: "480px", type: "file", accept: "image/png", subLabel: "type: png , size 512px*512px , max 200KB", maxSize: 200000 },
        { name: 'auth_page_logo', label: translate('resources.root_organization.auth_page_logo'), validate: validateRequired, width: "480px", type: "file", accept: "image/svg+xml, image/png", subLabel: "type: svg or png , size 220px*100px , max 200KB", maxSize: 200000 },
        { name: 'theme_light_primary_main', label: translate('resources.root_organization.theme_light_primary_main'), validate: validateRequired, type: "text" },
        { name: 'theme_light_primary_dark', label: translate('resources.root_organization.theme_light_primary_dark'), validate: validateRequired, type: "text" },
        { name: 'theme_light_primary_light', label: translate('resources.root_organization.theme_light_primary_light'), validate: validateRequired, type: "text" },
        { name: 'theme_light_background_default', label: translate('resources.root_organization.theme_light_background_default'), validate: validateRequired, type: "text" },
        { name: 'theme_dark_primary_main', label: translate('resources.root_organization.theme_dark_primary_main'), validate: validateRequired, type: "text" },
        { name: 'theme_dark_primary_dark', label: translate('resources.root_organization.theme_dark_primary_dark'), validate: validateRequired, type: "text" },
        { name: 'theme_dark_primary_light', label: translate('resources.root_organization.theme_dark_primary_light'), validate: validateRequired, type: "text" },
        { name: 'theme_dark_background_default', label: translate('resources.root_organization.theme_dark_background_default'), validate: validateRequired, type: "text" },
        { name: 'background_image1', label: translate('resources.root_organization.background_image1'), validate: validateRequired, width: "480px", type: "file", accept: "image/png", subLabel: "type: png , size 1900px*1000px , max 2MB", maxSize: 2000000 },
        { name: 'background_image2', label: translate('resources.root_organization.background_image2'), validate: validateRequired, width: "480px", type: "file", accept: "image/png", subLabel: "type: png , size 1900px*1000px , max 2MB", maxSize: 2000000 },
        { name: 'background_image3', label: translate('resources.root_organization.background_image3'), validate: validateRequired, width: "480px", type: "file", accept: "image/png", subLabel: "type: png , size 1900px*1000px , max 2MB", maxSize: 2000000 },
        { name: 'background_image4', label: translate('resources.root_organization.background_image4'), validate: validateRequired, width: "480px", type: "file", accept: "image/png", subLabel: "type: png , size 1900px*1000px , max 2MB", maxSize: 2000000 },
        { name: 'background_image5', label: translate('resources.root_organization.background_image5'), validate: validateRequired, width: "480px", type: "file", accept: "image/png", subLabel: "type: png , size 1900px*1000px , max 2MB", maxSize: 2000000 },
        { name: 'background_image6', label: translate('resources.root_organization.background_image6'), validate: validateRequired, width: "480px", type: "file", accept: "image/png", subLabel: "type: png , size 1900px*1000px , max 2MB", maxSize: 2000000 }
    ]

    const inputsDataInvoice = [
        { name: 'invoice_issuer_vat_number', label: translate('resources.root_organization.invoice_issuer_vat_number'), validate: validateRequired, type: "text" },
        { name: 'subscription_plans_currency', label: translate('resources.root_organization.subscription_plans_currency'), validate: validateRequired, type: "select", choices: [{ id: 'eur', name: 'EUR' }, { id: 'aud', name: 'AUD' }, { id: 'nzd', name: 'NZD' }, { id: 'usd', name: 'USD' }] },
        { name: 'platform_fee_tax_percent', label: translate('resources.root_organization.platform_fee_tax_percent'), validate: validateRequired, type: "number" },
        { name: 'invoice_number_prefix', label: translate('resources.root_organization.invoice_number_prefix'), validate: validateRequired, type: "text" },
        { name: 'invoice_issuer_name', label: translate('resources.root_organization.invoice_issuer_name'), validate: validateRequired, type: "text" },
        { name: 'invoice_issuer_address', label: translate('resources.root_organization.invoice_issuer_address'), width: "480px", validate: validateRequired, type: "text" },
        { name: 'invoice_issuer_phone_number', label: translate('resources.root_organization.invoice_issuer_phone_number'), validate: validateRequired, type: "text" },
        { name: 'invoice_issuer_email', label: translate('resources.root_organization.invoice_issuer_email'), validate: validateRequired, type: "text" },
        { name: 'invoice_issuer_web_site', label: translate('resources.root_organization.invoice_issuer_web_site'), validate: validateRequired, type: "text" },
        { name: 'invoice_logo', label: translate('resources.root_organization.invoice_logo'), validate: validateRequired, type: "file", accept: "image/png", subLabel: "type: png , size 250px*250px , max 200KB", maxSize: 200000 },
        { name: 'invoice_issuer_description1', label: translate('resources.root_organization.invoice_issuer_description1'), width: "480px", validate: validateRequired, type: "text" },
        { name: 'invoice_issuer_description2', label: translate('resources.root_organization.invoice_issuer_description2'), width: "480px", validate: validateRequired, type: "text" },
        { name: 'invoice_issuer_description3', label: translate('resources.root_organization.invoice_issuer_description3'), width: "480px", validate: validateRequired, type: "text" },
        { name: 'invoice_footer_description1', label: translate('resources.root_organization.invoice_footer_description1'), width: "480px", validate: validateRequired, type: "text" },
        { name: 'invoice_footer_description2', label: translate('resources.root_organization.invoice_footer_description2'), width: "480px", validate: validateRequired, type: "text" },
        { name: 'invoice_footer_description3', label: translate('resources.root_organization.invoice_footer_description3'), width: "480px", validate: validateRequired, type: "text" }
    ]

    const inputsDataNotification = [
        { name: 'notification_sender_email', label: translate('resources.root_organization.notification_sender_email'), validate: validateRequired, type: "text" },
        { name: 'notification_sender_name', label: translate('resources.root_organization.notification_sender_name'), validate: validateRequired, type: "text" },
        { name: 'notification_email_signature1', label: translate('resources.root_organization.notification_email_signature1'), validate: validateRequired, type: "text" },
        { name: 'notification_email_signature2', label: translate('resources.root_organization.notification_email_signature2'), validate: validateRequired, type: "text" },
        { name: 'notification_email_signature3', label: translate('resources.root_organization.notification_email_signature3'), validate: validateRequired, type: "text" },
        { name: 'notification_email_logo', label: translate('resources.root_organization.notification_email_logo'), validate: validateRequired, type: "file", accept: "image/png", subLabel: "type: png , size 220px*100px , max 200KB", maxSize: 200000 },

    ]



    const inputsDataStorage = [
        { name: 'events_ttl_months', label: translate('resources.root_organization.events_ttl_months'), validate: validateRequired, type: "number" },
    ]


    return (
        <>
            <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "24px", lineHeight: "42px", margin: "0px 0px 16px 0px" }}>
                <NavigateBackBtn /> {translate('resources.root_organizations.edit')}
            </Typography>
            <Edit
                title={'resources.organizations.edit'}
                sx={{
                    "& .MuiPaper-root": {
                        backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary,
                    },
                }}
            >
                <SimpleForm toolbar={<RootOrganizationEditToolBar />} >

                    <Typography color="text.main" sx={{
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "25px",
                        textAlign: "center",
                        margin: "0px 0px 10px 0px"
                    }}
                    >
                        {translate('resources.root_organization.sections.general')}
                    </Typography>
                    {styledFormInputs(inputsDataGeneral)}
                    <Typography color="text.main" sx={{
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "25px",
                        textAlign: "center",
                        margin: "20px 0px 10px 0px"
                    }}
                    >
                        {translate('resources.root_organization.sections.appConf')}
                    </Typography>
                    {styledFormInputs(inputsDataAppConf)}
                    <Typography color="text.main" sx={{
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "25px",
                        textAlign: "center",
                        margin: "20px 0px 10px 0px"
                    }}
                    >
                        {translate('resources.root_organization.sections.theme')}
                    </Typography>
                    {styledFormInputs(inputsDataTheme)}
                    <Typography color="text.main" sx={{
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "25px",
                        textAlign: "center",
                        margin: "20px 0px 10px 0px"
                    }}
                    >
                        {translate('resources.root_organization.sections.invoicing')}
                    </Typography>
                    {styledFormInputs(inputsDataInvoice)}
                    <Typography color="text.main" sx={{
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "25px",
                        textAlign: "center",
                        margin: "20px 0px 10px 0px"
                    }}
                    >
                        {translate('resources.root_organization.sections.data_storage')}
                    </Typography>
                    {styledFormInputs(inputsDataStorage)}
                    <Typography color="text.main" sx={{
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "25px",
                        textAlign: "center",
                        margin: "20px 0px 10px 0px"
                    }}
                    >
                        {translate('resources.root_organization.sections.notifications')}
                    </Typography>
                    {styledFormInputs(inputsDataNotification)}

                </SimpleForm>
            </Edit>
        </>
    )
}

export default RootOrganizationEdit