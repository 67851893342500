// in src/comments.js
import React from 'react';
import compose from 'recompose/compose';
import Card from '@mui/material/Card';
import { Box, Grid, Table, Typography, useMediaQuery, useTheme } from '@mui/material'
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import withStyles from '@mui/styles/withStyles';
import { DateField, useTranslate, TextField, TopToolbar, useListContext, usePermissions, FunctionField, ReferenceField } from 'react-admin';
import SettingsButton from '../../../ui/button/SettingsButton'
import SubscriptionButton from '../../../ui/button/SubscriptionButton'
import LocationButton from '../../../ui/button/LocationButton'
import { hasWriteAccess, isAdminPermissions } from '../../../helper/resourceHelper'
import { hasLocation } from '../../../utils/utils'

import AvatarField from '../../../ui/field/AvatarField'
import { Battery } from './Battery'
import TableBody from '@mui/material/TableBody';
import TrackingModeButton from '../../../ui/button/TrackingModeButton';
import { CardDetailRow } from '../../../ui/Common';




const DeviceCardHeader = ({ permissions, device }) => {

    return (

        <Box sx={{
            margin: "24px 24px 8px 24px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"

        }}>
            <Box sx={{
                margin: "0px 0px 20px 0px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
            }}>
                <Box >
                    {getAvatar(device)}
                </Box>
                <Box sx={{
                    margin: "0px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <SettingsButton disabled={!hasWriteAccess(permissions)} basePath={"devices"} record={device} />
                    <SubscriptionButton disabled={device.type !== 'unb' || !hasWriteAccess(permissions)} basePath={"devices"} record={device} />
                    <TrackingModeButton basePath={"devices"} key={device && device.configuration && device.configuration.recovery_mode_status ? device.configuration.recovery_mode_status : "key"} record={device} />
                    <LocationButton disabled={!hasLocation(device)} record={device} />
                </Box>
            </Box>
            <Box sx={{ margin: "0px", }}>
                <Typography sx={{ fontWeight: 700, fontSize: "20px", lineHeight: "28px" }}>{device ? `${device.name}` : ""}</Typography>
            </Box>

        </Box>

    );
}

const DeviceCardContent = ({ device }) => {
    const translate = useTranslate();
    const theme = useTheme();

    return (
        <Box sx={{
            width: "100%",
            padding: "0px 12px 0px 12px",
        }}>
            <Table sx={{
                width: "100%",
            }}>
                <TableBody sx={{
                    margin: "0px",
                }}>
                    {device.mac_address ? (
                        <CardDetailRow label={translate('resources.devices.fields.mac_address')} ValueComponent={() => <TextField source="mac_address" record={device} />} />
                    ) : (device.serial_number && (
                        <CardDetailRow label={translate('resources.devices.fields.mac_address')} ValueComponent={() => <TextField source="serial_number" record={device} />} />
                    ))}

                    {device.owner_id && isAdminPermissions() && (
                        <CardDetailRow label={translate('resources.devices.owner')} ValueComponent={() => <ReferenceField
                            allowEmpty={true}
                            source="owner_id"
                            reference="users"
                            record={device}
                        >
                            <FunctionField
                                render={record => record.first_name && record.last_name ? record.first_name.toUpperCase() + ' ' + record.last_name.toUpperCase() : record.email}
                                sortable={false}
                            />
                        </ReferenceField>} />
                    )}



                    {device.last_battery && (
                        <CardDetailRow label={translate('resources.devices.fields.battery')} ValueComponent={() => <Battery record={device} />} />
                    )}
                    {device.last_seen && (
                        <CardDetailRow label={translate('resources.devices.fields.last_seen')} ValueComponent={() => <DateField record={device} source="last_seen" type="date" showTime />} />
                    )}
                    {device.configuration && device.configuration.recovery_mode_status && (
                        <CardDetailRow label={translate('resources.devices.labels.tracking_mode')} ValueComponent={() => <TrackingModeButton basePath={"devices"} key={device && device.configuration && device.configuration.recovery_mode_status ? device.configuration.recovery_mode_status : "key"} record={device} />} />
                    )}

                </TableBody>
            </Table>
        </Box>
    );
}




const getAvatar = record => {
    const defaultAvatar = <AvatarField avatarUrl="https://ticatagsas.s3.amazonaws.com/57f3315c-e601-410a-9749-57fa11e123fc" size="45" />
    if (!record)
        return defaultAvatar

    if (record.image && record.image.src)
        return <AvatarField avatarUrl={record.image.src} size="45px" />

    if (record.product && record.product.image && record.product.image.src)
        return <AvatarField avatarUrl={record.product.image.src} size="45px" />

    return defaultAvatar
}

const MobileGrid = () => {

    const { data, isLoading } = useListContext();
    const theme = useTheme();

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const permissionsHook = usePermissions();
    const isLoadingPermissions = permissionsHook.isLoading;
    const permissions = permissionsHook.permissions;


    if (isLoading || (data?.length <= 0)) return null;


    return (
        <Grid
            container
            spacing={0}
            sx={{
                margin: '0px',
                width: "100%",
                backgroundColor: theme.palette.background.default,
            }}
        >
            {data && data.map((device, index) => (
                <Grid item sm={12} md={3} sx={{ width: "100%", padding: isSmall ? "0px 0px 8px 0px" : "0px 8px 8px 0px" }} >
                    <Card key={index}
                        sx={{
                            height: '100%',
                            display: 'flex',
                            borderRadius: "12px",
                            flexDirection: 'column',
                            margin: '0px',
                            padding: "0px"
                        }}>
                        <CardContent sx={{
                            ...theme.typography.body1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: "space-between",
                            padding: "0px"
                        }}>
                            <DeviceCardHeader device={device} permissions={permissions} />
                            <DeviceCardContent sx={{
                                width: "100%",
                            }} device={device} />
                        </CardContent>


                    </Card>
                </Grid>
            ))}
        </Grid>
    );

}


export default MobileGrid;