import { useLocaleState, useNotify, useRefresh, useResourceContext, useTranslate, useUpdate } from 'react-admin';
import { Alert, Button, Tooltip, useTheme } from '@mui/material'
import { listActionsColumnIconCommonStyle } from '../../themes/commonStyles';
import { ackNotificationLog, nAckNotificationLog } from '../../rest/restClient';
import { useState } from 'react';
import StandardDialog from '../dialog/StandardDialog';
import LoadingDialog from '../dialog/LoadingDialog';




const AckIcon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-square">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" /></svg>
    )
}

const NackIcon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-checkbox">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M9 11l3 3l8 -8" />
            <path d="M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9" />
        </svg>
    )
}



const NotificationLogAckButton = ({
    basePath = '',
    eventId,
    canAck,
    canNack,
    ...rest
}) => {
    const translate = useTranslate();
    const theme = useTheme();
    const refresh = useRefresh()
    const notify = useNotify();
    const [openLoadingDialog, setOpenLoadingDialog] = useState(false);

    if (!eventId) {
        return null
    }



    if (canAck == true) {
        return (<Tooltip
            title={translate(`resources.notification_logs.acknowledge`)}
            disableInteractive
        >
            <Button
                onClick={(e) => {
                    e.preventDefault();
                    setOpenLoadingDialog(true);
                    ackNotificationLog({ eventId }).
                        then(response => {
                            setOpenLoadingDialog(false);
                            if (response.status === 200) {
                                notify(
                                    <Alert severity="success" >
                                        {translate(`resources.notification_logs.request_success_acknowledged`)}
                                    </Alert>
                                );
                                refresh()

                            }
                            else {
                                notify(
                                    <Alert severity="error" >
                                        {translate(`resources.notification_logs.request_error_acknowledged`)}
                                    </Alert>
                                );
                            }

                        }).catch(err => {
                            setOpenLoadingDialog(false);
                            notify(
                                <Alert severity="error" >
                                    {translate(`resources.notification_logs.request_error_acknowledged`)}
                                </Alert>
                            );
                        })

                }}
                style={listActionsColumnIconCommonStyle({ isLastItem: false })}>
                <AckIcon color={theme.palette.primary.main} />
            </Button>
            <LoadingDialog isLoading={openLoadingDialog} />
        </Tooltip>)
    }


    if (canNack == true) {
        return (<Tooltip
            title={translate(`resources.notification_logs.nacknowledge`)}
            disableInteractive
        >

            <Button
                onClick={(e) => {
                    e.preventDefault();
                    setOpenLoadingDialog(true);
                    nAckNotificationLog({ eventId }).
                        then(response => {
                            setOpenLoadingDialog(false);
                            if (response.status === 200) {
                                notify(
                                    <Alert severity="success" >
                                        {translate(`resources.notification_logs.nacknowledged`)}
                                    </Alert>
                                );
                                refresh()
                            }
                            else {
                                notify(
                                    <Alert severity="error" >
                                        {translate(`resources.notification_logs.request_error_nacknowledged`)}
                                    </Alert>
                                );
                            }

                        }).catch(err => {
                            setOpenLoadingDialog(false);
                            notify(
                                <Alert severity="error" >
                                    {translate(`resources.notification_logs.request_error_nacknowledged`)}
                                </Alert>
                            );
                        })

                }}
                style={listActionsColumnIconCommonStyle({ isLastItem: false })}>
                <NackIcon color={theme.palette.primary.main} />
            </Button>
            <LoadingDialog isLoading={openLoadingDialog} />
        </Tooltip>)
    }
    return (null);
}



export const NotificationLogAckContainedButton = ({
    basePath = '',
    eventId,
    canAck,
    canNack,
    ...rest
}) => {
    const translate = useTranslate();
    const theme = useTheme();
    const refresh = useRefresh()
    const notify = useNotify();
    const [openLoadingDialog, setOpenLoadingDialog] = useState(false);

    if (!eventId) {
        return null
    }



    if (canAck == true) {
        return (<Tooltip
            title={translate(`resources.notification_logs.acknowledge`)}
            disableInteractive
        >
            <Button
                variant='contained'
                onClick={(e) => {
                    e.preventDefault();
                    setOpenLoadingDialog(true);
                    ackNotificationLog({ eventId }).
                        then(response => {
                            setOpenLoadingDialog(false);
                            if (response.status === 200) {
                                notify(
                                    <Alert severity="success" >
                                        {translate(`resources.notification_logs.request_success_acknowledged`)}
                                    </Alert>
                                );
                                refresh()

                            }
                            else {
                                notify(
                                    <Alert severity="error" >
                                        {translate(`resources.notification_logs.request_error_acknowledged`)}
                                    </Alert>
                                );
                            }

                        }).catch(err => {
                            setOpenLoadingDialog(false);
                            notify(
                                <Alert severity="error" >
                                    {translate(`resources.notification_logs.request_error_acknowledged`)}
                                </Alert>
                            );
                        })

                }}
            >
                {translate(`resources.notification_logs.acknowledge`)}
            </Button>
            <LoadingDialog isLoading={openLoadingDialog} />
        </Tooltip>)
    }


    if (canNack == true) {
        return (<Tooltip
            title={translate(`resources.notification_logs.nacknowledge`)}
            disableInteractive
        >

            <Button

                variant='contained'
                onClick={(e) => {
                    e.preventDefault();
                    setOpenLoadingDialog(true);
                    nAckNotificationLog({ eventId }).
                        then(response => {
                            setOpenLoadingDialog(false);
                            if (response.status === 200) {
                                notify(
                                    <Alert severity="success" >
                                        {translate(`resources.notification_logs.nacknowledged`)}
                                    </Alert>
                                );
                                refresh()
                            }
                            else {
                                notify(
                                    <Alert severity="error" >
                                        {translate(`resources.notification_logs.request_error_nacknowledged`)}
                                    </Alert>
                                );
                            }

                        }).catch(err => {
                            setOpenLoadingDialog(false);
                            notify(
                                <Alert severity="error" >
                                    {translate(`resources.notification_logs.request_error_nacknowledged`)}
                                </Alert>
                            );
                        })

                }}
            >
                {translate(`resources.notification_logs.nacknowledge`)}
            </Button>
            <LoadingDialog isLoading={openLoadingDialog} />
        </Tooltip>)
    }
    return (null);
}


export default NotificationLogAckButton;