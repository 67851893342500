import { Dialog, Alert, DialogActions, DialogContent, Button, Typography, Box } from '@mui/material';




const StandardDialog = (
    { open,
        onClose,
        fullWidth = true,
        width = 'auto',
        height = 'auto',
        showAlert = false,
        alertSeverity = 'info',
        alertText = '',
        bodyText = '',
        BodyComponent

    }
) => {

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"xlg"}
            fullWidth={fullWidth}
            sx={{ width: "100%", height: "100%" }}
            PaperProps={{ width: `${width} !important`, height: `${height} !important` }}
        >

            <DialogContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minWidth: "250px", padding: "20px" }} >

                {
                    showAlert === true &&
                    <Alert severity={alertSeverity} sx={{ width: '90%' }}>
                        {alertText}
                    </Alert>
                }
                {
                    bodyText !== '' &&
                    <Typography variant="body2" my={"5px"} mx={"0px"} sx={{ width: '80%' }}>
                        {bodyText}
                    </Typography>
                }
                {
                    BodyComponent !== undefined &&
                    <Box sx={{ marginY: 2 }}>
                        <BodyComponent />
                    </Box>
                }

            </DialogContent>
        </Dialog>
    )
}




export default StandardDialog;
