import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { required, TextInput, useNotify, useTranslate } from 'react-admin'
import tifizImage from '../tifiz.png'
import { H100Grid, HGrid, TitleForm } from '../../../ui/Grid'

import { Wizard } from '../../../ui/form/WizardForm'
import NextButton from '../../../ui/button/NextButton'
import { Box, TextField, Typography, useTheme } from '@mui/material'
import { Field } from 'react-final-form'



const Error = ({ name }) => (
    <Field
        name={name}
        subscription={{ touched: true, error: true }}
        render={({ meta: { touched, error } }) =>
            touched && error ? <span>{error}</span> : null
        }
    />
)


const CheckDevice = () => {
    const translate = useTranslate();
    const notify = useNotify();
    const theme = useTheme();

    return (
        <Wizard.Page >
            <Box sx={{
                width: '100%',
                height: '100%',
                background: "none",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <Box sx={{
                    width: '100%',
                    height: '100%',
                    background: "none",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    margin: "20px 0px 0px 0px",
                }}>

                    <Typography sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        lineHeight: "15px",
                        color: theme.palette.text.dark,
                        margin: "0px 0px 20px 0px",
                    }}>{translate('resources.devices.enter_device_id_instruction')}</Typography>
                    <Field name="deviceSn" >
                        {props => (
                            <div>
                                <TextField
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            padding: "0px !important",
                                            height: "48px",
                                            borderRadius: "12px !important",
                                            borderWidth: "1px",
                                            background: "none",
                                            color: theme.palette.text.primary

                                        },
                                    }}
                                    label={translate('resources.devices.device_id')}
                                    placeholder={translate('resources.devices.enter_device_id')}
                                    name={props.input.name}
                                    value={props.input.value}
                                    onChange={props.input.onChange}
                                    style={{ width: '300px' }}
                                />

                            </div>
                        )}
                    </Field>
                    <Error name={"deviceSn"} />
                </Box>

                <Box sx={{
                    width: '100%',
                    height: '100%',
                    background: "none",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>

                    <img src={tifizImage} alt="balise" style={{ maxHeight: '320px', maxWidth: '400px' }} />


                </Box>
            </Box>

        </Wizard.Page>
    );
};




export default CheckDevice;


