import React from 'react'
import { useDelete, useNotify, useTranslate } from 'react-admin'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'





const DeviceDetach = ({ selectedIds, resource }) => {
    const deleteMutation = useDelete();
    const notify = useNotify();

    const handleDelete = async () => {
        try {
            for (const id of selectedIds) {
                await deleteMutation(resource, id);
            }
            notify('Deleted successfully', 'success');
        } catch (error) {
            // Handle delete error here
            notify('Error deleting items', 'error');
        }
    };

    return null; // Render your component here
};

DeviceDetach.propTypes = {
    selectedIds: PropTypes.array.isRequired,
    resource: PropTypes.string.isRequired,

    
}


export default DeviceDetach