import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import { Tooltip, useTheme } from '@mui/material';



export const OptionsBtn = ({
    menuItems, width, height
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const theme = useTheme();
    const navigate = useNavigate();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const widthP = width ? width : "24";
    const heightP = height ? height : "24";

    if (!Array.isArray(menuItems) || menuItems.length === 0) {
        return null
    }

    return (
        <Box sx={{
            width: `${widthP}px`,
            height: `${heightP}px`,
            padding: '0px',
            margin: '0px',

        }}>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{
                    width: `${widthP}px`,
                    height: `${heightP}px`,
                    padding: '0px',
                    margin: '0px',
                    '&:hover': {
                        backgroundColor: 'transparent'
                    }
                    ,
                    '&:focus': {
                        backgroundColor: 'transparent'
                    }

                }}
            >
                <OptionsBtnIcon width={widthP} height={heightP} color={theme.palette.text.primary} />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}

            >

                {
                    menuItems.map((item, index) => {
                        return (
                            <MenuItem key={index} onClick={() => {
                                navigate(item.path)
                            }}>
                                {item.label}
                            </MenuItem>
                        )
                    })
                }

            </Menu>
        </Box>
    );
}







const OptionsBtnIcon = ({ width, height, color }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_12_39841)">
                <path d="M14 6C14 4.9 13.1 4 12 4C10.9 4 10 4.9 10 6C10 7.1 10.9 8 12 8C13.1 8 14 7.1 14 6ZM14 18C14 16.9 13.1 16 12 16C10.9 16 10 16.9 10 18C10 19.1 10.9 20 12 20C13.1 20 14 19.1 14 18ZM14 12C14 10.9 13.1 10 12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12Z" fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_12_39841">
                    <rect width={width} height={height} fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}








