import React from 'react'
import { RefreshIconButton as RaRefreshIconButton } from 'react-admin'
import { useTheme } from '@mui/material/styles';
import { color } from '@mui/system';





const RefreshIcon = () => {
    const theme = useTheme();
    return (
        <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.416992" width="32" height="32" rx="8" fill={theme.palette.mode === 'light' ? theme.palette.primary.light : theme.palette.dark.dark} />
            <g clipPath="url(#clip0_237_7999)">
                <path d="M15.9997 10.5833V7.25L11.833 11.4167L15.9997 15.5833V12.25C18.758 12.25 20.9997 14.4917 20.9997 17.25C20.9997 20.0083 18.758 22.25 15.9997 22.25C13.2413 22.25 10.9997 20.0083 10.9997 17.25H9.33301C9.33301 20.9333 12.3163 23.9167 15.9997 23.9167C19.683 23.9167 22.6663 20.9333 22.6663 17.25C22.6663 13.5667 19.683 10.5833 15.9997 10.5833Z" fill={theme.palette.primary.main} />
            </g>
            <defs>
                <clipPath id="clip0_237_7999">
                    <rect width="20" height="20" fill="white" transform="translate(6 6.41699)" />
                </clipPath>
            </defs>
        </svg>

    )
}

const customStyles = () => ({
    raRefreshIconButton: {
        height: "32px",
        width: "32px",
        padding: "0px",
        margin: "0px 8px 0px 8px",

    }
})



const RefreshIconButton = (props) => {
    const styles = customStyles();
    return (
        <RaRefreshIconButton sx={styles.raRefreshIconButton} icon={<RefreshIcon />} />
    );
};


export default RefreshIconButton