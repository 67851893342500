import { Card, Grid, CardContent, useTheme, TableBody, TableRow, TableCell } from "@mui/material";





function unflattenList(flatList, groupSize) {
    const result = [];
    for (let i = 0; i < flatList.length; i += groupSize) {
        result.push(flatList.slice(i, i + groupSize));
    }
    return result;
}


const PageHeader = ({ rows, isSmall }) => {
    const theme = useTheme();
    if (isSmall && rows && Array.isArray(rows)) {
        rows = unflattenList(rows.flat(),1)
    }

    return (
        <Grid item sm={12} sx={{
            width: "100%",
            margin: isSmall ? "8px 0px 10px 0px" : "0px 0px 10px 0px",
        }} >
            <Card sx={{
                height: '100%',
                display: 'flex',
                borderRadius: "12px",
                flexDirection: 'column',
                margin: '0px',
            }}>
                <CardContent sx={{
                    ...theme.typography.body1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: "space-between",
                    padding: "10px 10px 10px 10px !important"
                }}>
                    
                    <TableBody>

                        {rows && Array.isArray(rows) &&
                            rows.map((row, idx) => {
                                let tableCells = []
                                if (row && Array.isArray(row)) {
                                    row.map((cell, idx2) => {
                                        if (cell.title) {
                                            tableCells.push(<TableCell key={idx2 + 2} align="left" sx={{ padding: idx2 === 0 ? "0px" : "0px 0px 0px 20px", fontWeight: 500 }}>{`${cell.title}`}</TableCell>)
                                        }
                                        if (cell.message) {
                                            tableCells.push(<TableCell key={idx2 + 3} align="left" sx={{ padding: "0px 0px 0px 10px", fontWeight: 400 }}>{cell.message}</TableCell>)
                                        }


                                    });
                                }
                                return (
                                    <TableRow
                                        key={idx}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        {tableCells}
                                    </TableRow>
                                )
                            })
                        }

                    </TableBody>
                </CardContent>


            </Card>
        </Grid>
    )

}

export default PageHeader;