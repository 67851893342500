import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import { Box } from '@mui/material';
import { RO_LOGO_URL } from '../pages/configuration/actions';



const useStyles = makeStyles(() => ({
    logo: {
        width: "224px",
        height: "53px",
        padding: 1,
    },
    logoContainer: {
        width: "224px",
        height: "53px",
        margin: "0px",
        padding: "0px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'

    },
}));

const TempLogo = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="Calque_1" data-name="Calque 1" viewBox="0 0 70.712 50" width="70.712" height="50">
            <g>
                <g>
                    <path d="M10.482 19.125c0 -1.361 -0.067 -2.73 -0.126 -4.082 0.941 0.042 1.873 0.076 2.822 0.076s1.873 -0.034 3.007 -0.076a2.881 2.881 0 0 0 -0.042 0.47 2.94 2.94 0 0 0 0.042 0.47c-1.134 -0.109 -3.368 -0.202 -4.393 -0.202 -0.017 0.739 -0.034 1.403 -0.034 2.066s0.017 1.319 0.017 1.982c1.352 0 2.755 -0.025 4.065 -0.151a2.184 2.184 0 0 0 -0.059 0.47 2.251 2.251 0 0 0 0.059 0.47c-1.058 -0.109 -2.713 -0.126 -4.065 -0.126v1.344c0 1.352 0.059 2.73 0.126 4.082a3.99 3.99 0 0 0 -1.545 0c0.059 -1.352 0.126 -2.73 0.126 -4.082Z" fill="#a00000" />
                    <path d="M17.311 19.142c0.949 -0.714 1.705 -0.916 2.537 -0.916 1.512 0 2.553 0.932 2.553 2.335 0 1.176 -0.059 2.343 -0.059 3.67 0 0.521 0 1.167 0.613 1.167a0.68 0.68 0 0 0 0.328 -0.059v0.344a2.688 2.688 0 0 1 -1.008 0.218c-0.672 0 -1.025 -0.437 -1.083 -0.974 -0.756 0.756 -1.403 1.151 -2.478 1.151s-2.1 -0.739 -2.1 -1.94 0.857 -1.94 2.226 -2.159c2.285 -0.37 2.369 -0.496 2.369 -1.109 0 -1.058 -0.479 -1.982 -1.739 -1.982 -0.832 0 -1.621 0.395 -1.982 1.067h-0.176Zm3.897 2.805c-1.092 0.361 -3.301 0.252 -3.301 1.974 0 0.991 0.68 1.495 1.377 1.495s1.495 -0.521 1.856 -1.1Z" fill="#a00000" />
                    <path d="M39.778 18.226c1.831 0 3.83 1.058 3.83 3.922s-1.999 3.931 -3.83 3.931 -3.83 -1.058 -3.83 -3.931 1.999 -3.922 3.83 -3.922m0 7.349c1.856 0 2.444 -1.655 2.444 -3.427s-0.588 -3.418 -2.444 -3.418 -2.444 1.655 -2.444 3.418 0.58 3.427 2.444 3.427" fill="#a00000" />
                    <path d="M45.767 18.52c0 -1.445 -0.025 -3.477 -0.126 -4.536a4.2 4.2 0 0 0 0.714 0.076 4.032 4.032 0 0 0 0.706 -0.076c-0.092 1.058 -0.126 3.091 -0.126 4.536v2.856c0 1.453 0.034 3.486 0.126 4.544a3.36 3.36 0 0 0 -0.706 -0.076 3.502 3.502 0 0 0 -0.714 0.076c0.101 -1.058 0.126 -3.091 0.126 -4.544Z" fill="#a00000" />
                    <path d="M50.151 18.52c0 -1.445 -0.025 -3.477 -0.126 -4.536a4.2 4.2 0 0 0 0.714 0.076 4.032 4.032 0 0 0 0.706 -0.076c-0.092 1.058 -0.126 3.091 -0.126 4.536v2.856c0 1.453 0.034 3.486 0.126 4.544a3.36 3.36 0 0 0 -0.706 -0.076 3.502 3.502 0 0 0 -0.714 0.076c0.101 -1.058 0.126 -3.091 0.126 -4.544Z" fill="#a00000" />
                    <path d="M57.097 18.226c2.201 0 3.259 1.461 3.259 3.217 0 0.202 -0.017 0.412 -0.034 0.613 -1.134 -0.017 -2.251 -0.05 -3.368 -0.05 -0.697 0 -1.377 0.034 -2.066 0.05a4.116 4.116 0 0 0 -0.017 0.445c0 1.638 0.974 2.914 2.73 2.914 0.832 0 1.814 -0.487 2.36 -1.117l0.084 0.042 -0.101 0.773c-0.63 0.504 -1.655 0.966 -2.629 0.966 -2.276 0 -3.83 -1.613 -3.83 -3.704 0 -2.562 1.293 -4.149 3.612 -4.149m-2.184 3.234c0.647 0.017 1.394 0.042 2.058 0.042s1.428 -0.025 2.091 -0.042c0 -1.125 -0.294 -2.73 -1.94 -2.73s-2.125 1.596 -2.209 2.73" fill="#a00000" />
                </g>
                <g>
                    <path d="M29.405 24.962c-0.059 0.395 -0.126 0.806 -0.193 1.226 -1.016 -2.259 -3.25 -6.333 -3.855 -8.172a7.559 7.559 0 0 1 -1.881 0c0.412 0.924 4.233 7.82 5.325 10.255 -0.949 4.242 -2.755 8.987 -5.888 12.632 4.032 -3.704 6.778 -9.995 7.492 -15.328 1.302 -9.651 3.981 -14.623 7.274 -17.386 -4.208 2.94 -7.106 8.256 -8.273 16.773" fill="#a00000" />
                    <path d="M37.678 8.189c-3.292 2.763 -5.972 7.736 -7.274 17.386 -0.714 5.333 -3.46 11.624 -7.492 15.328 -1.302 1.512 -2.83 2.839 -4.62 3.855 6.459 -1.529 14.379 -7.232 15.58 -19.184C35.52 9.247 43.155 5.653 47.623 5.241c-3.805 0.092 -7.164 0.999 -9.945 2.948" fill="#ffac09" />
                </g>
            </g>
        </svg>

    )
}
const RoLogoAppBar = () => {
    const classes = useStyles();
    const imageSrc = localStorage.getItem(RO_LOGO_URL) || "https://images.crunchbase.com/image/upload/c_lpad,h_256,w_256,f_auto,q_auto:eco,dpr_1/v1446282194/zu7wapifznbz5nnvcdag.png"
    /*
    return (
        <Box className={classes.logoContainer}>
            <TempLogo />
        </Box>)
    */
    return (
        <Box className={classes.logoContainer}> <img src={imageSrc} alt="Connect-logo" className={classes.logo} /></Box>)

}


export default RoLogoAppBar;




