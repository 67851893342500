
import React, { useState, useRef } from 'react'
import { useTranslate, useListContext } from 'react-admin'
import { MenuItem, Box, Select, Button, Slide, Dialog, DialogContent } from '@mui/material'
import { useTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { ExportDataButton } from '../../ui/button/ExportDataButton';


const customStyles = ({ theme }) => ({
    container: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: theme.palette.background.paper,
        borderRadius: "12px",
        padding: "0px !important",
        margin: "0px !important",
    },
    itemGrid: { display: 'flex', alignItems: 'center', justifyContent: 'center', width: "80%", }
})




const FiltersIcon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-filter">
            <path stroke="none" d="M0 0h16v16H0z" fill="none" strokeWidth="0.6666666666666666" />
            <path d="M2.667 2.667h10.667v1.448a1.333 1.333 0 0 1 -0.391 0.943L10 8v4.667l-4 1.333v-5.667L3.013 5.048A1.333 1.333 0 0 1 2.667 4.151z" />
        </svg>
    )
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const FiltersDialog = (
    { open,
        setOpen,
        searchOption,
        setSearchOption,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        perPage,
        setFilters,
        handleChangeSearchOption }
) => {

    const theme = useTheme();
    const styles = customStyles({ theme });
    const contentBg = theme.palette.mode === 'light' ? theme.palette.background.paper : theme.palette.background.default;
    const translate = useTranslate()
    const handleClose = () => setOpen(false);
    const dialogRef = useRef(null);
    const handleDrag = (event) => {
        const dialogElement = dialogRef.current;
        if (!dialogElement) return;

        // Detect touch or mouse events
        const startY = event.type === 'mousedown'
            ? event.clientY
            : event.touches[0].clientY;

        const onMove = (moveEvent) => {
            const currentY = moveEvent.type === 'mousemove'
                ? moveEvent.clientY
                : moveEvent.touches[0].clientY;

            const deltaY = currentY - startY;

            if (deltaY > 150) {
                // Close the dialog if dragged down more than 150px
                handleClose();
            } else {
                // Temporarily move the dialog down
                dialogElement.style.transform = `translateY(${Math.min(deltaY, 150)}px)`;
            }
        };

        const onEnd = () => {
            // Reset dialog position and remove listeners
            dialogElement.style.transform = '';
            window.removeEventListener('mousemove', onMove);
            window.removeEventListener('mouseup', onEnd);
            window.removeEventListener('touchmove', onMove);
            window.removeEventListener('touchend', onEnd);
        };

        // Add listeners for dragging and release
        window.addEventListener('mousemove', onMove);
        window.addEventListener('mouseup', onEnd);
        window.addEventListener('touchmove', onMove);
        window.addEventListener('touchend', onEnd);
    };
    return (
        <Dialog
            ref={dialogRef}
            open={open}
            TransitionComponent={Transition}
            onClose={() => { }}
            fullScreen
            PaperProps={{
                style: { boxShadow: 'none' }, // Optional: Remove shadow around the dialog
            }}
            slotProps={{
                backdrop: { style: { backgroundColor: 'transparent' } }
            }}
            sx={{
                "& .MuiPaper-root": {
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    width: "100%",
                    height: "auto",
                    minHeight: "20%",
                    maxHeight: "70%",
                    margin: "0px",
                    padding: "0px",
                    border: 1,
                    borderTopColor: theme.palette.text.disabled,
                    borderWidth: "1px",
                    borderRadius: "12px 12px 0px 0px"
                }
            }}
        >
            
            <Box
                sx={{
                    width: "100%",
                    height: "15px",
                    margin: "0px",
                    padding: "10px 0px 10px 0px",
                    borderRadius: "0px",
                    backgroundColor: "none",
                    opacity: 0.45,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
                onMouseDown={handleDrag} // Handle mouse drag
                onTouchStart={handleDrag}

            >
                <Box
                    sx={{
                        width: "40px",
                        height: "4px",
                        margin: "1px",
                        borderRadius: "12px",
                        backgroundColor: theme.palette.text.secondary,
                        opacity: 0.45
                    }}


                >
                </Box>
            </Box>
            <DialogContent
                sx={{
                    margin: "0px",
                    padding: "16px 16px 30px 16px",
                    background: contentBg,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    alignItems: 'center'
                }}>


                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={searchOption}
                    label={translate("resources.locations.searchOption")}
                    onChange={handleChangeSearchOption}
                    sx={{
                        width: "100%",
                        height: "48px",
                        margin: "0px 0px 0px 0px !important",
                        "& .MuiInputBase-root": {
                            padding: "0px !important",
                            height: "48px",
                            textAlign: "center !important",
                            borderRadius: "12px !important",
                            borderWidth: "1px",
                            backgroundColor:
                                theme.palette.mode === "light"
                                    ? theme.palette.grey[50]
                                    : theme.palette.background.appFrame,
                            color: theme.palette.text.primary,
                        },
                        "& .MuiFormLabel-root": {
                            marginTop: "4px",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: "12px !important",
                            borderWidth: "1px",
                        },
                        "legend > span": {
                            opacity: 1,
                            color: theme.palette.primary.main,
                            height: "15px"
                        },
                        "legend": {
                            opacity: 1,
                            color: theme.palette.primary.main,
                            height: "15px",
                            fontSize: "11px"

                        },
                    }}
                >
                       <MenuItem value={"last_5"}>{translate("resources.notification_logs.notifLog5")}</MenuItem>
                <MenuItem value={"last_25"}>{translate("resources.notification_logs.notifLog25")}</MenuItem>
                <MenuItem value={"last_50"}>{translate("resources.notification_logs.notifLog50")}</MenuItem>
                <MenuItem value={"last_100"}>{translate("resources.notification_logs.notifLog100")}</MenuItem>
                <MenuItem value={"today"}>{translate("resources.notification_logs.notifLogToday")}</MenuItem>
                <MenuItem value={"yesterday"}>{translate("resources.notification_logs.notifLogYesterday")}</MenuItem>
                <MenuItem value={"custom"}>{translate("resources.notification_logs.customized")}</MenuItem>
                </Select>

                {startDate && endDate &&
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            label={translate("resources.devices.from")}
                            value={startDate}
                            onChange={(newValue) => { setStartDate(newValue); setSearchOption("custom") }}
                            sx={{
                                width: "100%",
                                height: "48px",
                                margin: "16px 0px 0px 0px !important",
                                "& .MuiInputBase-root": {
                                    padding: "0px !important",
                                    height: "48px",
                                    textAlign: "center !important",
                                    borderRadius: "12px !important",
                                    borderWidth: "1px",
                                    backgroundColor:
                                        theme.palette.mode === "light"
                                            ? theme.palette.grey[50]
                                            : theme.palette.background.appFrame,
                                    color: theme.palette.text.primary,
                                },
                                "& .MuiFormLabel-root": {
                                    marginTop: "4px",
                                },
                                "& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd": {
                                    paddingRight: "4px !important",
                                    paddingLeft: "10px !important",
                                },

                                "& .MuiButtonBase-root.MuiIconButton-root": {
                                    padding: "8px 2px 8px 2px !important",
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderRadius: "12px !important",
                                    borderWidth: "1px",
                                    width: "100%",
                                    padding: "0px 0px 0px 0px !important",
                                },

                            }}

                        />
                        <DateTimePicker
                            label={translate("resources.devices.to")}
                            value={endDate}
                            onChange={(newValue) => { setEndDate(newValue); setSearchOption("custom") }}
                            sx={{
                                width: "100%",
                                height: "48px",
                                margin: "16px 0px 0px 0px !important",
                                "& .MuiInputBase-root": {
                                    padding: "0px !important",
                                    height: "48px",
                                    textAlign: "center !important",
                                    borderRadius: "12px !important",
                                    borderWidth: "1px",
                                    backgroundColor:
                                        theme.palette.mode === "light"
                                            ? theme.palette.grey[50]
                                            : theme.palette.background.appFrame,
                                    color: theme.palette.text.primary,
                                },
                                "& .MuiFormLabel-root": {
                                    marginTop: "4px",
                                },
                                "& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd": {
                                    paddingRight: "4px !important",
                                    paddingLeft: "10px !important",
                                },

                                "& .MuiButtonBase-root.MuiIconButton-root": {
                                    padding: "8px 2px 8px 2px !important",
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderRadius: "12px !important",
                                    borderWidth: "1px",
                                    width: "100%",
                                    padding: "0px 0px 0px 0px !important",
                                },
                            }}
                        />
                    </LocalizationProvider>
                }


              
                <Box component="span" mt="16px" sx={{ width: "100%" }}>
                    <Button
                        variant='contained'
                        sx={{ width: "100%" }}
                        onClick={() => {
                            let filters = {}
                           

                            if (startDate) {
                                filters.start_date = startDate.toISOString()
                            }
                            if (endDate) {
                                filters.end_date = endDate.toISOString()
                                filters.size = 1000
                            }
                            if (perPage) {
                                filters.size = perPage
                            }

                            setFilters(filters, {}, true)

                            setOpen(false)

                        }}>
                        {translate("resources.locations.labels.viewHistory")}
                    </Button>
                </Box>
                <Box component="span" mb="8px" sx={{ width: "100%" }}>
                    <Button variant="text" sx={{ width: "100%" }} onClick={() => setOpen(false)}>
                        {translate("ra.action.close")}
                    </Button>
                </Box>

            </DialogContent>


        </Dialog>
    )
}



const FiltersBtn = ({
    label,
    color,
    searchOption,
    setSearchOption,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    perPage,
    setFilters,
    handleChangeSearchOption,
}) => {
    const [open, setOpen] = React.useState(false);

    return (
        <div>
            <Button sx={{ color: color }}
                onClick={(e) => { e.preventDefault(); setOpen(!open) }}
            >
                <FiltersIcon color={color} />&nbsp;{label}
            </Button>
            <FiltersDialog
                open={open}
                setOpen={setOpen}
                searchOption={searchOption}
                setSearchOption={setSearchOption}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                perPage={perPage}
                setFilters={setFilters}
                handleChangeSearchOption={handleChangeSearchOption}
            />
        </div>



    )
}

const MobileActions = ({
    searchOption,
    setSearchOption,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    perPage,
    setFilters,
    handleChangeSearchOption,
}) => {
    const theme = useTheme();
    const translate = useTranslate()

    return (


        <Box sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "0px",
            margin: "0px",
            width: "100%",
            height: "24px"

        }}>

            <FiltersBtn
                color={theme.palette.text.secondary}
                label={translate("resources.common.filter")}
                searchOption={searchOption}
                setSearchOption={setSearchOption}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                perPage={perPage}
                setFilters={setFilters}
                handleChangeSearchOption={handleChangeSearchOption}
            />

        </Box>


    )
}



const startDateFromSearchOption = (searchOption) => {
    let date = new Date()
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    switch (searchOption) {
        case "today":
            break;
        case "custom":
            break;
        case "yesterday":
            date.setDate(date.getDate() - 1)
            break;
        default:
            return null
    }
    return dayjs(date.toISOString())
}


const endDateFromSearchOption = (searchOption) => {
    let date = new Date()
    date.setHours(23)
    date.setMinutes(59)
    date.setSeconds(59)
    date.setMilliseconds(999)
    switch (searchOption) {
        case "today":
            break;
        case "custom":
            break;
        case "yesterday":
            date.setDate(date.getDate() - 1)
            break;
        default:
            return null
    }
    return dayjs(date.toISOString())
}


const perPageFromSearchOption = (searchOption) => {
    switch (searchOption) {
        case "last_5":
            return 5
        case "last_25":
            return 25
        case "last_50":
            return 50
        case "last_100":
            return 100
        default:
            return null
    }
}


const NotificationLogHistoryActions = ({recordId, isMobile}) => {
    const { setFilters } = useListContext()
    const theme = useTheme();
    const translate = useTranslate();
    const styles = customStyles({ theme });
    const [searchOption, setSearchOption] = useState("last_5");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [perPage, setPerPage] = useState(perPageFromSearchOption("last_5"));




    const handleChangeSearchOption = (event) => {
        const { value } = event.target
        setSearchOption(value)
        setStartDate(startDateFromSearchOption(value))
        setEndDate(endDateFromSearchOption(value))
        setPerPage(perPageFromSearchOption(value))
    }




    
    if (isMobile) return (<MobileActions
        searchOption={searchOption}
        setSearchOption={setSearchOption}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        perPage={perPage}
        setFilters={setFilters}
        handleChangeSearchOption={handleChangeSearchOption}

    />)

    return (

        <Box
            sx={styles.container}
        >

            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={searchOption}
                label={translate("resources.measures.searchOption")}
                onChange={handleChangeSearchOption}
                sx={{
                    width: "150px",
                    height: "48px",
                    margin: "0px 10px 0px 0px !important",
                    "& .MuiInputBase-root": {
                        padding: "0px !important",
                        height: "48px",
                        textAlign: "center !important",
                        borderRadius: "12px !important",
                        borderWidth: "1px",
                        backgroundColor:
                            theme.palette.mode === "light"
                                ? theme.palette.grey[50]
                                : theme.palette.background.appFrame,
                        color: theme.palette.text.primary,
                    },
                    "& .MuiFormLabel-root": {
                        marginTop: "4px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "12px !important",
                        borderWidth: "1px",
                    },
                    "legend > span": {
                        opacity: 1,
                        color: theme.palette.primary.main,
                        height: "15px"
                    },
                    "legend": {
                        opacity: 1,
                        color: theme.palette.primary.main,
                        height: "15px",
                        fontSize: "11px"

                    },
                }}
            >
                <MenuItem value={"last_5"}>{translate("resources.notification_logs.notifLog5")}</MenuItem>
                <MenuItem value={"last_25"}>{translate("resources.notification_logs.notifLog25")}</MenuItem>
                <MenuItem value={"last_50"}>{translate("resources.notification_logs.notifLog50")}</MenuItem>
                <MenuItem value={"last_100"}>{translate("resources.notification_logs.notifLog100")}</MenuItem>
                <MenuItem value={"today"}>{translate("resources.notification_logs.notifLogToday")}</MenuItem>
                <MenuItem value={"yesterday"}>{translate("resources.notification_logs.notifLogYesterday")}</MenuItem>
                <MenuItem value={"custom"}>{translate("resources.notification_logs.customized")}</MenuItem>
            </Select>

            {startDate && endDate &&
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        label={translate("resources.devices.from")}
                        value={startDate}
                        onChange={(newValue) => { setStartDate(newValue); setSearchOption("custom") }}
                        sx={{
                            width: "180px",
                            height: "48px",
                            margin: "0px 30px 0px 0px !important",
                            "& .MuiInputBase-root": {
                                padding: "0px !important",
                                height: "48px",
                                textAlign: "center !important",
                                borderRadius: "12px !important",
                                borderWidth: "1px",
                                backgroundColor:
                                    theme.palette.mode === "light"
                                        ? theme.palette.grey[50]
                                        : theme.palette.background.appFrame,
                                color: theme.palette.text.primary,
                            },
                            "& .MuiFormLabel-root": {
                                marginTop: "4px",
                            },
                            "& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd": {
                                paddingRight: "4px !important",
                                paddingLeft: "10px !important",
                            },

                            "& .MuiButtonBase-root.MuiIconButton-root": {
                                padding: "8px 2px 8px 2px !important",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderRadius: "12px !important",
                                borderWidth: "1px",
                                width: "200px",
                                padding: "0px 30px 0px 8px !important",
                            },

                        }}

                    />
                    <DateTimePicker
                        label={translate("resources.devices.to")}
                        value={endDate}
                        onChange={(newValue) => { setEndDate(newValue); setSearchOption("custom") }}
                        sx={{
                            width: "180px",
                            height: "48px",
                            margin: "0px 30px 0px 0px !important",
                            "& .MuiInputBase-root": {
                                padding: "0px !important",
                                height: "48px",
                                textAlign: "center !important",
                                borderRadius: "12px !important",
                                borderWidth: "1px",
                                backgroundColor:
                                    theme.palette.mode === "light"
                                        ? theme.palette.grey[50]
                                        : theme.palette.background.appFrame,
                                color: theme.palette.text.primary,
                            },
                            "& .MuiFormLabel-root": {
                                marginTop: "4px",
                            },
                            "& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd": {
                                paddingRight: "4px !important",
                                paddingLeft: "10px !important",
                            },

                            "& .MuiButtonBase-root.MuiIconButton-root": {
                                padding: "8px 2px 8px 2px !important",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderRadius: "12px !important",
                                borderWidth: "1px",
                                width: "200px",
                                padding: "0px 30px 0px 8px !important",
                            },
                        }}
                    />
                </LocalizationProvider>
            }



            <Button
                variant='contained'
                sx={{ my: 1 }}
                onClick={() => {
                    let filters = {}

                    if (startDate) {
                        filters.start_date = startDate.toISOString()
                    }
                    if (endDate) {
                        filters.end_date = endDate.toISOString()
                        filters.size = 1000
                    }
                    if (perPage) {
                        filters.size = perPage
                    }

                    setFilters(filters)

                }}>
                {translate("resources.locations.labels.viewHistory")}
            </Button>

            <Box sx={{
                display:'flex',
                flex:1
            }}/>
            <ExportDataButton selectedIds={recordId} selectedEventType={"notification_log"} />

        </Box>

    );
}





export default NotificationLogHistoryActions;





