import { Grid, useTheme, useMediaQuery, Box, Avatar, Card, Tooltip, ButtonBase, CardContent, Typography, TableBody, TableCell, Table } from "@mui/material"
import { TextField, useTranslate } from 'react-admin'
import { useNavigate } from "react-router-dom";
import { CardDetailRow } from "../Common";
import { IconX } from "@tabler/icons-react";








const CustomCardHeader = ({ Icon, title, onClose }) => {
    const theme = useTheme();
    return (
        <Box sx={{
            marginX: "12px",
            marginTop: "12px",
            marginBottom: "16px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start"

        }}>
            <Box
                sx={{
                    marginRight: "24px",

                }}
            >
                <Avatar
                    size={"45px"}

                    style={{ backgroundColor: theme.palette.background.default, width: "45px", height: "45px" }}
                >
                    <Icon />
                </Avatar>
            </Box>

            <Box
                sx={{
                    marginRight: "24px",

                }}
            >
                <Typography
                    color="text.main"
                    sx={{
                        fontFamily: "Roboto",
                        fontSize: "18px",
                        fontWeight: 450,
                        lineHeight: "29.3px",
                        textAlign: 'start',
                        margin: "5px 5px 10px 5px"
                    }}
                >
                    {title}
                </Typography>

            </Box>
            {
                onClose &&
                <Box flex={1} />
            }
            {
                onClose &&
                <Box
                >
                    <IconX onClick={() => onClose()} />

                </Box>
            }





        </Box>

    );
}




export const InfoLine = ({ isSmall, label, info }) => {
    return (

        <Grid
            item
            xs={12}
            md={6}
            xl={4}
            sx={{
                width: "100%",
                margin: isSmall ? "8px 0px 0px 0px" : '0px',
            }} >

            <Grid container spacing={0}>

                <Grid
                    item
                    sm={12}
                    sx={{
                        width: "100%",
                        margin: isSmall ? "8px 0px 0px 0px" : '0px',
                    }} >
                    <Box>
                        {label}
                    </Box>

                </Grid>

                <Grid
                    item
                    sm={12}
                    sx={{
                        width: "100%",
                        margin: isSmall ? "8px 0px 0px 0px" : '0px',
                    }} >

                    <Typography
                        color="text.main"
                        sx={{
                            fontFamily: "Roboto",
                            fontSize: "20px",
                            fontWeight: 450,
                            lineHeight: "29.3px",
                            textAlign: "center",
                            margin: "5px 5px 10px 5px"
                        }}
                    >
                        {info}
                    </Typography>

                </Grid>
            </Grid>

        </Grid>
    )
}






export const InfoTable2Col = ({ rows, lastCaption }) => {
    const theme = useTheme();
    return (

        <Table sx={{
            width: "100%",
        }}>
            <TableBody sx={{
                width: "100% !important",
                "& .MuiTableRow-root": {
                    width: "100%",
                },
                "& .MuiTableCell-root": {
                    borderBottomColor: theme.palette.divider
                }
            }}>
                {rows && rows.map((r, idx) => <CardDetailRow key={idx} label={r.label} ValueComponent={() => <TextField source="info" record={r} />} />)}

                {lastCaption && <TableCell colSpan={2} align="right" sx={{ fontSize: "12px", border: 0, padding: "15px 0px 0px 15px" }}>{lastCaption}</TableCell>}
            </TableBody>
        </Table>)

}


export const InfoTable2ColWithTitle = ({ title, rows }) => {
    const theme = useTheme();
    return (<TableBody sx={{
        width: "100%",
        "& .MuiTableRow-root": {
            width: "100%",
        },
        "& .MuiTableCell-root": {
            borderBottomColor: theme.palette.divider
        }
    }}>
        <TableCell align="left" sx={{ fontWeight: 550 }}>{title}</TableCell>
        {rows && rows.map((r, idx) => <CardDetailRow key={idx} label={r.label} ValueComponent={() => <TextField source="info" record={r} />} />)}
    </TableBody>)

}



const DashboardCard = ({ HeaderIcon, title, BodyComponent, redirectTo, onClose }) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const translate = useTranslate();
    const navigate = useNavigate()

    return (
        <Card
            sx={{
                height: '100%',
                width: "100%",
                display: 'flex',
                borderRadius: "12px",
                flexDirection: 'column',
                margin: '0px',
            }}>
            <Tooltip title={title}>
                {
                    redirectTo &&
                    <ButtonBase
                        onClick={() => { if (redirectTo) navigate(redirectTo); }}
                        disabled={!redirectTo}
                        sx={{
                            display: 'flex',
                            width: "100%"
                        }}
                    >
                        <CardContent sx={{
                            ...theme.typography.body1,
                            display: 'flex',
                            width: "100%",
                            flexDirection: 'column',
                            justifyContent: "space-between",
                            padding: "8px"
                        }}>
                            <CustomCardHeader Icon={() => <HeaderIcon />} title={title} onClose={onClose} />
                            <BodyComponent />

                        </CardContent>

                    </ButtonBase>
                }

                {
                    !redirectTo &&

                    <CardContent sx={{
                        ...theme.typography.body1,
                        display: 'flex',
                        width: "100%",
                        flexDirection: 'column',
                        justifyContent: "space-between",
                        padding: "8px"
                    }}>
                        <CustomCardHeader Icon={() => <HeaderIcon />} title={title} onClose={onClose} />
                        <BodyComponent />

                    </CardContent>


                }

            </Tooltip>
        </Card>
    )
}



export default DashboardCard;