
import React, { useState, useRef, useCallback } from 'react'
import { useTranslate, useListContext, useNotify, required, SimpleForm, useListPaginationContext } from 'react-admin'
import { MenuItem, Box, Select, Button, Dialog, DialogContent, Slide, IconButton, DialogTitle } from '@mui/material'
import { useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { ExportDataButton } from '../../ui/button/ExportDataButton'
import { addEvents } from '../../rest/restClient';
import styledFormInputs from '../../ui/inputs/styledFormInputs';
import { isSuperAdmin } from '../../helper/resourceHelper';


const customStyles = ({ theme }) => ({
    container: { width: "100%", p: "0px", height: "100%", display: "flex", flexDirection: "row", alignItems: "center" },
    itemGrid: { display: 'flex', alignItems: 'center', justifyContent: 'center', width: "80%", }
})





const AddLocationIcon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-map-pin-plus">
            <path stroke="none" d="M0 0h20v20H0z" fill="none" stroke-width="0.8333333333333334" />
            <path d="M7.5 9.167a2.5 2.5 0 1 0 5 0 2.5 2.5 0 0 0 -5 0" />
            <path d="M10.662 17.768a1.667 1.667 0 0 1 -1.839 -0.352l-3.537 -3.536a6.667 6.667 0 1 1 11.325 -3.847" />
            <path d="M13.333 15.833h5" />
            <path d="M15.833 13.333v5" />
        </svg>




    )
}


const AddLocationBtn = ({ onClick, color }) => {
    return (
        <IconButton
            onClick={(e) => {
                e.preventDefault();
                onClick();
            }}
        >
            <AddLocationIcon color={color} />
        </IconButton>

    )
}


const validateRequired = [required('Champs obligatoire')]




const AddLocationDialog = ({ open, setOpen, deviceId }) => {
    const translate = useTranslate();
    const notify = useNotify();
    const theme = useTheme();
    const locationSave = (location) => {
        location.device_id = deviceId
        location.name = "location"
        console.log("AddLocationDialog data ==>", location)
        const events = [location]
        addEvents(events).then((resp) => {
            if (resp.status === 201) {
                notify(translate('ra.notification.created'), 'info');
                setOpen(false)
            } else {
                notify(translate('ra.notification.http_error'), 'error');
            }
        })

    };



    const inputsData = [
        { name: 'timestamp', label: translate('resources.locations.fields.timestamp'), validate: validateRequired, type: "dateTime" },
        { name: 'latitude', label: translate('resources.locations.fields.latitude'), validate: validateRequired, type: "number" },
        { name: 'longitude', label: translate('resources.locations.fields.longitude'), validate: validateRequired, type: "number" }
    ]

    const optionalInputsData = [
        { name: 'altitude', label: translate('resources.locations.fields.altitude'), required: false, type: "number" },
        { name: 'speed', label: translate('resources.locations.fields.speed'), required: false, type: "number" },
        { name: 'accuracy', label: translate('resources.locations.fields.accuracy'), required: false, type: "number" },
        { name: 'formatted_address', label: translate('resources.locations.fields.formatted_address'), required: false, type: "text" },
        { name: 'country', label: translate('resources.locations.fields.country'), required: false, type: "text" },
        { name: 'state', label: translate('resources.locations.fields.state'), required: false, type: "text" },
        { name: 'city', label: translate('resources.locations.fields.city'), required: false, type: "text" },
        { name: 'postcode', label: translate('resources.locations.fields.postcode'), required: false, type: "text" }
    ]

    return (
        <Dialog
            onClose={() => { setOpen(false) }}
            open={open}
            scroll={"paper"}

        >
            <DialogTitle sx={{ fontSize: "18px", fontWeight: 500, color: theme.palette.text.primary }}>{translate('resources.locations.labels.addLocation')}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={(() => setOpen(false))}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent
                sx={{
                    width: { xs: "300px", md: "418px", lg: "418px" },
                    height: { xs: "418px", md: "418px", lg: "418px" },
                    margin: "0px",
                    padding: "0px",
                }}

            >

                <SimpleForm onSubmit={locationSave} >
                    {styledFormInputs(inputsData)}
                    {styledFormInputs(optionalInputsData)}
                </SimpleForm>
            </DialogContent>



        </Dialog>
    );
}



const startDateFromSearchOption = (searchOption) => {
    let date = new Date()
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    switch (searchOption) {
        case "today":
            break;
        case "custom":
            break;
        case "yesterday":
            date.setDate(date.getDate() - 1)
            break;
        default:
            return null
    }
    return dayjs(date.toISOString())
}


const endDateFromSearchOption = (searchOption) => {
    let date = new Date()
    date.setHours(23)
    date.setMinutes(59)
    date.setSeconds(59)
    date.setMilliseconds(999)
    switch (searchOption) {
        case "today":
            break;
        case "custom":
            break;
        case "yesterday":
            date.setDate(date.getDate() - 1)
            break;
        default:
            return null
    }
    return dayjs(date.toISOString())
}


const perPageFromSearchOption = (searchOption) => {
    switch (searchOption) {
        case "last_5":
            return 5
        case "last_25":
            return 25
        case "last_50":
            return 50
        case "last_100":
            return 100
        default:
            return 1000
    }
}


const ignorePaginationFromSearchOption = (searchOption) => {
    switch (searchOption) {
        case "last_5":
            return true
        case "last_25":
            return true
        case "last_50":
            return true
        case "last_100":
            return true
        default:
            return false
    }
}


const LocationHistoryActions = ({ recordId }) => {
    const theme = useTheme();
    const translate = useTranslate();
    const styles = customStyles({ theme });
    const [addLocationDialogOpen, setAddLocationDialogOpen] = useState(false);
    const [searchOption, setSearchOption] = useState("last_5");
    const [maxAccuracy, setMaxAccuracy] = useState("all");
    const [ignorePagination, setIgnorePagination] = useState(ignorePaginationFromSearchOption("last_5"));
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [perPage, setPerPage] = useState(perPageFromSearchOption("last_5"));
    const listContext = useListContext();


    const handleChangeSearchOption = (event) => {
        const { value } = event.target
        setSearchOption(value)
        setStartDate(startDateFromSearchOption(value))
        setEndDate(endDateFromSearchOption(value))
        setPerPage(perPageFromSearchOption(value))
        setIgnorePagination(ignorePaginationFromSearchOption(value))
    }


    const handleChangeMaxAccuracy = (event) => {
        const { value } = event.target
        setMaxAccuracy(value)
    }


    return (


        <Box
            sx={styles.container}
        >

            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={searchOption}
                label={translate("resources.locations.searchOption")}
                onChange={handleChangeSearchOption}
                sx={{
                    width: "150px",
                    height: "48px",
                    margin: "0px 10px 0px 0px !important",
                    "& .MuiInputBase-root": {
                        padding: "0px !important",
                        height: "48px",
                        textAlign: "center !important",
                        borderRadius: "12px !important",
                        borderWidth: "1px",
                        backgroundColor:
                            theme.palette.mode === "light"
                                ? theme.palette.grey[50]
                                : theme.palette.background.appFrame,
                        color: theme.palette.text.primary,
                    },
                    "& .MuiFormLabel-root": {
                        marginTop: "4px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "12px !important",
                        borderWidth: "1px",
                    },
                    "legend > span": {
                        opacity: 1,
                        color: theme.palette.primary.main,
                        height: "15px"
                    },
                    "legend": {
                        opacity: 1,
                        color: theme.palette.primary.main,
                        height: "15px",
                        fontSize: "11px"

                    },
                }}
            >
                <MenuItem value={"last_5"}>{translate("resources.locations.location5")}</MenuItem>
                <MenuItem value={"last_25"}>{translate("resources.locations.location25")}</MenuItem>
                <MenuItem value={"last_50"}>{translate("resources.locations.location50")}</MenuItem>
                <MenuItem value={"last_100"}>{translate("resources.locations.location100")}</MenuItem>
                <MenuItem value={"today"}>{translate("resources.locations.locationToday")}</MenuItem>
                <MenuItem value={"yesterday"}>{translate("resources.locations.locationYesterday")}</MenuItem>
                <MenuItem value={"custom"}>{translate("resources.locations.customized")}</MenuItem>
            </Select>

            {startDate && endDate &&
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        label={translate("resources.devices.from")}
                        value={startDate}
                        onChange={(newValue) => { setStartDate(newValue); setSearchOption("custom") }}
                        sx={{
                            width: "180px",
                            height: "48px",
                            margin: "0px 30px 0px 0px !important",
                            "& .MuiInputBase-root": {
                                padding: "0px !important",
                                height: "48px",
                                textAlign: "center !important",
                                borderRadius: "12px !important",
                                borderWidth: "1px",
                                backgroundColor:
                                    theme.palette.mode === "light"
                                        ? theme.palette.grey[50]
                                        : theme.palette.background.appFrame,
                                color: theme.palette.text.primary,
                            },
                            "& .MuiFormLabel-root": {
                                marginTop: "4px",
                            },
                            "& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd": {
                                paddingRight: "4px !important",
                                paddingLeft: "10px !important",
                            },

                            "& .MuiButtonBase-root.MuiIconButton-root": {
                                padding: "8px 2px 8px 2px !important",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderRadius: "12px !important",
                                borderWidth: "1px",
                                width: "200px",
                                padding: "0px 30px 0px 8px !important",
                            },

                        }}

                    />
                    <DateTimePicker
                        label={translate("resources.devices.to")}
                        value={endDate}
                        onChange={(newValue) => { setEndDate(newValue); setSearchOption("custom") }}
                        sx={{
                            width: "180px",
                            height: "48px",
                            margin: "0px 30px 0px 0px !important",
                            "& .MuiInputBase-root": {
                                padding: "0px !important",
                                height: "48px",
                                textAlign: "center !important",
                                borderRadius: "12px !important",
                                borderWidth: "1px",
                                backgroundColor:
                                    theme.palette.mode === "light"
                                        ? theme.palette.grey[50]
                                        : theme.palette.background.appFrame,
                                color: theme.palette.text.primary,
                            },
                            "& .MuiFormLabel-root": {
                                marginTop: "4px",
                            },
                            "& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd": {
                                paddingRight: "4px !important",
                                paddingLeft: "10px !important",
                            },

                            "& .MuiButtonBase-root.MuiIconButton-root": {
                                padding: "8px 2px 8px 2px !important",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderRadius: "12px !important",
                                borderWidth: "1px",
                                width: "200px",
                                padding: "0px 30px 0px 8px !important",
                            },
                        }}
                    />
                </LocalizationProvider>
            }

            <Select
                value={maxAccuracy}
                label={translate("resources.devices.labels.accuracy")}
                onChange={handleChangeMaxAccuracy}
                sx={{
                    width: "150px",
                    height: "48px",
                    margin: "0px 10px 0px 0px !important",
                    "& .MuiInputBase-root": {
                        padding: "0px !important",
                        height: "48px",
                        textAlign: "center !important",
                        borderRadius: "12px !important",
                        borderWidth: "1px",
                        backgroundColor:
                            theme.palette.mode === "light"
                                ? theme.palette.grey[50]
                                : theme.palette.background.appFrame,
                        color: theme.palette.text.primary,
                    },
                    "& .MuiFormLabel-root": {
                        marginTop: "4px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "12px !important",
                        borderWidth: "1px",
                    },
                    "legend > span": {
                        opacity: 1,
                        color: theme.palette.primary.main,
                        height: "15px"
                    },
                    "legend": {
                        opacity: 1,
                        color: theme.palette.primary.main,
                        height: "15px",
                        fontSize: "11px"

                    },
                }}
            >
                <MenuItem value={"all"}>{translate("resources.locations.accuracy.all")}</MenuItem>
                <MenuItem value={50}>{translate("resources.locations.accuracy.lessThan50m")}</MenuItem>
                <MenuItem value={100}>{translate("resources.locations.accuracy.lessThan100m")}</MenuItem>
                <MenuItem value={500}>{translate("resources.locations.accuracy.lessThan500m")}</MenuItem>
                <MenuItem value={1000}>{translate("resources.locations.accuracy.lessThan1km")}</MenuItem>
                <MenuItem value={5000}>{translate("resources.locations.accuracy.lessThan5km")}</MenuItem>
                <MenuItem value={10000}>{translate("resources.locations.accuracy.lessThan10km")}</MenuItem>
                <MenuItem value={20000}>{translate("resources.locations.accuracy.lessThan20km")}</MenuItem>
            </Select>

            <Button
                variant='contained'
                sx={{ my: 1 }}
                onClick={() => {
                    let filters = {}
                    if (maxAccuracy !== "all") {
                        filters.max_accuracy = parseInt(maxAccuracy)
                    }

                    if (startDate) {
                        filters.start_date = startDate.toISOString()
                    }
                    if (endDate) {
                        filters.end_date = endDate.toISOString()
                    }
                    if (ignorePagination) {
                        filters.ignore_pagination = true
                    } else {
                        filters.ignore_pagination = false
                    }

                    listContext.setFilters(filters, {}, false)
                    listContext.setPerPage(perPage)

                }}>
                {translate("resources.locations.labels.viewHistory")}
            </Button>

            <Box sx={{
                display: 'flex',
                flex: 1
            }} />
            <ExportDataButton selectedIds={recordId} selectedEventType={"location"} />
            {isSuperAdmin() && <AddLocationBtn color={theme.palette.primary.main} onClick={() => setAddLocationDialogOpen(true)} />}
            {isSuperAdmin() && <AddLocationDialog open={addLocationDialogOpen} setOpen={setAddLocationDialogOpen} deviceId={recordId} />}

        </Box>

    );
}







export default LocationHistoryActions