import React from 'react'

import {
    Edit,
    minLength,
    required,
    SaveButton,
    SimpleForm,
    Toolbar,
    useTranslate,
    useRedirect,
    useNotify,
} from 'react-admin'
import { Typography, useTheme } from '@mui/material';
import { isAdminPermissions, isSuperAdmin } from '../../../helper/resourceHelper';
import styledFormInputs from '../../../ui/inputs/styledFormInputs';
import NavigateBackBtn from '../../../ui/button/NavigateBackBtn';
import { USER_ORGANIZATION_ID } from '../../configuration/actions';
import { getOrganizationIdFromUrl } from '../../../utils/utils';



const validateRequired = [required('Champs obligatoire')]



const OrganizationEdit = () => {
    const translate = useTranslate();
    const theme = useTheme();

    const isAdminOfCurrentOrganization = () => {
        const userOrganizationId = localStorage.getItem(USER_ORGANIZATION_ID);
        const currentOrganizationId = getOrganizationIdFromUrl();
        return isAdminPermissions() && currentOrganizationId.length > 0 && userOrganizationId === currentOrganizationId
    }
    const inputsData = [
        { name: 'name', label: translate('resources.organization.name'), validate: validateRequired, type: "text" },
        { name: 'description', label: translate('resources.organization.description'), type: "text" },
    ]

    if (isSuperAdmin() ||
        (isAdminPermissions() &&
            !isAdminOfCurrentOrganization())
    ) {
        inputsData.push(
            { name: 'parent_organization_id', label: translate('resources.organization.parent'), validate: validateRequired, type: "reference", reference: "organizations", optionText: "name" },
        )
    } else {
        inputsData.push(
            { name: 'parent_organization_id', label: translate('resources.organization.parent'), type: "text", disabled: true },
        )
    }

    if (isSuperAdmin()) {
        inputsData.push(
            { name: 'root_organization_id', label: translate('resources.organization.root'), validate: validateRequired, type: "reference", reference: "root_organizations", optionText: "name", disabled: true },
        )
    } else {
        inputsData.push(
            { name: 'root_organization_id', label: translate('resources.organization.root'), type: "text", disabled: true },
        )
    }



    return (
        <>
            <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "24px", lineHeight: "42px", margin: "0px 0px 16px 0px" }}>
                <NavigateBackBtn /> {translate('resources.organizations.edit')}
            </Typography>
            <Edit
                title={'resources.organizations.edit'}
                sx={{
                    "& .MuiPaper-root": {
                        backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary,
                    },
                }}
            >
                <SimpleForm  >
                    {styledFormInputs(inputsData)}
                </SimpleForm>
            </Edit>
        </>
    )
}

export default OrganizationEdit