import React from 'react'
import { AppBar as RaAppBar, } from 'react-admin'
import { useMediaQuery } from '@mui/material';
import RoLogoAppBar from './RoLogoAppBar'
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import SidebarToggleButton from './SidebarToggleButton';
import RefreshIconButton from './RefreshIconButton';
import NotificationButton from './NotificationButton';
import ToggleThemeButton from './ToggleThemeButton';
import LanguageButton from './LanguageButton';
import LogoutButton from './LogoutButton';


const customStyles = ({ theme }) => ({
    raAppBar: {
        display: 'inline-block',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: theme.palette.text.primary,
        zIndex: 1000,
        '.RaAppBar-menuButton': {
            display: "none"
        },
        '.RaAppBar-title': {
            display: "none"
        },
        "box-shadow": "none"
    }
})






const AppBar = () => {
    const theme = useTheme();
    const styles = customStyles({ theme });
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    return (
        <RaAppBar
            sx={styles.raAppBar}
            toolbar={<></>}
            userMenu={<></>}
        >
            {!isSmall && <RoLogoAppBar />}
            <SidebarToggleButton />
            <Box sx={{ flex: 1 }} />
            <LanguageButton />
            <RefreshIconButton />
            <NotificationButton />
            <LogoutButton />

        </RaAppBar>);
}



export default AppBar


