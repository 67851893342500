import { Grid, useTheme, useMediaQuery, Box, Avatar, Card, Tooltip, ButtonBase, CardContent, Typography } from "@mui/material"
import { useDataProvider, useTranslate } from 'react-admin'
import { useNavigate } from "react-router-dom";
import DashboardCard, { InfoLine, InfoTable2Col } from "../../ui/card/DashboardCard";
import { useEffect, useState } from "react";
import { getDevicesSummary } from "../../rest/restClient";






const Icon = ({ color }) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-devices">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M13 9a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-6a1 1 0 0 1 -1 -1v-10z" />
            <path d="M18 8v-3a1 1 0 0 0 -1 -1h-13a1 1 0 0 0 -1 1v12a1 1 0 0 0 1 1h9" />
            <path d="M16 9h2" />
        </svg>
    )

}



const NumberOfAlertsBodyComponent = ({
    nbrOfAlerts,
    nbrOfEmailAlerts,
    nbrOfSmsAlerts
}) => {

    const translate = useTranslate();

    let rows = []

    if (nbrOfAlerts) {
        rows.push({ label: translate("resources.dashboard.number_of_alerts.nbr_of_alerts"), info: nbrOfAlerts })
    }
    if (nbrOfEmailAlerts) {
        rows.push({ label: translate("resources.dashboard.number_of_alerts.nbr_of_email_alerts"), info: nbrOfEmailAlerts })
    }
    if (nbrOfSmsAlerts) {
        rows.push({ label: translate("resources.dashboard.number_of_alerts.nbr_of_sms_alerts"), info: nbrOfSmsAlerts })
    }


    return (
        <InfoTable2Col rows={rows} />
    )

}


const NumberOfAlerts = ({ }) => {

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const translate = useTranslate();
    const [nbrOfAlerts, setNbrOfAlerts] = useState(10)
    const [nbrOfEmailAlerts, setNbrOfEmailAlerts] = useState(7)
    const [nbrOfSmsAlerts, setNbrOfSmsAlerts] = useState(3)
    useEffect(async () => {

    }, [])
    return null;
    /*
    return (

        <Grid
            item
            sm={12}
            md={6}
            sx={{
                width: "100%",
                margin: isSmall ? "8px 0px 0px 0px" : '0px',
            }} >
            <DashboardCard 
            HeaderIcon={()=><Icon color={theme.palette.primary.dark}/>} 
            title={translate("resources.dashboard.titles.number_of_alerts")} 
            BodyComponent={() => <NumberOfAlertsBodyComponent nbrOfAlerts={nbrOfAlerts} nbrOfEmailAlerts={nbrOfEmailAlerts} nbrOfSmsAlerts={nbrOfSmsAlerts}/>}
             redirectTo={"/notifications"} />
        </Grid>
    )
        */
}

export default NumberOfAlerts;