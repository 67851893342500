//import 'moment-duration-format'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { translate, GET_LIST, useTranslate, useListContext, } from 'react-admin'
import dataProviderFactory from '../../dataProvider'
import { TimeSeries } from 'pondjs'
import styled from 'jss-material-ui'
import { getDateTimeToFullLocalString, getHoursMinutesFromTimestamp, getDayMonthFromTimestamp } from '../../utils/date'
import { Paper99White, Title100Chart, H100Grid } from '../../ui/Grid';
import { IconArrowsMaximize, IconArrowsMinimize, IconHelp, IconX } from '@tabler/icons-react';
import { Area, AreaChart, LineChart, Cell, BarChart, Bar, Rectangle, ReferenceArea, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, ReferenceLine, Label } from 'recharts';
import InfoCard from './infoCard'
import { Box, Button, CircularProgress, Paper, Typography, IconButton, useTheme, useMediaQuery } from '@mui/material'
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import StandardDialog from '../../ui/dialog/StandardDialog'
//ticatag




export const P100 = styled('p')({
    root: {
        textAlign: 'right',
        width: '100%',
        marginTop: 2,
        marginBottom: 6,
    },
})


const getUnitSymbol = (unit) => {
    if (unit === null || unit === undefined) {
        return ''
    }
    if (unit === 'percent') return "%"
    if (unit === 'celsius') return "°C"
    if (unit === 'millivolt') return "mV"
    if (unit === 'microgram_per_m3') return "µg/m³"
    if (unit === 'hpa') return "hPa"
    if (unit === 'hectopascal') return "hPa"


    return unit
}




const LoadingCircularProgress = ({ color }) => {
    return (
        <Box sx={{ display: 'flex' }}>
            <CircularProgress color={color} />
        </Box>
    );
}





const CustomizedTick = (props) => {
    const { x, y, stroke, payload } = props;

    const timestamp = payload && payload.value ? payload.value : null;
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} fill="#666">
                <tspan textAnchor="middle" x="0" style={{ fontSize: '12px', fontWeight: 400, lineHeight: '20px' }}>
                    {timestamp ? getHoursMinutesFromTimestamp(timestamp) : ".."}
                </tspan>
                <tspan textAnchor="middle" x="0" dy="20" style={{ fontSize: '10px', fontWeight: 400, lineHeight: '20px' }}>
                    {timestamp ? getDayMonthFromTimestamp(timestamp) : ".."}
                </tspan>

            </text>
        </g>
    );
}

/**
 * Measures chart for events with values in a continues range 
 * 
 */

const MeasuresAreaChart = ({ chartProperties, translate, isSmall }) => {
    const [helpLegendOpen, setHelpLegendOpen] = useState(false);
    const [fullScreen, setFullScreen] = useState(false);

    const defsContent = chartProperties.thresholdPercentFromMax && chartProperties.eventName ? (
        <defs >
            <linearGradient id={`gradient${chartProperties.eventName}`} x1="0" y1="0" x2="0" y2="1" >
                <stop offset={0} stopColor="red" stopOpacity={1} />
                <stop offset={`${chartProperties.thresholdPercentFromMax}%`} stopColor="red" stopOpacity={1} />
                <stop offset={`${chartProperties.thresholdPercentFromMax}%`} stopColor={chartProperties.style.lineColor} stopOpacity={0.8} />
                <stop offset={1} stopColor={chartProperties.style.lineColor} stopOpacity={0} />
            </linearGradient>
        </defs>
    ) : (
        <defs >
            <linearGradient id={`gradient${chartProperties.eventName}`} x1="0" y1="0" x2="0" y2="1" >
                <stop offset="5%" stopColor={chartProperties.style.lineColor} stopOpacity={0.8} />
                <stop offset="95%" stopColor={chartProperties.style.lineColor} stopOpacity={0.05} />
            </linearGradient>
        </defs>
    );


    const handleClickHelpLegend = () => {
        setHelpLegendOpen(!helpLegendOpen);
    };
    const handleClickFullScreen = () => {
        setFullScreen(!fullScreen)
    }

    const ChartHeader = ({isFullScreen}) => {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'row', margin: "0px 0px 20px 0px" }}>
                <Typography variant='body2' fontWeight="800" pt={"2px"} mb={"2px"} ml={"10px"}> {chartProperties.label}</Typography>
                {chartProperties.unit !== null && chartProperties.unit !== undefined && <Typography variant='caption' fontWeight="400" pt={"2px"} mb={"2px"} ml={"5px"}> {`(${getUnitSymbol(chartProperties.unit)})`}</Typography>}
                <Box sx={{ flex: 1 }} />
                {chartProperties.showHelpLegend === true && <Box mr={"10px"}><IconHelp size={16}  cursor={"pointer"} onClick={() => handleClickHelpLegend()} /></Box>}
                {!isSmall && !isFullScreen &&<IconArrowsMaximize size={16} cursor={"pointer"} onClick={() => handleClickFullScreen()} />}
                {!isSmall &&isFullScreen&&<IconArrowsMinimize size={16} cursor={"pointer"} onClick={() => handleClickFullScreen()} />}
            </Box>
        )
    }

    const ChartMetrics = () => {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: "20px" }}>
                {chartProperties.showDangerThreshold === true &&
                    <InfoCard title={translate('resources.co2.treshold')} value={chartProperties.dangerThreshold + getUnitSymbol(chartProperties.unit)} isSmall={isSmall} />
                }
                <InfoCard title={translate('resources.co2.max')} value={chartProperties.max + getUnitSymbol(chartProperties.unit)} isSmall={isSmall} />
                <InfoCard title={translate('resources.co2.avg')} value={chartProperties.avg + getUnitSymbol(chartProperties.unit)} isSmall={isSmall} />
                <InfoCard title={translate('resources.co2.min')} value={chartProperties.min + getUnitSymbol(chartProperties.unit)} isSmall={isSmall} />
            </Box>
        )
    }

    const ChartResponsiveContainer = () => {
        return (
            <ResponsiveContainer width="100%" minWidth={300} aspect={isSmall ? (1 / 1) : (3 / 1)} minHeight={"140px"} >
                <AreaChart data={chartProperties.series[0].data} width={600} height={300} margin={{ top: 0, right: 0, left: 0, bottom: 80 }} >
                    {defsContent}
                    <ReferenceLine y={chartProperties.dangerThreshold} label={{ value: translate('resources.co2.treshold'), fill: "red", offset: 50, angle: 0, position: 'left' }} stroke="red" strokeDasharray="3 0" alwaysShow={true} />
                    <CartesianGrid strokeWidth={0.5} strokeDasharray='1 0' vertical={false} />
                    <Tooltip labelFormatter={getDateTimeToFullLocalString} formatter={(value, name, props) => [value + " " + getUnitSymbol(chartProperties.unit), chartProperties.label]} />
                    <Area type="monotone" name={chartProperties.series[0].name} dataKey="y" stroke={chartProperties.style.lineColor} fill={`url(#gradient${chartProperties.eventName})`} isAnimationActive={false} strokeWidth={3} dot={false} activeDot={{ stroke: chartProperties.style.lineColor, strokeWidth: 1, r: 6 }} />
                    <XAxis dataKey="x" type="number" tickCount={24} angle={-45} tick={CustomizedTick} dy={30} tickLine={false} domain={['dataMin', 'dataMax']} style={{ fontSize: "12px" }} />
                    <YAxis dataKey="y" type="number" tickCount={chartProperties.tickCount ? chartProperties.tickCount : 10} width={40} domain={Array.isArray(chartProperties.yDomain) && chartProperties.yDomain.length === 2 ? chartProperties.yDomain : [0, chartProperties.ymax]} style={{ fontSize: "12px" }} />
                    {
                        Array.isArray(chartProperties.referenceAreas) &&
                        chartProperties.referenceAreas.map((refArea, index) => (
                            <ReferenceArea
                                key={index}
                                x1={chartProperties.series[0].data[0].time}
                                x2={chartProperties.series[0].data[chartProperties.series[0].data.length - 1].time}
                                y1={refArea.yMin}
                                y2={refArea.yMax}
                                fill={refArea.color}
                                fillOpacity={0.2}
                            />
                        ))

                    }
                </AreaChart>
            </ResponsiveContainer>
        )
    }

    const ChartHelpDialog = ({ open }) => {
        return (
            <StandardDialog open={open}  BodyComponent={() =>
                <Box
                    sx={{
                        width: {
                            md: "800px",
                            lg: "1000px",
                            xlg: "1300px",
                        }
                    }}>
                    <Box sx={{ width: "100%", height: "100%" }}>
                        <Box sx={{ width: "90%", display:'flex' , flexDirection:"row" , justifyContent:"flex-end", padding:"12px 12px 12px 12px" }}><IconX size={24}  cursor={"pointer"} onClick={() => handleClickHelpLegend()} > </IconX></Box>
                        {chartProperties.HelpLegendComponent}
                        
                    </Box>
                </Box>
            } />
        )
    }

    const FullScreenDialog = ({ open, onClose }) => {
        return (
            <StandardDialog open={open} onClose={onClose} BodyComponent={() =>
                <Box
                    sx={{
                        width: {
                            md: "800px",
                            lg: "1000px",
                            xlg: "1300px",
                        },
                        height: "60%"
                    }}>
                    <Box sx={{ width: "100%", height: "100%" }}>
                        <ChartHeader isFullScreen={true} />
                        <ChartMetrics />
                        <ChartResponsiveContainer />

                    </Box>
                </Box >
            } />
        )
    }

    const Chart = () => {
        return (
            <Box sx={{ width: "100%", height: "100%" }}>
                <ChartHeader />
                <ChartMetrics />
                <ChartHelpDialog open={helpLegendOpen}  />
                <FullScreenDialog open={fullScreen} onClose={() => handleClickFullScreen()} />
                <ChartResponsiveContainer />
            </Box>
        )
    }

    return (<Chart />)

}


const MeasuresBarChart = ({ chartProperties, translate, isSmall }) => {
    const [helpLegendOpen, setHelpLegendOpen] = useState(false);
    const [fullScreen, setFullScreen] = useState(false);
    const handleClickHelpLegend = () => {
        setHelpLegendOpen(!helpLegendOpen);
    };
    const handleClickFullScreen = () => {
        setFullScreen(!fullScreen)
    }

    const ChartHeader = ({isFullScreen}) => {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'row', margin: "0px 0px 20px 0px" }}>
                <Typography variant='body2' fontWeight="800" pt={"2px"} mb={"2px"} ml={"10px"}> {chartProperties.label}</Typography>
                {chartProperties.unit !== null && chartProperties.unit !== undefined && <Typography variant='caption' fontWeight="400" pt={"2px"} mb={"2px"} ml={"5px"}> {`(${getUnitSymbol(chartProperties.unit)})`}</Typography>}
                <Box sx={{ flex: 1 }} />
                {chartProperties.showHelpLegend === true && <Box mr={"10px"}><IconHelp size={16}  cursor={"pointer"} onClick={() => handleClickHelpLegend()} /></Box>}
                {!isSmall && !isFullScreen &&<IconArrowsMaximize size={16} cursor={"pointer"} onClick={() => handleClickFullScreen()} />}
                {!isSmall &&isFullScreen&&<IconArrowsMinimize size={16} cursor={"pointer"} onClick={() => handleClickFullScreen()} />}
            </Box>
        )
    }


    const ChartMetrics = () => {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: "20px" }}>
                {chartProperties.showDangerThreshold === true &&
                    <InfoCard title={translate('resources.co2.treshold')} value={chartProperties.dangerThreshold + getUnitSymbol(chartProperties.unit)} isSmall={isSmall} />
                }
                <InfoCard title={translate('resources.co2.max')} value={chartProperties.max + getUnitSymbol(chartProperties.unit)} isSmall={isSmall} />
                <InfoCard title={translate('resources.co2.avg')} value={chartProperties.avg + getUnitSymbol(chartProperties.unit)} isSmall={isSmall} />
                <InfoCard title={translate('resources.co2.min')} value={chartProperties.min + getUnitSymbol(chartProperties.unit)} isSmall={isSmall} />
            </Box>
        )
    }

    const ChartResponsiveContainer = () => {
        return (
            <ResponsiveContainer width="100%" minWidth={300} aspect={isSmall ? (1 / 1) : (3 / 1)} minHeight={200} >
                <BarChart data={chartProperties.series[0].data} width={1000} height={300} margin={{ top: 20, right: isSmall ? 5 : 40, left: isSmall ? 0 : 40, bottom: 80 }} >
                    <CartesianGrid strokeWidth={0.5} strokeDasharray='1 0' vertical={false} />
                    <Tooltip labelFormatter={getDateTimeToFullLocalString} formatter={(value, name, props) => [value + " " + getUnitSymbol(chartProperties.unit), chartProperties.label]} />
                    <XAxis dataKey="x" type="number" tickCount={24} angle={-45} tick={CustomizedTick} dy={30} tickLine={false} domain={['dataMin', 'dataMax']} style={{ fontSize: "12px" }} />
                    <YAxis dataKey="y" type="number" tickCount={chartProperties.tickCount ? chartProperties.tickCount : 10} width={40} domain={Array.isArray(chartProperties.yDomain) && chartProperties.yDomain.length === 2 ? chartProperties.yDomain : [0, chartProperties.ymax]} style={{ fontSize: "12px" }} />

                    <Bar
                        name={chartProperties.series[0].name}
                        dataKey={`y`}
                        barSize={5}
                        fill="black"
                    >
                        {
                            chartProperties.series[0].data.map((entry, index) => {

                                for (const range of chartProperties.referenceAreas) {
                                    if (entry.y >= range.yMin && entry.y < range.yMax) {
                                        return (
                                            <Cell fill={range.color} key={`cell-${index}`} />
                                        )
                                    }
                                }

                                return (<Cell fill={range.color} key={`cell-${index}`} />)


                            })
                        }

                    </Bar>

                </BarChart>
            </ResponsiveContainer>
        )
    }

    const ChartHelpDialog = ({ open }) => {
        return (
            <StandardDialog open={open}  BodyComponent={() =>
                <Box
                    sx={{
                        width: {
                            md: "800px",
                            lg: "1000px",
                            xlg: "1300px",
                        }
                    }}>
                    <Box sx={{ width: "100%", height: "100%" }}>
                        <Box sx={{ width: "90%", display:'flex' , flexDirection:"row" , justifyContent:"flex-end", padding:"12px 12px 12px 12px" }}><IconX size={24}  cursor={"pointer"} onClick={() => handleClickHelpLegend()} > </IconX></Box>
                        {chartProperties.HelpLegendComponent}
                        
                    </Box>
                </Box>
            } />
        )
    }

    const FullScreenDialog = ({ open, onClose }) => {
        return (
            <StandardDialog open={open} onClose={onClose} BodyComponent={() =>
                <Box
                    sx={{
                        width: {
                            md: "800px",
                            lg: "1000px",
                            xlg: "1300px",
                        },
                        height: "60%"
                    }}>
                    <Box sx={{ width: "100%", height: "100%" }}>
                        <ChartHeader isFullScreen={true}/>
                        <ChartMetrics />
                        <ChartResponsiveContainer />

                    </Box>
                </Box >
            } />
        )
    }


    const Chart = () => {
        return (
            <Box sx={{ width: "100%", height: "100%" }}>
                <ChartHeader />
                <ChartMetrics />
                <ChartHelpDialog open={helpLegendOpen}  />
                <FullScreenDialog open={fullScreen} onClose={() => handleClickFullScreen()} />
                <ChartResponsiveContainer />
            </Box>
        )
    }

    return (<Chart />)
}



const SensorCharts = ({ classes, ...props }) => {

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const translate = useTranslate();
    const { device, eventName, chartOptions } = props
    const { data, isLoading, isFetching } = useListContext();






    const points = []
    const dataPoints = []
    const chartProperties = { ...chartOptions }




    // no data to display
    if (isLoading) {

        return <LoadingCircularProgress color="primary" />;
    }
    // no data to display
    if (isFetching) {

        return <LoadingCircularProgress color="success" />;
    }
    if (Array.isArray(data) && data.length === 0) {
        return null;
    }
    if ((data === undefined || data === null)) {

        return null;
    }




    let filteredData = {}
    // filter units if they are different
    if (chartProperties.unitToShow !== undefined && chartProperties.unitToShow !== null) {
        filteredData = data.filter((ev) => ev.unit === chartProperties.unitToShow);
    } else {
        filteredData = data.filter((ev) => ev.unit === data[0].unit);

    }

    //get time/values for standard charts
    for (let i = 1; i <= filteredData.length; i += 1) {

        const event = filteredData[filteredData.length - i]

        if (event !== undefined && event !== null && eventName === event.name) {
            const time = Date.parse(event.timestamp)
            const value = Math.round(event.value * 100) / 100
            dataPoints.push({ x: time, y: value })
            points.push([time, value])

            //get unit
            chartProperties.unit = event.unit !== undefined ? event.unit : null;

        }


        //temp unit for co2 
        if (event !== undefined && event !== null && event.name === "co2") {
            // no data to display 
            chartProperties.unit = "ppm"
        }
    }



    // Make the TimeSeries here from the points collected above
    const series = new TimeSeries({
        name: eventName,
        columns: ['time', eventName],
        points: points,
    })


    chartProperties.series = [{
        name: eventName,
        data: dataPoints
    }];


    // calculate statistics
    if (points.length > 0) {
        chartProperties.avg = parseInt(series.avg(eventName), 10)
        chartProperties.max = parseInt(series.max(eventName), 10)
        chartProperties.min = parseInt(series.min(eventName), 10)
        chartProperties.maxTime = series.range() ? series.range().end() : 0
        chartProperties.minTime = series.range() ? series.range().begin() : 0
        chartProperties.ymax = chartProperties.max + (10 - (chartProperties.max % 10))
    } else {
        chartProperties.show = false;
    }


    // add threshold 
    if (chartProperties.showDangerThreshold === true) {
        if (device != null && device.configuration) {
            chartProperties.dangerThreshold = device.configuration.co2_alert_threshold
            chartProperties.thresholdPercentFromMax = ((chartProperties.max - chartProperties.dangerThreshold) / chartProperties.max) * 100;
        } else {
            chartProperties.showDangerThreshold = false
            chartProperties.dangerThreshold = null
        }

    }

    return (
        <Paper elevation={0} sx={{
            backgroundColor: `${theme.palette.background.paper} !important`,
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            margin: 0,
            borderRadius: "12px",
            padding: "20px",
            width: '100%',
            minHeight: '300px',
            maxHeight: '400px'
        }}>
            <div id="chartsToExport" >
                {chartProperties.type === "AreaChart" && <MeasuresAreaChart chartProperties={chartProperties} translate={translate} isSmall={isSmall} />}
                {chartProperties.type === "BarChart" && <MeasuresBarChart chartProperties={chartProperties} translate={translate} isSmall={isSmall} />}
            </div >

        </Paper>

    )


}
export default SensorCharts;


