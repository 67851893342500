import { useTranslate } from "react-admin";



const SearchInputLabelComponent = (labelProps) => {

    const translate = useTranslate();
    const SearchIcon = () => {
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_165_9911)">
                    <path d="M10.3338 9.33326H9.80708L9.62042 9.15326C10.4204 8.21993 10.8338 6.9466 10.6071 5.59326C10.2938 3.73993 8.74708 2.25993 6.88042 2.03326C4.06042 1.6866 1.68708 4.05993 2.03375 6.87993C2.26042 8.74659 3.74042 10.2933 5.59375 10.6066C6.94708 10.8333 8.22042 10.4199 9.15375 9.61993L9.33375 9.80659V10.3333L12.1671 13.1666C12.4404 13.4399 12.8871 13.4399 13.1604 13.1666C13.4338 12.8933 13.4338 12.4466 13.1604 12.1733L10.3338 9.33326ZM6.33375 9.33326C4.67375 9.33326 3.33375 7.99326 3.33375 6.33326C3.33375 4.67326 4.67375 3.33326 6.33375 3.33326C7.99375 3.33326 9.33375 4.67326 9.33375 6.33326C9.33375 7.99326 7.99375 9.33326 6.33375 9.33326Z" fill="#697586" />
                </g>
                <defs>
                    <clipPath id="clip0_165_9911">
                        <rect width="16" height="16" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        )
    }
    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%"
        }}>
            <SearchIcon {...labelProps} />
            <div style={{
                marginLeft: "8px",
                color: "inherit",
            }}>
                {translate('resources.common.search')}
            </div>
        </div>

    )
}


export default SearchInputLabelComponent;