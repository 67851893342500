import React, { useEffect, useState } from 'react'
import { Admin, resolveBrowserLocale, Resource, CustomRoutes, usePermissions } from 'react-admin'
import { customTheme } from './themes';
//jss
import JssProvider from 'react-jss/lib/JssProvider'
//auth
import authClient from './authProvider'
import { Login, LoginRedirect, LoginPage } from './pages/login/login'
//i18n
import i18nProvider from './i18nProvider'
//overidding layout & theme
import Layout from './layout/Layout'
import { CHANGE_THEME, RO_APP_TITLE, RO_FAVICON_URL, RO_ICON_180x180_URL, RO_ICON_192x192_URL, RO_ICON_512x512_URL, RO_ICON_96x96_URL, RO_LOGO_URL, RO_REFRESHED_AT, USER_FAV_LANGUAGE, USER_FAV_LANGUAGE_IS_SET } from './pages/configuration/actions'
//material-ui
import { AccountEdit } from './pages/account'

//resources
import { DeviceCreate, DeviceEdit, DeviceIcon, DeviceList } from './pages/device'
import { ExportCreate } from './pages/export'
import { HelpList, HelpIcon } from './pages/help'
import { HookCreate, HookEdit, HookList } from './pages/hook'
import { IntegrationList, IntegrationJohnDeereCreate, IntegrationTibibCreate, IntegrationJohnDeereList, IntegrationTibibList } from './pages/integration'
import { FopList } from './pages/fop'
import { LocationIcon, LocationList } from './pages/location'
import { MeasureIcon, MeasureList } from './pages/measure'
import { NotificationLogIcon, NotificationLogList } from './pages/notificationLog'
import { NotificationCreate, NotificationEdit, NotificationIcon, NotificationList } from './pages/notification'
import { OrganizationCreate, OrganizationEdit, OrganizationList, OrganizationIcon } from './pages/organization'
import { RootOrganizationCreate, RootOrganizationEdit, RootOrganizationList, RootOrganizationIcon } from './pages/rootOrganization'
import { InvoiceList, InvoiceIcon } from './pages/invoice'
import { AppOwnerInvoiceList, AppOwnerInvoiceIcon } from './pages/appOwnerInvoice'
import { UserCreate, UserEdit, UserIcon, UserList } from './pages/user'
import { NotificationEventList, NotificationEventIcon } from './pages/notificationLogsHistory'
import { ProductCreate, ProductEdit, ProductIcon, ProductList } from './pages/product'

import { SubscriptionsList, SubscriptionIcon, SubscriptionCreate } from './pages/subscription';
import { DeviceSubPlanList, DeviceSubPlanCreate, DeviceSubPlanEdit, DeviceSubPlanShow, DeviceSubPlanIcon } from './pages/deviceSubPlan'
import { SmsCreditPlanList, SmsCreditPlanCreate, SmsCreditPlanEdit, SmsCreditPlanShow, SmsCreditPlanIcon } from './pages/smsCreditPlan'

//components
import LocationHistoryList from './pages/locationsHistory/index'
import SensorHistoryList from './pages/sensorsHistory/index'
import NotificationLogHistoryList from './pages/notificationLogsHistory/index'
import DeviceAdd from './pages/device/add/DeviceAdd'
import DeviceAddBulk from './pages/device/add/DeviceAddBulk'
import UserInvite from './pages/user/invite/UserInvite'
import CheckoutSuccess from './pages/device/checkoutSuccess/checkoutSuccess'



//sagas
import realtimeSaga from './sagas/realtimeSaga'
import configReducer from './reducer/configReducer'
import { hasWriteAccess, isAdminPermissions, isROAdmin, isRootOrganizationUser, isSuperAdmin } from './helper/resourceHelper'
import dataProviderFactory from './dataProvider'
import { BrowserRouter, Route } from 'react-router-dom'
import { createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import { connectRouter } from 'connected-react-router';

// Make sure to import the correct adminReducer 


import { ROParametersLoader, ROParametersInit } from './helper/rootOrganizationResource'
import SmsCreditCheckoutSuccess from './pages/notification/checkoutSuccess/checkoutSuccess';
import Dashboard from './pages/dashboard';





// Définition des reducers personnalisés pour le store Redux
const customReducers = ({
    config: configReducer,// Reducer pour gérer la configuration
    router: connectRouter(history), // Reducer pour gérer la navigation
});



const dataProvider = await dataProviderFactory('rest');
//const dataProvider = await fakeDataProvider(JSON.parse(JSON.stringify(dataProv)))



const App = () => {
    const rootOrganizationParamsLoaded = localStorage.getItem(RO_REFRESHED_AT) ? true : false;

    if (window.location.pathname.startsWith("/load-ro-parameters")) return <ROParametersLoader />
    if (!rootOrganizationParamsLoaded) return <ROParametersInit />
    if (window.location.pathname.startsWith("/redirect")) return <LoginRedirect />
    if (window.location.pathname.startsWith("/init-login")) return <Login />


    useEffect(() => {
        const faviconIco = localStorage.getItem(RO_FAVICON_URL);
        const icon96x96 = localStorage.getItem(RO_ICON_96x96_URL);
        const icon180x180 = localStorage.getItem(RO_ICON_180x180_URL);
        const icon192x192 = localStorage.getItem(RO_ICON_192x192_URL);
        const icon512x512 = localStorage.getItem(RO_ICON_512x512_URL);


        const changeFavicon = (iconURL) => {
            const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.type = 'image/x-icon';
            link.rel = 'shortcut icon';
            link.href = iconURL;
            document.getElementsByTagName('head')[0].appendChild(link);
        };

        const dynamicManifest = {
            name: localStorage.getItem(RO_APP_TITLE),
            short_name: localStorage.getItem(RO_APP_TITLE),
            start_url: "./index.html",
            display: 'standalone',
            background_color: '#ffffff',
            theme_color: '#000000',
            description: 'An awesome web app',
            icons: [
                { src: faviconIco, sizes: '48x48 72x72 96x96 128x128 256x256 512x512', type: 'image/x-icon' },
                { src: icon96x96, sizes: '96x96', type: 'image/png' },
                { src: icon180x180, sizes: '180x180', type: 'image/png' },
                { src: icon192x192, sizes: '192x192', type: 'image/png' },
                { src: icon512x512, sizes: '512x512', type: 'image/png' }
            ]
        };
        const manifestBlob = new Blob([JSON.stringify(dynamicManifest)], { type: 'application/json' });
        const manifestUrl = URL.createObjectURL(manifestBlob);

        let manifestLink = document.querySelector("link[rel='manifest']");
        if (!manifestLink) {
            manifestLink = document.createElement('link');
            manifestLink.rel = 'manifest';
            document.head.appendChild(manifestLink);
        }
        manifestLink.href = manifestUrl;
        document.title = localStorage.getItem(RO_APP_TITLE);
        changeFavicon(localStorage.getItem(RO_FAVICON_URL));

    }, []);

    return (


        <Provider store={createStore(combineReducers(customReducers))}>

            <BrowserRouter>

                <Admin
                    layout={Layout}
                    authProvider={authClient}
                    //customSagas={[realtimeSaga(dataProvider)]}
                    dataProvider={dataProvider}
                    i18nProvider={i18nProvider}
                    locale={localStorage.getItem(USER_FAV_LANGUAGE) ? localStorage.getItem(USER_FAV_LANGUAGE) : resolveBrowserLocale()}
                    loginPage={LoginPage}
                    title={localStorage.getItem(RO_APP_TITLE)}
                    theme={customTheme.light}
                    darkTheme={customTheme.light}
                >

                    {permissions => [
                        <Resource
                            key="dashboard"
                            name="dashboard"
                            list={Dashboard}
                        />,

                        <Resource
                            key="devices"
                            name="devices"
                            icon={DeviceIcon}
                            create={hasWriteAccess(permissions) ? DeviceCreate : null}
                            edit={hasWriteAccess(permissions) ? DeviceEdit : null}
                            list={DeviceList}
                        />,

                        <Resource
                            key="notifications"
                            name="notifications"
                            icon={NotificationIcon}
                            create={hasWriteAccess(permissions) ? NotificationCreate : null}
                            edit={hasWriteAccess(permissions) ? NotificationEdit : null}
                            list={NotificationList}
                        />,
                        <Resource
                            key="users"
                            name="users"
                            icon={UserIcon}
                            create={isAdminPermissions(permissions) ? UserCreate : undefined}
                            edit={isAdminPermissions(permissions) ? UserEdit : undefined}
                            list={isAdminPermissions(permissions) ? UserList : undefined}
                        />,
                        <Resource
                            key="products"
                            name="products"
                            icon={ProductIcon}
                            create={isSuperAdmin(permissions) ? ProductCreate : undefined}
                            edit={isSuperAdmin(permissions) ? ProductEdit : undefined}
                            list={isSuperAdmin(permissions) ? ProductList : undefined}
                        />,
                        <Resource
                            key="subscriptions"
                            name="subscriptions"
                            icon={SubscriptionIcon}
                            list={isSuperAdmin(permissions) ? SubscriptionsList : undefined}
                            create={isSuperAdmin(permissions) ? SubscriptionCreate : undefined}
                        />,
                        <Resource
                            key="device_sub_plans"
                            name="device_sub_plans"
                            icon={DeviceSubPlanIcon}
                            list={(isSuperAdmin(permissions) || isROAdmin(permissions)) ? DeviceSubPlanList : undefined}
                            create={(isSuperAdmin(permissions) || isROAdmin(permissions)) ? DeviceSubPlanCreate : undefined}
                            edit={(isSuperAdmin(permissions) || isROAdmin(permissions)) ? DeviceSubPlanEdit : undefined}
                            show={(isSuperAdmin(permissions) || isROAdmin(permissions)) ? DeviceSubPlanShow : undefined}
                        />,

                        <Resource
                            key="sms_credit_plans"
                            name="sms_credit_plans"
                            icon={SmsCreditPlanIcon}
                            list={(isSuperAdmin(permissions)) ? SmsCreditPlanList : undefined}
                            create={(isSuperAdmin(permissions)) ? SmsCreditPlanCreate : undefined}
                            edit={(isSuperAdmin(permissions)) ? SmsCreditPlanEdit : undefined}
                            show={(isSuperAdmin(permissions)) ? SmsCreditPlanShow : undefined}
                        />,

                        <Resource
                            key="organizations"
                            name="organizations"
                            icon={OrganizationIcon}
                            create={isAdminPermissions(permissions) ? OrganizationCreate : undefined}
                            edit={isAdminPermissions(permissions) ? OrganizationEdit : undefined}
                            list={isAdminPermissions(permissions) ? OrganizationList : undefined}
                        />,
                        <Resource
                            key="root_organizations"
                            name="root_organizations"
                            icon={OrganizationIcon}
                            create={isSuperAdmin(permissions) ? RootOrganizationCreate : undefined}
                            edit={isSuperAdmin(permissions) ? RootOrganizationEdit : undefined}
                            list={isSuperAdmin(permissions) ? RootOrganizationList : undefined}
                        />,
                        <Resource key="hooks"
                            name="hooks"
                            create={hasWriteAccess(permissions) ? HookCreate : undefined}
                            edit={hasWriteAccess(permissions) ? HookEdit : undefined}
                            list={hasWriteAccess(permissions) ? HookList : undefined}
                        />,
                        <Resource
                            key="integrations_john_deere"
                            name="integrations_john_deere"
                            list={isAdminPermissions(permissions) ? IntegrationJohnDeereList : undefined}
                            create={isAdminPermissions(permissions) ? IntegrationJohnDeereCreate : undefined}
                        />
                        ,
                        <Resource
                            key="integrations_tibib"
                            name="integrations_tibib"
                            list={isAdminPermissions(permissions) ? IntegrationTibibList : undefined}
                            create={isAdminPermissions(permissions) ? IntegrationTibibCreate : undefined}
                        />,
                        <Resource
                            key="integrations"
                            name="integrations"
                            list={isAdminPermissions(permissions) ? IntegrationList : undefined}
                        />
                        ,
                        <Resource
                            key="help"
                            name="help"
                            icon={HelpIcon}
                            list={HelpList}
                        />,
                        <Resource
                            key="contact"
                            name="contact"
                        />,
                        <Resource key="device_attach" name="devices_attach" />,
                        <Resource key="device_status" name="devices_status" />,
                        <Resource key="devices_bulk" name="devices_bulk" />,
                        <Resource
                            key="locations"
                            name="locations"
                            icon={LocationIcon}
                            list={LocationList}
                        />,
                        <Resource
                            key="measures"
                            name="measures"
                            icon={MeasureIcon}
                            list={MeasureList}
                        />,
                        <Resource
                            key="notification_logs"
                            name="notification_logs"
                            icon={NotificationLogIcon}
                            list={NotificationLogList}
                        />,
                        <Resource
                            key="invoices"
                            name="invoices"
                            icon={InvoiceIcon}
                            list={InvoiceList}
                        />,
                        <Resource
                            key="app_owner_invoices"
                            name="app_owner_invoices"
                            icon={AppOwnerInvoiceIcon}
                            list={(isSuperAdmin(permissions) || isROAdmin(permissions)) ? AppOwnerInvoiceList : undefined}
                        />,

                        <Resource
                            key="export"
                            name="export"
                            create={ExportCreate}
                        />,
                        <Resource
                            key="exportPdf"
                            name="exportPdf"

                        />,
                        <Resource
                            key="notification_subjects"
                            name="notification_subjects"

                        />,
                        <Resource
                            key="hook_events"
                            name="hook_events"

                        />,

                        <Resource key="fops" name="fops" list={FopList} />,

                        <Resource key="users_invite" name="users_invite" />,
                        <Resource key="ammonia_events" name="ammonia_events" />,
                        <Resource key="aqi_events" name="aqi_events" />,
                        <Resource key="battery_events" name="battery_events" />,
                        <Resource key="battery_status_events" name="battery_status_events" />,
                        <Resource key="benzen_events" name="benzen_events" />,
                        <Resource key="button_events" name="button_events" />,
                        <Resource key="co2_events" name="co2_events" />,
                        <Resource key="computed_distance_events" name="computed_distance_events" />,
                        <Resource key="computed_location_events" name="computed_location_events" />,
                        <Resource key="distance_events" name="distance_events" />,
                        <Resource key="formaldehyde_events" name="formaldehyde_events" />,
                        <Resource key="door_events" name="door_events" />,
                        <Resource key="geofence_monitoring_events" name="geofence_monitoring_events" />,
                        <Resource key="high_g_events" name="high_g_events" />,
                        <Resource key="humidity_events" name="humidity_events" />,
                        <Resource key="level_events" name="level_events" />,
                        <Resource key="location_events" name="location_events" />,
                        <Resource key="luminosity_events" name="luminosity_events" />,
                        <Resource key="motion_events" name="motion_events" />,
                        <Resource key="mox_events" name="mox_events" />,
                        <Resource key="noise_events" name="noise_events" />,
                        <Resource key="pm1_events" name="pm1_events" />,
                        <Resource key="pm2p5_events" name="pm2p5_events" />,
                        <Resource key="pm10_events" name="pm10_events" />,
                        <Resource key="presence_events" name="presence_events" />,
                        <Resource key="pressure_events" name="pressure_events" />,
                        <Resource key="rollover_events" name="rollover_events" />,
                        <Resource key="shock_events" name="shock_events" />,
                        <Resource key="temperature_events" name="temperature_events" />,
                        <Resource key="total_odometer_events" name="total_odometer_events" />,
                        <Resource key="tvoc_events" name="tvoc_events" />,
                        <Resource key="water_leak_events" name="water_leak_events" />,
                        <Resource key="notification_log_events" name="notification_log_events" />,
                        <Resource key="configuration_plan" name="configuration_plan" />,
                        <Resource key="configuration_plans" name="configuration_plans" />,
                        <Resource key="account" name="account" edit={AccountEdit} />,
                        <Resource key="device_configurations" name="device_configurations" />,
                        <CustomRoutes>
                            <Route key={0} exact path="/locations/:id/history" element={<LocationHistoryList />} />
                            <Route key={1} exact path="/notification_logs/:id/history" element={<NotificationLogHistoryList />} />
                            <Route key={2} exact path="/devices/add_bulk" element={isSuperAdmin(permissions) ? <DeviceAddBulk /> : <div></div>} />
                            <Route key={3} exact path="/measures/:id/history" element={<SensorHistoryList />} />
                            <Route key={4} exact path="/users/invite" element={<UserInvite />} />
                            <Route key={5} exact path="/devices/add" element={isSuperAdmin(permissions) ? <DeviceAdd /> : <div></div>} />
                            <Route key={6} exact path="/devices/checkout-success" element={<CheckoutSuccess />} />
                            <Route key={7} exact path="/notifications/sms-credit-checkout-success" element={<SmsCreditCheckoutSuccess />} />
                        </CustomRoutes>
                    ]
                    }


                </Admin>

            </BrowserRouter>
        </Provider>
    )

}

//}



export default App;

