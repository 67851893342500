import React from 'react'
import { useTranslate } from 'react-admin'

import { Divider, Typography, Box, TextField, useMediaQuery, useTheme, ButtonBase, Card, CardContent } from '@mui/material'
import style from 'jss-material-ui'
import { getFormattedPrice } from '../../../helper/resourceHelper'
import { Wizard } from '../../../ui/form/WizardForm'
import { Field, useForm, useFormState } from 'react-final-form'

const SDivider = style(Divider)(theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        width: '50%',
        marginTop: 20,
        marginBottom: 20
    },
}))


const PostpaidPlansExists = (plans) => {
    return plans && plans && plans.filter(plan => plan.type.toLowerCase() === 'postpaid').length > 0

}

const PrepaidPlansExists = (plans) => {
    return plans && plans && plans.filter(plan => plan.type.toLowerCase() === 'prepaid').length > 0

}

const VoucherAllowed = (plans) => {
    return plans && plans && plans.filter(plan => plan.operator.toLowerCase() === 'sigfox').length > 0

}

const OnePlanTypeAllowed = (plans) => {

    return (
        (PostpaidPlansExists(plans) && !PrepaidPlansExists(plans) && !VoucherAllowed(plans)) ? 'postpaid' :
            (!PostpaidPlansExists(plans) && PrepaidPlansExists(plans) && !VoucherAllowed(plans)) ? 'prepaid' :
                (!PostpaidPlansExists(plans) && !PrepaidPlansExists(plans) && VoucherAllowed(plans)) ? 'voucher' :
                    null
    )

}



const ButtonBaseAdapter = ({ input: { onChange, value }, label, ...rest }) => {
    const translate = useTranslate();
    const theme = useTheme();
    const isSmall = useMediaQuery(
        theme => theme.breakpoints.down('sm'),
        { noSsr: true }
    );
    const { values } = useFormState();
    const { submit } = useForm();
    if (!values || !values.plans || !values.selectedPlanType) return null

    const selectedBg = ["linear-gradient(0deg,#FFFFFF,#FFFFFF)", `linear-gradient(180deg, rgba(33, 150, 243,0.2) 0%, rgba(255, 255, 255, 0.2) 91.89%)`]


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', margin: "0px 0px 0px 0px", padding: "20px" }}>
            <Typography
                color="text.main"
                sx={{
                    fontFamily: "Roboto",
                    fontSize: isSmall ? "20px" : "25px",
                    fontWeight: 500,
                    lineHeight: "29.3px",
                    textAlign: "center",
                    margin: "0px"
                }}
            >
                {translate('resources.devices.select_offer')}
            </Typography>
            <Divider sx={{
                width: "50px", borderWidth: isSmall ? "2px" : "4px", borderColor: theme.palette.primary.main,
                margin: "8px 0px 20px 0px"
            }} />


            <Box sx={{ display: 'flex', flexDirection: isSmall ? 'column' : 'row', alignItems: 'center', justifyContent: 'center', margin: "0px 0px 20px 0px", width: "100%" }}>
                {values.plansBySelectedType && values.plansBySelectedType.map(function (plan, index) {
                    const translate = useTranslate();
                    const duration = values.selectedPlanType === "postpaid" ? '/ ' + translate('resources.' + plan.frequency) : null
                    const price = getFormattedPrice(plan)
                    const isSelected = value && value.plan_id === plan.plan_id
                    return (
                        <ButtonBase
                            onClick={() => { onChange(plan) }}
                        >
                            <Card sx={{
                                display: 'flex',
                                width: "339.33px",
                                height: "299px",
                                margin: "20px",
                                border: (isSelected) ? 3 : 1,
                                borderRadius: "12px",
                                borderColor: (isSelected) ? 'primary.main' : 'text.secondary',
                                background: {
                                    xs: (isSelected) ? selectedBg : "none",
                                    sm: (isSelected) ? selectedBg : "none",
                                }
                            }}>
                                <CardContent sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    margin: "0px",
                                    padding: "15px"
                                }}>
                                    <Typography
                                        color="text.main"
                                        sx={{
                                            fontFamily: "Roboto",
                                            fontSize: "25px",
                                            fontWeight: 500,
                                            lineHeight: "29.3px",
                                            textAlign: "center",
                                            margin: "15px 5px 5px 5px"


                                        }}
                                    >
                                        {plan.title}
                                    </Typography>
                                    <Divider sx={{
                                        width: "50px", borderWidth: isSmall ? "2px" : "4px", borderColor: theme.palette.primary.main,
                                        margin: "0px 20px 15px 20px"
                                    }} />
                                    <Typography
                                        color="text.secondary"
                                        sx={{
                                            fontFamily: "Roboto",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            lineHeight: "20px",
                                            textAlign: "center",
                                            margin: "0px 0px 15px 0px"
                                        }}
                                    >
                                        {plan.description}
                                    </Typography>

                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'flex-end',
                                        justifyContent: 'center',
                                        margin: "0px",
                                        padding: "0px",
                                        width: "100%"
                                    }}>

                                        <Typography
                                            color={(isSelected) ? 'primary.dark' : 'text.secondary'}
                                            sx={{
                                                fontFamily: "Roboto",
                                                fontSize: "35px",
                                                fontWeight: 700,
                                                lineHeight: "62px",
                                                textAlign: 'end',
                                                verticalAlign: 'text-bottom',
                                                margin: "0px 2px 2px 0px"
                                            }}
                                        >
                                            {price}
                                        </Typography>
                                        {duration && <Typography
                                            color={'text.secondary'}
                                            sx={{
                                                fontFamily: "Roboto",
                                                fontSize: "12px",
                                                fontWeight: 400,
                                                lineHeight: "45px",
                                                textAlign: 'end',
                                                verticalAlign: 'text-bottom',
                                                margin: "0px 0px 2px 0px"
                                            }}
                                        >
                                            {duration}
                                        </Typography>}
                                    </Box>
                                    <Typography
                                        color="text.secondary"
                                        sx={{
                                            fontFamily: "Roboto",
                                            fontSize: "12px",
                                            fontWeight: 400,
                                            lineHeight: "20px",
                                            textAlign: "center",
                                            margin: "0px 0px 15px 0px"
                                        }}
                                    >
                                        {"Inc.TAX"}
                                    </Typography>

                                </CardContent>
                            </Card >

                        </ButtonBase>)
                })
                }

            </Box >

        </Box>

    )
}




const SelectPlan = (props) => {

    const translate = useTranslate();
    const { values } = useFormState();
    const theme = useTheme();

    const getVoucherTitle = voucher => {
        const { code, plan } = voucher
        const type = translate('resources.subscriptions.voucher')
        const title =
            type +
            ' - #' +
            code +
            ' - ' +
            plan.frequency_interval +
            ' ' +
            translate('resources.' + plan.frequency)
        return title
    }

    return (

        <Wizard.Page >


            {values.selectedPlanType === "voucher" &&
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    margin: "0px 0px 0px 0px",
                    padding: "20px"
                }}
                >

                    <Typography
                        color="text.main"
                        sx={{
                            fontFamily: "Roboto",
                            fontSize: "25px",
                            fontWeight: 500,
                            lineHeight: "29.3px",
                            textAlign: "center",
                            margin: "0px"
                        }}
                    >
                        {translate('resources.subscriptions.voucher')}
                    </Typography>

                    <Divider sx={{
                        width: "50px", borderWidth: "4px", borderColor: theme.palette.primary.main,
                        margin: "8px 0px 20px 0px"
                    }}
                    />

                    <Typography
                        color="text.secondary"
                        sx={{
                            fontFamily: "Roboto",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "20px",
                            textAlign: "center",
                            margin: "20px 20px 8px 0px"
                        }}
                    >
                        {translate('resources.subscriptions.enter_voucher')}
                    </Typography>

                    <Field
                        name="voucher.code"
                    >
                        {props => (
                            <div>

                                <TextField
                                    id="filled-basic"
                                    label={translate('resources.subscriptions.voucher_code')}
                                    placeholder={translate('resources.subscriptions.enter_voucher')}
                                    name={props.input.name}
                                    value={props.input.value}
                                    onChange={props.input.onChange}
                                    sx={{
                                        width: '300px',
                                        margin: "0px 20px 20px 0px",
                                        "& .MuiInputBase-root": {
                                            padding: "0px !important",
                                            height: "48px",
                                            borderRadius: "12px !important",
                                            borderWidth: "1px",
                                            background: "none",
                                            color: theme.palette.text.primary
                                        },
                                    }}
                                />


                            </div>
                        )}
                    </Field>

                </Box>

            }

            {values.selectedPlanType !== "voucher" &&

                <Field
                    name="selectedPlan"
                    label="selectedPlan"
                    component={ButtonBaseAdapter}
                />
            }

        </Wizard.Page>
    )
}

export default SelectPlan
