import React, { useEffect, useState } from 'react'
import { Create, SimpleForm, required, useLocaleState, useNotify, useRedirect, useTranslate } from 'react-admin'
import { Typography, useTheme } from '@mui/material';
import styledFormInputs from '../../../ui/inputs/styledFormInputs';
import { useFormContext, useWatch } from 'react-hook-form'
import { getPlans } from '../../../rest/restClient';
import dayjs from 'dayjs';
import { getUrlParams } from '../../../utils/utils'
import NavigateBackBtn from '../../../ui/button/NavigateBackBtn';





const validateRequired = [required('Champs obligatoire')]



const InputDefaultValuesHelper = ({
    deviceId
}) => {
    const { getValues, setValue } = useFormContext();

    useEffect(() => {

        setTimeout(() => {
            if (deviceId) {
                setValue('device_id', deviceId);
            }
            
        }, 1000);
        
    }, [getValues, setValue]);

    return (
        <>
        </>
    );
};

const SubscriptionPlansInputHelper = ({
    plans,
    setPlans,
    setPlansChoices,
}) => {
    const { getValues, setValue } = useFormContext();
    const [locale, setLocale] = useLocaleState();
    const notify = useNotify();
    const translate = useTranslate();
    const selectedDeviceId = useWatch({ name: 'device_id' });
    const selectedPlanId = useWatch({ name: 'plan_id' });

    useEffect(async () => {
        if (selectedDeviceId) {

            getPlans(selectedDeviceId, locale).then(response => {

                if (response &&
                    response.json &&
                    response.json._embedded &&
                    response.json._embedded.device_sub_plans &&
                    Array.isArray(response.json._embedded.device_sub_plans)
                ) {
                    setPlansChoices(response.json._embedded.device_sub_plans.map((p) => ({ id: p.plan_id, name: p.title })))
                    setPlans(response.json._embedded.device_sub_plans)

                } else {
                    notify(translate('resources.plans.not_found'), 'warning')
                    return;
                }

            }).catch(response => {

                switch (response.status) {
                    case 400:
                        notify(translate('resources.plans.not_found'), 'warning')
                        return;
                    case 404:
                        notify(translate('resources.plans.not_found'), 'warning')
                        return;
                    default:
                        notify(
                            `${translate('resources.internal_error')}: (${response.status
                            })`,
                            'warning'
                        )
                        return;
                }
            })


        }
    }, [selectedDeviceId, getValues, setValue]);


    useEffect(async () => {
        const selectedPlan = plans.filter((p) => p.plan_id === selectedPlanId)
        if (selectedPlan && selectedPlan.length > 0 &&
            selectedPlan[0].frequency_interval &&
            selectedPlan[0].type === "prepaid" &&
            selectedPlan[0].frequency === "month"
        ) {
            const today = new Date();
            const endDay = new Date();
            endDay.setMonth(today.getMonth() + selectedPlan[0].frequency_interval)
            setValue("end_date", dayjs(endDay.toISOString()))
        }
    }, [selectedPlanId, getValues, setValue]);

    return (
        <>
        </>
    );
};



const SubscriptionCreate = props => {
    const theme = useTheme();
    const translate = useTranslate();
    const [plans, setPlans] = useState([]);
    const [plansChoices, setPlansChoices] = useState([]);
    const notify = useNotify();
    const redirect = useRedirect();
    const today = new Date();
    today.setHours(0)
    today.setMinutes(0)
    today.setSeconds(0)
    today.setMilliseconds(0)
    const defaultStartDate = dayjs(today.toISOString());
    today.setHours(24)
    today.setMinutes(59)
    today.setSeconds(59)
    today.setMilliseconds(0)
    const defaultEndDate = dayjs(today.toISOString());
    const deviceId = getUrlParams('deviceId')


    const onSuccess = () => {
        notify('resources.subscriptions.subscription_success', { messageArgs: { smart_count: 1 } });
        redirect('/subscriptions');
    }


  

    const inputsDataGeneral = [
        { name: 'device_id', label: translate('resources.subscriptions.fields.device.name'), validate: validateRequired, type: "reference", reference: "devices", optionText: "name", filter: { type: 'unb' } },
        { name: 'plan_id', label: translate('resources.subscriptions.fields.plan.description'), validate: validateRequired, type: "select", choices: plansChoices },
        { name: 'start_date', label: translate('resources.subscriptions.fields.start_date'), validate: validateRequired, type: "dateTime", defaultValue: defaultStartDate },
        { name: 'end_date', label: translate('resources.subscriptions.fields.end_date'), validate: validateRequired, type: "dateTime", defaultValue: defaultEndDate }
    ]
    return (
        <>
        <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "24px", lineHeight: "42px", margin: "0px 0px 16px 0px" }}>
            <NavigateBackBtn /> {translate('resources.subscriptions.create')}
        </Typography>
        <Create
            sx={{
                "& .MuiPaper-root": {
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                },
                marginTop: '20px'
            }}
            resource='subscriptions_create'
            mutationOptions={{ onSuccess }}

        >
            <SimpleForm>
                {styledFormInputs(inputsDataGeneral)}
                <SubscriptionPlansInputHelper
                    plans={plans}
                    setPlans={setPlans}
                    setPlansChoices={setPlansChoices}
                />
                <InputDefaultValuesHelper deviceId={deviceId}/>
            </SimpleForm>
        </Create>
        
        </>)
}
export default SubscriptionCreate