import React, { useState } from 'react'
import { useNotify, useTranslate, } from 'react-admin'
import withStyles from '@mui/styles/withStyles';
import { VGrid } from '../../../ui/Grid'
import {
    Box,
    Divider,
    useTheme,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material'

import style from 'jss-material-ui'
import { Wizard } from '../../../ui/form/WizardForm'
import { Field, useFormState } from 'react-final-form'



const styles = theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            padding: '0 1em 1em 1em',
        },
        [theme.breakpoints.down('lg')]: {
            padding: '0 1em 5em 1em',
        },
    },
    control: {
        padding: theme.spacing(2),
    },
    card: {
        display: 'flex',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 151,
        height: 151,
    },
    gridItem: {
        textAlign: 'center',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    tableDevice: {
        maxWidth: 250,
    },
    tableFop: {
        maxWidth: 650,
    },
    tableSubscription: {
        maxWidth: 400,
    },
    tableCell: {
        borderBottom: 0,
        fontSize: '0.875rem',
        fontWeight: 400,
    },
    offer: {
        border: `1px solid ${theme.palette.primary.main}`,
        padding: 30,
    },
})

const SDivider = style(Divider)(theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        width: '80%',
        marginBottom: 15,
    },
}))





const hasActiveSubscription = device => {
    return (
        device.last_subscription &&
        device.last_subscription.status === 'active' &&
        device.last_subscription.end_date &&
        new Date() < new Date(device.last_subscription.end_date)
    )
}









const AttachDevice = () => {
    const translate = useTranslate();
    const theme = useTheme();

    const { values } = useFormState();
    const { device, voucher, selectedPlanType, selectedPlan } = values;



    return (
        <Wizard.Page >


            {hasActiveSubscription(device) && (
                <Grid container spacing={0}
                >
                    <Grid item xs={12} sx={{ width: '60%', display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: "center", marginBottom: "20px", marginTop: "15px" }}>

                        <Table sx={{ maxWidth: '500px', }}>
                            <TableBody>
                                <TableRow key={0}>
                                    <TableCell sx={{
                                        borderBottom: 0,
                                        paddingY: "8px",
                                        fontSize: '0.875rem',
                                        fontWeight: 400,
                                    }} component="th" scope="row">
                                        <Typography variant="h6" gutterBottom>
                                            {translate('resources.devices.your_device')}
                                        </Typography>
                                    </TableCell>

                                </TableRow>
                                <TableRow key={1}>
                                    <TableCell sx={{
                                        borderBottom: 0,
                                        paddingY: "8px",
                                        fontSize: '0.875rem',
                                        fontWeight: 400,
                                    }} component="th" scope="row">
                                        {translate('resources.devices.fields.name')}
                                    </TableCell>
                                    <TableCell sx={{
                                        borderBottom: 0,
                                        paddingY: "8px",
                                        fontSize: '0.875rem',
                                        fontWeight: 400,
                                    }} component="th" scope="row">
                                        {device.name}
                                    </TableCell>
                                </TableRow>
                                <TableRow key={2}>
                                    <TableCell sx={{
                                        borderBottom: 0,
                                        paddingY: "8px",
                                        fontSize: '0.875rem',
                                        fontWeight: 400,
                                    }} component="th" scope="row">
                                        {translate('resources.devices.identifier')}
                                    </TableCell>
                                    <TableCell sx={{
                                        borderBottom: 0,
                                        paddingY: "8px",
                                        fontSize: '0.875rem',
                                        fontWeight: 400,
                                    }} component="th" scope="row">
                                        {device.serial_number}
                                    </TableCell>
                                </TableRow>




                                <TableRow key={3}>
                                    <TableCell sx={{
                                        borderBottom: 0,
                                        paddingY: "8px",
                                        fontSize: '0.875rem',
                                        fontWeight: 400,
                                    }} component="th" scope="row">
                                        <Typography variant="h6" sx={{ marginTop: "20px" }}>
                                            {translate('resources.subscriptions.subscription_already_attached_to_device')}
                                        </Typography>
                                    </TableCell>

                                </TableRow>

                                <TableRow key={4}>
                                    <TableCell sx={{
                                        borderBottom: 0,
                                        fontSize: '0.875rem',
                                        fontWeight: 400,
                                    }} component="th" scope="row">
                                        {translate('resources.subscriptions.fields.plan.description')}
                                    </TableCell>
                                    <TableCell sx={{
                                        borderBottom: 0,
                                        fontSize: '0.875rem',
                                        fontWeight: 400,
                                    }} component="th" scope="row">
                                        {device.last_subscription.plan ?
                                            device.last_subscription.plan.description : ""
                                        }
                                    </TableCell>
                                </TableRow>
                                <TableRow key={5}>
                                    <TableCell sx={{
                                        borderBottom: 0,
                                        fontSize: '0.875rem',
                                        fontWeight: 400,
                                    }} component="th" scope="row">
                                        {translate('resources.devices.fields.last_subscription.start_date')}
                                    </TableCell>
                                    <TableCell sx={{
                                        borderBottom: 0,
                                        fontSize: '0.875rem',
                                        fontWeight: 400,
                                    }} component="th" scope="row">
                                        {new Date(device.last_subscription.start_date) ? new Date(device.last_subscription.start_date).toLocaleString() : device.last_subscription.start_date}

                                    </TableCell>
                                </TableRow>
                                <TableRow key={6}>
                                    <TableCell sx={{
                                        borderBottom: 0,
                                        fontSize: '0.875rem',
                                        fontWeight: 400,
                                    }} component="th" scope="row">
                                        {translate('resources.devices.fields.last_subscription.end_date')}
                                    </TableCell>
                                    <TableCell sx={{
                                        borderBottom: 0,
                                        fontSize: '0.875rem',
                                        fontWeight: 400,
                                    }} component="th" scope="row">

                                        {new Date(device.last_subscription.end_date) ? new Date(device.last_subscription.end_date).toLocaleString() : device.last_subscription.end_date}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>


                </Grid>
            )}

            {!hasActiveSubscription(device) && (
                <Grid container spacing={0}
                >
                    <Grid item xs={12} sx={{ width: '60%', display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: "center", marginBottom: "20px", marginTop: "15px" }}>

                        <Table sx={{ maxWidth: '500px', }}>
                            <TableBody>
                                <TableRow key={0}>
                                    <TableCell sx={{
                                        borderBottom: 0,
                                        paddingY: "8px",
                                        fontSize: '0.875rem',
                                        fontWeight: 400,
                                    }} component="th" scope="row">
                                        <Typography variant="h6" gutterBottom>
                                            {translate('resources.devices.your_device')}
                                        </Typography>
                                    </TableCell>

                                </TableRow>
                                <TableRow key={1}>
                                    <TableCell sx={{
                                        borderBottom: 0,
                                        paddingY: "8px",
                                        fontSize: '0.875rem',
                                        fontWeight: 400,
                                    }} component="th" scope="row">
                                        {translate('resources.devices.fields.name')}
                                    </TableCell>
                                    <TableCell sx={{
                                        borderBottom: 0,
                                        paddingY: "8px",
                                        fontSize: '0.875rem',
                                        fontWeight: 400,
                                    }} component="th" scope="row">
                                        {device.name}
                                    </TableCell>
                                </TableRow>
                                <TableRow key={2}>
                                    <TableCell sx={{
                                        borderBottom: 0,
                                        paddingY: "8px",
                                        fontSize: '0.875rem',
                                        fontWeight: 400,
                                    }} component="th" scope="row">
                                        {translate('resources.devices.identifier')}
                                    </TableCell>
                                    <TableCell sx={{
                                        borderBottom: 0,
                                        paddingY: "8px",
                                        fontSize: '0.875rem',
                                        fontWeight: 400,
                                    }} component="th" scope="row">
                                        {device.serial_number}
                                    </TableCell>
                                </TableRow>

                                <TableRow key={3}>
                                    <TableCell sx={{
                                        borderBottom: 0,
                                        paddingY: "8px",
                                        fontSize: '0.875rem',
                                    }} component="th" scope="row">
                                        <Typography variant="h6" gutterBottom sx={{ maxWidth: '250px', marginTop: "15px" }}>
                                            {translate('resources.subscriptions.selected_offer')}
                                        </Typography>
                                    </TableCell>

                                </TableRow>
                                <TableRow key={4}>
                                    <TableCell sx={{
                                        borderBottom: 0,
                                        fontSize: '0.875rem',
                                        fontWeight: 400,
                                    }} component="th" scope="row">
                                        {translate('resources.subscriptions.fields.plan.type')}
                                    </TableCell>
                                    <TableCell sx={{
                                        borderBottom: 0,
                                        fontSize: '0.875rem',
                                        fontWeight: 400,
                                    }} component="th" scope="row">
                                        {selectedPlanType}
                                    </TableCell>
                                </TableRow>
                                <TableRow key={5}>
                                    <TableCell sx={{
                                        borderBottom: 0,
                                        fontSize: '0.875rem',
                                        fontWeight: 400,
                                    }} component="th" scope="row">
                                        {translate('resources.subscriptions.fields.plan.description')}
                                    </TableCell>
                                    <TableCell sx={{
                                        borderBottom: 0,
                                        fontSize: '0.875rem',
                                        fontWeight: 400,
                                    }} component="th" scope="row">
                                        {voucher && voucher.plan && translate('resources.subscriptions.voucher_name', {
                                            quantity: voucher.plan.frequency_interval,
                                        })}
                                        {voucher && voucher.plan && <br />}
                                        {voucher && voucher.plan && translate('resources.subscriptions.voucher_title', {
                                            voucher_code: voucher.code,
                                        })}
                                        {!voucher && selectedPlan && selectedPlan.description}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>



                    </Grid>


                </Grid>
            )}

        </Wizard.Page>
    );
};




export default AttachDevice;



