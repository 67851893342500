import { Box, IconButton, useTheme, Tooltip } from "@mui/material"
import { useRedirect, useTranslate } from "react-admin";

const Icon = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-upload">
            <path stroke="none" d="M0 0h18v18H0z" fill="none" strokeWidth="0.75" />
            <path d="M3 12.75v1.5a1.5 1.5 0 0 0 1.5 1.5h9a1.5 1.5 0 0 0 1.5 -1.5v-1.5" />
            <path d="m5.25 6.75 3.75 -3.75 3.75 3.75" />
            <path d="M9 3v9" />
        </svg>

    )
}


export const ExportDataButton = ({ selectedIds, selectedEventType }) => {
    const theme = useTheme();
    const translate = useTranslate()
    const redirect = useRedirect();
    let path = `/export/create`
    if (selectedIds || selectedEventType) { path = path + "?" }
    if (selectedIds) { path = path + `selectedIds=${selectedIds}&` }
    if (selectedEventType) { path = path + `selectedEventType=${selectedEventType}&` }

    return (
        <Box sx={{
            padding: "0px",
            marginRight: "20px",
        }}>

            <Tooltip enterDelay={300} title={translate('resources.locations.labels.exportData')} >
                <IconButton
                    sx={{
                        padding: "4px",
                    }}
                    onClick={() => {
                        redirect(path);
                    }}
                >
                    <Icon color={theme.palette.primary.dark}/>
                </IconButton>
            </Tooltip>


        </Box>

    )


}