import React, { useEffect } from 'react'
import {
    Edit,
    required,
    SimpleForm,
    useTranslate,
} from 'react-admin'
import 'react-phone-number-input/style.css'
import { Typography, useMediaQuery, useTheme } from '@mui/material'
import styledFormInputs from '../../../ui/inputs/styledFormInputs'
import { useFormContext, useWatch } from 'react-hook-form'
import NavigateBackBtn from '../../../ui/button/NavigateBackBtn'


const validateRequired = [required('Champs obligatoire')]

const ComponentBySourceEqual = ({ source, equals, Component }) => {
    const element = useWatch({ name: source });
    if (element && element === equals) {
        return (
            <Component />
        )
    }
    return null;
}


const ComponentBySourceNotEqual = ({ source, equals, Component }) => {
    const element = useWatch({ name: source });
    if (element && element !== equals) {
        return (
            <Component />
        )
    }
    return null;
}

const InputDefaultValuesHelper = ({
}) => {
    const { getValues, setValue } = useFormContext();

    useEffect(() => {
        setTimeout(() => {
            setValue('auth_type', "Basic");
        }, 1000);

        setTimeout(() => {
            setValue('version', getValues("version"));
        }, 1000);

        
        setTimeout(() => {
            const applyOn = getValues("device_id")?  "oneDevice":"oneOrganization"
            setValue('applyOn',applyOn );
        }, 1000);

    }, [getValues, setValue]);

    return (
        <>
        </>
    );
};


const ResetInputHelper = ({
}) => {
    const { getValues, setValue } = useFormContext();
    const selectedAuthActive = useWatch({ name: 'auth_active' });

    const selectedApplyOn = useWatch({ name: 'applyOn' });

    useEffect(async () => {
        if (selectedAuthActive === false) {
            setValue('auth_username', null);
            setValue('auth_password', null);
        }

    }, [selectedAuthActive, getValues, setValue]);

    useEffect(async () => {
        if (selectedApplyOn === "oneDevice") {
            setValue('organization_id', null);
        }

        if (selectedApplyOn === "oneOrganization") {
            setValue('device_id', null);
        }

    }, [selectedApplyOn, getValues, setValue]);

    return (
        <>
        </>
    );
};

const HookEdit = () => {
    const translate = useTranslate();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));


    
    const inputsDataGeneral = [
        { name: 'active', label: translate('resources.hooks.fields.active'), validate: validateRequired, type: "boolean" },
        { name: 'name', label: translate('resources.hooks.fields.name'), validate: validateRequired, type: "text" },
        { name: 'applyOn', label: translate('resources.hooks.fields.applyOn'), validate: validateRequired, type: "select", defaultValue : "oneDevice" ,choices: [{ id: 'oneDevice', name: translate('resources.hooks.fields.applyOnOneDevice') }, { id: 'oneOrganization', name: translate('resources.hooks.fields.applyOnOneOrganization') }] },
   ]

    const inputsDataGeneral2 = [
        { name: 'events', label: translate('resources.hooks.fields.events'), validate: validateRequired, type: "referenceAutocompleteArrayInput", reference: "hook_events",  optionValue: "event", optionText: (s) => (s && s.event) ? translate(`resources.hooks.${s.event}`) : "n" ,width: isSmall ? "300px" : "480px",height: "250px" },
        { name: 'target_url', label: translate('resources.hooks.fields.target_url'), validate: validateRequired, type: "text",width: isSmall ? "300px" : "480px" },
        { name: 'version', label: translate('resources.hooks.fields.version'), validate: validateRequired, type: "text",disabled:true},
        { name: 'auth_active', label: translate('resources.hooks.fields.auth'), validate: validateRequired, type: "boolean" },
    ]

    const inputsDataAuth = [
        { name: 'auth_type', label: translate('resources.hooks.fields.auth_type'), validate: validateRequired, defaultValue: "Basic", type: "text", disabled:true},
        { name: 'auth_username', label: translate('resources.hooks.fields.auth_username'), validate: validateRequired, type: "text" },
        { name: 'auth_password', label: translate('resources.hooks.fields.auth_password'), validate: validateRequired, type: "password"},
    ]

    return (
        <>
        <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "24px", lineHeight: "42px", margin: "0px 0px 16px 0px" }}>
            <NavigateBackBtn /> {translate('resources.hooks.edit')}
        </Typography>
        <Edit redirect="list" title={translate('resources.hooks.edit')} sx={{ "& .MuiPaper-root": { backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary, }}}>
            <SimpleForm  >

            {styledFormInputs(inputsDataGeneral)}
            <ComponentBySourceEqual
                    source={"applyOn"}
                    equals={"oneDevice"}
                    Component={() => styledFormInputs(
                        [
                            { name: 'device_id', label: translate('resources.hooks.fields.device'), validate: validateRequired, type: "reference", reference: "devices", optionText: "name"}
                        ]
                    )}
                />

            <ComponentBySourceEqual
                    source={"applyOn"}
                    equals={"oneOrganization"}
                    Component={() => styledFormInputs(
                        [
                            { name: 'organization_id', label: translate('resources.hooks.fields.organization'), validate: validateRequired, type: "reference", reference: "organizations", optionText: "name" }
                        ]
                    )}
                />

            {styledFormInputs(inputsDataGeneral2)}
            <ComponentBySourceEqual
                    source={"auth_active"}
                    equals={true}
                    Component={() => styledFormInputs(inputsDataAuth)}
                />
            <InputDefaultValuesHelper/>
            
            <ResetInputHelper />
            </SimpleForm>
        </Edit>
        </>
    )
}

export default HookEdit
