import React from 'react'
import { Link, useRecordContext } from 'react-admin'

export const LinkFullNameField = ({ label }) => {
    const record = useRecordContext()

    if (!record) return null
    return (
        <Link label={label} to={`/users/${record.id}`}>
            <FullNameField record={record} />
        </Link>
    )
}




export const fullName = ({ record = {} }) => {
    const { email = '', first_name = '', last_name = '' } = record
    let value = ``

    if (first_name.length === 0 && last_name.length === 0) {
        value = email
    } else {
        value = `${first_name} ${last_name}`
    }

    return value
}

export const FullNameField = ({ record }) => {
    return (
        <div
            style={{
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center',
            }}
        >
            {fullName({ record })}
        </div>
    )
}
