import { useEffect, useState } from 'react'
import { billingPortalSession } from '../../../rest/restClient'
import { Box, Typography,useTheme } from '@mui/material'
import { useNotify, useTranslate } from 'react-admin';
import LoadingDialog from '../../../ui/dialog/LoadingDialog';
import NavigateBackBtn from '../../../ui/button/NavigateBackBtn';







const LoadingPopUp = ({ isLoading }) => {
    return (
        <LoadingDialog
            isLoading={isLoading}
        />
    )

};

const FopList = () => {
    const notify = useNotify();
    const translate = useTranslate();
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {

        billingPortalSession().then((res) => {
            if (res.json && res.json.session_url) {
                const sessionUrl = res.json.session_url;
                setIsLoading(false);
                window.open(sessionUrl, "_self");
            } else {
                setIsLoading(false);
                notify(translate('resources.fops.error_billing_portal'), 'warning');
            }
        })
    }, [])


    return (
        <>
            <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "24px", lineHeight: "42px", margin: "0px 0px 16px 0px" }}>
                <NavigateBackBtn /> {translate('resources.fops.name', { smart_count: 2 })}
            </Typography>
            <LoadingPopUp isLoading={isLoading} />
        </>

    )
}

export default FopList




