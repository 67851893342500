import { useLogout, useTranslate } from 'react-admin'
import { Tooltip, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';




const LogoutIcon = () => {
    const theme = useTheme();
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" stroke={theme.palette.primary.main} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-logout">
            <rect width="32" height="32" rx="8" fill={theme.palette.mode === 'light' ? theme.palette.primary.light : theme.palette.dark.dark} stroke="none" />
            <path transform="translate(6, 4)" stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path transform="translate(6, 4)" d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
            <path transform="translate(6, 4)" d="M9 12h12l-3 -3" />
            <path transform="translate(6, 4)" d="M18 15l3 -3" />
        </svg>
    )
}


const LogoutButton = ()=> {
    const logout = useLogout();
    const translate = useTranslate()
    const handleClick = () => logout();
    return (



        <Tooltip enterDelay={300} title={translate('ra.auth.logout')} >

            <IconButton
                color="inherit"
                onClick={handleClick}
            >
                <LogoutIcon/>
            </IconButton>


        </Tooltip>

    );
};


export default LogoutButton
