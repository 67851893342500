import React, { useEffect, useState } from 'react'
import {
    Create,
    required,
    SimpleForm,
    useTranslate,
} from 'react-admin'
import 'react-phone-number-input/style.css'
import { useFormContext, useWatch } from 'react-hook-form'
import { Button, TableBody, TableCell, TableRow, Typography, useTheme } from '@mui/material'
import styledFormInputs from '../../../ui/inputs/styledFormInputs'
import StandardDialog from '../../../ui/dialog/StandardDialog'
import NavigateBackBtn from '../../../ui/button/NavigateBackBtn'




const validateRequired = [required('Champs obligatoire')]



const InfoAboutIntegrationJohnDeereDialog = ({ openDialog, setOpenDialog }) => {
    const translate = useTranslate();

    return (
        <StandardDialog
            open={openDialog}
            fullWidth={true}
            onClose={() => { setOpenDialog(false); }}
            BodyComponent={() => {

                return (
                    <TableBody>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell align="left" sx={{ padding: "0px 0px 10px 0px", fontWeight: 500 }}>
                                {translate('resources.integrations.info_about_integration_john_deere.title1')}
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell align="left" sx={{ padding: "0px 0px 5px 0px", fontWeight: 400 }}>
                                {translate('resources.integrations.info_about_integration_john_deere.message1_1')}
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell align="left" sx={{ padding: "0px 0px 5px 0px", fontWeight: 400 }}>
                                {translate('resources.integrations.info_about_integration_john_deere.message1_2')}
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell align="left" sx={{ padding: "15px 0px 5px 0px", fontWeight: 500 }}>
                                {translate('resources.integrations.info_about_integration_john_deere.title2')}
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell align="left" sx={{ padding: "0px 0px 5px 0px", fontWeight: 400 }}>
                                {translate('resources.integrations.info_about_integration_john_deere.message2')}
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

                            <TableCell align="center" sx={{ padding: "0px", fontWeight: 500 }}>
                                <Button onClick={() => { setOpenDialog(false); }} variant='contained' sx={{ margin: "25px 0px  0px 10px", }}>
                                    {translate('resources.common.ok')}
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                )
            }}
        />
    );
};



const IntegrationJohnDeereCreate = () => {
    const translate = useTranslate();
    const theme = useTheme();
    const [openInfoAboutIntegrationJohnDeereDialog, setOpenInfoAboutIntegrationJohnDeereDialog] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setOpenInfoAboutIntegrationJohnDeereDialog(true)
        }, 500);

    }, [])

    const inputsDataGeneral = [
        { name: 'organization_id', label: translate('resources.integrations.fields.organization'), validate: validateRequired, type: "reference", reference: "organizations", optionText: "name" },
        { name: 'johndeere_organization_id', label: translate('resources.integrations.fields.jdOrganization'), validate: validateRequired, type: "text" },
        { name: 'email', label: translate('resources.integrations.fields.email'), validate: validateRequired, type: "text" }
    ]

    return (
        <>
            <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "24px", lineHeight: "42px", margin: "0px 0px 16px 0px" }}>
                <NavigateBackBtn /> {translate('resources.integrations.create_new_jd_integration')}
            </Typography>
            <Create
                title={translate('resources.integrations.create_new_jd_integration')}
                sx={{
                    "& .MuiPaper-root": {
                        backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary,
                    },
                    marginTop: '20px'
                }}
                redirect="list"

            >
                <SimpleForm  >
                    {styledFormInputs(inputsDataGeneral)}
                    <InfoAboutIntegrationJohnDeereDialog openDialog={openInfoAboutIntegrationJohnDeereDialog} setOpenDialog={setOpenInfoAboutIntegrationJohnDeereDialog} />
                </SimpleForm>
            </Create>
        </>

    )
}

export default IntegrationJohnDeereCreate
