// in src/comments.js
import React from 'react';
import { hasWriteAccess } from '../../../helper/resourceHelper'
import { EditButton, ReferenceField, TextField, useTranslate, useListContext, TopToolbar, usePermissions, required } from 'react-admin';
import { Table, useTheme, Grid, TableBody, Typography, CardContent, Card, Avatar, Tooltip, ButtonBase, Box } from '@mui/material'
import { CardDetailRow } from '../../../ui/Common';
import { OrganizationIcon, ProductIcon } from '..';
import { useNavigate } from 'react-router-dom';




const ProductCardContent = ({ product }) => {
    const translate = useTranslate();
    const theme = useTheme();

    return (
        <Box sx={{
            width: "100%",
            padding: "0px 12px 0px 12px",
        }}>
            <Table sx={{
                width: "100%",
            }}>
                <TableBody sx={{
                    margin: "0px",
                }}>

                    {product.identifier && (
                        <CardDetailRow label={translate('resources.products.fields.identifier')} ValueComponent={() => <TextField source="identifier" record={product} />} />
                    )}
                    {product.version && (
                        <CardDetailRow label={translate('resources.products.fields.version')} ValueComponent={() => <TextField source="version" record={product} />} />
                    )}

                </TableBody>
            </Table>
        </Box>
    );
}


const ProductCardHeader = ({ permissions, product }) => {
    const translate = useTranslate();
    const theme = useTheme();

    return (


        <Box sx={{
            padding: "24px 24px 8px 24px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"

        }}>
            <Box sx={{
                margin: "0px 0px 20px 0px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
            }}>
                <Box >
                    {product.image && product.image.src ? (
                        <img
                            style={{ width: "50px", height: "50px", backgroundColor: "transparent", borderRadius: "10px", margin: "0px", padding: "0px" }}
                            alt={product.marketing_name ? product.marketing_name : null}
                            src={product.image.src}
                        />
                    ) :
                        <Avatar
                            size={"45px"}
                            style={{ width: "45px", height: "45px" }}
                        >
                            <ProductIcon />
                        </Avatar>
                    }
                </Box>
                <Box sx={{
                    width: "24px",
                    height: "24px",
                }}>

                </Box>
            </Box>
            <Box sx={{ margin: "0px", }}>
                <Typography sx={{ textAlign: 'start', fontWeight: 700, fontSize: "20px", lineHeight: "28px" }}>{product ? `${product.marketing_name}` : ""}</Typography>
            </Box>
        </Box>

    );
}

const MobileGrid = () => {

    const { data, isLoading } = useListContext();
    const theme = useTheme();
    const permissionsHook = usePermissions();
    const isLoadingPermissions = permissionsHook.isLoading;
    const permissions = permissionsHook.permissions;
    const translate = useTranslate();
    const navigate = useNavigate()


    if (isLoading || (data?.length <= 0)) return null;


    return (
        <Grid
            container
            spacing={0}
            sx={{
                margin: '0px',
                width: "100%",
                backgroundColor: theme.palette.background.default,
            }}
        >
            {data && data.map((product, index) => (
                <Grid item sm={12} md={3} sx={{ width: "100%", padding: "0px 0px 8px 0px" }} >
                    <Card key={index}
                        sx={{
                            height: '100%',
                            display: 'flex',
                            borderRadius: "12px",
                            flexDirection: 'column',
                            margin: '0px',
                            padding: "0px"
                        }}>
                        <CardContent sx={{
                            ...theme.typography.body1,
                            width: "100%",
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: "space-between",
                            padding: "0px"
                        }}>
                            <Tooltip title={translate(`resources.products.browse_product`)} disableInteractive >
                                <ButtonBase
                                    onClick={() => { if (hasWriteAccess(permissions)) { navigate(`/products/${product.product_id}`); } }}
                                    sx={{
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        margin: '0px',
                                        padding: "0px"
                                    }}
                                >
                                    <ProductCardHeader product={product} permissions={permissions} />
                                    <ProductCardContent sx={{
                                        width: "100%",
                                    }} product={product} />

                                </ButtonBase>

                            </Tooltip>
                        </CardContent>
                    </Card>

                </Grid>
            ))
            }
        </Grid >
    )


}




export default MobileGrid