
import { useTheme } from '@mui/material/styles';
const DocumentationIcon = () => {

    const theme = useTheme();
    return (

        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_606_7696)">
                <path d="M17 3H7C5.89543 3 5 3.89543 5 5V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V5C19 3.89543 18.1046 3 17 3Z" stroke={theme.palette.text.primary} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M9 6.99998H15" stroke={theme.palette.text.primary} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M9 11H15" stroke={theme.palette.text.primary} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M9 15H13" stroke={theme.palette.text.primary} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_606_7696">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>


    )
}



export default DocumentationIcon