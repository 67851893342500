import React, { useEffect, useState } from 'react'
import { useController, useWatch } from 'react-hook-form'

import style from 'jss-material-ui'
import { SimpleForm, useTranslate, Toolbar, SaveButton, SelectInput, AutocompleteInput } from 'react-admin'



import {
    CircularProgress,
    FormGroup,
    Grid,
    useMediaQuery,
    MenuItem,
    Paper,
    useTheme,
    TextField,
    Typography,
    Autocomplete,
} from '@mui/material'

import { BingProvider } from 'leaflet-geosearch'

import { MapContainer, TileLayer } from 'react-leaflet'

import 'leaflet/dist/leaflet.css'

import MapDraw from './mapDraw'
import { formatAddress, geoReverse } from '../../utils/geocode'
import { autocompleteInputCommonStyle } from '../../themes/commonStyles'



const provider = new BingProvider({
    params: {
        key: process.env.REACT_APP_BING_API_KEY,
    },
})






const SMap = style(MapContainer)({
    root: {
        margin: 0,
        display: 'flex',
        flex: '1',
        flexDirection: 'row',
        height: 350,
        width: 350,
    },
})

const GeofenceInput = ({
}) => {
    const translate = useTranslate();
    const theme = useTheme()
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
    const [zoom, setZoom] = useState(5);
    const [center, setCenter] = useState({ lat: 46.208333, lng: 2.2 });
    const [place, setPlace] = useState('');
    const [places, setPlaces] = useState([]);
    const [radius, setRadius] = useState(25000);
    const [loading, setLoading] = useState(false);
    const { field: radiusField } = useController({ name: "geofence_radius", defaultValue: 50000 });
    const { field: longitudeField } = useController({ name: "geofence_longitude", defaultValue: 2.2 });
    const { field: latitudeField } = useController({ name: "geofence_latitude", defaultValue: 46.208333 });
    const { field: formattedAddressField } = useController({ name: "geofence_formatted_address", defaultValue: '' });



    // Handle input change and fetch places from API
    const handleInputChange = async (event, newInputValue) => {
        if(event===null)return;
        setPlace(newInputValue);
        if (newInputValue.length > 2) {  // Example: Fetch after 2 characters
            setLoading(true);
            try {
                const response = await provider.search({ query: newInputValue })
                setPlaces(response);  // Assuming the response is an array of places
            } catch (error) {
                console.error("Error fetching places:", error);
            } finally {
                setLoading(false);
            }
        } else {
            setPlaces([]);
        }
    };


    useEffect(() => {
        if (latitudeField.value &&longitudeField.value &&  radiusField.value) {
            changeCenter({ lat: latitudeField.value,
                 lng: longitudeField.value,
                  address: formattedAddressField.value }, radiusField.value)
        }
    }, [])



    useEffect(() => {
        if (center && center.lat) latitudeField.onChange(center.lat)
        if (center && center.lng) longitudeField.onChange(center.lng)
        if (radius) radiusField.onChange(radius)
    }, [center, radius])



    useEffect(() => {
        if (place) formattedAddressField.onChange(place)
    }, [place])


   
    const onPlaceSelected = (event, suggestion) => {
        event.preventDefault()
        if (suggestion && suggestion.y && suggestion.x) {
            changeCenter({ lat: suggestion.y, lng: suggestion.x, address: suggestion.label }, 100)
        }

    };


    const reverseGeocoding = (latitude, longitude) => {
        try {

            geoReverse(latitude, longitude)
            .then(function(response) {
                return response.json();
              }).then(function(data) {
                const formatted_address = formatAddress(data.address)
                setPlace(formatted_address)
                setPlaces([formatted_address])
              });
    
        } catch (ex) {
            
        }
    }




    const changeCenter = ({ lat, lng, address }, radius) => {
        let zoom = 9

        if (radius < 100) {
            zoom = 18
        }
        if (radius < 250) {
            zoom = 16
        } else if (radius < 1000) {
            zoom = 14
        } else if (radius < 1500) {
            zoom = 13
        } else if (radius < 3000) {
            zoom = 12
        } else if (radius <= 7500) {
            zoom = 11
        } else if (radius <= 10000) {
            zoom = 10
        } else if (radius <= 20000) {
            zoom = 9
        } else if (radius <= 50000) {
            zoom = 8
        } else if (radius <= 80000) {
            zoom = 7
        } else if (radius <= 100000) {
            zoom = 6
        } else if (radius >= 100000) {
            zoom = 5
        }


        setCenter({ lat: lat, lng: lng })
        setRadius(radius)
        setPlace(address)
        setZoom(zoom)
    };


    const onEdited = e => {
        const layer = e.layer
        const lat = layer._latlng.lat
        const lng = layer._latlng.lng
        changeCenter({ lat: lat, lng: lng, address: '' }, Math.round(layer._mRadius / 100) * 100)
        reverseGeocoding(lat, lng)
       
    };


    return (
        <Grid container sx={{ width: '100%' }}>

            <Grid item xs={12} sx={{ marginTop: isSmall ? "10px" : "0px" }}>
                <Autocomplete
                    options={places}
                    loading={loading}
                    getOptionLabel={(option) => option.label || ''}  // Customize according to your API response
                    onChange={onPlaceSelected}  // Trigger when an option is selected
                    inputValue={place}
                    onInputChange={handleInputChange}  // Handle typing to trigger API fetch
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Enter Address"
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                    sx={autocompleteInputCommonStyle({ theme, isSmall, width: isSmall ? "350px" : "400px" })}
                />
                
            </Grid>

            <Grid item xs={12} sx={{ marginTop: "10px" }}>
                <SMap key={(center && zoom && radius ? center.lat + center.lng + zoom + radius : 0).toString()} center={center} zoom={zoom} style={{ width: '100%' }}>
                    <TileLayer
                        attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <MapDraw onEdited={onEdited} editing={true} center={center} radius={radius} />
                </SMap>
            </Grid>

        </Grid>
    )
}

export default GeofenceInput
