import { Tooltip, IconButton } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslate,    useDataProvider } from 'react-admin';



const isFired = (device) => {

    if (device &&
        device.last_nack_notification_log_date
    ) {
        const date = new Date(device.last_nack_notification_log_date)
        return date < new Date()
    }
    return false
}


const NotificationsIcon = ({ notifCount }) => {
    const theme = useTheme();
    let bgColor = theme.palette.primary.light;
    let color = theme.palette.primary.main;
    let width = 36;
    if (notifCount > 0 && notifCount < 10) {
        bgColor = theme.palette.error.dark;
        color = '#ffffff'
        width = 50;
    }
    if (notifCount > 9 && notifCount < 100) {
        bgColor = theme.palette.error.dark;
        color = '#ffffff'
        width = 60;
    }
    if (notifCount > 99) {
        bgColor = theme.palette.error.dark;
        color = '#ffffff'
        width = 70;
    }

    return (
        <svg width={width} height="33" viewBox={`0 0 ${width} 33`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.416992" width={width} height="32" rx="8" fill={bgColor} />
            <g clipPath="url(#clip0_237_8007)">
                <path d="M17.9998 24.75C18.9165 24.75 19.6665 24 19.6665 23.0833H16.3331C16.3331 24 17.0748 24.75 17.9998 24.75ZM22.9998 19.75V15.5833C22.9998 13.025 21.6331 10.8833 19.2498 10.3167V9.75C19.2498 9.05833 18.6915 8.5 17.9998 8.5C17.3081 8.5 16.7498 9.05833 16.7498 9.75V10.3167C14.3581 10.8833 12.9998 13.0167 12.9998 15.5833V19.75L11.9248 20.825C11.3998 21.35 11.7665 22.25 12.5081 22.25H23.4831C24.2248 22.25 24.5998 21.35 24.0748 20.825L22.9998 19.75Z" fill={color} />
            </g>

            {notifCount != null && notifCount > 0 && <g>
                <text x="32" y="22" fontFamily="Roboto" fontSize="16px" fontWeight="bold" fill={color}>{`${notifCount > 9 ? "9+" : notifCount}`}</text>

            </g>}
            <defs>
                <clipPath id="clip0_237_8007">
                    <rect width="20" height="20" fill={color} transform="translate(8 6.41699)" />
                </clipPath>
            </defs>
        </svg>



    )
}


const NotificationButton = () => {
    const navigate = useNavigate();
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const [unAckNotifications, setUnAckNotifications] = useState(0);
    useEffect(async() => {
        const { data:devices } = await dataProvider.getMany('number_nack_notification_logs', {  sort:{ field: 'last_nack_notification_log_date', order: 'DESC' } ,pagination:{page:1, perPage:10}});
        if (devices && Array.isArray(devices) && devices.length > 0) {
            const n = devices.filter(device => isFired(device)).length;
            if(n>0){
                setUnAckNotifications(n);
            }
        } 
    }, []);

    if (unAckNotifications < 1) return null;


  

    return (
        <Tooltip title={translate('resources.notification_logs.nacknowledged_notifications')}  >
            <IconButton
                onClick={() => { navigate('/notification_logs'); }}
                color="inherit" sx={{
                    height: "32px",
                    minWidth: "36px",
                    maxWidth: "75px",
                    padding: "0px",
                    margin: "0px 8px 0px 8px"
                }}>

                <NotificationsIcon notifCount={unAckNotifications} />

            </IconButton>
        </Tooltip>
    );
}

export default NotificationButton
