import React from 'react';
import compose from 'recompose/compose'

import { useLogout, useTranslate } from 'react-admin';

import { Button } from '@mui/material';
import { RO_APP_TITLE, VERSION_CHECK_DATE } from '../../pages/configuration/actions';
import { getAppVersion } from '../../rest/restClient';




const RefreshVersionButton = ({
    basePath = '',
    record = {},
    ...rest
}) => {


    const logout = useLogout();

    const checkVersion = () => {
        getAppVersion().then(response => {
            if (response && response.json && response.json._embedded && response.json._embedded.software_versions
                && response.json._embedded.software_versions.length === 1
                && response.json._embedded.software_versions[0].software_name === "connect-app"
            ) {
                const software_version = response.json._embedded.software_versions[0].release_version
                if (software_version != process.env.REACT_APP_VERSION) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        }).then(required => {
            if (required === true) {
                logout();
            } else {
                console.log("[" + new Date().toLocaleString() + "] [App Version Check] " + process.env.REACT_APP_VERSION);
            }
        })
    }

    if (!localStorage.getItem(VERSION_CHECK_DATE)) {
        checkVersion();
    }


    if (localStorage.getItem(VERSION_CHECK_DATE) !== null) {
        const tenMinutesAfterCheckDate = new Date(localStorage.getItem(VERSION_CHECK_DATE)).getTime() + 600000;
        const now = new Date().getTime();
        if (now > tenMinutesAfterCheckDate) {
            localStorage.setItem(VERSION_CHECK_DATE, new Date().toISOString())
            checkVersion();
        }
    }







    return (<Button
        // color="primary"
        onClick={() => { }}
        {...rest}
    >
        {`@${localStorage.getItem(RO_APP_TITLE)} ${process.env.REACT_APP_VERSION}`}

    </Button>


    )

};



const enhance = compose(
);

export default enhance(RefreshVersionButton);