import React from 'react';
import { Link } from 'react-router-dom';
import { useListContext, useRecordContext, useTranslate } from 'react-admin';
import { Tooltip, useTheme } from '@mui/material'
import { listActionsColumnIconCommonStyle } from '../../themes/commonStyles';



const Icon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-eye">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
            <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
        </svg>

    )
}
const ShowButton = ({
    basePath = '',
    disabled,
    ...rest
}) => {
    const translate = useTranslate();
    const { resource } = useListContext();
    const record = useRecordContext();
    const theme = useTheme();
    const isLightMode = theme.palette.mode === 'light';
    const disabledColor = isLightMode ? theme.palette.grey[400] : theme.palette.grey[500];

    return (

        <Tooltip title={disabled ? "" : translate(`ra.action.show`)} disableInteractive >

            <Link
                disabled
                to={disabled ? `/${resource}` : `/${resource}/${record.id}/show`}
                style={listActionsColumnIconCommonStyle({ isLastItem: false })}>
                <Icon color={disabled ? disabledColor : theme.palette.primary.main} />
            </Link>
        </Tooltip>
    );
}



export default ShowButton;