import * as React from 'react';

import { Box, useTheme, Typography } from '@mui/material/';



const InfoCard = (props) => {

    const { title, value, isSmall } = props
    const theme = useTheme()
    return (
        <Box sx={{ background: "none !important", display: "flex", flexDirection: "row" }}>

            <Typography sx={{ marginLeft: "10px", fontSize: isSmall ? "10px" : "14px", lineHeight: "20px", fontWeight: 500 }} align="center">
                {`${title}:`}
            </Typography>

            <Typography sx={{ marginLeft: "2px", fontSize: isSmall ? "10px" : "14px", lineHeight: "20px", fontWeight: 500 }} align="center" >
                {value}
            </Typography>

        </Box>
    );
}


export default InfoCard
