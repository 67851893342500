import React from 'react'
import PropTypes from 'prop-types'
import L from 'leaflet'
import './markerPulse'
import './markerPulse.css'
//react-google-maps
import MarkerClusterGroup from 'react-leaflet-markercluster'
import 'react-leaflet-markercluster/dist/styles.min.css';
//ticatag
import { Marker } from 'react-leaflet'
import TifizMarker from './marker'
import {
    getId,
    getLatitude,
    getLongitude,
    hasLocation,
    isDeviceLocalisable,
} from '../../utils/utils'
import { blueIconUrl } from './icons/bluee'
import { blue12_12_IconUrl, blue24_24_IconUrl } from './icons/blue'
import { useTranslate } from 'react-admin'




const MarkerSelectIcon = new L.Icon.Pulse({
    fillColor: 'blue',
    color: 'blue',
})

const MarkerDefaultIcon = new L.Icon({
    iconUrl: blueIconUrl,
    iconSize: [12, 12],
})

const getIconSize = size => {
    if (size === 'large') {
        return [48, 48]
    } else if (size === 'small') {
        return [12, 12]
    }
    return [24, 24]
}

const getIconUrl = device => {

    if (device.icon_name) {
        return device.icon_name
    } else if (localStorage.getItem(`${device.device_id}_icon_url`)) {

        return localStorage.getItem(`${device.device_id}_icon_url`)
    }
    return null;
}



const markerClusterer = (props) => {

    const { handleSelect, updateFormattedAddress, scrollYContainerRef, scrollXContainerRef, locations, idSelected, disableClusteringAtZoom } = props

    const renderMarker = (location, idSelected, index) => {

        if (location && location.id && location.lat && location.lng) {

            return (
                <TifizMarker
                    icon={
                        location.id === idSelected
                            ? MarkerSelectIcon
                            : MarkerDefaultIcon
                    }
                    key={index}
                    index={index}
                    handleSelect={handleSelect}
                    scrollYContainerRef={scrollYContainerRef}
                    scrollXContainerRef={scrollXContainerRef}
                    location={location}
                    selected={location.id === idSelected}
                    updateFormattedAddress={updateFormattedAddress}

                />
            )
        } else {
            return <div key={index} />
        }
    }



    if (!locations || (locations && locations.length === 0)) {
        return null
    }


    return (
        <MarkerClusterGroup key={disableClusteringAtZoom * 200} disableClusteringAtZoom={disableClusteringAtZoom}>
            {locations.map((location, index) => renderMarker(location, idSelected, index))}
        </MarkerClusterGroup>

    )


}



export default markerClusterer;


