import moment from 'moment'

let lastMonth = 12
let lastDays = 31
let lastHours = 23

export function getTimeToStr(timestamp) {
    const dateMonths = new Date(timestamp).getMonth()
    const dateDays = new Date(timestamp).getDate()
    const dateHours = new Date(timestamp).getHours()
    let res = 0

    if (dateHours < lastHours) {
        if (dateDays < lastDays) {
            if (dateMonths < lastMonth && dateMonths === 1) res = moment(timestamp).format('YYYY')
            else res = moment(timestamp).format('DD MMM')
        } else res = moment(timestamp).format('DD MMM')
    } else {
        if (dateHours === lastHours) {
            if (dateDays !== lastDays) res = moment(timestamp).format('DD MMM')
            else res = `${moment(timestamp).format('HH')}h ${moment(timestamp).format('mm')}mn`
        } else res = `${moment(timestamp).format('HH')}h`
    }

    lastMonth = dateMonths
    lastDays = dateDays
    lastHours = dateHours

    return res
}

export function getDateTimeToFullLocalString(timestamp) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const dateTime = new Date(timestamp)
    return dateTime.toLocaleString({ timeZone: timezone })
}


export function getHoursMinutesFromTimestamp(timestamp) {
    const dateTime = new Date(timestamp)
    const hours = String(dateTime.getHours()).padStart(2, '0');
    const minutes = String(dateTime.getMinutes()).padStart(2, '0');
    return (`${hours}:${minutes}`);
}

export function getDayMonthFromTimestamp(timestamp) {
    const dateTime = new Date(timestamp)
    const day = String(dateTime.getDate()).padStart(2, '0');
    const month = String(dateTime.getMonth() + 1).padStart(2, '0'); // Months are zero based    
    return (`${day}/${month} `);
}




