import React from 'react';
import { useLocaleState, useRecordContext, useRefresh, useTranslate } from 'react-admin';
import { Button, Tooltip, useTheme } from '@mui/material'
import { listActionsColumnIconCommonStyle } from '../../themes/commonStyles';
import { validateAppOwnerInvoice } from '../../rest/restClient';



const Icon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-checkbox">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M9 11l3 3l8 -8" />
            <path d="M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9" />
        </svg>
    )
}
const AppOwnerInvoiceValidateButton = ({
    basePath = '',
    disabled,
    ...rest
}) => {
    const translate = useTranslate();
    const theme = useTheme();
    const record = useRecordContext();
    const [locale, setLocale] = useLocaleState()
    const refresh = useRefresh();
    const isLightMode = theme.palette.mode === 'light';
    const disabledColor = isLightMode ? theme.palette.grey[400] : theme.palette.grey[500];
    if (!record || record.app_owner_invoice_id === undefined || record.status !== "draft") {
        return null
    }
    return (

        <Tooltip title={disabled ? "" : translate(`resources.app_owner_invoices.validate`)} disableInteractive >

            <Button
                onClick={(e) => {
                    e.preventDefault();
                    validateAppOwnerInvoice({ invoiceId: record.app_owner_invoice_id })
                        .then(res => {
                            if (res && res.status && res.status === 202) {
                                refresh()
                            }
                        }
                        )
                        .catch(err => { })

                }}
                style={listActionsColumnIconCommonStyle({ isLastItem: false })}>
                <Icon color={disabled ? disabledColor : theme.palette.primary.main} />
            </Button>
        </Tooltip>
    );
}



export default AppOwnerInvoiceValidateButton;