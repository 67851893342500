import Palette from "./palette";
import { createTheme } from "@mui/material/styles";
import Typography from "./typography";
import customShadows from "./shadows";
import componentStyleOverrides from "./compStyleOverride";
import { ThemeMode } from "../config";

const buildTheme = (mode) => {
  const palette = Palette(mode);
  const borderRadius = "12px";
  const fontFamily = `'Roboto', sans-serif`;
  const typography = Typography(palette, borderRadius, fontFamily);
  const shadows = customShadows(palette.mode, palette);
  const components = componentStyleOverrides(palette, borderRadius, false);
  
  const theme = createTheme({
    palette: palette,
    sidebar: {
        width: '224px',
        closedWidth: '65px',
    },
    typography: typography,
    customShadows: shadows,
    components: components,
  });

  

  return theme;
};

const lightTheme = buildTheme(ThemeMode.LIGHT);
const darkTheme = buildTheme(ThemeMode.DARK);

export const customTheme = { light: lightTheme, dark: darkTheme };
