import { Grid, useTheme, useMediaQuery, Box, Avatar, Card, Tooltip, ButtonBase, CardContent, Typography } from "@mui/material"
import { useTranslate } from 'react-admin'
import DeviceSubscriptionsSummary from "./DeviceSubscriptionsSummary";
import NumberOfDevicesInAccount from "./NumberOfDevicesInAccount";
import SmsCreditSummary from "./SmsCreditSummary";
import LocationsSummary from "./LocationsSummary";
import NumberOfNonAcknowledgeAlerts from "./NumberOfNonAcknowledgeAlerts";
import { useEffect, useState } from "react";
import { getDevicesSummary } from "../../rest/restClient";
import numberOfDevicesByProducts from "./NumberOfDevicesByProducts";
import NumberOfAlerts from "./NumberOfAlerts";
import NumberOfStationaryDevices from "./NumberOfStationaryDevices";
import NumberOfDevicesOutsideOrganizationBoundary from "./NumberOfDevicesOutsideOrganizationBoundary";
import BroadcastMessages from "./BroadcastMessages";







const Dashboard = ({ }) => {

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const translate = useTranslate();
    const [devicesSummary, setDevicesSummary] = useState(null);
    useEffect(async () => {
        getDevicesSummary().then((res) => {
            if (res.status === 200) {
                setDevicesSummary(res.json)
            }
        })
    }, [])

    return (
        <>
            <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "24px", lineHeight: "42px", margin: "0px 0px 16px 0px" }}>
                {translate('resources.dashboard.name')}
            </Typography>
            <Grid
                container
                spacing={isSmall ? 0 : 1}
                sx={{
                    margin: '0px',
                    width: "100%",
                    backgroundColor: theme.palette.background.default,
                }}
            >



                <BroadcastMessages />

                <Grid
                    item
                    sm={12}
                    md={6}
                    sx={{
                        width: "100%",
                        margin: isSmall ? "8px 0px 0px 0px" : '0px',
                    }} >
                    <Grid
                        container
                        spacing={isSmall ? 0 : 1}
                        sx={{
                            margin: '0px',
                            width: "100%",
                            backgroundColor: theme.palette.background.default,
                        }}
                    >
                        <NumberOfDevicesInAccount devicesSummary={devicesSummary} />
                        <NumberOfAlerts />
                        <SmsCreditSummary />
                        <DeviceSubscriptionsSummary />
                        {
                            /*
                            <NumberOfStationaryDevices />
                            <NumberOfDevicesOutsideOrganizationBoundary />
                            */
                        }
                    </Grid>
                </Grid>

                <Grid
                    item
                    sm={12}
                    md={6}
                    sx={{
                        width: "100%",
                        margin: isSmall ? "8px 0px 0px 0px" : '0px',
                    }} >
                    <Grid
                        container
                        spacing={isSmall ? 0 : 1}
                        sx={{
                            margin: '0px',
                            width: "100%",
                            backgroundColor: theme.palette.background.default,
                        }}
                    >

                        <LocationsSummary />
                    </Grid>
                </Grid>









                {numberOfDevicesByProducts(devicesSummary)}

            </Grid >
        </>
    )
}

export default Dashboard;