// not used for the moment

import { create, useUpdate, useCreate } from 'react-admin'
import { addEvents, updateDeviceAddress, updateDeviceIcon, updateDeviceStatus, updateEventAddress } from '../../rest/restClient'
import { UserCreate } from '../../pages/user'
import { USER_ID, USER_ORGANIZATION_ID } from '../../pages/configuration/actions';


export const updateStatus = (id, record) => {
    const [update] = useUpdate();
    return update(
        'locations',
        { id, data: { ...data, record, status: 'active' } },
        {
            onSuccess: (data) => {
                updateDeviceStatus({ id, status: 'active' })
            }
        }
    );
}


export const updateIcon = (id, device, icon) => {
    const [update] = useUpdate();
    return update(
        'locations',
        { id, data: { ...device, icon_name: icon } },
        {
            onSuccess: (data) => {
                updateDeviceIcon({ id: payload.id, device: { ...payload.data } })
            }
        }
    );

}

export const acknowledgeRollover = (device) => {
    const [create] = useCreate();
    return create(
        'events',
        { id, data: { ...data } },
        {
            payload: {
                device_id: device.id,
                user_id: localStorage.getItem(USER_ID),
                organization_id: localStorage.getItem(USER_ORGANIZATION_ID),
                timestamp: (new Date()).toISOString()
            }
        },
        {
            onSuccess: (data) => {
                addEvents(payload)
                device.last_rollover = payload;
                updateDeviceRollover(device.id, { ...device })
            }
        }
    );
}


export const acknowledgeMotion = (device) => {
    const [create] = useCreate();

    return create(
        'events',
        { id, data: { ...data } },
        {
            payload: {
                device_id: device.id,
                user_id: localStorage.getItem(USER_ID),
                organization_id: localStorage.getItem(USER_ORGANIZATION_ID),
                timestamp: (new Date()).toISOString()
            }
        },
        {
            onSuccess: (data) => {
                addEvents(payload)
                device.last_motion = payload;
                updateDeviceMotion(device.id, { ...device })
            }
        }
    );
}




export const updateDeviceRollover = (id, data) => {
    const [update] = useUpdate();
    return update(
        'locations',
        { id, data: { ...data } },
        {
            onSuccess: (data) => {

            }
        }
    );

};

export const updateDeviceMotion = (id, data) => {
    const [update] = useUpdate();
    return update(
        'locations',
        { id, data: { ...data } },
        {
            onSuccess: (data) => {

            }
        }
    );
};

export const updateFormattedAddress = (id, record, data, resource) => {
    const [update] = useUpdate();
    return update(
        'locations',
        { id, data: { ...data } },
        {
            onSuccess: (data) => {
                resource === 'locations' ?
                    updateDeviceAddress({ id: id, formatted_address: data }) :
                    updateEventAddress({ id: id, formatted_address: data })
            }
        }
    );
};



export const createGeofence = record => {
    const create = useCreate();
    return create('geofences', record, 'geofences', null)
}



// Old Code :
/*
import { crudCreate, CRUD_CREATE, CRUD_UPDATE, UPDATE,useUpdate } from 'react-admin'
import { addEvents, updateDeviceAddress, updateDeviceIcon, updateDeviceStatus, updateEventAddress } from '../../rest/restClient'



export const updateStatus = (id, record) => ({
    type: CRUD_UPDATE,
    payload: { id, 
        data: {...record, status: 'active' } 
    },
    meta: { resource: 'locations', 
        optimistic: true, 
        fetch: UPDATE,
        callback: ({ payload, requestPayload }) => { 
            updateDeviceStatus({ id, status: 'active' })
        }
    },
})

export const updateIcon = (id, device, icon) => ({
    type: CRUD_UPDATE,
    payload: { id, 
        data: {...device, icon_name: icon } 
    },
    meta: { resource: 'locations',
        optimistic: true, 
        fetch: UPDATE,
        callback: ({ payload, requestPayload }) => { 
            updateDeviceIcon( {id: payload.id, device: {...payload.data }})
        }
    },
})

export const acknowledgeRollover= (device)=> ({
    type: CRUD_CREATE,
    payload: { 
        device_id: device.id,
        name: 'rollover',
        user_id: localStorage.getItem(USER_ID),
        organization_id: localStorage.getItem(USER_ORGANIZATION_ID),
        status: 'clear',
        timestamp: (new Date()).toISOString()  
    },
    meta: { resource: 'events', 
        callback: ({ payload, requestPayload }) => { 
            addEvents(payload)
            device.last_rollover = payload;
            updateDeviceRollover(device.id, {...device })
        }
    },
})

export const acknowledgeMotion= (device)=> ({
    type: CRUD_CREATE,
    payload: { 
        device_id: device.id,
        name: 'motion',
        user_id: localStorage.getItem(USER_ID),
        organization_id: localStorage.getItem(USER_ORGANIZATION_ID),
        status: 'still',
        timestamp: (new Date()).toISOString()  
    },
    meta: { resource: 'events', 
        callback: ({ payload, requestPayload }) => { 
            addEvents(payload)
            device.last_motion = payload;
            updateDeviceMotion(device.id, {...device })
        }
    },
})




export const updateDeviceRollover = (id, data) => {
    const [update] = useUpdate();
     return update(
        'locations',
        { id, data: { ...data } },
        {
           onSuccess: (data) => {
               
            }
        }
    );

};

export const updateDeviceMotion = (id, data) => ({
    type: CRUD_UPDATE,
    payload: { id, data: { ...data } },
    meta: { 
        resource: 'locations', 
        optimistic: true, 
        fetch: UPDATE,
    },
});

export const updateFormattedAddress = (id, record, data, resource) => ({
    type: CRUD_UPDATE,
    payload: { id, 
        data: {...record, formatted_address: data } 
    },
    meta: { resource: resource, 
        optimistic: true, 
        fetch: UPDATE,
        callback: ({ payload, requestPayload }) => { 
            resource === 'locations' ? 
                updateDeviceAddress({ id:id, formatted_address:data}) : 
                updateEventAddress({ id:id, formatted_address:data})
        } 
    },
}) 

export const createGeofence = record => {
    return crudCreate('geofences', record, 'geofences', null)
}*/