// in src/comments.js
import React from 'react';
import { hasWriteAccess } from '../../../helper/resourceHelper'
import { EditButton, ReferenceField, TextField, useTranslate, useListContext, TopToolbar, usePermissions, required, FunctionField } from 'react-admin';
import { Table,useTheme, Grid, TableBody, Typography, CardContent, Card, Avatar, Tooltip, ButtonBase, Box } from '@mui/material'
import { CardDetailRow } from '../../../ui/Common';
import { DeviceSubPlanIcon } from '..';
import { useNavigate } from 'react-router-dom';
import CustomChip from '../../../ui/CustomChip';




const DeviceSubPlanCardContent = ({ plan }) => {
    const translate = useTranslate();
    const theme = useTheme();

    return (
        <Box sx={{
            width: "100%",
            padding: "0px 12px 0px 12px",
        }}>
            <Table sx={{
                width: "100%",
            }}>
                <TableBody sx={{
                    margin: "0px",
                }}>
                    {plan.created_by && (
                        <CardDetailRow label={translate('resources.device_sub_plans.fields.created_by')} ValueComponent={() => <ReferenceField
                            allowEmpty={true}
                            source="created_by"
                            reference="users"
                            record={plan}
                        >
                            <FunctionField
                                render={record => record.first_name && record.last_name ? record.first_name.toUpperCase() + ' ' + record.last_name.toUpperCase() : record.email}
                                sortable={false}
                            />
                        </ReferenceField>} />
                    )}
                    {plan.amount && (
                        <CardDetailRow label={translate('resources.device_sub_plans.amount')} ValueComponent={() => <TextField source="amount" record={plan} />} />
                    )}
                    {plan.tax_percent && (
                        <CardDetailRow label={translate('resources.device_sub_plans.tax_percent')} ValueComponent={() => <TextField source="tax_percent" record={plan} />} />
                    )}
                    {plan.viewership_rule && (
                        <CardDetailRow label={translate('resources.device_sub_plans.fields.viewership_rule')} ValueComponent={() => <FunctionField
                            label={translate('resources.device_sub_plans.fields.viewership_rule')}
                            render={record => record.viewership_rule ? <CustomChip label={translate(`resources.device_sub_plans.viewership_rule.${record.viewership_rule}`)} value={record.viewership_rule} /> : null}
                            record={plan}
                            sortable={false}
                        />} />
                    )}


                    {plan.root_organization_id && (
                        <CardDetailRow label={translate('resources.device_sub_plans.root_organization_id')} ValueComponent={() => <ReferenceField
                            allowEmpty={true}
                            source="root_organization_id"
                            reference="root_organizations"
                            record={plan}
                        >
                            <TextField source="name" />
                        </ReferenceField>} />
                    )}
                    
                </TableBody>
            </Table>
        </Box>
    );
}


const DeviceSubPlanCardHeader = ({ permissions, plan }) => {
    const translate = useTranslate();
    const theme = useTheme();

    return (


        <Box sx={{
            padding: "24px 24px 8px 24px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"

        }}>
            <Box sx={{
                margin: "0px 0px 20px 0px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
            }}>
                <Box >
                    <Avatar
                        size={"45px"}
                        style={{ width: "45px", height: "45px" }}
                    >
                        <DeviceSubPlanIcon />
                    </Avatar>
                </Box>
                <Box sx={{
                    width: "24px",
                    height: "24px",
                }}>

                </Box>
            </Box>
            <Box sx={{ margin: "0px", }}>
                <Typography sx={{ textAlign: 'start', fontWeight: 700, fontSize: "20px", lineHeight: "28px" }}>{plan ? `${plan.title}` : ""}</Typography>
            </Box>
        </Box>

    );
}

const MobileGrid = () => {

    const { data, isLoading } = useListContext();
    const theme = useTheme();
    const permissionsHook = usePermissions();
    const isLoadingPermissions = permissionsHook.isLoading;
    const permissions = permissionsHook.permissions;
    const translate = useTranslate();
    const navigate = useNavigate()


    if (isLoading || (data?.length <= 0)) return null;


    return (
        <Grid
            container
            spacing={0}
            sx={{
                margin: '0px',
                width: "100%",
                backgroundColor: theme.palette.background.default,
            }}
        >
            {data && data.map((plan, index) => (
                <Grid item sm={12} md={3} sx={{ width: "100%", padding:  "0px 0px 8px 0px" }} >
                    <Card key={index}
                        sx={{
                            height: '100%',
                            display: 'flex',
                            borderRadius: "12px",
                            flexDirection: 'column',
                            margin: '0px',
                            padding: "0px"
                        }}>
                        <CardContent sx={{
                            ...theme.typography.body1,
                            width: "100%",
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: "space-between",
                            padding: "0px"
                        }}>
                            <Tooltip title={translate(`resources.device_sub_plans.browse_device_sub_plan`)} disableInteractive >
                                <ButtonBase
                                    onClick={() => { if (hasWriteAccess(permissions)) { navigate(`/device_sub_plans/${plan.plan_id}`); } }}
                                    sx={{
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        margin: '0px',
                                        padding: "0px"
                                    }}
                                >
                                    <DeviceSubPlanCardHeader plan={plan} permissions={permissions} />
                                    <DeviceSubPlanCardContent sx={{
                                        width: "100%",
                                    }} plan={plan} />

                                </ButtonBase>

                            </Tooltip>
                        </CardContent>
                    </Card>

                </Grid>
            ))
            }
        </Grid >
    )


}




export default MobileGrid