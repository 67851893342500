import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, useTheme } from '@mui/material';
import faq from '../faq';
import NavigateBackBtn from '../../../ui/button/NavigateBackBtn';
import { useTranslate } from 'react-admin';
import MarkdownView from 'react-showdown';

const HelpList = () => {
    const theme = useTheme();
    const translate = useTranslate();
    const faqSections = faq();

    return (
        <>
            <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "24px", lineHeight: "42px", margin: "0px 0px 16px 0px" }}>
                <NavigateBackBtn /> {translate('resources.help.faq')}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: "column"
                }}>

                <Box >

                    {faqSections.map((section, idx) => {
                        return (
                            <Box key={idx}>
                                <Typography sx={{ fontSize: "16px", fontWeight: 500, lineHeight: "25px", margin: idx === 0 ? "10px 0px 10px 0px" : "40px 0px 10px 0px", color: theme.palette.text.primary }}>{section.title}</Typography>
                                {section.content.map((row, index) => {

                                    return (
                                        <Accordion key={index}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id={index}
                                            >
                                                <Typography sx={{ fontSize: "14px", fontWeight: 500, lineHeight: "25px", color: theme.palette.text.primary }}>{row.title}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails
                                                sx={{
                                                    maxWidth: {
                                                        xs: "300px",
                                                        sm: "450px",
                                                        md: "700px",
                                                        lg: "1000px"
                                                    }
                                                }}
                                            >
                                                {
                                                    <MarkdownView
                                                        markdown={row.content}
                                                        options={{ tables: true, emoji: true, simpleLineBreaks: true }}
                                                        style={{}}
                                                    />
                                                }
                                            </AccordionDetails>
                                        </Accordion>
                                    )
                                })}


                            </Box>

                        )
                    })}


                </Box>

            </Box>
        </>
    );
}


export default HelpList