import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import Chip from '@mui/material/Chip'
import { Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'



const AcknowledgeChip = ({ ackTime }) => {
    const theme = useTheme();
    const translate = useTranslate();
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center ',
                alignItems: 'flex-start',
                flexDirection: 'column'
            }}>
            <Chip
                label={translate('resources.notification_logs.acknowledged')}
                size="small"
                sx={{
                    height: "24px",
                    width: "85px",
                    fontSize: "10px",
                    fontWeight: 500,
                    bgcolor: theme.palette.mode === 'light' ? 'background.default' : 'dark.main',
                    color: 'text.dark'
                }}
            />
            {ackTime &&
                <Typography variant="caption" style={{ fontSize: '7px' }}>{new Date(ackTime).toLocaleString()}</Typography>}
        </Box>


    )
}

const NacknowledgeChip = () => {
    const theme = useTheme();
    const translate = useTranslate();
    return (

        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center ',
                alignItems: 'flex-start',
                flexDirection: 'column'
            }}>
            <Chip
                label={translate('resources.notification_logs.nacknowledged')}
                size="small"
                sx={{
                    height: "24px",
                    width: "85px",
                    fontSize: "10px",
                    fontWeight: 500,
                    bgcolor: 'error.light',
                    color: 'error.dark'
                }}
            />
        </Box>




    )
}






export const AckChip = ({ record }) => {
    const translate = useTranslate();
    const [open, setOpen] = useState(false)
    const handleClick = () => {
        setOpen(!open)
    }
    if (record && record.acknowledged === true) {
        return (
            <AcknowledgeChip ackTime={record.ack_time} />
        )
    }


    if (record && record.acknowledged === false) {
        return (
            <NacknowledgeChip />
        )

    }



    return null

}

