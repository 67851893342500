import React, { useEffect } from 'react';
import { useTranslate } from 'react-admin'
import { Grid, useTheme } from '@mui/material';
import { Dialog, DialogContent, Box, Typography } from '@mui/material'
import { useNavigate } from "react-router-dom";




const CheckoutSuccessIcon = ({ bgColor }) => {
    return (<svg width="100px" height="100px" viewBox="0 0 6 6" version="1" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 48 48">
        <path fill="#4CAF50" cx="24" cy="24" r="21" d="M5.625 3A2.625 2.625 0 0 1 3 5.625A2.625 2.625 0 0 1 0.375 3A2.625 2.625 0 0 1 5.625 3z">
            <animate
                attributeType="XML"
                attributeName="fill"
                values="rgba(0, 200, 83, 0.2);rgba(0, 200, 83, 0.2);rgba(0, 200, 83, 0.2);#4CAF50;rgba(0, 200, 83, 0.2);#4CAF50"
                dur="4s"
                repeatCount="indefinite" />
        </path>
        <path fill={bgColor} points="34.6,14.6 21,28.2 15.4,22.6 12.6,25.4 21,33.8 37.4,17.4" d="M4.325 1.825L2.625 3.525L1.925 2.825L1.575 3.175L2.625 4.225L4.675 2.175Z" />
    </svg>
    )
}

const CheckoutSuccess = ({ classes, ...props }) => {
    const navigate = useNavigate();
    const translate = useTranslate();
    const theme = useTheme();
    const contentBg = theme.palette.mode === "light" ? ["linear-gradient(0deg,#FFFFFF,#FFFFFF)", `linear-gradient(180deg, rgba(0, 200, 83, 0.2) 0%, rgba(255, 255, 255, 0.2) 38.3%)`] : ["linear-gradient(0deg, #111936, #111936)", `linear-gradient(180deg, rgba(0, 200, 83, 0.2) 0%, rgba(33, 41, 70, 0.2) 38.3%);`]

    useEffect(() => {
        setTimeout(() => {
            navigate('/devices')
        }, 4000)
    }, []);


    return (
        <Grid container spacing={2}>
            <Dialog
                open={true}
                onClose={() => { }}
                sx={{
                    "& .MuiPaper-root": {
                        width: { xs: "300px", md: "418px", lg: "627px", },
                        maxWidth: { xs: "300px", md: "418px", lg: "627px", },
                        height: { xs: "418px", md: "418px", lg: "332px" },
                        margin: "0px",
                        padding: "0px"
                    }
                }}
            >
                <DialogContent 
                 sx={{
                    width: { xs: "300px", md: "418px", lg: "627px" },
                    height: { xs: "418px", md: "418px", lg: "332px" },
                    margin: "0px",
                    padding: "0px",
                    background: { xs: contentBg, lg: contentBg },
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
                >
                    <Box sx={{
                        width: { xs: "276px", md: "394px", lg: "579px" },
                        height: "60px",
                        margin: { xs: "24px 12px 16px 12px", lg: "24px 24px 16px 24px" },
                        padding: "0px",
                        borderWidth: "10px",
                        borderColor: theme.palette.primary.main,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: "space-between",
                        alignItems: "flex-start"
                    }}>
                        <Typography sx={{
                            fontFamily: "Roboto",
                            fontSize: "20px",
                            fontWeight: 700,
                            lineHeight: "28px",
                            textAlign: "left"

                        }}>{translate('resources.subscriptions.name', { smart_count: 1 })}
                        </Typography>
                        <Typography sx={{
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "24px",
                            textAlign: "left"

                        }}>{translate('resources.subscriptions.subscription_success')}
                        </Typography>

                    </Box>
                    <Box sx={{
                        width: { xs: "276px", md: "394px", lg: "579px" },
                        height: "148px",
                        margin: "0px",
                        padding: "0px",
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: "center",
                        alignItems: "center"
                    }}>

                        <CheckoutSuccessIcon bgColor={theme.palette.background.paper} />

                    </Box>
                    <Box sx={{
                        width: { xs: "276px", md: "394px", lg: "579px" },
                        height: "44px",
                        margin: { xs: "24px 12px 16px 12px", lg: "24px 24px 16px 24px" },
                        padding: "0px",
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: "center"
                    }}>

                        <Typography sx={{
                            fontFamily: "Roboto",
                            fontSize: "12px",
                            fontWeight: 400,
                            lineHeight: "20px",
                            textAlign: "left"

                        }}>{translate('resources.common.home_redirect')}
                        </Typography>




                    </Box>
                </DialogContent>


            </Dialog>
        </Grid>

    )
}

export default CheckoutSuccess

