import React, { useEffect, useState } from 'react';
import compose from 'recompose/compose'
import PropTypes from 'prop-types';
import SubscriptionIcon from '@mui/icons-material/CreditCard';
import { Link } from 'react-router-dom';
import { linkToRecord, useNotify, useRedirect, useRefresh, useTranslate } from 'react-admin';
import { Button, Dialog, DialogContent, Box, Tooltip, useTheme, Typography, Chip, TextField } from '@mui/material'
import { log } from 'loglevel';
import { listActionsColumnIconCommonStyle } from '../../themes/commonStyles';
import { cancelSubscription, getLastSubscription, getSubscriptions } from '../../rest/restClient';
import { isSuperAdmin } from '../../helper/resourceHelper';



const Icon = ({ color }) => {
    return (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_7_569007)">
            <path d="M16.667 3.3335H3.33366C2.40866 3.3335 1.67533 4.07516 1.67533 5.00016L1.66699 15.0002C1.66699 15.9252 2.40866 16.6668 3.33366 16.6668H16.667C17.592 16.6668 18.3337 15.9252 18.3337 15.0002V5.00016C18.3337 4.07516 17.592 3.3335 16.667 3.3335ZM16.667 15.0002H3.33366V10.0002H16.667V15.0002ZM16.667 6.66683H3.33366V5.00016H16.667V6.66683Z" fill={color} />
        </g>
        <defs>
            <clipPath id="clip0_7_569007">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>

    )
}


const CalendarIcon = ({ color }) => {
    return (<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_308_11363)">
            <path d="M12.1663 1.99984V2.49984H12.6663H13.333C13.7902 2.49984 14.1663 2.87598 14.1663 3.33317V13.9998C14.1663 14.457 13.7902 14.8332 13.333 14.8332H2.66634C2.20915 14.8332 1.83301 14.457 1.83301 13.9998V3.33317C1.83301 2.87598 2.20915 2.49984 2.66634 2.49984H3.33301H3.83301V1.99984V1.1665H4.16634V1.99984V2.49984H4.66634H11.333H11.833V1.99984V1.1665H12.1663V1.99984ZM13.833 3.33317V2.83317H13.333H2.66634H2.16634V3.33317V5.33317V5.83317H2.66634H13.333H13.833V5.33317V3.33317ZM2.16634 13.9998V14.4998H2.66634H13.333H13.833V13.9998V6.6665V6.1665H13.333H2.66634H2.16634V6.6665V13.9998Z" fill="#364152" stroke="#364152" />
        </g>
        <defs>
            <clipPath id="clip0_308_11363">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>



    )
}

const SubscriptionDialogDateField = ({ dateText, label }) => {
    const theme = useTheme();
    return (<Box sx={{
        width: { xs: "276px", md: "394px", lg: "579px" },
        height: "66px",
        margin: "0px",
        padding: "0px",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "space-between",
        borderRadius: "12px",
        borderWidth: "1px",
        border: 1,
        borderColor: theme.palette.text.primary

    }}>
        <Typography sx={{
            margin: "14px 16px 0px 16px",
            padding: "0px",
            fontFamily: "Roboto",
            fontSize: "10px",
            fontWeight: 500,
            lineHeight: "16px",
            textAlign: "left"

        }}>{label}</Typography>
        <Typography sx={{
            margin: "0px 16px 14px 16px",
            padding: "0px",
            fontFamily: "Roboto",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "20px",
            textAlign: "left",
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
            alignItems: 'center'


        }}><CalendarIcon />&nbsp;{dateText}</Typography>

    </Box>

    )
}


const isAutomaticallyRenewable = (sub) => {
    return (sub && sub.plan && sub.plan.type && sub.plan.type.toLowerCase() === "postpaid")
}

const SubscriptionDialogChip = ({ bgcolor, color, label }) => {

    const translate = useTranslate();
    if (label === null) return null
    return (<Chip
        size="small"
        variant="outlined"
        sx={{
            height: "22px",
            fontSize: "12px",
            borderWidth: "1px !important",
            borderColor: color,
            fontWeight: 500,
            bgcolor: bgcolor,
            color: color
        }}
        label={label} />
    )
}
const SubscriptionDialog = ({
    open,
    setOpen,
    deviceId,
    latestSubscription
}) => {
    const translate = useTranslate();
    const theme = useTheme();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    //ui elements
    let chipBgColor = "grey.light"
    let chipColor = "grey.dark"
    let chipLabel = null
    let contentBg = theme.palette.mode === "light" ? ["linear-gradient(0deg,#FFFFFF,#FFFFFF)", `linear-gradient(180deg, rgba(54, 65, 82, 0.4) 0%, rgba(255, 255, 255, 0.2) 38.3%)`] : ["linear-gradient(0deg, #111936, #111936)", `linear-gradient(180deg, rgba(54, 65, 82, 0.4) 0%, rgba(33, 41, 70, 0.2) 38.3%);`]
    let description = translate('resources.subscriptions.no_subscription_found')
    let actionButton = {
        label: translate('resources.subscriptions.add_subscription').toUpperCase(),
        onclick: () => { handleAddSubscription(); setOpen(false) },
        color: `#ffffff !important`,
        backgroundColor: `${theme.palette.primary.main} !important`,
    }

    let addSubAsSuperAdminButton = isSuperAdmin() ? {
        label: translate('resources.subscriptions.add_subscription_as_super_admin'),
        tooltipTitle: translate('resources.subscriptions.add_subscription_as_super_admin_tooltip'),
        onclick: () => { redirect(`/subscriptions/create?deviceId=${deviceId}`); setOpen(false) },
        color: `#ffffff !important`,
        backgroundColor: `${theme.palette.primary.main} !important`,
    } : null





    //functions
    const handleCancelSubscription = (id) => {
        cancelSubscription(id).then((res => {
            if (res && res.status === 204) {
                redirect("list", "dashboard")
                redirect("list", "devices")
                notify(translate('resources.subscriptions.subscription_canceled'));
            }
        }))
    }

    const handleAddSubscription = () => {
        redirect(`/devices/create?device_id=${deviceId}`);
    }



    if (!latestSubscription) {
        //no_subscription_found
    } else if (
        latestSubscription.start_date == null ||
        latestSubscription.end_date == null ||
        latestSubscription.status == null ||
        latestSubscription.subscription_id == null ||
        !["active", "canceled", "unpaid", "past"].includes(latestSubscription.status.toLowerCase())
    ) {
        latestSubscription = null
        chipLabel = translate('resources.subscriptions.statuses.unknown')
        description = translate('resources.subscriptions.subscription_recognizing_error')
        actionButton = {
            label: "contacter nous ",
            onclick: () => {
                setOpen(false)
            },
            color: `#ffffff !important`,
            backgroundColor: `${theme.palette.primary.main} !important`,
        }
    } else {

        description = latestSubscription.plan && latestSubscription.plan.title ? latestSubscription.plan.title : ""
        if (latestSubscription.status.toLowerCase() == "active") {
            chipBgColor = "success.light"
            chipColor = "success.dark"
            chipLabel = translate('resources.subscriptions.statuses.active')
            contentBg = theme.palette.mode === "light" ? ["linear-gradient(0deg,#FFFFFF,#FFFFFF)", `linear-gradient(180deg, rgba(0, 200, 83, 0.2) 0%, rgba(255, 255, 255, 0.2) 38.3%)`] : ["linear-gradient(0deg, #111936, #111936)", `linear-gradient(180deg, rgba(0, 200, 83, 0.2) 0%, rgba(33, 41, 70, 0.2) 38.3%);`]
            addSubAsSuperAdminButton = null;
            actionButton = isAutomaticallyRenewable(latestSubscription) ? {
                label: translate('resources.subscriptions.cancel_subscription').toUpperCase(),
                onclick: () => {
                    handleCancelSubscription(latestSubscription.subscription_id);
                    setOpen(false)
                },
                color: `${theme.palette.orange.dark} !important`,
                backgroundColor: `${theme.palette.orange.light} !important`,
            } : null
        }
        if (latestSubscription.status.toLowerCase() == "unpaid") {
            chipBgColor = "warning.light"
            chipColor = "warning.dark"
            chipLabel = translate('resources.subscriptions.statuses.unpaid')
            contentBg = theme.palette.mode === "light" ? ["linear-gradient(0deg,#FFFFFF,#FFFFFF)", `linear-gradient(180deg, rgba(255, 193, 7, 0.2) 0%, rgba(255, 255, 255, 0.2) 38.3%)`] : ["linear-gradient(0deg, #111936, #111936)", `linear-gradient(180deg, rgba(255, 193, 7, 0.2) 0%, rgba(33, 41, 70, 0.2) 38.3%);`]
            description = translate('resources.subscriptions.states.unpaid')
            addSubAsSuperAdminButton = null;
            actionButton = null
        }
        if (latestSubscription.status.toLowerCase() == "past") {
            chipBgColor = "error.light"
            chipColor = "error.dark"
            chipLabel = translate('resources.subscriptions.statuses.past')
            contentBg = theme.palette.mode === "light" ? ["linear-gradient(0deg,#FFFFFF,#FFFFFF)", `linear-gradient(180deg, rgba(216, 67, 21, 0.2) 0%, rgba(255, 255, 255, 0.2) 38.3%)`] : ["linear-gradient(0deg, #111936, #111936)", `linear-gradient(180deg, rgba(216, 67, 21, 0.2) 0%, rgba(33, 41, 70, 0.2) 38.3%);`]
            actionButton = {
                label: translate('resources.subscriptions.add_subscription').toUpperCase(),
                onclick: () => {
                    handleAddSubscription();
                    setOpen(false)
                },
                color: `#ffffff !important`,
                backgroundColor: `${theme.palette.primary.main} !important`,
            }
        }
        if (latestSubscription.status.toLowerCase() == "canceled") {
            chipLabel = translate('resources.subscriptions.statuses.canceled')
            actionButton = {
                label: translate('resources.subscriptions.add_subscription').toUpperCase(),
                onclick: () => {
                    handleAddSubscription();
                    setOpen(false)
                },
                color: `#ffffff !important`,
                backgroundColor: `${theme.palette.primary.main} !important`,
            }
        }
    }




    return (<Dialog
        open={open}
        onClose={() => { }}
        sx={{
            "& .MuiPaper-root": {
                width: { xs: "300px", md: "418px", lg: "627px", },
                maxWidth: { xs: "300px", md: "418px", lg: "627px", },
                height: { xs: "418px", md: "418px", lg: "332px" },
                margin: "0px",
                padding: "0px"
            }
        }}
    >
        <DialogContent
            sx={{
                width: { xs: "300px", md: "418px", lg: "627px" },
                height: { xs: "418px", md: "418px", lg: "332px" },
                margin: "0px",
                padding: "0px",
                background: { xs: contentBg, lg: contentBg },
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >

            <Box sx={{
                width: { xs: "276px", md: "394px", lg: "579px" },
                height: "60px",
                margin: { xs: "24px 12px 16px 12px", lg: "24px 24px 16px 24px" },
                padding: "0px",
                borderWidth: "10px",
                borderColor: theme.palette.primary.main,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: "space-between",
                alignItems: "flex-start"
            }}>
                <Typography sx={{
                    fontFamily: "Roboto",
                    fontSize: "20px",
                    fontWeight: 700,
                    lineHeight: "28px",
                    textAlign: "left"

                }}>{translate('resources.subscriptions.name', { smart_count: 1 })} <SubscriptionDialogChip bgcolor={chipBgColor} label={chipLabel} color={chipColor} />
                </Typography>
                <Typography sx={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    textAlign: "left"

                }}>{description}</Typography>
            </Box>
            <Box sx={{
                width: { xs: "276px", md: "394px", lg: "579px" },
                height: "148px",
                margin: "0px",
                padding: "0px",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: "space-between",
                alignItems: "flex-start"
            }}>
                {latestSubscription && <SubscriptionDialogDateField label={translate(`resources.subscriptions.fields.start_date`)} dateText={new Date(latestSubscription.start_date).toLocaleString()} />}
                {latestSubscription && <SubscriptionDialogDateField label={translate(`resources.subscriptions.fields.end_date`)} dateText={new Date(latestSubscription.end_date).toLocaleString()} />}
            </Box>
            <Box sx={{
                width: { xs: "276px", md: "394px", lg: "579px" },
                height: { xs: "140px", lg: "44px" },
                margin: { xs: "16px 12px 24px 12px", lg: "16px 24px 24px 24px" },
                padding: "0px",
                display: 'flex',
                flexDirection: { xs: "column", lg: "row" },
                justifyContent: 'flex-end',
                alignItems: "center"
            }}>



                {addSubAsSuperAdminButton && <Tooltip title={addSubAsSuperAdminButton.tooltipTitle} >
                    <Button
                        onClick={addSubAsSuperAdminButton.onclick}
                        variant="text"
                        sx={{
                            fontFamily: "Roboto",
                            fontSize: "14px !important",
                            fontWeight: 500,
                            lineHeight: "20px !important",
                            padding: "12px 16px 12px 16px",
                            margin: { xs: "20px 10px 10px 5px", lg: "0px 10px 0px 0px" },
                            color: addSubAsSuperAdminButton.color,
                            backgroundColor: addSubAsSuperAdminButton.backgroundColor,
                        }}
                    >
                        {addSubAsSuperAdminButton.label.length > 15 ? `${addSubAsSuperAdminButton.label.substring(0, 15)}...` : addSubAsSuperAdminButton.label}
                    </Button>
                </Tooltip>}

                {actionButton && <Button
                    onClick={actionButton.onclick}
                    variant="text"
                    sx={{
                        fontFamily: "Roboto",
                        fontSize: "14px !important",
                        fontWeight: 500,
                        lineHeight: "20px !important",
                        padding: "12px 16px 12px 16px",
                        color: actionButton.color,
                        backgroundColor: actionButton.backgroundColor,
                    }}
                >
                    {actionButton.label}
                </Button>}

                <Button
                    onClick={() => setOpen(false)}
                    variant="text"
                    sx={{
                        fontFamily: "Roboto",
                        fontSize: "14px !important",
                        fontWeight: 500,
                        lineHeight: "20px !important",
                        padding: "12px 16px 12px 16px",
                        color: `${theme.palette.text.dark} !important`,
                    }}
                >
                    {translate("ra.action.close").toUpperCase()}
                </Button>

            </Box>
        </DialogContent>


    </Dialog>)
}

const SubscriptionButton = ({
    basePath = '',
    disabled,
    record = {},
    ...rest
}) => {
    const translate = useTranslate();
    const [openDialog, setOpenDialog] = useState(false);
    const [latestSubscription, setLatestSubscription] = useState(null);
    const [deviceSn, setDeviceSn] = useState(null);
    const [deviceId, setDeviceId] = useState(null);
    const theme = useTheme();
    const isLightMode = theme.palette.mode === 'light';
    const disabledColor = isLightMode ? theme.palette.grey[400] : theme.palette.grey[500];


    useEffect(() => {
        if (record && record.mac_address) {
            setDeviceSn(record.mac_address);
        }
        if (record && record.serial_number) {
            setDeviceSn(record.serial_number);
        }
        if (record && record.device_id) {
            setDeviceId(record.device_id);
        }
        if (record) {
            setLatestSubscription(record.last_subscription)
        }

    }, []);

    return (

        <Tooltip title={disabled ? "" : translate(`resources.devices.browse_subscription`)} disableInteractive >
            <Button
                component={Link}
                disabled={disabled}
                onClick={() => setOpenDialog(true)}
                {...rest}
                sx={listActionsColumnIconCommonStyle({ isLastItem: false })}
            >
                <Icon color={disabled ? disabledColor : theme.palette.primary.main} />
            </Button>
            <SubscriptionDialog open={openDialog} setOpen={setOpenDialog} deviceId={deviceId} deviceSn={deviceSn} latestSubscription={latestSubscription} />
        </Tooltip>
    );
}
SubscriptionButton.propTypes = {
    basePath: PropTypes.string,
    className: PropTypes.string,
    classes: PropTypes.object,
    record: PropTypes.object,
};

export default SubscriptionButton