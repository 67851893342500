const DropZoneContentIcon = () => {
    return (
        <svg width="135" height="115" viewBox="0 0 135 115" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M103.526 111.859H25.3955C13.9013 111.859 4.58594 102.544 4.58594 91.0493V70.8944C4.58594 63.9878 10.1831 58.3906 17.0897 58.3906H111.837C118.744 58.3906 124.341 63.9878 124.341 70.8944V91.0493C124.336 102.539 115.021 111.859 103.526 111.859Z" fill="#91D5FF" />
            <path d="M44.6438 35.7223L37.9176 38.7865C37.6674 38.9004 37.3696 38.789 37.2556 38.5389C37.1417 38.2887 37.2531 37.9909 37.5032 37.8769L44.2295 34.8128C44.4796 34.6988 44.7775 34.8102 44.8914 35.0604C45.0054 35.3105 44.8939 35.6084 44.6438 35.7223Z" fill="white" />
            <path d="M49.6927 46.8044L42.9665 49.8685C42.7164 49.9825 42.4185 49.871 42.3045 49.6209C42.1906 49.3708 42.302 49.0729 42.5521 48.9589L49.2784 45.8948C49.5285 45.7808 49.8264 45.8923 49.9403 46.1424C50.0543 46.3925 49.9429 46.6904 49.6927 46.8044Z" fill="white" />
            <path d="M59.6537 55.7823L18.5459 74.509C17.8637 74.8198 17.0607 74.5156 16.752 73.838L1.07904 39.4336C0.768272 38.7515 1.07243 37.9484 1.75005 37.6397L42.8579 18.913C43.5401 18.6022 44.3431 18.9064 44.6518 19.584L60.3248 53.9884C60.6355 54.6705 60.3314 55.4736 59.6537 55.7823ZM2.16441 38.5493C1.98704 38.6301 1.90781 38.8419 1.9886 39.0193L17.6615 73.4236C17.7423 73.601 17.9542 73.6802 18.1315 73.5994L59.2394 54.8727C59.4168 54.7919 59.496 54.5801 59.4152 54.4027L43.7423 19.9984C43.6615 19.821 43.4496 19.7418 43.2723 19.8226L2.16441 38.5493Z" fill="#E6F7FF" />
            <g filter="url(#filter0_d_364_6461)">
                <path d="M59.6537 55.7823L18.5459 74.509C17.8637 74.8198 17.0607 74.5156 16.752 73.838L1.07904 39.4336C0.768272 38.7515 1.07243 37.9484 1.75005 37.6397L42.8579 18.913C43.5401 18.6022 44.3431 18.9064 44.6518 19.584L60.3248 53.9884C60.6355 54.6705 60.3314 55.4736 59.6537 55.7823ZM2.16441 38.5493C1.98704 38.6301 1.90781 38.8419 1.9886 39.0193L17.6615 73.4236C17.7423 73.601 17.9542 73.6802 18.1315 73.5994L59.2394 54.8727C59.4168 54.7919 59.496 54.5801 59.4152 54.4027L43.7423 19.9984C43.6615 19.821 43.4496 19.7418 43.2723 19.8226L2.16441 38.5493Z" fill="#E6F7FF" />
            </g>
            <path d="M42.1935 30.341L10.1403 44.9428C9.89015 45.0568 9.59227 44.9453 9.47832 44.6952C9.36437 44.4451 9.4758 44.1472 9.72593 44.0333L41.7791 29.4314C42.0292 29.3175 42.3271 29.4289 42.4411 29.679C42.555 29.9292 42.4436 30.227 42.1935 30.341Z" fill="#91D5FF" />
            <path d="M47.0928 41.0969L15.0396 55.6987C14.7895 55.8126 14.4916 55.7012 14.3777 55.4511C14.2637 55.2009 14.3752 54.9031 14.6253 54.7891L46.6784 40.1873C46.9285 40.0733 47.2265 40.1848 47.3405 40.4349C47.4565 40.6896 47.3429 40.9829 47.0928 41.0969Z" fill="#91D5FF" />
            <path d="M29.8733 42.4509L12.5915 50.3236C12.3414 50.4375 12.0435 50.3261 11.9295 50.076C11.8156 49.8258 11.927 49.528 12.1771 49.414L29.4589 41.5413C29.709 41.4273 30.0069 41.5388 30.1209 41.7889C30.2348 42.039 30.1234 42.3369 29.8733 42.4509Z" fill="#91D5FF" />
            <path d="M34.9221 53.5348L17.6403 61.4076C17.3902 61.5215 17.0923 61.4101 16.9784 61.16C16.8644 60.9098 16.9757 60.6119 17.2259 60.498L34.5077 52.6253C34.7579 52.5113 35.0557 52.6227 35.1696 52.8729C35.2835 53.123 35.1722 53.4209 34.9221 53.5348Z" fill="#91D5FF" />
            <path d="M31.6353 61.2301L19.9792 66.5401C19.7291 66.654 19.4312 66.5426 19.3172 66.2925C19.2033 66.0423 19.3147 65.7445 19.5648 65.6305L31.2209 60.3206C31.4711 60.2066 31.7689 60.318 31.8829 60.5682C31.9968 60.8183 31.8854 61.1162 31.6353 61.2301Z" fill="#91D5FF" />
            <g opacity="0.81">
                <path d="M83.042 43.8351L90.3802 44.7191C90.6531 44.752 90.8495 45.0021 90.8166 45.275C90.7837 45.5479 90.5336 45.7443 90.2607 45.7114L82.9224 44.8274C82.6495 44.7945 82.4532 44.5443 82.486 44.2714C82.5189 43.9986 82.7691 43.8022 83.042 43.8351Z" fill="white" />
                <path d="M84.499 31.7433L91.8371 32.6273C92.11 32.6602 92.3064 32.9103 92.2736 33.1832C92.2408 33.4561 91.9905 33.6525 91.7176 33.6196L84.3795 32.7356C84.1066 32.7027 83.9102 32.4525 83.9431 32.1796C83.9759 31.9068 84.2261 31.7104 84.499 31.7433Z" fill="white" />
                <g filter="url(#filter1_d_364_6461)">
                    <path d="M90.9955 13.7045L131.772 33.1417C132.449 33.4643 132.732 34.2749 132.412 34.947L116.144 69.0742C115.822 69.7509 115.011 70.0344 114.339 69.714L73.5625 50.2768C72.8858 49.9542 72.6023 49.1437 72.9227 48.4715L89.1903 14.3443C89.5128 13.6676 90.3234 13.3841 90.9955 13.7045ZM114.769 68.8118C114.945 68.8956 115.158 68.8201 115.242 68.6442L131.51 34.5169C131.593 34.341 131.518 34.1278 131.342 34.044L90.5655 14.6067C90.3895 14.5229 90.1764 14.5984 90.0925 14.7744L73.8249 48.9016C73.7411 49.0775 73.8166 49.2907 73.9925 49.3746L114.769 68.8118Z" fill="#E6F7FF" />
                    <path d="M90.9955 13.7045L131.772 33.1417C132.449 33.4643 132.732 34.2749 132.412 34.947L116.144 69.0742C115.822 69.7509 115.011 70.0344 114.339 69.714L73.5625 50.2768C72.8858 49.9542 72.6023 49.1437 72.9227 48.4715L89.1903 14.3443C89.5128 13.6676 90.3234 13.3841 90.9955 13.7045ZM114.769 68.8118C114.945 68.8956 115.158 68.8201 115.242 68.6442L131.51 34.5169C131.593 34.341 131.518 34.1278 131.342 34.044L90.5655 14.6067C90.3895 14.5229 90.1764 14.5984 90.0925 14.7744L73.8249 48.9016C73.7411 49.0775 73.8166 49.2907 73.9925 49.3746L114.769 68.8118Z" fill="#E6F7FF" />
                </g>
                <path d="M82.7418 43.4356L114.537 58.5914C114.785 58.7097 114.891 59.0095 114.773 59.2576C114.654 59.5057 114.355 59.6119 114.106 59.4936L82.3118 44.3378C82.0637 44.2195 81.9574 43.9198 82.0757 43.6717C82.194 43.4235 82.4937 43.3173 82.7418 43.4356Z" fill="#91D5FF" />
                <path d="M87.8268 32.7679L119.622 47.9237C119.87 48.042 119.976 48.3418 119.858 48.5899C119.739 48.838 119.44 48.9442 119.191 48.826L87.3967 33.6701C87.1486 33.5519 87.0424 33.2521 87.1606 33.004C87.2811 32.7514 87.5787 32.6496 87.8268 32.7679Z" fill="#91D5FF" />
                <path d="M99.9382 45.084L117.081 53.2555C117.329 53.3737 117.435 53.6735 117.317 53.9216C117.198 54.1697 116.899 54.276 116.65 54.1577L99.5081 45.9863C99.26 45.868 99.1537 45.5682 99.272 45.3201C99.3903 45.072 99.69 44.9658 99.9382 45.084Z" fill="#91D5FF" />
                <path d="M105.178 34.0899L122.321 42.2613C122.569 42.3796 122.675 42.6794 122.557 42.9275C122.439 43.1756 122.139 43.2818 121.891 43.1636L104.748 34.9921C104.5 34.8739 104.394 34.5741 104.512 34.326C104.63 34.0779 104.93 33.9716 105.178 34.0899Z" fill="#91D5FF" />
                <path d="M113.186 31.6563L124.748 37.1677C124.997 37.2859 125.103 37.5857 124.985 37.8338C124.866 38.082 124.567 38.1882 124.318 38.0699L112.756 32.5585C112.508 32.4402 112.402 32.1405 112.52 31.8924C112.638 31.6442 112.938 31.538 113.186 31.6563Z" fill="#91D5FF" />
            </g>
            <path opacity="0.36" d="M51.7562 62.7143L97.3102 75.8906C98.4237 76.2112 99.5884 75.5723 99.909 74.4588L113.085 28.9047C113.406 27.7912 112.767 26.6265 111.653 26.3059L66.0995 13.1296C64.986 12.809 63.8213 13.4479 63.5007 14.5614L50.3243 60.1155C49.9976 61.2254 50.6414 62.3889 51.7562 62.7143Z" fill="#9CD1FA" />
            <path d="M56.0346 61.221L99.5186 73.8025C101.204 74.2923 102.968 73.3191 103.453 71.6349L116.034 28.1509C116.524 26.4653 115.551 24.7015 113.867 24.2166L70.3828 11.6351C68.6972 11.1453 66.9334 12.1185 66.4484 13.8027L53.867 57.2867C53.3772 58.9723 54.3503 60.7361 56.0346 61.221Z" fill="white" />
            <path d="M58.459 57.3528L99.6004 69.2541L111.502 28.1127L70.3604 16.2114L58.459 57.3528Z" fill="#91D5FF" />
            <path opacity="0.47" d="M58.3391 57.5408L57.8633 57.4031L62.5843 41.0906L62.6469 40.8776L70.0719 37.3472C72.6252 36.1341 75.7184 37.0011 77.2646 39.3681L84.5454 50.5155C84.824 50.9432 85.3525 51.1239 85.8351 50.9549L93.9686 48.09C95.4862 47.5541 97.1346 48.2554 97.8055 49.7166L102.654 60.2668L99.9587 69.5816L58.3391 57.5408ZM96.902 50.1314C96.4485 49.1399 95.3243 48.6619 94.2941 49.0274L86.1606 51.8923C85.2486 52.2163 84.2426 51.8724 83.7128 51.0614L76.432 39.914C75.1557 37.9616 72.6027 37.2412 70.495 38.2433L63.4734 41.5791L59.0905 56.7269L99.2826 68.3544L101.599 60.3442L96.902 50.1314Z" fill="white" />
            <path opacity="0.47" d="M83.5396 38.1948C80.7467 37.388 79.1346 34.4595 79.9414 31.6665C80.7482 28.8736 83.6767 27.2615 86.4697 28.0683C89.2627 28.8751 90.8748 31.8036 90.0679 34.5966C89.4196 36.8358 87.4082 38.3191 85.1958 38.3977C84.6543 38.4221 84.0946 38.3582 83.5396 38.1948ZM80.8931 31.9421C80.2367 34.2093 81.5479 36.5867 83.8152 37.2431C86.0824 37.8995 88.4598 36.5883 89.1162 34.321C89.7727 32.0538 88.4614 29.6764 86.1942 29.02C85.7463 28.8903 85.2922 28.8347 84.8476 28.8541C83.0569 28.9245 81.4211 30.1275 80.8931 31.9421Z" fill="white" />
            <path opacity="0.36" d="M76.1126 60.7962L43.4538 65.5188C41.7177 65.7698 40.1043 64.564 39.8532 62.828L35.1307 30.1691C34.8796 28.4331 36.0854 26.8196 37.8215 26.5686L70.4803 21.846C72.2164 21.595 73.8298 22.8008 74.0809 24.5368L78.8034 57.1956C79.0502 58.9374 77.8487 60.5452 76.1126 60.7962Z" fill="#4C65BA" />
            <path d="M73.8481 59.1048L41.1892 63.8274C39.4532 64.0784 37.8397 62.8726 37.5887 61.1365L32.8661 28.4777C32.6151 26.7417 33.8209 25.1282 35.5569 24.8772L68.2157 20.1546C69.9518 19.9036 71.5653 21.1094 71.8163 22.8454L76.5389 55.5042C76.7906 57.2453 75.5841 58.8538 73.8481 59.1048Z" fill="white" />
            <path opacity="0.39" d="M68.4991 23.5054L36.1865 28.1779L40.859 60.4905L73.1716 55.818L68.4991 23.5054Z" fill="#FF7875" />
            <path opacity="0.47" d="M73.7515 56.0226L40.4596 60.8367L39.3789 53.3633L44.1678 45.8691C44.8593 44.7845 46.2446 44.4125 47.3905 45.0042L53.2299 48.0227C53.5249 48.1769 53.8888 48.1092 54.1046 47.8558L60.7689 40.2015C62.2241 38.5318 64.7148 38.1918 66.5672 39.4085L71.8462 42.8817L73.7466 56.0234L73.7515 56.0226ZM41.2916 59.7116L72.6249 55.1807L70.9291 43.4536L66.0187 40.2251C64.5796 39.2819 62.6435 39.5467 61.5128 40.8413L54.8485 48.4955C54.3286 49.0908 53.4738 49.2548 52.7699 48.892L46.9255 45.8743C46.238 45.5193 45.4072 45.7454 44.9963 46.3957L40.4048 53.5785L41.2916 59.7116Z" fill="white" />
            <path opacity="0.47" d="M56.2599 38.8444C54.0045 39.1705 51.8992 37.6016 51.5724 35.3412C51.2455 33.0809 52.8152 30.9805 55.0755 30.6537C57.3309 30.3276 59.4362 31.8965 59.763 34.1568C60.0899 36.4172 58.5153 38.5182 56.2599 38.8444ZM55.2178 31.638C53.5015 31.8861 52.3085 33.4826 52.5566 35.1989C52.8048 36.9152 54.4013 38.1083 56.1176 37.8601C57.8339 37.6119 59.027 36.0154 58.7788 34.2992C58.5306 32.5829 56.9341 31.3898 55.2178 31.638Z" fill="white" />
            <path opacity="0.36" d="M52.3789 69.5091L31.0657 77.4964C29.1032 78.2301 26.92 77.2399 26.1863 75.2774L18.199 53.9642C17.4653 52.0016 18.4555 49.8184 20.418 49.0847L41.7313 41.0975C43.6938 40.3638 45.877 41.354 46.6107 43.3165L54.5979 64.6297C55.3317 66.5922 54.3355 68.7754 52.3789 69.5091Z" fill="#4C65BA" />
            <path d="M50.3926 68.6088L29.0794 76.596C27.1169 77.3297 24.9336 76.3395 24.1999 74.377L16.2127 53.0638C15.479 51.1012 16.4692 48.918 18.4317 48.1843L39.7449 40.1971C41.7074 39.4634 43.8907 40.4536 44.6244 42.4161L52.6116 63.7293C53.3513 65.6918 52.3551 67.875 50.3926 68.6088Z" fill="white" />
            <path d="M41.7718 42.1963L18.2002 51.0294L27.0333 74.601L50.6049 65.768L41.7718 42.1963Z" fill="#FFBB96" />
            <path opacity="0.47" d="M26.6581 75.2004L24.5166 69.4799L26.9205 62.972C27.3023 61.9401 28.3939 61.3734 29.4557 61.6597L34.353 62.972C34.5081 63.0138 34.6692 62.9422 34.7467 62.805L38.5107 55.9451C39.3816 54.3584 41.3143 53.6486 43.0083 54.2988L47.6432 56.0823L51.3416 65.9605L26.6581 75.2004ZM25.7752 69.4739L27.35 73.6793L49.8145 65.2626L46.7127 56.989L42.5729 55.3964C41.4336 54.9549 40.1272 55.4381 39.5367 56.5059L35.7727 63.3657C35.4327 63.9861 34.7169 64.2903 34.0369 64.1113L29.1395 62.799C28.6683 62.6738 28.1911 62.9243 28.0181 63.3776L25.7752 69.4739Z" fill="white" />
            <path opacity="0.47" d="M33.9531 56.1783C32.5751 56.1783 31.2747 55.3372 30.7677 53.9712C30.1116 52.2174 31.0004 50.2549 32.7601 49.5988C34.5138 48.9426 36.4763 49.8314 37.1325 51.5911C37.7886 53.3448 36.8998 55.3074 35.1401 55.9635C34.7524 56.1126 34.3527 56.1783 33.9531 56.1783ZM33.9471 50.5651C33.6906 50.5651 33.4281 50.6128 33.1716 50.7083C32.0263 51.1378 31.4477 52.4143 31.8772 53.5596C32.3067 54.7049 33.5832 55.2835 34.7285 54.86C35.8738 54.4305 36.4524 53.154 36.023 52.0087C35.6949 51.1139 34.8478 50.5651 33.9471 50.5651Z" fill="white" />
            <path opacity="0.36" d="M113.671 73.0324L92.4476 81.2541C90.4933 82.0094 88.2993 81.0433 87.544 79.089L79.3223 57.8651C78.567 55.9108 79.5331 53.7168 81.4874 52.9615L102.711 44.7398C104.665 43.9845 106.859 44.9506 107.615 46.9049L115.836 68.1288C116.592 70.0831 115.62 72.2772 113.671 73.0324Z" fill="#4C65BA" />
            <path d="M111.675 72.1535L90.4515 80.3752C88.4972 81.1305 86.3032 80.1644 85.5479 78.2101L77.3262 56.9862C76.5709 55.0319 77.537 52.8379 79.4913 52.0826L100.715 43.8609C102.669 43.1056 104.863 44.0717 105.619 46.026L113.84 67.2499C114.601 69.2041 113.629 71.3982 111.675 72.1535Z" fill="white" />
            <path d="M102.764 45.8354L79.291 54.9277L88.3834 78.4006L111.856 69.3082L102.764 45.8354Z" fill="#D9F7BE" />
            <path opacity="0.47" d="M88.014 79.0054L85.8096 73.3088L88.1417 66.7749C88.512 65.7387 89.5973 65.1601 90.6622 65.4347L95.5737 66.6929C95.7293 66.733 95.8895 66.6596 95.9655 66.5216L99.6537 59.6207C100.507 58.0245 102.432 57.2933 104.133 57.9248L108.787 59.6572L112.594 69.494L88.014 79.0054ZM87.0681 73.289L88.6892 77.4768L111.06 68.813L107.867 60.5741L103.71 59.0271C102.565 58.5983 101.265 59.0958 100.686 60.17L96.9977 67.0709C96.6645 67.695 95.9521 68.0071 95.2701 67.8357L90.3586 66.5774C89.8861 66.4573 89.4116 66.7131 89.2437 67.1683L87.0681 73.289Z" fill="white" />
            <path opacity="0.47" d="M95.0999 59.9041C93.7221 59.9193 92.4125 59.0926 91.8904 57.7323C91.215 55.9859 92.0821 54.0137 93.8345 53.3382C95.5809 52.6627 97.5531 53.5299 98.2286 55.2822C98.904 57.0286 98.0369 59.0008 96.2845 59.6763C95.8985 59.8297 95.4996 59.8997 95.0999 59.9041ZM95.0321 54.2914C94.7756 54.2942 94.5137 54.3448 94.2583 54.4431C93.1178 54.8852 92.5533 56.168 92.9953 57.3085C93.4374 58.449 94.7203 59.0135 95.8608 58.5774C97.0013 58.1353 97.5658 56.8525 97.1237 55.712C96.7858 54.8209 95.9328 54.2815 95.0321 54.2914Z" fill="white" />
            <path d="M64.4611 108.446C81.9267 108.446 96.0853 94.287 96.0853 76.8215C96.0853 59.3559 81.9267 45.1973 64.4611 45.1973C46.9955 45.1973 32.8369 59.3559 32.8369 76.8215C32.8369 94.287 46.9955 108.446 64.4611 108.446Z" fill="#1890FF" />
            <path d="M105.026 111.728H23.8914C13.2267 111.728 4.58105 103.083 4.58105 92.418V86.0662C4.58105 75.4015 13.2267 66.7559 23.8914 66.7559H105.026C115.691 66.7559 124.336 75.4015 124.336 86.0662V92.418C124.336 103.083 115.691 111.728 105.026 111.728Z" fill="#1890FF" />
            <path d="M86.8193 92.5547C99.1692 80.2048 99.1692 60.1817 86.8193 47.8318C74.4694 35.4819 54.4463 35.4819 42.0964 47.8318C29.7465 60.1817 29.7465 80.2048 42.0964 92.5547C54.4463 104.905 74.4694 104.905 86.8193 92.5547Z" fill="#1890FF" />
            <path d="M57.0643 74.901C56.6895 74.901 56.3096 74.7561 56.0248 74.4662C55.4601 73.8915 55.4701 72.972 56.0448 72.4072L64.5305 64.0664L72.8814 72.4172C73.4511 72.9869 73.4511 73.9065 72.8814 74.4762C72.3116 75.0459 71.3921 75.0459 70.8224 74.4762L64.5105 68.1644L58.0788 74.4862C57.7989 74.7611 57.4341 74.901 57.0643 74.901Z" fill="white" />
            <path d="M64.4601 86.4704C63.6555 86.4704 63.0059 85.8208 63.0059 85.0162V66.1105C63.0059 65.3059 63.6555 64.6562 64.4601 64.6562C65.2647 64.6562 65.9144 65.3059 65.9144 66.1105V85.0162C65.9144 85.8208 65.2647 86.4704 64.4601 86.4704Z" fill="white" />
            <path d="M78.2091 95.4517H50.488C48.499 95.4517 46.8848 93.8325 46.8848 91.8485V81.7785C46.8848 80.9739 47.5344 80.3242 48.339 80.3242C49.1436 80.3242 49.7933 80.9739 49.7933 81.7785V91.8485C49.7933 92.2333 50.1032 92.5431 50.488 92.5431H78.2091C78.7138 92.5431 79.1186 92.1333 79.1186 91.6336V81.7785C79.1186 80.9739 79.7683 80.3242 80.5729 80.3242C81.3775 80.3242 82.0272 80.9739 82.0272 81.7785V91.6336C82.0322 93.7375 80.318 95.4517 78.2091 95.4517Z" fill="white" />
            <defs>
                <filter id="filter0_d_364_6461" x="0.957031" y="18.791" width="59.4902" height="56.8398" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.0941176 0 0 0 0 0.564706 0 0 0 0 1 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_364_6461" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_364_6461" result="shape" />
                </filter>
                <filter id="filter1_d_364_6461" x="72.791" y="13.5728" width="60.7529" height="57.2729" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="1" dy="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.0941176 0 0 0 0 0.564706 0 0 0 0 1 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_364_6461" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_364_6461" result="shape" />
                </filter>
            </defs>
        </svg>

    )
}


export default DropZoneContentIcon;