import { Box, Button, Typography, useTheme } from '@mui/material';
import { CreateButton, useTranslate, useListContext, usePermissions } from 'react-admin';
import { AddBtnWithOptionalMenu } from '../../../ui/button/AddBtn';
import { hasWriteAccess, isSuperAdmin } from '../../../helper/resourceHelper';




const AddIcon = () => {


    return (<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_28_41490)">
            <path d="M38 46C38 28.3269 52.3269 14 70 14C87.6731 14 102 28.3269 102 46C102 63.6731 87.6731 78 70 78C52.3269 78 38 63.6731 38 46Z" fill="#2196F3" />
            <g clipPath="url(#clip0_28_41490)">
                <path d="M81.6673 47.6666H71.6673V57.6666H68.334V47.6666H58.334V44.3333H68.334V34.3333H71.6673V44.3333H81.6673V47.6666Z" fill="white" />
            </g>
        </g>
        <defs>
            <filter id="filter0_d_28_41490" x="0" y="0" width="140" height="140" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="24" />
                <feGaussianBlur stdDeviation="19" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.0340278 0 0 0 0 0.0582019 0 0 0 0 0.145833 0 0 0 0.07 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_28_41490" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_28_41490" result="shape" />
            </filter>
            <clipPath id="clip0_28_41490">
                <rect width="40" height="40" fill="white" transform="translate(50 26)" />
            </clipPath>
        </defs>
    </svg>
    )
}
const Empty = () => {
    const theme = useTheme();
    const { resource } = useListContext();
    const translate = useTranslate();
    const { isLoading, permissions } = usePermissions();
    const addBtnMenuItems = []
    if (isSuperAdmin()) {
        addBtnMenuItems.push({
            label: translate('resources.devices.add_new_device'),
            path: `/${resource}/add`
        })
    }

    if (hasWriteAccess(permissions)) {
        addBtnMenuItems.push({
            label: translate('resources.devices.activate_new_device'),
            path: `/${resource}/create`
        })
    }
    return (<Box
        sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: "100px 0px 100px 0px"

        }}>
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '256px',
            width: '263px',
            padding: "60px 40px 60px 40px",
            backgroundColor: theme.palette.background.paper,
            borderRadius: "12px",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",

        }}>
            <AddBtnWithOptionalMenu menuItems={addBtnMenuItems} width="64" height="64" />
            <Typography sx={{
                margin: "24px 0px 0px 0px",
                fontWeight: 700,
                fontSize: "20px",
                lineHeight: "28px",
                color: theme.palette.text.primary
            }}>
                Add device
            </Typography>
            <Typography sx={{
                margin: "0px 0px 0px 0px",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px",
                color: theme.palette.text.primary
            }}>
                Lorem ipsum dolor sit amet...
            </Typography>
        </Box>

    </Box>)
}


export default Empty;