

const ExportIcon = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-upload">
            <path stroke="none" d="M0 0h18v18H0z" fill="none" strokeWidth="0.75" />
            <path d="M3 12.75v1.5a1.5 1.5 0 0 0 1.5 1.5h9a1.5 1.5 0 0 0 1.5 -1.5v-1.5" />
            <path d="m5.25 6.75 3.75 -3.75 3.75 3.75" />
            <path d="M9 3v9" />
        </svg>

    )
}



export default ExportIcon