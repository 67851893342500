import React, { useState } from 'react';
import compose from 'recompose/compose'
import PropTypes from 'prop-types';
import TrackingModeIcon from '@mui/icons-material/NearMe';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReportIcon from '@mui/icons-material/Report';
import { useTranslate, useRedirect, useRefresh } from 'react-admin';
import { Button, Tooltip, useTheme } from '@mui/material'
import { Switch, Dialog, DialogContent, DialogContentText, DialogTitle, Typography, CircularProgress } from '@mui/material';
import { updateDeviceConfiguation, updateDeviceConfiguationLocal } from '../../rest/restClient';
import { RepeatOneSharp } from '@mui/icons-material';
import { red, orange, green, grey } from '@mui/material/colors';
import { isDgmatter } from '../../helper/resourceHelper';
import { listActionsColumnIconCommonStyle } from '../../themes/commonStyles';



const Icon = ({ color }) => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-live-photo"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /><path d="M12 12m-5 0a5 5 0 1 0 10 0a5 5 0 1 0 -10 0" /><path d="M15.9 20.11l0 .01" /><path d="M19.04 17.61l0 .01" /><path d="M20.77 14l0 .01" /><path d="M20.77 10l0 .01" /><path d="M19.04 6.39l0 .01" /><path d="M15.9 3.89l0 .01" /><path d="M12 3l0 .01" /><path d="M8.1 3.89l0 .01" /><path d="M4.96 6.39l0 .01" /><path d="M3.23 10l0 .01" /><path d="M3.23 14l0 .01" /><path d="M4.96 17.61l0 .01" /><path d="M8.1 20.11l0 .01" /><path d="M12 21l0 .01" /></svg>

    )
}

const TrackingModeButton = ({
    basePath = '',
    record = {},
    ...rest
}) => {
    const translate = useTranslate();
    const theme = useTheme();
    const isLightMode = theme.palette.mode === 'light';
    const disabledColor = isLightMode ? theme.palette.grey[400] : theme.palette.grey[500];
    if (record.type !== 'unb' || record.configuration === undefined || !isDgmatter(record)) {
        return (
            <Tooltip title={translate('resources.devices.labels.tracking_mode')} disableInteractive>
                <Button
                    disabled={true}
                    sx={listActionsColumnIconCommonStyle({ isLastItem: false })}
                    {...rest}
                >
                    <Icon color={disabledColor} />
                </Button>


            </Tooltip>

        )
    }
    const [trackingModeStatus, setTrackingModeStatus] = useState(record.configuration.recovery_mode_status.toLowerCase());
    const [openDialog, setopenDialog] = useState(false);
    const [dialogMode, setDialogMode] = useState();
    const [dialogMessage, setDialogMessage] = useState();
    const redirectTo = useRedirect();
    const refresh = useRefresh();
    const lastRecoveryMode = record.configuration.last_recovery_mode_request ? new Date(record.configuration.last_recovery_mode_request) : null
    const durationFomLastRecoveryModeHr = lastRecoveryMode ? Math.ceil(Math.abs(new Date() - lastRecoveryMode) / (1000 * 60 * 60)) : null;


    const DialogPopUp = (props) => {
        const { record } = props;
        const translate = useTranslate();
        return (<Dialog
            open={openDialog}
            onClose={() => { setopenDialog(false); return null }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"

        >
            <DialogTitle id="alert-dialog-title" sx={{ display: "flex", flexDirection: 'row', minWidth: 500 }}>
                <TrackingModeIcon color="primary" fontSize="small" /><Typography variant="h6" color="primary">&nbsp;{translate(`resources.devices.labels.tracking_mode`)}</Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" sx={{ display: "flex", justifyContent: 'center' }}>
                    {dialogMode === "success" ? <CheckCircleIcon color="success" fontSize="large" /> :
                        dialogMode === "error" ? <ReportIcon sx={{ color: red[500] }} fontSize="large" /> :
                            dialogMode === "already_on_tracking_mode" ? null :
                                dialogMode === "timeout" ? null :
                                    dialogMode === "tracking_mode_pending" ? null :
                                        dialogMode === "loading" ? <CircularProgress /> :
                                            null
                    }
                    {dialogMode === "success" ? translate(`resources.devices.success_setting_on_tracking_mode`) :
                        dialogMode === "error" ? translate(`resources.devices.error_setting_on_tracking_mode`) :
                            dialogMode === "already_on_tracking_mode" ? translate(`resources.devices.already_on_tracking_mode`) :
                                dialogMode === "tracking_mode_pending" ? translate(`resources.devices.tracking_mode_pending`) :
                                    dialogMode === "loading" ? translate(`resources.devices.setting_on_tracking_mode`) :
                                        dialogMode === "timeout" ? translate(`resources.devices.setting_on_tracking_mode_timeout`) :
                                            null
                    }

                </DialogContentText>
                <Typography variant="body1" sx={{ color: red[500] }} mt={2} >&nbsp;{dialogMessage}</Typography>

            </DialogContent>


        </Dialog>)
    }



    const handleClick = (e) => {

        if (trackingModeStatus === "active") {
            setDialogMode("already_on_tracking_mode")
            setopenDialog(true);
            setTimeout(() => {
                setopenDialog(false);
            }, 5000)
        } else if (trackingModeStatus === "pending" && durationFomLastRecoveryModeHr && durationFomLastRecoveryModeHr < 24) {
            setDialogMode("tracking_mode_pending")
            setopenDialog(true);
            setTimeout(() => {
                setopenDialog(false);
                refresh()
            }, 5000)
        } else {
            setDialogMode("loading")
            setopenDialog(true);

            updateDeviceConfiguation({ device_configuration: { id: record.configuration.device_configuration_id, recovery_mode_request: (new Date()).toISOString() } })
                .then((response) => {

                    if (response.status === 204 || response.status === 200) {
                        setDialogMode("success")
                        setTimeout(() => {
                            setopenDialog(false);
                            setDialogMode("")
                            refresh()
                            //redirectTo("list", "/devices");
                        }, 3000)
                    } else {
                        setDialogMode("error")
                        setTimeout(() => {
                            setopenDialog(false);
                            setDialogMode("")
                        }, 7000)
                    }
                })
                .catch((e) => {
                    setDialogMode("error")
                    setDialogMessage("  Error details : " + JSON.stringify(e))
                    setTimeout(() => {
                        setopenDialog(false);
                        setDialogMode("")
                        setDialogMessage("")
                    }, 7000)
                })



            setTimeout(() => {
                setDialogMode("timeout")
                setTimeout(() => {
                    setopenDialog(false);
                    setDialogMode("")
                }, 3000)

            }, 15000)
        }



    }
    return (<Tooltip title={translate(`resources.devices.last_tracking_mode_entry`) + ": " + record.configuration.last_recovery_mode_entry} disableInteractive>

        <Button
            color="primary"
            onClick={handleClick}
            {...rest}
        >
            <Typography variant='inherit'
                sx={{
                    color: trackingModeStatus === "pending" ? orange[800] :
                        trackingModeStatus === "active" ? green[800] :
                            trackingModeStatus === "inactive" ? grey[700] :
                                null
                }} >
                {trackingModeStatus.toUpperCase()}&nbsp;
            </Typography>

            <TrackingModeIcon

                sx={{
                    color: trackingModeStatus === "pending" ? orange[800] :
                        trackingModeStatus === "active" ? green[800] :
                            trackingModeStatus === "inactive" ? grey[700] :
                                null
                }}

                fontSize="small" />
            <DialogPopUp />
        </Button>


    </Tooltip>)

};

/*
<Switch checked={trackingMode}
                onChange={handleClick} /> 
                
                */
TrackingModeButton.propTypes = {
    basePath: PropTypes.string,
    className: PropTypes.string,
    classes: PropTypes.object,
    record: PropTypes.object,
};

const enhance = compose(
);

export default enhance(TrackingModeButton);