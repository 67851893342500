
import React, { Fragment, useEffect, useState } from 'react'
import {
    AutocompleteInput,
    BooleanInput,
    Create,
    FormDataConsumer,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar,
    useDataProvider,
    useGetMany,
    useLocaleState,
    useTranslate,
} from 'react-admin'
import withStyles from '@mui/styles/withStyles';
import Autocomplete from '@mui/material/Autocomplete';
import { Typography, useMediaQuery, useTheme } from '@mui/material'
import { useWatch, useFormContext } from 'react-hook-form'
import styledFormInputs from '../../../ui/inputs/styledFormInputs';
import { autocompleteInputCommonStyle } from '../../../themes/commonStyles';
import { useNavigate } from 'react-router-dom';
import NavigateBackBtn from '../../../ui/button/NavigateBackBtn';



const styles = theme => ({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    list: {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
    }

})

const ComponentBySourceEqual = ({ source, equals, Component }) => {
    const element = useWatch({ name: source });
    if (element && element === equals) {
        return (
            <Component />
        )
    }
    return null;
}


const validateRequired = [required('Champs obligatoire')]





const ProductInputHelper = ({

}) => {
    const { getValues, setValue } = useFormContext();
    const selectedProductId = useWatch({ name: 'product_id' });
    const dataProvider = useDataProvider();

    useEffect(async () => {
        if (selectedProductId) {
            const { data } = await dataProvider.getOne('products', { id: selectedProductId });

            if (data && data.device_type) {
                setValue('type', data.device_type);
            }
        }
    }, [selectedProductId, getValues, setValue]);

    return (
        <>
        </>
    );
};





const DeviceAdd = (
    (() => {
        const theme = useTheme();
        const translate = useTranslate();
        const navigate = useNavigate()
        const [locale, setLocale] = useLocaleState()
        const dataProvider = useDataProvider();
        const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
        const transform = async (data) => {
            if (data.plan_ids && Array.isArray(data.plan_ids)) {
                const { data: plans } = await dataProvider.getMany('device_sub_plans', { filter: { device_sub_plan_ids: data.plan_ids } });
                if (plans && Array.isArray(plans) && plans.length > 0) {
                    const filtredTags = plans.filter(p => data.plan_ids.includes(p.plan_id) && p.tag && p.tag.length > 0)
                    const tags = filtredTags && filtredTags.length > 0 ? filtredTags.map(p => p.tag) : []
                    data.tags = tags;
                }
                delete data.plan_ids;
                return data
            } else {
                return data
            }
        };


        const inputsData1 = [
            { name: 'name', label: translate('resources.devices.fields.name'), validate: validateRequired, type: "text" },
            { name: 'organization_id', label: translate('resources.devices.fields.organization'), validate: validateRequired, type: "reference", reference: "organizations", optionText: "name" },
            { name: 'owner_id', label: translate('resources.devices.fields.owner'), type: "reference", reference: "users", optionText: (u) => (u.first_name && u.last_name ? u.first_name.toUpperCase() + ' ' + u.last_name.toUpperCase() : u.email), disabled: true },
            { name: 'product_id', label: translate('resources.devices.product'), validate: validateRequired, type: "reference", reference: "products", optionText: "marketing_name" },
            { name: 'type', label: translate('resources.devices.type'), validate: validateRequired, type: "select", choices: [{ id: "ibeacon", name: "IBEACON" }, { id: "unb", name: "UNB" }], disabled: true },
        ]

        const inputsDataUnb = [
            { name: 'serial_number', label: translate('resources.devices.fields.serial_number'), validate: validateRequired, type: "text" },
        ]


        const inputsDataIbeacon = [
            { name: 'mac_address', label: translate('resources.devices.fields.mac_address'), validate: validateRequired, type: "text" },
            { name: 'proximity_uuid', label: translate('resources.devices.fields.proximity_uuid'), validate: validateRequired, type: "text" },
            { name: 'minor', label: translate('resources.devices.fields.minor'), validate: validateRequired, type: "number" },
            { name: 'major', label: translate('resources.devices.fields.major'), validate: validateRequired, type: "number" },
        ]

        const inputsData2 = [
            { name: 'secondary_mac_address', label: translate('resources.devices.fields.secondary_mac_address'), type: "text" },
            { name: 'software_version', label: translate('resources.devices.fields.software_version'), type: "text" },
            { name: 'plan_ids', label: translate('resources.devices.device_sub_plans'), width: isSmall ? "300px" : "480px", height: "250px", type: "referenceAutocompleteArrayInput", reference: "device_sub_plans", optionText: "title", filter: { language: locale } },

        ]

        return (
            <>
                <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "24px", lineHeight: "42px", margin: "0px 0px 16px 0px" }}>
                    <NavigateBackBtn /> {translate('resources.devices.page_titles.add_device')}
                </Typography>
                <Create
                    redirect="list"
                    title={('resources.devices.add_new_device')}
                    resource="devices"
                    sx={{
                        "& .MuiPaper-root": {
                            backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary,
                        }
                    }}

                    transform={transform}
                >
                    <SimpleForm >
                        {styledFormInputs(inputsData1)}


                        <ProductInputHelper />

                        <ComponentBySourceEqual
                            source={"type"}
                            equals={"ibeacon"}
                            Component={() => styledFormInputs(inputsDataIbeacon)}
                        />

                        <ComponentBySourceEqual
                            source={"type"}
                            equals={"unb"}
                            Component={() => styledFormInputs(inputsDataUnb)}
                        />

                        {styledFormInputs(inputsData2)}

                    </SimpleForm>
                </Create>
            </>
        )
    }))

export default DeviceAdd