import React, { useEffect, useState } from 'react'
import {
    Create,
    required,
    SaveButton,
    SimpleForm,
    Toolbar,
    useNotify,
    useRedirect,
    useTranslate,
} from 'react-admin'
import { Typography, useMediaQuery, useTheme } from '@mui/material'
import { getUrlParams } from '../../../utils/utils'
import styledFormInputs from '../../../ui/inputs/styledFormInputs';
import dayjs from 'dayjs';
import { useFormContext, useWatch } from 'react-hook-form'
import NavigateBackBtn from '../../../ui/button/NavigateBackBtn';






const validateRequired = [required('Champs obligatoire')]




const InputDefaultValuesHelper = ({
    device_ids,
    event_type
}) => {
    const { getValues, setValue } = useFormContext();

    useEffect(() => {

        setTimeout(() => {
            if (device_ids) {
                setValue('device_ids', device_ids);
            }
            if (event_type) {
                setValue('event_type', event_type);
            }
        }, 1000);

    }, [getValues, setValue]);



    return (
        <>
        </>
    );
};


const DataFormatChoicesInputHelper = ({
    setFormatChoices
}) => {
    const { getValues, setValue } = useFormContext();
    const selectedEventType = useWatch({ name: 'event_type' });

    useEffect(async () => {
        if (selectedEventType === "location") {
            setFormatChoices([{ id: 'csv', name: 'CSV' }, { id: 'json', name: 'JSON' }, { id: 'gpx', name: 'GPX' }, { id: 'pdf', name: 'PDF' }])
        }
        if (selectedEventType !== "location") {
            setFormatChoices([{ id: 'csv', name: 'CSV' }, { id: 'json', name: 'JSON' }])
        }
    }, [selectedEventType, getValues, setValue]);

    return (
        <>
        </>
    );
};


const ExportCreateToolbar = props => (
    <Toolbar {...props} >
        <SaveButton label="resources.export.action" />
    </Toolbar>
);

const ExportCreate = ({ ...props }) => {
    const selectedIds = getUrlParams('selectedIds')
    const selectedEventType = getUrlParams('selectedEventType')
    const [formatChoices, setFormatChoices] = useState([])
    const device_ids = selectedIds !== '' ? [selectedIds] : null
    const event_type = selectedEventType !== '' ? selectedEventType : null
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const today = new Date();
    today.setHours(0)
    today.setMinutes(0)
    today.setSeconds(0)
    today.setMilliseconds(0)
    const defaultStartDate = dayjs(today.toISOString());
    today.setHours(24)
    today.setMinutes(59)
    today.setSeconds(59)
    today.setMilliseconds(0)
    const defaultEndDate = dayjs(today.toISOString());
    const sensorsNames = ["location", "humidity", "temperature", "co2", "battery", "pressure", "luminosity", "formaldehyde", "tvoc", "ammonia", "noise", "mox", "aqi", "pm1", "pm2p5", "pm10", "benzen", "presence", "door", "water_leak", "shock", "button", "motion", "notification_log"];
    const eventTypeChoices = sensorsNames.map((s) => { return ({ id: s, name: translate(`resources.${s}.head`) }) })
    const inputsData = [
        { name: 'device_ids', label: translate('resources.export.fields.device_ids'), validate: validateRequired, type: "referenceAutocompleteArrayInput", reference: "devices", disabled: device_ids ? true : false, optionText: "name", width: isSmall ? "300px" : "480px", height: "250px" },
        { name: 'event_type', label: translate('resources.export.fields.event_type'), validate: validateRequired, type: "select", disabled: event_type ? true : false, choices: eventTypeChoices },
        { name: 'format', label: translate('resources.export.fields.format'), validate: validateRequired, type: "select", choices: formatChoices },
        { name: 'start_date', label: translate('resources.export.fields.start_date'), validate: validateRequired, type: "dateTime", defaultValue: defaultStartDate },
        { name: 'end_date', label: translate('resources.export.fields.end_date'), validate: validateRequired, type: "dateTime", defaultValue: defaultEndDate },
        { name: 'language', label: translate('resources.export.fields.language'), validate: validateRequired, type: "select", choices: [{ id: 'fr', name: 'FR' }, { id: 'en', name: 'EN' }, { id: 'es', name: 'ES' }] }
        

    ]

    const onSuccess = () => {
        notify('resources.export.export_starting', { messageArgs: { smart_count: 1 } });
        redirect('/devices');
    }


    return (
        <>
            <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "24px", lineHeight: "42px", margin: "0px 0px 16px 0px" }}>
                <NavigateBackBtn /> {translate('resources.export.create')}
            </Typography>
            <Create {...props}
                title={'resources.export.create'}
                sx={{
                    "& .MuiPaper-root":
                    {
                        backgroundColor: theme.palette.background.paper,
                        color: theme.palette.text.primary,
                    }
                }}
                mutationOptions={{ onSuccess }}
            >
                <SimpleForm toolbar={<ExportCreateToolbar />}>

                    {styledFormInputs(inputsData)}
                    <InputDefaultValuesHelper device_ids={device_ids} event_type={event_type} />
                    <DataFormatChoicesInputHelper setFormatChoices={setFormatChoices} />
                </SimpleForm>
            </Create>
        </>
    );
}

export default ExportCreate

