
import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import * as Leaflet from 'leaflet'
import { Marker, useMap, Popup, CircleMarker, Circle } from 'react-leaflet'
//ticatag
import MarkerPopup from './markerPopup'
import * as ReactDOM from 'react-dom'
import { getFormattedAddress, getId, getLatitude, getLongitude, sVars } from '../../utils/utils'
import { formatAddress, geoReverse } from '../../utils/geocode'
import withStyles from '@mui/styles/withStyles';
import { useTranslate } from 'react-admin'


const styles = theme => ({
    //
})



const TifizMarker = React.memo((props) => {

    const { location, selected, handleSelect, scrollYContainerRef, scrollXContainerRef, icon } = props



    const reverseGeocoding = async (record) => {
        try {
            const { latitude, longitude } = record.last_location || record
            const res = await geoReverse(latitude, longitude)
            const json = await res.json()

            const formatted_address = formatAddress(json.address)

            if (record.last_location) {
                record.last_location.formatted_address = formatted_address
            }


            props.updateFormattedAddress(
                getId(record),
                record,
                formatted_address,
                record.last_location ? 'locations' : 'location_events'
            )
        } catch (ex) { }
    }

    const checkGeocode = (record) => {
        const formatted_address = getFormattedAddress(record)

        if (!formatted_address) reverseGeocoding(record)
    }







    if (selected && location.accuracy && location.accuracy > 50) {
        return (
            <Circle
                center={[location.lat, location.lng]}
                radius={location.accuracy}
                color={'blue'}
                fillOpacity={0.4}
            >

            </Circle>
        )
    }

    return (
        <Marker
            icon={icon}
            position={[location.lat, location.lng]}
            eventHandlers={{
                click: (e) => {
                    const elem = document.getElementById(`menu-custom-list-item-${location.id}`);
                    if (scrollYContainerRef && scrollYContainerRef.current && elem && elem.offsetTop) {
                        scrollYContainerRef.current.scrollTop = elem.offsetTop
                    }

                    if (scrollXContainerRef && scrollXContainerRef.current && elem && elem.offsetTop) {
                        // Center the element horizontally
                        const scrollPosition =
                            elem.offsetLeft - scrollXContainerRef.current.offsetWidth / 2 + elem.offsetWidth / 2;

                        scrollXContainerRef.current.scrollLeft = scrollPosition;
                    }
                    handleSelect(location)
                },
            }}

        >

        </Marker >

    )
})


TifizMarker.propTypes = {
    index: PropTypes.number.isRequired,
    lastLocation: PropTypes.bool.isRequired,
    record: PropTypes.object.isRequired,
    selected: PropTypes.bool.isRequired,
    updateFormattedAddress: PropTypes.func.isRequired,
}

export default TifizMarker

