
import React, { Fragment, useEffect, useState } from 'react'
import {
    Create,
    required,
    SimpleForm,
    useCreate,
    useDataProvider,
    useLocaleState,
    useTranslate,
} from 'react-admin'
import { Typography, useMediaQuery, useTheme } from '@mui/material'
import { useWatch, useFormContext } from 'react-hook-form'
import styledFormInputs from '../../../ui/inputs/styledFormInputs';
import NavigateBackBtn from '../../../ui/button/NavigateBackBtn';











const ComponentBySourceEqual = ({ source, equals, Component }) => {
    const element = useWatch({ name: source });
    if (element && element === equals) {
        return (
            <Component />
        )
    }
    return null;
}


const validateRequired = [required('Champs obligatoire')]





const ProductInputHelper = ({

}) => {
    const { getValues, setValue } = useFormContext();
    const selectedProductId = useWatch({ name: 'product_id' });
    const dataProvider = useDataProvider();

    useEffect(async () => {
        if (selectedProductId) {
            const { data } = await dataProvider.getOne('products', { id: selectedProductId });

            if (data && data.device_type) {
                setValue('type', data.device_type);
            }
        }
    }, [selectedProductId, getValues, setValue]);

    return (
        <>
        </>
    );
};



const tagsChoices = [{ id: 'button_press', name: 'BUTTON_PRESS' },
{ id: 'button_double_pressed', name: 'BUTTON_DOUBLE_PRESSED' },
{ id: 'button_held', name: 'BUTTON_HELD' },
{ id: 'geofence_crossed', name: 'GEOFENCE_CROSSED' },
{ id: 'battery_low', name: 'BATTERY_LOW' },
{ id: 'battery_status_bad', name: 'BATTERY_STATUS_BAD' },
{ id: 'door_opened', name: 'DOOR_OPENED' },
{ id: 'water_leak_detected', name: 'WATER_LEAK_DETECTED' },
{ id: 'shock_detected', name: 'SHOCK_DETECTED' },
{ id: 'rollover_detected', name: 'ROLLOVER_DETECTED' },
{ id: 'temperature_threshold_reached', name: 'TEMPERATURE_THRESHOLD_REACHED' },
{ id: 'co2_threshold_reached', name: 'CO2_THRESHOLD_REACHED' },
{ id: 'humidity_threshold_reached', name: 'HUMIDITY_THRESHOLD_REACHED' },
{ id: 'address_country_changed', name: 'ADDRESS_COUNTRY_CHANGED' },
{ id: 'address_state_changed', name: 'ADDRESS_STATE_CHANGED' },
{ id: 'address_city_changed', name: 'ADDRESS_CITY_CHANGED' },
{ id: 'high_g_thresholds_reached', name: 'HIGH_G_THRESHOLDS_REACHED' },
{ id: 'motion_detected', name: 'MOTION_DETECTED' }]


const DeviceAddBulk = (
    (() => {
        const theme = useTheme();
        const translate = useTranslate();
        const [create] = useCreate();
        const dataProvider = useDataProvider();
        const [locale, setLocale]= useLocaleState();
        const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
        const devicesBatchSave = (data) => {
            create(
                'devices_bulk',
                { data },
                {
                    onSuccess: () => {
                        notify(translate('ra.notification.created'), 'info');
                        redirectTo("list", "devices");
                    },
                }
            )

        };

        const transform = async (data) => {
            if (data.plan_ids && Array.isArray(data.plan_ids)) {
                const { data:plans } = await dataProvider.getMany('device_sub_plans', { filter: { device_sub_plan_ids: data.plan_ids } });
                if (plans && Array.isArray(plans) && plans.length > 0) {
                    const filtredTags = plans.filter(p => data.plan_ids.includes(p.plan_id) && p.tag && p.tag.length > 0)
                    const tags = filtredTags && filtredTags.length > 0 ? filtredTags.map(p => p.tag) : []
                    data.tags= tags;
                }
                delete data.plan_ids;
                return data
            }else{
                return data
            }
        };



        const inputsData1 = [
            { name: 'name', label: translate('resources.devices.fields.name'), validate: validateRequired, type: "text" },
            { name: 'organization_id', label: translate('resources.devices.fields.organization'), validate: validateRequired, type: "reference", reference: "organizations", optionText: "name" },
            { name: 'owner_id', label: translate('resources.devices.fields.owner'), type: "reference", reference: "users", optionText: (u) => (u.first_name && u.last_name ? u.first_name.toUpperCase() + ' ' + u.last_name.toUpperCase() : u.email) },
            { name: 'product_id', label: translate('resources.devices.product'), validate: validateRequired, type: "reference", reference: "products", optionText: "marketing_name" },
            { name: 'type', label: translate('resources.devices.type'), validate: validateRequired, type: "select", choices: [{ id: "ibeacon", name: "IBEACON" }, { id: "unb", name: "UNB" }], disabled: true },
        ]

        const inputsDataUnb = [
            { name: 'ids', label: translate('resources.devices.fields.unb_ids_file'), validate: validateRequired, width: isSmall ? "300px" : "480px", type: "file", accept: ".csv", subLabel: "type: csv , max 5MB",maxSize:5000000 },
        ]


        const inputsDataIbeacon = [
            { name: 'ids', label: translate('resources.devices.fields.ibeacon_ids_file'), validate: validateRequired, width: isSmall ? "300px" : "480px", type: "file", accept: ".csv", subLabel: "type: csv , max 5MB",maxSize:5000000 },
        ]

        const inputsData2 = [
            { name: 'software_version', label: translate('resources.devices.fields.software_version'), type: "text" },
            { name: 'plan_ids', label: translate('resources.devices.device_sub_plans'), width: isSmall ? "300px" : "480px", height: "250px", type: "referenceAutocompleteArrayInput", reference: "device_sub_plans", optionText: "title",filter:{language:locale} },
          
        ]

        return (
            <>
                <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "24px", lineHeight: "42px", margin: "0px 0px 16px 0px" }}>
                    <NavigateBackBtn /> {translate('resources.devices.page_titles.add_devices_bulk')}
                </Typography>
            <Create
                redirect="list"
                title={('resources.devices.add_new_device')}
                resource="devices_bulk"
                sx={{
                    "& .MuiPaper-root": {
                        backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary,
                    }
                }}
                transform={transform}
                >
                <SimpleForm onSubmit={devicesBatchSave} >
                    {styledFormInputs(inputsData1)}


                    <ProductInputHelper />

                    <ComponentBySourceEqual
                        source={"type"}
                        equals={"ibeacon"}
                        Component={() => styledFormInputs(inputsDataIbeacon)}
                    />

                    <ComponentBySourceEqual
                        source={"type"}
                        equals={"unb"}
                        Component={() => styledFormInputs(inputsDataUnb)}
                    />

                    {styledFormInputs(inputsData2)}

                </SimpleForm>
            </Create>
            </>
        )
    }))

export default DeviceAddBulk