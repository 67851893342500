import React, { useEffect, useState } from 'react'
import {
    Create,
    required,
    SimpleForm,
    useTranslate,
} from 'react-admin'
import 'react-phone-number-input/style.css'
import { useFormContext, useWatch } from 'react-hook-form'
import { Button, TableBody, TableCell, TableRow, Typography, useMediaQuery, useTheme } from '@mui/material'
import styledFormInputs from '../../../ui/inputs/styledFormInputs'
import StandardDialog from '../../../ui/dialog/StandardDialog'
import NavigateBackBtn from '../../../ui/button/NavigateBackBtn'




const validateRequired = [required('Champs obligatoire')]

const ComponentBySourceEqual = ({ source, equals, Component }) => {
    const element = useWatch({ name: source });
    if (element && element === equals) {
        return (
            <Component />
        )
    }
    return null;
}


const InputDefaultValuesHelper = ({
}) => {
    const { getValues, setValue } = useFormContext();

    useEffect(() => {
        setTimeout(() => {
            setValue('auth_type', "Basic");
            setValue('version', "v2");
        }, 1000);

    }, [getValues, setValue]);

    return (
        <>
        </>
    );
};

const HookEventsInputHelper = ({
    setDeviceId
}) => {
    const { getValues, setValue } = useFormContext();
    const selectedDeviceId = useWatch({ name: 'device_id' });

    useEffect(async () => {
        if (selectedDeviceId) {
            setDeviceId(selectedDeviceId)
        }
    }, [selectedDeviceId, getValues, setValue]);

    return (
        <>
        </>
    );
};


const ResetInputHelper = ({
}) => {
    const { getValues, setValue } = useFormContext();
    const selectedAuthActive = useWatch({ name: 'auth_active' });

    const selectedApplyOn = useWatch({ name: 'applyOn' });

    useEffect(async () => {
        if (selectedAuthActive === false) {
            setValue('auth_username', null);
            setValue('auth_password', null);
        }

    }, [selectedAuthActive, getValues, setValue]);

    useEffect(async () => {
        if (selectedApplyOn === "oneDevice") {
            setValue('organization_id', null);
        }

        if (selectedApplyOn === "oneOrganization") {
            setValue('device_id', null);
        }

    }, [selectedApplyOn, getValues, setValue]);

    return (
        <>
        </>
    );
};


const InfoAboutHooksDialog = ({ openDialog, setOpenDialog }) => {
    const translate = useTranslate();

    return (
        <StandardDialog
            open={openDialog}
            fullWidth={true}
            onClose={() => { setOpenDialog(false); }}
            BodyComponent={() => {

                return (
                    <TableBody>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell align="left" sx={{ padding: "0px 0px 10px 0px", fontWeight: 500 }}>
                                {translate('resources.hooks.info_about_hooks.title')}
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell align="left" sx={{ padding: "0px 0px 5px 0px", fontWeight: 400 }}>
                                {translate('resources.hooks.info_about_hooks.message1')}
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell align="left" sx={{ padding: "0px 0px 5px 0px", fontWeight: 400 }}>
                                {translate('resources.hooks.info_about_hooks.message2')}
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell align="left" sx={{ padding: "0px 0px 5px 0px", fontWeight: 400 }}>
                                {translate('resources.hooks.info_about_hooks.message3')}
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

                            <TableCell align="center" sx={{ padding: "0px", fontWeight: 500 }}>
                                <Button onClick={() => { setOpenDialog(false); }} variant='contained' sx={{ margin: "25px 0px  0px 10px", }}>
                                    {translate('resources.common.ok')}
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                )
            }}
        />
    );
};



const HookCreate = () => {
    const translate = useTranslate();
    const theme = useTheme();
    const [deviceId, setDeviceId] = useState(null);
    const [openInfoAboutHooksDialog, setOpenInfoAboutHooksDialog] = useState(false);
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        setTimeout(() => {
            setOpenInfoAboutHooksDialog(true)
        }, 500);

    }, [])

    const inputsDataGeneral = [
        { name: 'active', label: translate('resources.hooks.fields.active'), validate: validateRequired, type: "boolean" },
        { name: 'name', label: translate('resources.hooks.fields.name'), validate: validateRequired, type: "text" },
        { name: 'applyOn', label: translate('resources.hooks.fields.applyOn'), validate: validateRequired, type: "select", choices: [{ id: 'oneDevice', name: translate('resources.hooks.fields.applyOnOneDevice') }, { id: 'oneOrganization', name: translate('resources.hooks.fields.applyOnOneOrganization') }] },
    ]


    const inputsDataApplyOnOneDevice = [
        { name: 'device_id', label: translate('resources.hooks.fields.device'), validate: validateRequired, type: "reference", reference: "devices", optionText: "name" },
    ]


    const inputsDataHookEvents = (filter) => ([
        { name: 'events', label: translate('resources.hooks.fields.events'), validate: validateRequired, type: "referenceAutocompleteArrayInput", reference: "hook_events", filter: filter, optionValue: "event", optionText: (s) => (s && s.event) ? translate(`resources.hooks.${s.event}`) : "n", width: isSmall ? "300px" : "480px", height: "250px" },
    ])

    const inputsDataApplyOnOneOrganization = [
        { name: 'organization_id', label: translate('resources.hooks.fields.organization'), validate: validateRequired, type: "reference", reference: "organizations", optionText: "name" },
    ]


    const inputsDataGeneral2 = [
        { name: 'target_url', label: translate('resources.hooks.fields.target_url'), validate: validateRequired, type: "text", width: isSmall ? "300px" : "480px" },
        { name: 'version', label: translate('resources.hooks.fields.version'), validate: validateRequired, type: "select", choices: [{ id: 'v2', name: "V2" }], disabled: true },
        { name: 'auth_active', label: translate('resources.hooks.fields.auth'), validate: validateRequired, type: "boolean" },
    ]

    const inputsDataAuth = [
        { name: 'auth_type', label: translate('resources.hooks.fields.auth_type'), validate: validateRequired, defaultValue: "Basic", type: "text", disabled: true },
        { name: 'auth_username', label: translate('resources.hooks.fields.auth_username'), validate: validateRequired, type: "text" },
        { name: 'auth_password', label: translate('resources.hooks.fields.auth_password'), validate: validateRequired, type: "password" },
    ]


    return (
        <>
            <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "24px", lineHeight: "42px", margin: "0px 0px 16px 0px" }}>
                <NavigateBackBtn /> {translate('resources.hooks.create')}
            </Typography>
            <Create
                title={translate('resources.hooks.create')}
                sx={{
                    "& .MuiPaper-root": {
                        backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary,
                    },
                }}
                redirect="list"

            >
                <SimpleForm>
                    {styledFormInputs(inputsDataGeneral)}
                    <ComponentBySourceEqual
                        source={"applyOn"}
                        equals={"oneDevice"}
                        Component={() => styledFormInputs(inputsDataApplyOnOneDevice)}
                    />

                    <ComponentBySourceEqual
                        source={"applyOn"}
                        equals={"oneDevice"}
                        Component={() => <HookEventsInputHelper setDeviceId={setDeviceId} />}
                    />
                    <ComponentBySourceEqual
                        source={"applyOn"}
                        equals={"oneDevice"}
                        Component={() => deviceId ? styledFormInputs(inputsDataHookEvents({ device_id: deviceId })) : <div />}
                    />

                    <ComponentBySourceEqual
                        source={"applyOn"}
                        equals={"oneOrganization"}
                        Component={() => styledFormInputs(inputsDataApplyOnOneOrganization)}
                    />
                    <ComponentBySourceEqual
                        source={"applyOn"}
                        equals={"oneOrganization"}
                        Component={() => styledFormInputs(inputsDataHookEvents({}))}
                    />

                    {styledFormInputs(inputsDataGeneral2)}


                    <ComponentBySourceEqual
                        source={"auth_active"}
                        equals={true}
                        Component={() => styledFormInputs(inputsDataAuth)}
                    />

                    <InputDefaultValuesHelper />
                    <ResetInputHelper />
                    <InfoAboutHooksDialog openDialog={openInfoAboutHooksDialog} setOpenDialog={setOpenInfoAboutHooksDialog} />

                </SimpleForm>
            </Create>
        </>

    )
}

export default HookCreate
