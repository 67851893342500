import { useEffect, useState } from 'react'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import ConnectingDialog from '../ui/dialog/ConnectingDialog';
import { id } from 'date-fns/locale';
import { Buffer } from "buffer";
import { CLIENT_ACCESS_TOKEN, CLIENT_ACCESS_TOKEN_EXPIRY_DATE, RO_APP_TITLE, RO_COMPANY_URL, RO_CONTACT_PAGE_URL, RO_FAVICON_URL, RO_FIREBASE_API_KEY, RO_FIREBASE_APP_ID, RO_FIREBASE_AUTH_DOMAIN, RO_FIREBASE_MEASUREMENT_ID, RO_FIREBASE_MESSAGING_SENDER_ID, RO_FIREBASE_PROJECT_ID, RO_FIREBASE_STORAGE_BUCKET, RO_ICON_180x180_URL, RO_ICON_192x192_URL, RO_ICON_512x512_URL, RO_ICON_96x96_URL, RO_ID, RO_LOGO_URL, RO_NAME, RO_REFRESHED_AT, RO_THEME_DARK_BACKGROUND_DEFAULT, RO_THEME_DARK_PRIMARY_DARK, RO_THEME_DARK_PRIMARY_LIGHT, RO_THEME_DARK_PRIMARY_MAIN, RO_THEME_LIGHT_BACKGROUND_DEFAULT, RO_THEME_LIGHT_PRIMARY_DARK, RO_THEME_LIGHT_PRIMARY_LIGHT, RO_THEME_LIGHT_PRIMARY_MAIN } from '../pages/configuration/actions';

export const ROParametersInit = () => {
    const [dialogAlertSeverity, setDialogAlertSeverity] = useState("info");
    const [dialogAlertText, setDialogAlertText] = useState("Loading Client Access....");

    // 1 : get client token
    useEffect(() => {

        const authServerUrl = process.env.REACT_APP_AUTH_URL;
        const url = `${authServerUrl}/oauth2/token?grant_type=client_credentials`;
        const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
        const clientSecret = process.env.REACT_APP_AUTH_CLIENT_SECRET;
        const headers = {
            'Authorization': `Basic ${Buffer.from(`${clientId}:${clientSecret}`).toString('base64')}`
        };

        axios.post(url, {}, { headers })
            .then(async (response) => {

                if (response?.status === 200) {
                    setDialogAlertSeverity("success")
                    setDialogAlertText("Success loading Client Access....")
                    const token = response.data;
                    loginSuccessWrapper({ accessToken: token.access_token })
                    setTimeout(() => {
                        window.location.href = "/load-ro-parameters"
                    }, 1000)
                }
            }).catch((err) => {
                setDialogAlertSeverity("error")
                setDialogAlertText("Error loading Client Access....")
                console.log(err);
            })

    }, [])

    return (
        <ConnectingDialog open={true} />
    );
}

export const ROParametersLoader = () => {
    const [dialogAlertSeverity, setDialogAlertSeverity] = useState("info");
    const [dialogAlertText, setDialogAlertText] = useState("Loading Root Organization Parameters....");

    // 1 : get client token
    useEffect(() => {
        const appUrl = encodeURIComponent(window.location.origin)
        const authServerUrl = process.env.REACT_APP_AUTH_URL;
        const url = `${authServerUrl}/organizations/root/app-url?appUrl=${appUrl}&includes=logos,firebase,theme`;
        const clientAcessToken = localStorage.getItem(CLIENT_ACCESS_TOKEN);

        if (clientAcessToken) {
            const headers = {
                'Authorization': `Bearer ${clientAcessToken}`
            };

            axios.get(url, { headers })
                .then(async (response) => {

                    if (response?.status === 200) {
                        setDialogAlertSeverity("success")
                        setDialogAlertText("Success loading root organization parameters....")
                        processROParameters({ data: response.data, setDialogAlertSeverity, setDialogAlertText })

                    }

                }).catch((err) => {
                    setDialogAlertSeverity("error")
                    setDialogAlertText("Error loading root organization parameters....")

                })

        } else {
            setDialogAlertSeverity("error")
            setDialogAlertText("Error loading Client Access")
        }

    }, [])

    return (
        <ConnectingDialog open={true} />
    );
}





const processROParameters = ({ data, setDialogAlertSeverity, setDialogAlertText }) => {

    if (data &&
        (data.root_organization_id) &&
        (data.name) &&
        (data.app_title) &&
        (data.theme_light_primary_main) &&
        (data.theme_light_primary_dark) &&
        (data.theme_light_primary_light) &&
        (data.theme_light_background_default) &&
        (data.theme_dark_primary_main) &&
        (data.theme_dark_primary_dark) &&
        (data.theme_dark_primary_light) &&
        (data.theme_dark_background_default) &&
        (data.connect_header_logo && data.connect_header_logo.src) &&
        (data.connect_favicon_logo && data.connect_favicon_logo.src) &&
        (data.connect_icon96x96_png && data.connect_icon96x96_png.src) &&
        (data.connect_icon180x180_png && data.connect_icon180x180_png.src) &&
        (data.connect_icon192x192_png && data.connect_icon192x192_png.src) &&
        (data.connect_icon512x512_png && data.connect_icon512x512_png.src) &&
        (data.contact_page_url) &&
        (data.company_url) &&
        (data.firebase_api_key) &&
        (data.firebase_auth_domain) &&
        (data.firebase_project_id) &&
        (data.firebase_storage_bucket) &&
        (data.firebase_messaging_sender_id) &&
        (data.firebase_app_id) &&
        (data.firebase_measurement_id)

    ) {
        localStorage.setItem(RO_ID, data.root_organization_id);
        localStorage.setItem(RO_NAME, data.name);
        localStorage.setItem(RO_APP_TITLE, data.app_title);
        localStorage.setItem(RO_THEME_LIGHT_PRIMARY_MAIN, data.theme_light_primary_main);
        localStorage.setItem(RO_THEME_LIGHT_PRIMARY_DARK, data.theme_light_primary_dark);
        localStorage.setItem(RO_THEME_LIGHT_PRIMARY_LIGHT, data.theme_light_primary_light);
        localStorage.setItem(RO_THEME_LIGHT_BACKGROUND_DEFAULT, data.theme_light_background_default);
        localStorage.setItem(RO_THEME_DARK_PRIMARY_MAIN, data.theme_dark_primary_main);
        localStorage.setItem(RO_THEME_DARK_PRIMARY_DARK, data.theme_dark_primary_dark);
        localStorage.setItem(RO_THEME_DARK_PRIMARY_LIGHT, data.theme_dark_primary_light);
        localStorage.setItem(RO_THEME_DARK_BACKGROUND_DEFAULT, data.theme_dark_background_default);
        localStorage.setItem(RO_LOGO_URL, data.connect_header_logo.src);
        localStorage.setItem(RO_FAVICON_URL, data.connect_favicon_logo.src);
        localStorage.setItem(RO_ICON_96x96_URL, data.connect_icon96x96_png.src);
        localStorage.setItem(RO_ICON_180x180_URL, data.connect_icon180x180_png.src);
        localStorage.setItem(RO_ICON_192x192_URL, data.connect_icon192x192_png.src);
        localStorage.setItem(RO_ICON_512x512_URL, data.connect_icon512x512_png.src);
        localStorage.setItem(RO_COMPANY_URL, data.company_url);
        localStorage.setItem(RO_CONTACT_PAGE_URL, data.contact_page_url);
        localStorage.setItem(RO_FIREBASE_API_KEY, data.firebase_api_key);
        localStorage.setItem(RO_FIREBASE_AUTH_DOMAIN, data.firebase_auth_domain);
        localStorage.setItem(RO_FIREBASE_PROJECT_ID, data.firebase_project_id);
        localStorage.setItem(RO_FIREBASE_STORAGE_BUCKET, data.firebase_storage_bucket);
        localStorage.setItem(RO_FIREBASE_MESSAGING_SENDER_ID, data.firebase_messaging_sender_id);
        localStorage.setItem(RO_FIREBASE_APP_ID, data.firebase_app_id);
        localStorage.setItem(RO_FIREBASE_MEASUREMENT_ID, data.firebase_measurement_id);


        localStorage.setItem(RO_REFRESHED_AT, new Date().toISOString())
        setTimeout(() => {
            window.location.pathname = "/init-login"
        }, 1000)
    } else {

        setDialogAlertSeverity("error")
        setDialogAlertText("Error loading root organization parameters : parameters required!")
    }

}







const loginSuccessWrapper = ({ accessToken }) => {
    const decodedAccessToken = jwt_decode(accessToken)
    const accessTokenExpiryDate = decodedAccessToken.exp

    localStorage.setItem(CLIENT_ACCESS_TOKEN, accessToken)
    localStorage.setItem(CLIENT_ACCESS_TOKEN_EXPIRY_DATE, accessTokenExpiryDate)

}