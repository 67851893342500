import { IconButton, Tooltip, useTheme } from "@mui/material";
import { IconChevronLeft } from "@tabler/icons-react";
import { useTranslate } from "react-admin";
import { useNavigate } from "react-router-dom";



const NavigateBackBtn = () => {
    const translate = useTranslate();
    const navigate = useNavigate();
    const theme = useTheme();

    return (

        <Tooltip title={translate(`ra.action.back`)} disableInteractive >
            <IconButton onClick={() => navigate(-1)} sx={{padding:"0px"}}>
                <IconChevronLeft color={theme.palette.text.primary} width={"40px"} height={"40px"} />
            </IconButton>
        </Tooltip>
    );

}


export default NavigateBackBtn;