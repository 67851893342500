import React from 'react'
import { useTranslate } from 'react-admin'
import Typography from '@mui/material/Typography'
import { Card, CardContent, ButtonBase, Box, useMediaQuery, useTheme, Divider } from '@mui/material'
import { Wizard } from '../../../ui/form/WizardForm'

import { Field, useForm, useFormState } from 'react-final-form'



const PostpaidPlansExists = (plans) => {
    return plans && plans && plans.filter(plan => plan.type.toLowerCase() === 'postpaid').length > 0

}

const PrepaidPlansExists = (plans) => {
    return plans && plans && plans.filter(plan => plan.type.toLowerCase() === 'prepaid').length > 0

}


const PostpaidIcon = () => (<svg width="80" height="79" viewBox="0 0 80 79" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.166504 39.5C0.166504 17.6848 17.8513 0 39.6665 0C61.4818 0 79.1665 17.6848 79.1665 39.5C79.1665 61.3152 61.4818 79 39.6665 79C17.8513 79 0.166504 61.3152 0.166504 39.5Z" fill="#E3F2FD" />
    <path d="M25.083 39.4998C25.083 35.6321 26.6195 31.9228 29.3544 29.1879C32.0893 26.453 35.7986 24.9165 39.6663 24.9165C43.5341 24.9165 47.2434 26.453 49.9783 29.1879C52.7132 31.9228 54.2497 35.6321 54.2497 39.4998C54.2497 43.3676 52.7132 47.0769 49.9783 49.8118C47.2434 52.5467 43.5341 54.0832 39.6663 54.0832C35.7986 54.0832 32.0893 52.5467 29.3544 49.8118C26.6195 47.0769 25.083 43.3676 25.083 39.4998ZM39.6663 30.841C39.3188 30.841 38.9998 31.0404 38.8517 31.348L36.7896 35.5293L32.1753 36.2015C31.8335 36.2528 31.5487 36.492 31.4404 36.8224C31.3322 37.1528 31.4233 37.5174 31.6683 37.7567L35.0065 41.0151L34.2204 45.6123C34.1634 45.9541 34.3001 46.3016 34.585 46.501C34.8698 46.7004 35.2401 46.7345 35.542 46.5693L39.6663 44.4046L43.7964 46.575C44.104 46.7345 44.4743 46.7118 44.7591 46.5067C45.0439 46.3016 45.1807 45.9598 45.1237 45.618L44.3376 41.0208L47.6758 37.7624C47.9264 37.5174 48.0119 37.1585 47.9036 36.8281C47.7954 36.4977 47.5106 36.2585 47.1688 36.2072L42.5488 35.535L40.4867 31.3537C40.3328 31.0404 40.0138 30.8467 39.672 30.8467L39.6663 30.841Z" fill="white" />
    <path d="M40.4814 31.3483C40.3276 31.035 40.0086 30.8413 39.6668 30.8413C39.325 30.8413 39.0003 31.0407 38.8522 31.3483L36.79 35.5296L32.17 36.2018C31.8282 36.2531 31.5434 36.4924 31.4352 36.8228C31.3269 37.1532 31.4181 37.5177 31.663 37.757L35.0012 41.0155L34.2151 45.6126C34.1581 45.9544 34.2949 46.3019 34.5797 46.5013C34.8645 46.7007 35.2348 46.7349 35.5424 46.5697L39.6668 44.4049L43.7968 46.5754C44.1044 46.7349 44.4747 46.7121 44.7595 46.507C45.0444 46.3019 45.1811 45.9601 45.1241 45.6183L44.338 41.0212L47.6762 37.7627C47.9269 37.5177 48.0123 37.1589 47.9041 36.8285C47.7958 36.498 47.511 36.2588 47.1692 36.2075L42.555 35.5353L40.4928 31.354L40.4814 31.3483Z" fill="#2196F3" />
</svg>
)


const PostpaidIconSmall = () => (<svg width="60" height="59.25" viewBox="0 0 60 59.25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.125 29.625C0.125 13.264 13.388 0 29.75 0s29.625 13.264 29.625 29.625S46.111 59.25 29.75 59.25 0.124 45.986 0.124 29.625" fill="#E3F2FD" />
    <path d="M18.812 29.625a10.937 10.937 0 1 1 21.875 0 10.937 10.937 0 0 1 -21.875 0m10.937 -6.494c-0.26 0 -0.5 0.15 -0.61 0.38l-1.546 3.136 -3.461 0.504a0.685 0.685 0 0 0 -0.38 1.166l2.504 2.445 -0.59 3.447a0.675 0.675 0 0 0 0.274 0.667 0.682 0.682 0 0 0 0.718 0.051l3.093 -1.623 3.098 1.627c0.231 0.12 0.509 0.103 0.722 -0.051a0.675 0.675 0 0 0 0.274 -0.667l-0.59 -3.448 2.503 -2.444a0.687 0.687 0 0 0 -0.38 -1.166l-3.465 -0.504 -1.546 -3.136a0.682 0.682 0 0 0 -0.611 -0.38z" fill="white" />
    <path d="M30.361 23.511a0.681 0.681 0 0 0 -1.222 0L27.593 26.648l-3.465 0.504a0.687 0.687 0 0 0 -0.38 1.166l2.503 2.444 -0.59 3.448a0.675 0.675 0 0 0 0.274 0.666c0.214 0.15 0.491 0.176 0.722 0.052l3.094 -1.624 3.098 1.627c0.23 0.12 0.509 0.103 0.722 -0.051a0.675 0.675 0 0 0 0.273 -0.667l-0.59 -3.448 2.503 -2.444a0.687 0.687 0 0 0 -0.38 -1.166l-3.46 -0.505 -1.546 -3.136z" fill="#2196F3" />
</svg>

)
const PrepaidIcon = () => (<svg width="80" height="79" viewBox="0 0 80 79" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.5 39.5C0.5 17.6848 18.1848 0 40 0C61.8152 0 79.5 17.6848 79.5 39.5C79.5 61.3152 61.8152 79 40 79C18.1848 79 0.5 61.3152 0.5 39.5Z" fill="white" />
    <path d="M46.0156 30.3853C46.0156 31.029 45.9017 31.6499 45.6966 32.2253C45.5029 32.2139 45.3035 32.2082 45.1042 32.2082H37.8125C37.015 32.2082 36.2402 32.3107 35.5054 32.5044C35.2319 31.855 35.0781 31.1372 35.0781 30.3853C35.0781 27.366 37.5277 24.9165 40.5469 24.9165C43.5661 24.9165 46.0156 27.366 46.0156 30.3853Z" fill="#90CAF9" />
    <path d="M52.1108 31.5189L51.3247 34.6634C52.2248 35.5065 52.9596 36.5319 53.4609 37.6769H54.2186C55.2269 37.6769 56.0415 38.4915 56.0415 39.4998V44.9686C56.0415 45.9769 55.2269 46.7915 54.2186 46.7915H52.3957C51.8773 47.4808 51.262 48.096 50.5728 48.6144V52.2603C50.5728 53.2686 49.7581 54.0832 48.7498 54.0832H46.9269C45.9186 54.0832 45.104 53.2686 45.104 52.2603V50.4373H37.8123V52.2603C37.8123 53.2686 36.9977 54.0832 35.9894 54.0832H34.1665C33.1582 54.0832 32.3436 53.2686 32.3436 52.2603V48.6144C30.3555 47.1219 28.9997 44.8376 28.7433 42.2342H27.1027C24.9608 42.2342 23.229 40.5024 23.229 38.3605C23.229 36.2186 24.9608 34.4868 27.1027 34.4868H27.3306C28.0882 34.4868 28.6978 35.0964 28.6978 35.854C28.6978 36.6117 28.0882 37.2212 27.3306 37.2212H27.1027C26.4761 37.2212 25.9634 37.7339 25.9634 38.3605C25.9634 38.9871 26.4761 39.4998 27.1027 39.4998H28.88C29.7231 35.3413 33.4032 32.2082 37.8123 32.2082H45.104C45.5483 32.2082 45.987 32.2424 46.4142 32.2993C47.4168 31.1315 48.9093 30.3853 50.5728 30.3853H51.2279C51.8203 30.3853 52.2533 30.9435 52.1108 31.5189ZM47.3826 41.3228C47.7453 41.3228 48.093 41.1787 48.3494 40.9223C48.6058 40.6659 48.7498 40.3182 48.7498 39.9556C48.7498 39.593 48.6058 39.2452 48.3494 38.9888C48.093 38.7324 47.7453 38.5884 47.3826 38.5884C47.02 38.5884 46.6723 38.7324 46.4159 38.9888C46.1595 39.2452 46.0155 39.593 46.0155 39.9556C46.0155 40.3182 46.1595 40.6659 46.4159 40.9223C46.6723 41.1787 47.02 41.3228 47.3826 41.3228Z" fill="#2196F3" />
</svg>
)


const PrepaidIconSmall = () => (<svg width="60" height="59.25" viewBox="0 0 60 59.25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.375 29.625C0.375 13.264 13.639 0 30 0s29.625 13.264 29.625 29.625S46.361 59.25 30 59.25 0.375 45.986 0.375 29.625" fill="white" />
    <path d="M34.512 22.789c0 0.483 -0.086 0.949 -0.24 1.38a7.5 7.5 0 0 0 -0.444 -0.013h-5.468c-0.599 0 -1.18 0.077 -1.731 0.222a4.103 4.103 0 0 1 3.781 -5.691 4.103 4.103 0 0 1 4.103 4.103" fill="#90CAF9" />
    <path d="m39.082 23.639 -0.589 2.358a6.9 6.9 0 0 1 1.602 2.26h0.569A1.365 1.365 0 0 1 42.03 29.625v4.102a1.365 1.365 0 0 1 -1.367 1.367h-1.367a6.9 6.9 0 0 1 -1.367 1.367v2.734a1.365 1.365 0 0 1 -1.367 1.367h-1.367a1.365 1.365 0 0 1 -1.367 -1.367v-1.367h-5.469v1.367a1.365 1.365 0 0 1 -1.367 1.367h-1.367a1.365 1.365 0 0 1 -1.367 -1.367v-2.734a6.825 6.825 0 0 1 -2.7 -4.785h-1.231a2.902 2.902 0 0 1 -2.905 -2.905 2.902 2.902 0 0 1 2.905 -2.905h0.171c0.568 0 1.025 0.458 1.025 1.025 0 0.569 -0.458 1.025 -1.025 1.025h-0.171c-0.47 0 -0.855 0.385 -0.855 0.855s0.385 0.854 0.855 0.854h1.333c0.632 -3.119 3.392 -5.469 6.699 -5.469h5.469c0.333 0 0.662 0.026 0.983 0.068a4.103 4.103 0 0 1 3.119 -1.435h0.491a0.684 0.684 0 0 1 0.662 0.85m-3.545 7.353a1.025 1.025 0 1 0 0 -2.051 1.025 1.025 0 0 0 0 2.051" fill="#2196F3" />
</svg>
)

const VoucherIcon = () => (<svg width="80" height="79" viewBox="0 0 80 79" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.833008 39.5C0.833008 17.6848 18.5178 0 40.333 0C62.1483 0 79.833 17.6848 79.833 39.5C79.833 61.3152 62.1483 79 40.333 79C18.5178 79 0.833008 61.3152 0.833008 39.5Z" fill="#E3F2FD" />
    <path d="M24.2915 30.3856C24.2915 28.3747 25.9264 26.7397 27.9373 26.7397H53.4582C55.4691 26.7397 57.104 28.3747 57.104 30.3856V48.6147C57.104 50.6257 55.4691 52.2606 53.4582 52.2606H27.9373C25.9264 52.2606 24.2915 50.6257 24.2915 48.6147V30.3856ZM30.6717 41.3231C30.1704 41.3231 29.7603 41.7332 29.7603 42.2345C29.7603 42.7358 30.1704 43.146 30.6717 43.146H50.7238C51.2251 43.146 51.6353 42.7358 51.6353 42.2345C51.6353 41.7332 51.2251 41.3231 50.7238 41.3231H30.6717ZM29.7603 45.8804C29.7603 46.3817 30.1704 46.7918 30.6717 46.7918H34.3175C34.8188 46.7918 35.229 46.3817 35.229 45.8804C35.229 45.3791 34.8188 44.9689 34.3175 44.9689H30.6717C30.1704 44.9689 29.7603 45.3791 29.7603 45.8804ZM37.0519 45.8804C37.0519 46.3817 37.4621 46.7918 37.9634 46.7918H45.255C45.7563 46.7918 46.1665 46.3817 46.1665 45.8804C46.1665 45.3791 45.7563 44.9689 45.255 44.9689H37.9634C37.4621 44.9689 37.0519 45.3791 37.0519 45.8804ZM47.5337 30.3856C46.776 30.3856 46.1665 30.9951 46.1665 31.7528V34.4871C46.1665 35.2448 46.776 35.8543 47.5337 35.8543H52.091C52.8486 35.8543 53.4582 35.2448 53.4582 34.4871V31.7528C53.4582 30.9951 52.8486 30.3856 52.091 30.3856H47.5337Z" fill="#2196F3" />
    <path d="M30.6717 41.3228C30.1704 41.3228 29.7603 41.7329 29.7603 42.2342C29.7603 42.7355 30.1704 43.1457 30.6717 43.1457H50.7238C51.2251 43.1457 51.6353 42.7355 51.6353 42.2342C51.6353 41.7329 51.2251 41.3228 50.7238 41.3228H30.6717ZM29.7603 45.88C29.7603 46.3813 30.1704 46.7915 30.6717 46.7915H34.3175C34.8188 46.7915 35.229 46.3813 35.229 45.88C35.229 45.3787 34.8188 44.9686 34.3175 44.9686H30.6717C30.1704 44.9686 29.7603 45.3787 29.7603 45.88ZM37.0519 45.88C37.0519 46.3813 37.4621 46.7915 37.9634 46.7915H45.255C45.7563 46.7915 46.1665 46.3813 46.1665 45.88C46.1665 45.3787 45.7563 44.9686 45.255 44.9686H37.9634C37.4621 44.9686 37.0519 45.3787 37.0519 45.88Z" fill="#90CAF9" />
</svg>
)


const VoucherIconSmall = () => (<svg width="60" height="59.25" viewBox="0 0 60 59.25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.625 29.625C0.625 13.264 13.889 0 30.25 0s29.625 13.264 29.625 29.625S46.611 59.25 30.25 59.25 0.625 45.986 0.625 29.625" fill="#E3F2FD" />
    <path d="M18.219 22.79a2.737 2.737 0 0 1 2.734 -2.734h19.141a2.737 2.737 0 0 1 2.734 2.734v13.672a2.737 2.737 0 0 1 -2.734 2.734h-19.14a2.737 2.737 0 0 1 -2.734 -2.734zm4.785 8.203a0.685 0.685 0 0 0 -0.684 0.683c0 0.377 0.307 0.684 0.684 0.684h15.039c0.376 0 0.683 -0.307 0.683 -0.684a0.685 0.685 0 0 0 -0.683 -0.682zm-0.684 3.418c0 0.377 0.307 0.684 0.684 0.684h2.734c0.375 0 0.683 -0.307 0.683 -0.684a0.685 0.685 0 0 0 -0.683 -0.683h-2.734a0.685 0.685 0 0 0 -0.684 0.683m5.469 0c0 0.377 0.307 0.684 0.683 0.684h5.469c0.376 0 0.683 -0.307 0.683 -0.684a0.685 0.685 0 0 0 -0.683 -0.683h-5.469a0.685 0.685 0 0 0 -0.683 0.683m7.861 -11.62c-0.569 0 -1.025 0.458 -1.025 1.025v2.05c0 0.569 0.457 1.025 1.025 1.025h3.418c0.569 0 1.025 -0.458 1.025 -1.025v-2.05c0 -0.569 -0.458 -1.025 -1.025 -1.025z" fill="#2196F3" />
    <path d="M23.004 30.992a0.685 0.685 0 0 0 -0.684 0.683c0 0.377 0.307 0.684 0.684 0.684h15.039c0.376 0 0.683 -0.307 0.683 -0.684a0.685 0.685 0 0 0 -0.683 -0.683zm-0.684 3.418c0 0.376 0.307 0.683 0.684 0.683h2.734c0.375 0 0.683 -0.307 0.683 -0.683a0.685 0.685 0 0 0 -0.683 -0.683h-2.734a0.685 0.685 0 0 0 -0.684 0.683m5.469 0c0 0.376 0.307 0.683 0.683 0.683h5.469c0.376 0 0.683 -0.307 0.683 -0.683a0.685 0.685 0 0 0 -0.683 -0.683h-5.469a0.685 0.685 0 0 0 -0.683 0.683" fill="#90CAF9" />
</svg>
)



const ButtonBaseAdapter = ({ input: { onChange, value }, label, ...rest }) => {
    const translate = useTranslate();
    const theme = useTheme();
    const { values } = useFormState();
    if (!values || !values.plans) return null
    const isSmall = useMediaQuery(
        theme => theme.breakpoints.down('sm')
    );

    const selectedBg = theme.palette.mode === "light" ? ["linear-gradient(0deg,#FFFFFF,#FFFFFF)", `linear-gradient(180deg, rgba(33, 150, 243,0.2) 0%, rgba(255, 255, 255, 0.2) 91.89%)`] : ["linear-gradient(0deg, #111936, #111936)", `linear-gradient(180deg, rgba(33, 150, 243,0.2) 0%, rgba(33, 41, 70, 0.2) 91.89%);`]

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', margin: "0px 0px 0px 0px", padding: "20px" }}>
            <Typography
                color="text.main"
                sx={{
                    fontFamily: "Roboto",
                    fontSize: "25px",
                    fontWeight: 500,
                    lineHeight: "29.3px",
                    textAlign: "center",
                    margin: "0px"
                }}
            >
                {translate('resources.devices.select_offer_type')}
            </Typography>
            <Divider sx={{
                width: "50px", borderWidth: "4px", borderColor: theme.palette.primary.main,
                margin: "8px 0px 20px 0px"
            }} />
            <Box sx={{ display: 'flex', flexDirection: isSmall ? 'column' : 'row', alignItems: 'center', justifyContent: 'center', margin: "0px 0px 20px 0px", width: "100%" }}>
                {PostpaidPlansExists(values.plans) &&
                    <ButtonBase
                        onClick={() => { onChange("postpaid") }}
                    >
                        <Card sx={{
                            display: 'flex',
                            width: "339.33px",
                            height: isSmall ? "200px" : "413px",
                            margin: "20px",
                            border: (value === "postpaid") ? 3 : 1,
                            borderRadius: "12px",
                            borderColor: (value === "postpaid") ? 'primary.main' : 'text.secondary',
                            background: {
                                xs:(value === "postpaid") ? selectedBg : "none",
                                sm:(value === "postpaid") ? selectedBg : "none",
                            }
                        }}>
                            <CardContent sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                margin: "0px",
                                padding: "15px"
                            }}>
                                {isSmall ? <PostpaidIconSmall /> : <PostpaidIcon />}
                                <Typography
                                    color="text.main"
                                    sx={{
                                        fontFamily: "Roboto",
                                        fontSize: isSmall ? "14px" : "25px",
                                        fontWeight: 500,
                                        lineHeight: "29.3px",
                                        textAlign: "center",
                                        margin: isSmall ? "15px 5px 5px 5px" : "35px 20px 15px 20px"
                                    }}
                                >
                                    {translate('resources.subscriptions.name', { smart_count: 1, })}
                                </Typography>
                                <Divider sx={{
                                    width: "50px", borderWidth: isSmall ? "2px":"4px", borderColor: theme.palette.primary.main,
                                    margin: "0px 20px 15px 20px"
                                }} />
                                <Typography
                                    color="text.secondary"
                                    sx={{
                                        fontFamily: "Roboto",
                                        fontSize: "12px",
                                        fontWeight: 400,
                                        lineHeight: "20px",
                                        textAlign: "center",
                                        margin: "0px 0px 15px 0px"
                                    }}
                                >
                                    {translate('resources.subscriptions.description', { smart_count: 1, })}
                                </Typography>
                            </CardContent>
                        </Card >

                    </ButtonBase>
                }
                {PrepaidPlansExists(values.plans) &&
                    <ButtonBase
                        onClick={() => { onChange("prepaid") }}
                    >

                        <Card sx={{
                            display: 'flex',
                            width: "339.33px",
                            height: isSmall ? "200px" : "413px",
                            margin: "20px",
                            border: (value === "prepaid") ? 3 : 1,
                            borderRadius: "12px",
                            borderColor: (value === "prepaid") ? 'primary.main' : 'text.secondary',
                            background: {
                                xs:(value === "prepaid") ? selectedBg : "none",
                                sm:(value === "prepaid") ? selectedBg : "none",
                            }
                            
                        }}>
                            <CardContent sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                margin: "0px",
                                padding: "15px"
                            }}>
                                {isSmall ? <PrepaidIconSmall /> : <PrepaidIcon />}
                                <Typography
                                    color="text.main"
                                    sx={{
                                        fontFamily: "Roboto",
                                        fontSize: isSmall ? "14px" : "25px",
                                        fontWeight: 500,
                                        lineHeight: "29.3px",
                                        textAlign: "center",
                                        margin: isSmall ? "15px 5px 5px 5px" : "35px 20px 15px 20px"
                                    }}
                                >
                                    {translate('resources.subscriptions.prepaid_offer')}
                                </Typography>
                                <Divider sx={{
                                    width: "50px", borderWidth: isSmall ? "2px":"4px", borderColor: theme.palette.primary.main,
                                    margin: "0px 20px 15px 20px"
                                }} />
                                <Typography
                                    color="text.secondary"
                                    sx={{
                                        fontFamily: "Roboto",
                                        fontSize: "12px",
                                        fontWeight: 400,
                                        lineHeight: "20px",
                                        textAlign: "center",
                                        margin: "0px 0px 15px 0px"
                                    }}
                                >
                                    {translate('resources.subscriptions.prepaid_offer_description', { smart_count: 1, })}
                                </Typography>
                            </CardContent>
                        </Card >

                    </ButtonBase>
                }
                <ButtonBase
                    onClick={() => { onChange("voucher") }}
                >


                    <Card sx={{
                        display: 'flex',
                        width: "339.33px",
                        height: isSmall ? "200px" : "413px",
                        margin: "20px",
                        border: (value === "voucher") ? 3 : 1,
                        borderRadius: "12px",
                        borderColor: (value === "voucher") ? 'primary.main' : 'text.secondary',
                        background: {
                            xs:(value === "voucher") ? selectedBg : "none",
                            sm:(value === "voucher") ? selectedBg : "none",
                        }
                    }}>
                        <CardContent sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            margin: "0px",
                            padding: "15px"
                        }}>
                            {isSmall ? <VoucherIconSmall /> : <VoucherIcon />}
                            <Typography
                                color="text.main"
                                sx={{
                                    fontFamily: "Roboto",
                                    fontSize: isSmall ? "14px" : "25px",
                                    fontWeight: 500,
                                    lineHeight: "29.3px",
                                    textAlign: "center",
                                    margin: isSmall ? "15px 5px 5px 5px" : "35px 20px 15px 20px"
                                }}
                            >
                                {translate('resources.subscriptions.voucher')}
                            </Typography>
                            <Divider sx={{
                                width: "50px", borderWidth:isSmall ? "2px": "4px", borderColor: theme.palette.primary.main,
                                margin: "0px 20px 15px 20px"
                            }} />
                            <Typography
                                color="text.secondary"
                                sx={{
                                    fontFamily: "Roboto",
                                    fontSize: "12px",
                                    fontWeight: 400,
                                    lineHeight: "20px",
                                    textAlign: "center",
                                    margin: "0px 0px 15px 0px"
                                }}
                            >
                                {translate('resources.subscriptions.voucher_description', { smart_count: 1, })}
                            </Typography>
                        </CardContent>

                    </Card >

                </ButtonBase>
            </Box >
        </Box >

    )
}




const SelectPlanType = (props) => {


    return (

        <Wizard.Page >

            <Field
                name="selectedPlanType"
                label="selectedPlanType"
                component={ButtonBaseAdapter}
            />

        </Wizard.Page>
    )
}

export default SelectPlanType
