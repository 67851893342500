import {
    API_KEY,
    AUTHORITIES,
    CHANGE_MARKER,
    CHANGE_THEME,
    NBR_HISTORY,
    ROLE_ORGA_USER,
    SELECT_RIGHTMENU,
    ROUTING_MODE,
    DISABLE_CLUSTERING_AT_ZOOM
} from '../pages/configuration/actions'

const initState = {
    [AUTHORITIES]: localStorage.getItem(AUTHORITIES) || ROLE_ORGA_USER,
    [CHANGE_THEME]: localStorage.getItem(CHANGE_THEME) || 'light',
    [CHANGE_MARKER]: true,
    [SELECT_RIGHTMENU]: localStorage.getItem(SELECT_RIGHTMENU) === "true" || true,
    [NBR_HISTORY]: localStorage.getItem(NBR_HISTORY) || 25,
    [ROUTING_MODE]: localStorage.getItem(ROUTING_MODE) || 'boat',
    [DISABLE_CLUSTERING_AT_ZOOM]: localStorage.getItem(DISABLE_CLUSTERING_AT_ZOOM) === "true" || true,

}


export default (previousState = initState, { type, payload }) => {
    if (previousState[type] !== undefined) {
        return { ...previousState, [type]: payload }
    }

    return previousState
}
