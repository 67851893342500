import React, { useState, useEffect } from 'react';

import { useFormContext } from 'react-hook-form';
import { CREATE, crudUpdate, SaveButton, UPDATE, useRedirect, useCreate, useNotify, useUpdate, useTranslate, useDataProvider, useRecordContext } from 'react-admin';
import isEqual from 'lodash/isEqual'
import { isSuperAdmin } from '../../../helper/resourceHelper';
import { updateDeviceNameOrgIdOwnerId } from '../../../rest/restClient'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const YABBY_PRODUCTS_NAME = ["yabbygps", "yabbyedge", 'yabby3-4g']



const computeRetryPeriod = (geolocationPeriod) => geolocationPeriod <= 5 ? 0 : 5

const dialogRequired = (record, values) => {
    return (YABBY_PRODUCTS_NAME.includes(values.product.identifier.toLowerCase())
        &&
        YABBY_PRODUCTS_NAME.includes(record.product.identifier.toLowerCase())
        &&
        values.configuration
        &&
        record.configuration
        &&
        values.configuration.subscription_plan_id != record.configuration.subscription_plan_id
    )
}

const isYabby = (record) => {
    return (
        record &&
        record.product &&
        YABBY_PRODUCTS_NAME.includes(record.product.identifier.toLowerCase())

    )
}

export const SaveDeviceConfigButton = (props => {

    const [create] = useCreate();
    const record = useRecordContext()
    const [update, { isLoading, error }] = useUpdate();
    const redirectTo = useRedirect();
    const translate = useTranslate();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const [plans, setPlans] = useState();
    const [openDialog, setopenDialog] = useState(false);
    const { previousData } = props;
    const { getValues } = useFormContext()


    useEffect(() => {
        dataProvider.getList('configuration_plans', {})
            .then(({ data }) => {
                setPlans(data);

            })
            .catch(error => {

            });
    }, []);

    const handleCloseDialog = () => {
        setopenDialog(false);
    };
    const handleAccept = () => {
        let values = getValues()
        setopenDialog(false);

        console.log("record ==> ", record)
        console.log("record.configuration ==> ", record.configuration)
        console.log("valuesParam.configuration.configuration ==> ", values.configuration)

        if (values.configuration && !isEqual(record.configuration, values.configuration)) {

            // edit when /events? response changes  ----------------------
            values.configuration.co2_send_interval = values.configuration.send_interval
            values.configuration.co2_extra_measurement_count = values.configuration.extra_measurement_count
            delete values.configuration.extra_measurement_count
            delete values.configuration.send_interval
            //-------------------------------------------------------------


            update(
                'device_configurations',
                {
                    id: values.configuration.device_configuration_id,
                    data: { ...values.configuration, retry_period: computeRetryPeriod(values.configuration.geolocation_period) }
                },

                {

                    onSuccess: () => {
                        notify(translate('resources.common.update_success'), 'info');
                        redirectTo("list", "/devices");
                    }
                }
            ).catch((e) => {
                console.log("e ==> ", e)
            })
            if (error) {
                console.log(error)

            }
        }



    };


    const DialogPopUp = () => {
        const values = getValues();
        if (!isYabby(record)) {
            return <div />
        } else {

            const currentPrice = record && plans && plans.filter((plan => plan.plan_id === record.configuration.yabby_configuration_plan_id)).length > 0 ? plans.filter((plan => plan.plan_id === record.configuration.yabby_configuration_plan_id))[0].price : "-"
            const currentCurrency = record && plans && plans.filter((plan => plan.plan_id === record.configuration.yabby_configuration_plan_id)).length > 0 ? plans.filter((plan => plan.plan_id === record.configuration.yabby_configuration_plan_id))[0].currency : "-"
            const newPrice = values && plans && plans.filter((plan => plan.plan_id === values.configuration.yabby_configuration_plan_id)).length > 0 ? plans.filter((plan => plan.plan_id === values.configuration.yabby_configuration_plan_id))[0].price : "-"
            const newCurrency = values && plans && plans.filter((plan => plan.plan_id === values.configuration.yabby_configuration_plan_id)).length > 0 ? plans.filter((plan => plan.plan_id === values.configuration.yabby_configuration_plan_id))[0].currency : "-"
            const translate = useTranslate();

            return (<Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"

            >
                <DialogTitle id="alert-dialog-title">
                    {newPrice > currentPrice && translate('resources.devices.config.plans.upgrading_sub_alert')}
                    {newPrice < currentPrice && translate('resources.devices.config.plans.downgrading_sub_alert')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText previousDataid="alert-dialog-description">
                        {translate('resources.devices.config.plans.switching_sub_alert')} <strong>{(currentPrice / 100).toFixed(2)} &nbsp;{currentCurrency.toUpperCase()}</strong> {translate('resources.devices.config.plans.to')} : <strong>{(newPrice / 100).toFixed(2)}&nbsp;{newCurrency.toUpperCase()}</strong>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}> {translate('resources.devices.config.plans.return')}</Button>
                    <Button onClick={handleAccept} autoFocus>
                        {translate('resources.devices.config.plans.continue')}
                    </Button>
                </DialogActions>
            </Dialog>)
        }

    }

    const handleClick = (e) => {
        e.preventDefault();
        const values = getValues();
        if (dialogRequired(record, values)) {
            setopenDialog(true);
        } else {
            handleAccept();
        }

    }

    return <div><SaveButton onClick={handleClick} /><DialogPopUp /></div>;
});


