import React from 'react'
import { useController } from 'react-hook-form'
import { Edit, email, required, Toolbar, useTranslate, SimpleForm, SaveButton, useRedirect, useLocaleState } from 'react-admin'
import PhoneInput from 'react-phone-number-input'
import withStyles from '@mui/styles/withStyles';
import { SaveAccountButton } from './SaveAccountButton'
import 'react-phone-number-input/style.css'
import { useTheme, Button, Box, Typography } from '@mui/material';
import styledFormInputs from '../../../ui/inputs/styledFormInputs';
import { useNavigate } from 'react-router-dom';
import { RO_ID } from '../../configuration/actions';
import NavigateBackBtn from '../../../ui/button/NavigateBackBtn';



const lang = [
    { id: 'fr', name: 'resources.users.lang.fr' },
    { id: 'en', name: 'resources.users.lang.en' },
    { id: 'es', name: 'resources.users.lang.es' },
]

const validateRequired = [required('Champs obligatoire')]
const validateEmail = [required('Champs obligatoire'), email('Syntaxe incorrecte')]




const AccountEditActions = () => {
    const redirect = useRedirect();
    const [locale, setLocale] = useLocaleState();
    const translate = useTranslate();
    const authServerUrl = process.env.REACT_APP_AUTH_URL;
    const updatePwdUrl = `${authServerUrl}/forgot_password?organization=${localStorage.getItem(RO_ID)}&language=${locale}`;

    return (
        <Toolbar >
            <Box sx={{ flex: 1 }} />
            <Button onClick={() => redirect(updatePwdUrl)}  >
                {translate("resources.users.change_my_password")}
            </Button>
        </Toolbar>
    )
}

const AccountEditToolbar = () => {
    return (
        <Toolbar>
            <SaveButton />
        </Toolbar>
    )
}


const AccountEdit = (() => {
    const translate = useTranslate();
    const theme = useTheme();
    const inputsData = [
        { name: 'first_name', label: translate('resources.users.first_name'), validate: validateRequired, type: "text" },
        { name: 'last_name', label: translate('resources.users.last_name'), validate: validateRequired, type: "text" },
        { name: 'email', label: translate('resources.users.email'), validate: validateEmail, type: "text", disabled: true },
        { name: 'lang_key', label: translate('resources.users.language'), validate: validateRequired, type: "select", choices: lang, },
        { name: 'organization_id', label: translate('resources.organization.organization'), validate: validateRequired, type: "reference", reference: "organizations", optionText: "name", disabled: true },
    ]


    return (

        <>
            <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "24px", lineHeight: "42px", margin: "0px 0px 16px 0px" }}>
                <NavigateBackBtn /> {translate('resources.account.edit')}
            </Typography>
            <Edit
                title={translate('resources.account.edit')}
                actions={<AccountEditActions />}
                sx={{
                    "& .MuiPaper-root": {
                        backgroundColor: theme.palette.background.paper,
                        color: theme.palette.text.primary,
                    },
                }}
            >

                <SimpleForm
                    redirect="list"
                    toolbar={<AccountEditToolbar />}
                    actions
                >

                    {styledFormInputs(inputsData)}

                </SimpleForm>

            </Edit>
        </>
    )
}
);

export default (AccountEdit)