import React from 'react'
import { Tooltip, IconButton } from '@mui/material'
import { useTranslate, useSidebarState } from 'react-admin'
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';

const SidebarToggleIcon = () => {
    const theme = useTheme();
    return (
        <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 8.41699C0 3.99871 3.58172 0.416992 8 0.416992H24C28.4183 0.416992 32 3.99871 32 8.41699V24.417C32 28.8353 28.4183 32.417 24 32.417H8C3.58172 32.417 0 28.8353 0 24.417V8.41699Z" fill={theme.palette.mode === 'light' ? theme.palette.primary.light : theme.palette.dark.dark} />
            <g clipPath="url(#clip0_237_7980)">
                <path d="M11.0938 11.417H21.7604" stroke={theme.palette.primary.main} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11.0938 16.417H21.7604" stroke={theme.palette.primary.main} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11.0938 21.417H21.7604" stroke={theme.palette.primary.main} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_237_7980">
                    <rect width="16" height="16" fill="white" transform="translate(8 8.41699)" />
                </clipPath>
            </defs>
        </svg>


    )
}

const PREFIX = 'RaSidebarToggleButton';

const SidebarToggleButtonClasses = {
    menuButtonIconClosed: `${PREFIX}-menuButtonIconClosed`,
    menuButtonIconOpen: `${PREFIX}-menuButtonIconOpen`,
};

const StyledIconButton = styled(IconButton, {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
    [`& .${SidebarToggleButtonClasses.menuButtonIconClosed}`]: {
        transition: theme.transitions.create(['transform'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        transform: 'rotate(0deg)',
    },

    [`& .${SidebarToggleButtonClasses.menuButtonIconOpen}`]: {
        transition: theme.transitions.create(['transform'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        transform: 'rotate(180deg)',
    },
}));


const customStyles = () => ({

    styledIconButton: {
        width: "32px",
        height: "32px",
        padding: "0px",
        margin: "0px 0px 0px 20px"
    },

})

const SidebarToggleButton = (props) => {
    const translate = useTranslate();
    const [open, setOpen] = useSidebarState();
    const styles = customStyles();
    return (
        <Tooltip
            className="customBtn-sidebar-toggle"
            title={translate(
                open ? 'ra.action.close_menu' : 'ra.action.open_menu',
                { _: 'Open/Close menu' }
            )}
            enterDelay={500}
        >
            <StyledIconButton color="inherit" onClick={() => setOpen(!open)} sx={styles.styledIconButton}>
                <SidebarToggleIcon
                    classes={{
                        root: open
                            ? SidebarToggleButtonClasses.menuButtonIconOpen
                            : SidebarToggleButtonClasses.menuButtonIconClosed,
                    }}
                />
            </StyledIconButton>
        </Tooltip>
    );
};


export default SidebarToggleButton