// in src/comments.js
import React from 'react';
import { hasWriteAccess } from '../../../helper/resourceHelper'
import { BooleanField, EditButton, ReferenceField, TextField, useTranslate, useListContext, TopToolbar, usePermissions, ChipField } from 'react-admin';
import { useTheme, Grid, TableBody, CardContent, Card, Typography, Avatar, Box, Chip, ButtonBase, Tooltip } from '@mui/material'
import { CardDetailRow } from '../../../ui/Common';
import { HookIcon } from '..';
import { useNavigate } from 'react-router-dom';





const HookCardHeader = ({ permissions, hook }) => {
    const translate = useTranslate();
    const theme = useTheme();

    return (
        <Box sx={{
            marginX: "12px",
            marginTop: "12px",
            marginBottom: "16px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start"

        }}>
            <Box
                sx={{
                    marginRight: "24px",

                }}
            >
                <Avatar
                    size={"45px"}
                    style={{ width: "45px", height: "45px" }}
                >
                    <HookIcon/>
                </Avatar>
            </Box>



            <TableBody sx={{
                "& .MuiTableRow-root": {
                    width: "100%",
                },
                "& .MuiTableCell-root": {

                    width: "100%",
                    margin: "0px",
                    padding: "5px",
                    borderBottomColor: theme.palette.divider,

                }
            }}>
                {hook.name
                    && (
                        <CardDetailRow label={<TextField sx={{ fontSize: "18px", fontWeight: 500 }} source="name" record={hook} />} ValueComponent={() => <div />} />
                    )
                }





            </TableBody>


        </Box>

    );
}


const HookCardContent = ({ hook }) => {
    const translate = useTranslate();


    return (

        <TableBody>
            {hook.device_id && (
                <CardDetailRow label={translate('resources.devices.name', { smart_count: 1 })} ValueComponent={() => <ReferenceField
                    allowEmpty={true}
                    source="device_id"
                    reference="devices"
                    record={hook}
                >
                    <TextField source="name" />
                </ReferenceField>} />
            )}

            {hook.organization_id && (
                <CardDetailRow label={translate('resources.organization.organization')} ValueComponent={() =>
                    <ReferenceField
                        allowEmpty
                        source="organization_id"
                        reference="organizations"
                        sortable={false}
                        record={hook}
                    >
                        <TextField source="name" />
                    </ReferenceField>} />
            )}


            {hook.events && Array.isArray(hook.events) && (
                <CardDetailRow label={translate('resources.hooks.fields.events')} ValueComponent={() =>
                    <Box>

                        {
                            hook.events.map((event, idx) => {
                                if (idx < 1) return (<ChipField
                                    key={idx}
                                    sx={{ fontSize: "10px" }}
                                    record={{event:translate(`resources.hooks.${event}`)}}
                                    source={ "event"}

                                />)
                                if (idx === 1) return (<ChipField
                                    key={idx}
                                    sx={{ fontSize: "10px" }}
                                    record={{ v: "..." }}
                                    source={"v"}
                                />
                                ) 

                            })
                              

                        }
                    </Box>
                }
                />
            )}

            {hook.target_url && (
                <CardDetailRow label={translate('resources.hooks.fields.target_url')} ValueComponent={() => <Typography sx={{fontSize:"12px", width:"100px"}}>{hook.target_url.length > 30 ? hook.target_url.substring(0, 30) + '...' : hook.target_url}</Typography>} />
            )}
            {hook.version && (
                <CardDetailRow label={translate('resources.hooks.fields.version')} ValueComponent={() => <TextField source="version" record={hook}></TextField>} />
            )}
          

            {(hook.active ===true ||hook.active ===false ) && (
                <CardDetailRow label={translate('resources.hooks.fields.active')} ValueComponent={() => <BooleanField
                    record={hook}
                    source="active"
                />} />
            )}


        </TableBody>

    );
}

const hookIsInactive = (hook) => {
    return (!hook ||
        hook.active !== true)
    

}

const MobileGrid =({ isSmall }) => {

    const { data, isLoading } = useListContext();
    const theme = useTheme();
    const permissionsHook = usePermissions();
    const isLoadingPermissions = permissionsHook.isLoading;
    const permissions = permissionsHook.permissions;
    const translate = useTranslate();
    const navigate = useNavigate()
    const inactiveBg = theme.palette.mode === "light" ? ["linear-gradient(0deg,#FFFFFF,#FFFFFF)", `linear-gradient(180deg, rgba(216, 67, 21, 0.2) 0%, rgba(255, 255, 255, 0.2) 38.3%)`] : ["linear-gradient(0deg, #111936, #111936)", `linear-gradient(180deg, rgba(216, 67, 21, 0.2) 0%, rgba(33, 41, 70, 0.2) 38.3%);`]
   

    if (isLoading || (data?.length <= 0)) return null;


    return (

        <Grid
        container
        spacing={isSmall ? 0 : 1}
        sx={{
            margin: '0px',
            width: "100%",
            backgroundColor: theme.palette.background.default,
        }}
        >
            {data && data.map((hook, index) => (
                <Grid item sm={12} md={3} sx={{
                    width: "100%",
                    margin: isSmall ? "8px 0px 0px 0px" : '0px',
                }} >
                    <Card key={index}
                        sx={{
                            height: '100%',
                            display: 'flex',
                            borderRadius: "12px",
                            flexDirection: 'column',
                            margin: '0px',
                            justifyContent: "space-between",
                            background:hookIsInactive(hook) ? inactiveBg : null,
                        }}

                    >
                        <Tooltip title={translate(`resources.hooks.browse_hook`)}>
                            <ButtonBase
                                onClick={() => { navigate(`/hooks/${hook.hook_id}`) }}

                                sx={{ display: 'flex', width: "100%" }}
                            >

                                <CardContent sx={{
                                    ...theme.typography.body1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: "space-between",
                                    padding: "8px"
                                }}>
                                    
                                    <HookCardHeader hook={hook} permissions={permissions} />
                                    <HookCardContent hook={hook} />
                                </CardContent>
                            </ButtonBase>
                        </Tooltip>

                    </Card>
                </Grid>
            ))}
        </Grid>
    )


}




export default MobileGrid