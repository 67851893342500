import React, { useEffect, useState } from 'react'
import { useController } from 'react-hook-form'
import {
    Edit,
    email,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
    useRecordContext,
    useTranslate,
} from 'react-admin'
import { ROLE_ORGA_ADMIN, ROLE_ORGA_USER, ROLE_USER, USER_ID, USER_ORGANIZATION_ID } from '../../configuration/actions'
import { isAdminPermissions, isSuperAdmin } from '../../../helper/resourceHelper'
import PhoneInput from 'react-phone-number-input'
import withStyles from '@mui/styles/withStyles';
import { TableBody, TableCell, TableRow, useTheme, Button, Typography } from '@mui/material';
import styledFormInputs from '../../../ui/inputs/styledFormInputs'
import StandardDialog from '../../../ui/dialog/StandardDialog'
import { useFormContext, useWatch } from 'react-hook-form';
import NavigateBackBtn from '../../../ui/button/NavigateBackBtn'
import { getUserIdFromUrl } from '../../../utils/utils'



const validateRequired = [required('Champs obligatoire')]
const validateEmail = [required('Champs obligatoire'), email('Syntaxe incorrecte')]



const PhoneNumberInput = ({ value, onChange }) => {
    const translate = useTranslate();
    return (
        <PhoneInput
            country="FR"
            placeholder={translate('resources.users.phoneNumber')}
            value={value}
            onChange={onChange}
            style={{
                width: '220px',
                marginTop: '8px',
                marginBottom: '8px'
            }}
        />
    )
}


const PhoneNumberField = ({ source }) => {

    const input = useController({ name: source, defaultValue: '' });
    return <PhoneNumberInput {...input.field} />
}

const roleChoices = [
    { id: ROLE_ORGA_USER, name: 'resources.role.member' },
    { id: ROLE_ORGA_ADMIN, name: 'resources.role.admin' },
    { id: ROLE_USER, name: 'resources.role.owner' },
]


const ConfirmUserOrganizationUpdateHelper = ({ }) => {
    const { getValues, setValue } = useFormContext();
    const translate = useTranslate();
    const [openDialog, setOpenDialog] = useState(false);
    const selectedOrganizationId = useWatch({ name: 'organization_id' });
    const selectedConfirmEditOrganization = useWatch({ name: 'confirm_edit_organization' });
    const record = useRecordContext()


    useEffect(() => {
        setTimeout(() => {
            setValue('confirm_edit_organization', false);
        }, 1000);
    }, [getValues, setValue]);


    useEffect(() => {
        if (record &&
            record.organization_id &&
            selectedOrganizationId &&
            selectedConfirmEditOrganization === false &&
            record.organization_id !== selectedOrganizationId
        ) {
            setOpenDialog(true)
        }

    }, [selectedOrganizationId, selectedConfirmEditOrganization, getValues, setValue]);

    return (
        <StandardDialog
            open={openDialog}
            fullWidth={true}
            onClose={() => { setOpenDialog(false); }}
            BodyComponent={() => {

                return (
                    <TableBody>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell colSpan={4} align="left" sx={{ padding: "0px", fontWeight: 500 }}>
                                {translate('resources.users.confirm_edit_organization_message')}
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell align="center" sx={{ padding: "0px", fontWeight: 500 }}>
                                <Button onClick={() => { setValue('organization_id', record.organization_id); setOpenDialog(false); }} sx={{ margin: "25px 10px  0px 0px", }}>{translate('resources.common.cancel')}</Button>
                            </TableCell>
                            <TableCell align="center" sx={{ padding: "0px", fontWeight: 500 }}>
                                <Button onClick={() => { setValue('confirm_edit_organization', true); setOpenDialog(false); }} variant='contained' sx={{ margin: "25px 0px  0px 10px", }}>
                                    {translate('resources.common.i_confirm')}
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                )
            }}
        />
    );
};


const UserEdit = () => {
    const translate = useTranslate();
    const theme = useTheme();

    const isCurrentUser = () => {
        const userId = localStorage.getItem(USER_ID);
        const currentUserId = getUserIdFromUrl();
        return currentUserId.length > 0 && userId === currentUserId
    }


    const inputsData = [
        { name: 'company_name', label: translate('resources.users.company_name'), type: "text" },
        { name: 'first_name', label: translate('resources.users.first_name'), validate: validateRequired, type: "text" },
        { name: 'last_name', label: translate('resources.users.last_name'), validate: validateRequired, type: "text" },
        { name: 'email', label: translate('resources.users.email'), validate: validateEmail, type: "text", disabled: true },
    ]

    if (!isCurrentUser() && isAdminPermissions()) {
        inputsData.push({ name: 'role', label: translate('resources.users.role'), validate: validateRequired, type: "select", choices: roleChoices, });
        inputsData.push({ name: 'organization_id', label: translate('resources.organization.organization'), validate: validateRequired, type: "reference", reference: "organizations", optionText: "name" });
    }

    return (
        <>
            <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "24px", lineHeight: "42px", margin: "0px 0px 16px 0px" }}>
                <NavigateBackBtn /> {translate('resources.users.edit')}
            </Typography>
            <Edit title={translate('resources.users.edit')}
                sx={{
                    "& .MuiPaper-root": {
                        backgroundColor: theme.palette.background.paper,
                        color: theme.palette.text.primary,
                    },
                }}
            >

                <SimpleForm redirect="list">

                    {styledFormInputs(inputsData)}
                    <PhoneNumberField source="phone_number" />
                    <ConfirmUserOrganizationUpdateHelper />

                </SimpleForm>
            </Edit>
        </>
    )
}
export default UserEdit
