import { getValueForNestedKey } from './utils'



export const calculateLocations = ({ data, page, perPage, latKey, longKey, timestampKey, altKey, accuracyKey, iconUrlKey, formattedAddressKey, nameKey }) => {


    let minLat, minLong, maxLat, maxLong
    let locations = []
    let bounds = undefined


    if ((!data) || (data?.length === 0)) {

        return { bounds, locations }
    }

    data.forEach((record, idx) => {
        const lat = getValueForNestedKey(record, latKey)
        const lng = getValueForNestedKey(record, longKey)
        const timestamp = getValueForNestedKey(record, timestampKey);
        const alt = getValueForNestedKey(record, altKey);
        const accuracy = getValueForNestedKey(record, accuracyKey);
        const iconUrl = getValueForNestedKey(record, iconUrlKey);
        const name = getValueForNestedKey(record, nameKey);
        const formattedAddress = getValueForNestedKey(record, formattedAddressKey);

        if (lat !== null && lng !== null && timestamp !== null) {
            locations.push({ id: record.id, number: idx + 1 + ((page - 1) * perPage), lat: lat, lng: lng, timestamp: timestamp, alt: alt, accuracy: accuracy, formattedAddress: formattedAddress, meta: { name: name, iconUrl: iconUrl }, record: record, hasLocation: true })
            if (!minLat || minLat > lat) {
                minLat = lat
            }
            if (!minLong || minLong > lng) {
                minLong = lng
            }
            if (!maxLat || maxLat < lat) {
                maxLat = lat
            }
            if (!maxLong || maxLong < lng) {
                maxLong = lng
            }
        } else {
            locations.push({ id: record.id, record: record, hasLocation: false })

        }

    })


    if (minLat && minLong && maxLat && maxLong) {

        bounds = [[minLat, minLong], [maxLat, maxLong]]


    }

    return { bounds, locations }
}

