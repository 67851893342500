import React from 'react';
import compose from 'recompose/compose'
import PropTypes from 'prop-types';
import ContentSettings from '@mui/icons-material/Settings';
import { Link } from 'react-router-dom';
import { linkToRecord, useLocaleState, useRecordContext, useResourceContext, useTranslate, useUpdate } from 'react-admin';
import { Button, Tooltip, useTheme } from '@mui/material'
import { useRedirect } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { listActionsColumnIconCommonStyle } from '../../themes/commonStyles';
import { downloadAppOwnerInvoice } from '../../rest/restClient';



const Icon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-download">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
            <path d="M7 11l5 5l5 -5" />
            <path d="M12 4l0 12" />
        </svg>
    )
}
const AppOwnerInvoiceDownloadButton = ({
    basePath = '',
    disabled,
    ...rest
}) => {
    const translate = useTranslate();
    const theme = useTheme();
    const record = useRecordContext();
    const [locale, setLocale] = useLocaleState()
    const isLightMode = theme.palette.mode === 'light';
    const disabledColor = isLightMode ? theme.palette.grey[400] : theme.palette.grey[500];
    if (!record || record.app_owner_invoice_id === undefined) {
        return null
    }
    return (

        <Tooltip title={disabled ? "" : translate(`resources.app_owner_invoices.download`)} disableInteractive >

            <Button
                onClick={(e) => {
                    e.preventDefault();
                    downloadAppOwnerInvoice({ invoiceId: record.app_owner_invoice_id, locale })
                        .then(res => {
                            if (res && res.json && res.json.link) {
                                window.open(res.json.link, '_blank')
                            }
                        }
                        )
                        .catch(err => { })
                }}
                style={listActionsColumnIconCommonStyle({ isLastItem: false })}>
                <Icon color={disabled ? disabledColor : theme.palette.primary.main} />
            </Button>
        </Tooltip>
    );
}



export default AppOwnerInvoiceDownloadButton;