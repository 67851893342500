import React from 'react'

import {
    Show,
    required,
    SimpleForm,
    useTranslate,
} from 'react-admin'
import { Typography, useTheme } from '@mui/material';
import styledFormInputs from '../../../ui/inputs/styledFormInputs';
import { isSuperAdmin } from '../../../helper/resourceHelper';



const validateRequired = [required('Champs obligatoire')]


const DeviceSubPlanShow = ({ classes, ...props }) => {
    const translate = useTranslate();
    const theme = useTheme();

    const inputsDataGeneral = [
        { name: 'title_fr', label: translate('resources.device_sub_plans.title_fr'), validate: validateRequired, type: "text" },
        { name: 'description_fr', label: translate('resources.device_sub_plans.description_fr'), validate: validateRequired, width: "480px", type: "text" },
        { name: 'title_en', label: translate('resources.device_sub_plans.title_en'), validate: validateRequired, type: "text" },
        { name: 'description_en', label: translate('resources.device_sub_plans.description_en'), validate: validateRequired, width: "480px", type: "text" },
        { name: 'title_es', label: translate('resources.device_sub_plans.title_es'), validate: validateRequired, type: "text" },
        { name: 'description_es', label: translate('resources.device_sub_plans.description_es'), validate: validateRequired, width: "480px", type: "text" },
        { name: 'root_organization_id', label: translate('resources.device_sub_plans.root_organization_id'), validate: validateRequired, type: "reference", reference: "root_organizations", optionText: "name" },
        { name: 'is_default', label: translate('resources.device_sub_plans.is_default'), validate: validateRequired, type: "boolean" },
    ]

    const inputsDataPricing = [
        { name: 'amount', label: translate('resources.device_sub_plans.amount'), validate: validateRequired, type: "number" },
        { name: 'tax_percent', label: translate('resources.device_sub_plans.tax_percent'), validate: validateRequired, type: "number" },
        { name: 'currency', label: translate('resources.device_sub_plans.currency'), validate: validateRequired, type: "select", choices: [{ id: 'eur', name: 'EUR' }, { id: 'aud', name: 'AUD' }, { id: 'nzd', name: 'NZD' }, { id: 'usd', name: 'USD' }] },
        { name: 'type', label: translate('resources.device_sub_plans.type'), validate: validateRequired, type: "select", choices: [{ id: 'prepaid', name: 'PREPAID' }, { id: 'postpaid', name: 'POSTPAID' }, { id: 'pro', name: 'PRO' }] },
        { name: 'frequency', label: translate('resources.device_sub_plans.frequency'), validate: validateRequired, type: "select", choices: [{ id: 'day', name: 'DAY' }, { id: 'week', name: 'WEEK' }, { id: 'month', name: 'MONTH' }, { id: 'year', name: 'YEAR' }] },
        { name: 'frequency_interval', label: translate('resources.device_sub_plans.frequency_interval'), validate: validateRequired, type: "number" },
        { name: 'trial_period_days', label: translate('resources.device_sub_plans.trial_period_days'), validate: validateRequired, type: "number" },
        { name: 'payment_method_type', label: translate('resources.device_sub_plans.payment_method_type'), validate: validateRequired, type: "select", choices: [{ id: 'card', name: 'CARD' }, { id: 'sepa_debit', name: 'SEPA_DEBIT' }] },
    ]

    const inputsDataAppOwnerFees = [
        { name: 'app_owner_percent_fees', label: translate('resources.device_sub_plans.app_owner_percent_fees'), validate: validateRequired, type: "number" },
        { name: 'app_owner_fixed_fees', label: translate('resources.device_sub_plans.app_owner_fixed_fees'), validate: validateRequired, type: "number" },
    ]

    const inputsDataStripe = [
        { name: 'stripe_price_id', label: translate('resources.device_sub_plans.stripe_price_id'), validate: validateRequired, type: "text" },
        { name: 'stripe_tax_id', label: translate('resources.device_sub_plans.stripe_tax_id'), validate: validateRequired, type: "text" },
    ]


    return (
        <Show
            redirect="list"
            title={'resources.device_sub_plans.show'}
            sx={{
                "& .MuiPaper-root": {
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                },
                marginTop: '20px'
            }}
        >

            <SimpleForm >

                <Typography color="text.main" sx={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "25px",
                    textAlign: "center",
                    margin: "0px 0px 10px 0px"
                }}
                >
                    {translate('resources.device_sub_plans.sections.general')}
                </Typography>
                {styledFormInputs(inputsDataGeneral)}
                <Typography color="text.main" sx={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "25px",
                    textAlign: "center",
                    margin: "20px 0px 10px 0px"
                }}
                >
                    {translate('resources.device_sub_plans.sections.pricing')}
                </Typography>
                {styledFormInputs(inputsDataPricing)}
                {isSuperAdmin() && <Typography color="text.main" sx={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "25px",
                    textAlign: "center",
                    margin: "20px 0px 10px 0px"
                }}
                >
                    {translate('resources.device_sub_plans.sections.app_owner_fees')}
                </Typography>}
                {isSuperAdmin() && styledFormInputs(inputsDataAppOwnerFees)}

                {isSuperAdmin() && <Typography color="text.main" sx={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "25px",
                    textAlign: "center",
                    margin: "20px 0px 10px 0px"
                }}
                >
                    {translate('resources.device_sub_plans.sections.stripe')}
                </Typography>}
                {isSuperAdmin() && styledFormInputs(inputsDataStripe)}


            </SimpleForm>
        </Show>
    )
}

export default DeviceSubPlanShow



