import { Box, Typography, IconButton, useTheme } from "@mui/material"
import ViewListIcon from '@mui/icons-material/ViewList';
import GridViewIcon from '@mui/icons-material/GridView';
import {  useTranslate } from "react-admin";
import { IconList as TableModeIcon } from '@tabler/icons-react';
import { IconLayoutGrid as CardModeIcon } from '@tabler/icons-react';



export const LIST_TYPE_CARDS = "0"
export const LIST_TYPE_TABLE = "1"

export const ListTypeButton = ({ listFormat, setListFormat }) => {
    const theme = useTheme();
    const translate = useTranslate()
    return (
        <Box sx={{
            padding: "0px",
            marginRight: "20px",
        }}>

            <IconButton

                sx={{
                    padding: "4px",
                }}
                onClick={() => {
                    if (listFormat === LIST_TYPE_CARDS) {
                        setListFormat(LIST_TYPE_TABLE)
                    }
                    if (listFormat === LIST_TYPE_TABLE) {
                        setListFormat(LIST_TYPE_CARDS)
                    }
                }}>
                {
                    (listFormat === LIST_TYPE_TABLE) &&
                    <CardModeIcon sx={{ color: theme.palette.primary.dark, padding: "0px", marginRight: "8px", marginLeft: "2px", height: "18px", width: "18px" }} />
                }

                {
                    (listFormat === LIST_TYPE_CARDS) &&
                    <TableModeIcon sx={{ color: theme.palette.primary.dark, padding: "0px", marginRight: "8px", marginLeft: "2px", height: "18px", width: "18px" }} />
                }

            </IconButton>

        </Box>

    )


}