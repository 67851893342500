import React from 'react'
import { useController } from 'react-hook-form'
import {
    Create,
    email,
    minLength,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
    useNotify,
    useRedirect,
    useTranslate
} from 'react-admin'
import { ROLE_ORGA_ADMIN, ROLE_ORGA_USER, ROLE_USER, USER_ORGANIZATION_ID } from '../../configuration/actions'
import PhoneInput from 'react-phone-number-input'
import { Typography, useTheme } from '@mui/material';
import styledFormInputs from '../../../ui/inputs/styledFormInputs'
import NavigateBackBtn from '../../../ui/button/NavigateBackBtn'



const validateRequired = [required('Champs obligatoire')]
const validateEmail = [required('Champs obligatoire'), email('Syntaxe incorrecte')]
const validateMinLength = [
    required('Champs obligatoire'),
    minLength(6, 'La longueur de la données doit etre >= 6'),
]





const PhoneNumberInput = ({ value, onChange }) => {
    const translate = useTranslate();
    return (
        <PhoneInput
            country="FR"
            placeholder={translate('resources.users.phoneNumber')}
            value={value}
            onChange={onChange}
            style={{
                width: '220px',
                marginTop: '8px',
                marginBottom: '8px'
            }}
        />
    )
}


const PhoneNumberField = ({ source }) => {

    const input = useController({ name: source, defaultValue: '' });
    return <PhoneNumberInput {...input.field} />
}



const UserCreate = () => {
    const translate = useTranslate();
    const theme = useTheme();
    const notify = useNotify();
    const redirect = useRedirect();
    const onSuccess = () => {
        notify('resources.users.creation_success');
        redirect('/users');
    }

    const inputsData = [
        { name: 'company_name', label: translate('resources.users.company_name'), type: "text" },
        { name: 'first_name', label: translate('resources.users.first_name'),validate: validateRequired, type: "text" },
        { name: 'last_name', label: translate('resources.users.last_name'),validate: validateRequired, type: "text" },
        { name: 'email', label: translate('resources.users.email'), validate: validateEmail, type: "text" },
        { name: 'password', label: translate('resources.users.password'),validate: validateMinLength, type: "password" },
        { name: 'cpassword', label: translate('resources.users.cpassword'),validate: validateRequired, type: "password" },
        { name: 'role', label: translate('resources.users.role'), validate: validateRequired, type: "select", choices: roleChoices, },
        { name: 'organization_id', label: translate('resources.organization.organization'), validate: validateRequired, type: "reference", reference: "organizations", optionText: "name" },
    ]



    return (
        <>
            <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "24px", lineHeight: "42px", margin: "0px 0px 16px 0px" }}>
                <NavigateBackBtn /> {translate('resources.users.create')}
            </Typography>
        <Create 
        title={'resources.users.create'}
        mutationOptions={{ onSuccess }}
        >
            <SimpleForm redirect="list">
            {styledFormInputs(inputsData)}

            
                <PhoneNumberField source="phone_number" />
                
            </SimpleForm>
        </Create>
        </>
    )
}

const roleChoices = [
    { id: ROLE_ORGA_USER, name: 'resources.role.member' },
    { id: ROLE_ORGA_ADMIN, name: 'resources.role.admin' },
    { id: ROLE_USER, name: 'resources.role.owner' },
]

export default UserCreate