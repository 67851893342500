import React, { useRef, useState } from 'react';
import { useLocaleState, useRecordContext, useTranslate } from 'react-admin';
import {Box, Button,Link, Tooltip, useTheme } from '@mui/material'
import { listActionsColumnIconCommonStyle } from '../../themes/commonStyles';
import { downloadInvoice } from '../../rest/restClient';
import { useEffect } from 'react';
import StandardDialog from '../dialog/StandardDialog';




const Icon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-download">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
            <path d="M7 11l5 5l5 -5" />
            <path d="M12 4l0 12" />
        </svg>
    )
}



const InvoiceDownloadButton = ({
    basePath = '',
    recordP = null,
    disabled,
    ...rest
}) => {
    const translate = useTranslate();
    const theme = useTheme();
    const record = recordP ? recordP : useRecordContext();
    const [locale, setLocale] = useLocaleState()
    const [downloadFileUrl, setDownloadFileUrl] = useState(null);
    const isLightMode = theme.palette.mode === 'light';
    const disabledColor = isLightMode ? theme.palette.grey[400] : theme.palette.grey[500];
    if (!record || record.invoice_id === undefined) {
        return null
    }



    return (

        <Tooltip title={disabled ? "" : translate(`resources.invoices.download`)} disableInteractive >

            <Button
                onClick={(e) => {
                    e.preventDefault();

                    downloadInvoice({ invoiceId: record.invoice_id, locale })
                        .then(res => {
                            if (res && res.json && res.json.link) {
                                setDownloadFileUrl(res.json.link)
                            }
                        })
                        .catch(err => { })
                }}
                style={listActionsColumnIconCommonStyle({ isLastItem: true   })}>
                <Icon color={disabled ? disabledColor : theme.palette.primary.main} />
            </Button>
             <StandardDialog  open={downloadFileUrl!==null} fullWidth={false} bodyText={translate(`resources.invoices.click_button_bellow_to_download_you_invoice_number`,{invoice_number:record.invoice_number})} BodyComponent={() => <Link underline="none" href={downloadFileUrl} sx={{backgroundColor:theme.palette.primary.dark,color: "white", padding:"16px",  borderRadius:"8px", fontSize:"14px", fontWeight:700 }}>{translate(`resources.invoices.download`)}</Link> }   />
        </Tooltip>
    );
}



export default InvoiceDownloadButton;