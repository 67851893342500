import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material';
import { useNotify, useTranslate, useRedirect, useLocaleState } from 'react-admin'
import CheckDevice from './CheckDevice'
import AttachDevice from './AttachDevice'
import SelectPlan from './SelectPlan'
import SelectPlanType from './SelectPlanType';
import {
    addVoucherSubscription,
    deviceAttach,
    deviceToActivate,
    getPlans,
    searchVoucher,
    checkoutSession,
    deviceToSubscribe,
} from '../../../rest/restClient'
import { Wizard } from '../../../ui/form/WizardForm';
import { RO_ID } from '../../configuration/actions'
import { useLocation } from 'react-router-dom';



function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}


const hasActiveSubscription = device => {
    return (
        (device.last_subscription &&
            device.last_subscription.status === 'active' &&
            device.last_subscription.end_date &&
            new Date() < new Date(device.last_subscription.end_date)) ? true : false
    )
}

function getMiddleElement(arr) {
    const middleIndex = Math.floor(arr.length / 2);
    if (arr.length % 2 === 0) {
        // If array length is even, return the element to the left of the middle
        return arr[middleIndex - 1];
    } else {
        // If array length is odd, return the middle element
        return arr[middleIndex];
    }
}


const PostpaidPlansExists = (plans) => {
    return plans && plans && plans.filter(plan => plan.type.toLowerCase() === 'postpaid').length > 0

}

const PrepaidPlansExists = (plans) => {
    return plans && plans && plans.filter(plan => plan.type.toLowerCase() === 'prepaid').length > 0

}




const DeviceCreate = () => {

    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();
    const [locale, setLocale] = useLocaleState();
    const query = useQuery();
    const [loading, setLoading] = useState(true);
    const [initialValues, setInitialValues] = useState({});
    const [initialPage, setInitialPage] = useState(0);

    useEffect(() => {
        if (query.get("device_id") && query.get("device_id").length > 3) {
            handleCheckDevice({ deviceId: query.get("device_id") }).then(res => {
                if (res && res.success === true && res.values) {
                    setInitialValues({ ...res.values })
                    setInitialPage(1)
                    setLoading(false)
                }
            }
            )
        } else {
            setLoading(false)
        }
    }, [])


    const handleCheckDevice = async (values) => {
        let deviceFetch = null
        let deviceIdentifier = null

        if (values && values.deviceId) {
            deviceFetch = deviceToSubscribe;
            deviceIdentifier = values.deviceId;
        }

        if (values && values.deviceSn) {
            deviceFetch = deviceToActivate;
            deviceIdentifier = values.deviceSn;
        }


        return new Promise((resolve, reject) => {
            if (deviceFetch && deviceIdentifier) {

                deviceFetch(deviceIdentifier)
                    .then((response) => {
                        const device = response.json
                        const rootOrganizationId = device.organization && device.organization.root_organization_id ? device.organization.root_organization_id : null;

                        if (rootOrganizationId === null) {
                            notify(translate('resources.devices.error_verifing_device'), 'error')
                            reject({ success: false })
                        }

                        if (rootOrganizationId != localStorage.getItem(RO_ID)) {
                            notify(translate('resources.devices.not_found'), 'warning')
                            reject({ success: false })
                        }
                        if (device.last_subscription && device.last_subscription.status === 'active') {
                            notify(translate('resources.devices.available'), 'info')
                            //setPage(ATTACH_DEVICE, values, { device: device })
                            resolve({ success: true, values: { ...values, device: device, forbiddenPages: [1, 2] } })
                        } else {

                            getPlans(device.device_id, locale).then(response => {

                                if (response && response.json && response.json._embedded && response.json._embedded.device_sub_plans) {
                                    const plans = response.json._embedded.device_sub_plans
                                    const defaultSelectedPlanType = PostpaidPlansExists(plans) ? "postpaid" : PrepaidPlansExists(plans) ? "prepaid" : "voucher"
                                    resolve({ success: true, values: { ...values, device: device, plans: plans, selectedPlanType: defaultSelectedPlanType, forbiddenPages: [] } })
                                } else {
                                    notify(translate('resources.plans.not_found'), 'warning')
                                    reject({ success: false })
                                }

                            }).catch(response => {

                                switch (response.status) {
                                    case 400:
                                        notify(translate('resources.plans.not_found'), 'warning')
                                        reject({ success: false })
                                    case 404:
                                        notify(translate('resources.plans.not_found'), 'warning')
                                        reject({ success: false })
                                    default:
                                        notify(
                                            `${translate('resources.internal_error')}: (${response.status
                                            })`,
                                            'warning'
                                        )
                                        reject({ success: false })
                                }
                            })

                        }
                    })
                    .catch(response => {
                        switch (response.status) {
                            case 400:
                                notify(translate('resources.devices.already_attached'), 'warning')
                                reject({ success: false })

                            case 404:
                                notify(translate('resources.devices.not_found'), 'warning')

                                reject({ success: false })
                            default:
                                notify(
                                    `${translate('resources.internal_error')}: ${response.body.description} (${response.status
                                    })`,
                                    'warning'
                                )
                                reject({ success: false })
                        }
                    })
            } else {
                notify(translate('resources.devices.not_found'), 'warning')
                reject({ success: false })
            }

        });


    }

    const handleSelectPlanType = (values) => {

        return new Promise((resolve, reject) => {
            if (!values.selectedPlanType) {
                notify(translate('resources.subscriptions.no_selected_plan_type'), 'warning')
                reject({ success: false })
            } else {
                if (values.selectedPlanType === "voucher") {
                    values.selectedPlan = null
                    resolve({ success: true, values })
                }
                if (values.selectedPlanType === "prepaid") {
                    const plansBySelectedType = values.plans && values.plans.filter(plan => plan.type === 'prepaid').sort((a, b) => a.amount - b.amount)
                    values.voucher = null
                    values.plansBySelectedType = plansBySelectedType
                    values.selectedPlan = getMiddleElement(plansBySelectedType)
                    resolve({ success: true, values })
                }
                if (values.selectedPlanType === "postpaid") {
                    const plansBySelectedType = values.plans && values.plans.filter(plan => plan.type === 'postpaid').sort((a, b) => a.amount - b.amount)
                    values.voucher = null
                    values.plansBySelectedType = plansBySelectedType
                    values.selectedPlan = getMiddleElement(plansBySelectedType)
                    resolve({ success: true, values })
                }
                reject({ success: false })

            }
        })


    }

    const handleSelectPlan = (values) => {

        return new Promise((resolve, reject) => {

            if (values.selectedPlanType === "voucher" && values.voucher) {

                const { code } = values.voucher
                if (code.length === 10) {
                    searchVoucher(code)
                        .then(response => {
                            const { json } = response
                            values.selectedPlan = null
                            values.voucher = json
                            resolve({ success: true, values })

                        })
                        .catch(response => {
                            switch (response.status) {
                                case 400:
                                    notify(
                                        translate('resources.subscriptions.voucher_already_burnt'),
                                        'warning'
                                    )
                                    break
                                case 404:
                                    notify(
                                        translate('resources.subscriptions.voucher_not_found'),
                                        'warning'
                                    )
                                    break
                                default:
                                    notify(
                                        `${translate('resources.internal_error')}: ${response.body.description} (${response.status
                                        })`,
                                        'error'
                                    )

                            }

                            reject({ success: false })
                        })
                } else {
                    notify(translate('resources.subscriptions.voucher_length_must_be_gt_10'), 'warning')
                    reject({ success: false })
                }

            } else if (values.selectedPlan) {
                resolve({ success: true, values })
            } else {

                notify(translate('resources.subscriptions.no_selected_plan'), 'warning')
                reject({ success: false })


            }

        })
    }

    const handleAttachDevice = async (values) => {

        return new Promise(async (resolve, reject) => {

            const { voucher, selectedPlan, selectedPlanType, device } = values

            if (hasActiveSubscription(device)) {
                attachDevice(device)
            } else if (selectedPlanType !== "voucher" && selectedPlan) {

                checkoutSession({
                    device_id: device.device_id,
                    plan_id: selectedPlan.plan_id,
                    payment_method_types: [selectedPlan.payment_method_type],
                    checkout_success_actions: ["ATTACH_DEVICE_TO_USER", "CREATE_DEVICE_SUBSCRIPTION", "CREATE_INVOICE"]
                })
                    .then((response) => {
                        if (response.json && response.json.session_url) {
                            const sessionUrl = response.json.session_url
                            window.open(sessionUrl, "_self");
                            resolve({ success: true, values })
                        } else {
                            notify(translate('resources.subscriptions.checkout_session_error'), 'warning')
                            reject({ success: false })
                        }

                    })
                    .catch(error => {
                        notify(translate('resources.subscriptions.checkout_session_error'), 'warning')
                        reject({ success: false })

                    })
            } else if (voucher && voucher.code) {
                let subscription = { device_id: device.device_id }
                subscription['voucher_code'] = voucher.code

                addVoucherSubscription(subscription)
                    .then(() => {
                        attachDevice(device)
                    })
                    .catch(response => {
                        notify(
                            `${translate('resources.internal_error')}: ${response.body.description} (${response.status
                            })`,
                            'warning'
                        );
                        redirect('list', 'devices');
                    })
            }
        })
    }


    const attachDevice = (device) => {
        const attach = {
            name: device.name,
            type: device.type,
            serial_number: device.serial_number,
        }


        deviceAttach(attach)
            .then(() => {
                notify(translate('resources.devices.attached'), 'info')
                redirect('list', 'devices');
            })
            .catch(response => {
                notify(
                    `${translate('resources.internal_error')}: ${response.body.description} (${response.status
                    })`,
                    'warning'
                )
                redirect('list', 'devices');
            })

    }

    if (loading) return <div></div>
    return (

        <Box>
            <Wizard
                initialValues={initialValues}
                initialPage={initialPage}
                onSubmit={handleAttachDevice}
                submitBtnlabel={translate('resources.devices.attach')}
                title={translate('resources.devices.activate_new_device')}
            >
                <CheckDevice next={handleCheckDevice} label={translate('resources.devices.select_device_information')} />

                <SelectPlanType next={handleSelectPlanType} label={translate('resources.devices.select_offer_type')} />

                <SelectPlan next={handleSelectPlan} label={translate('resources.devices.select_offer')} />

                <AttachDevice label={translate('resources.devices.review_info')} />

            </Wizard>
        </Box>
    )
}


export default DeviceCreate