import React, { useState } from 'react'
import { useTranslate, useRecordContext } from 'react-admin'
import withStyles from '@mui/styles/withStyles';
import Chip from '@mui/material/Chip'
import { green, red } from '@mui/material/colors'
import { useTheme } from '@mui/material'
import { getNumberOfDaysSinceLastSeen } from '../../../helper/resourceHelper'
import { Box, Typography } from '@mui/material'
import { ca } from 'date-fns/locale';



const ChipBaterryGoodStatus = () => {
    const theme = useTheme();
    const translate = useTranslate();
    return (

        <Chip
            label={translate('resources.devices.battery_status.good')}
            size="small"
            sx={{
                height: "24px",
                width: "65px",
                fontSize: "10px",
                fontWeight: 500,
                bgcolor: 'success.light',
                color: 'success.dark'
            }}
        />
    )
}

const ChipBaterryBadStatus = () => {
    const theme = useTheme();
    const translate = useTranslate();
    return (

        <Chip
            label={translate('resources.devices.battery_status.bad')}
            size="small"
            sx={{
                height: "24px",
                width: "65px",
                fontSize: "10px",
                fontWeight: 500,
                bgcolor: 'error.light',
                color: 'error.dark'
            }}
        />
    )
}


const chipChipBaterryPercentColor = (value) => {
    const theme = useTheme();

    if (value >= 0 && value <= 30) {
        return ({
            main: 'error.dark',
            bg: 'error.light',
        })
    } else if (value >= 30 && value <= 60) {
        return ({
            main: theme.palette.mode === 'light' ? theme.palette.grey[900] : theme.palette.common.black,
            bg: 'warning.light',
        })
    } else if (value >= 60 && value <= 100) {
        return ({
            main: 'success.dark',
            bg: 'success.light',
        })
    }
    return ({
        main: 'success.dark',
        bg: 'success.light',
    })
}
const ChipBaterryPercent = ({ value }) => {
    const color = chipChipBaterryPercentColor(value)
    return (
        <Chip
            label={`${value}%`}
            size="small"
            sx={{
                height: "24px",
                width: "65px",
                fontSize: "10px",
                fontWeight: 500,
                bgcolor: color.bg,
                color: color.main
            }}
        />
    )
}



const ChipBaterryMillivolt = ({ value }) => {
    const theme = useTheme();
    return (

        <Chip
            label={`${value} mV`}
            size="small"
            sx={{
                height: "24px",
                width: "65px",
                fontSize: "10px",
                fontWeight: 500,
                bgcolor: theme.palette.mode === 'light' ? 'background.default' : 'dark.main',
                color: 'text.dark'
            }}
        />
    )
}



export const Battery = ({ record }) => {
    const translate = useTranslate();
    const [open, setOpen] = useState(false)
    const handleClick = () => {
        setOpen(!open)
    }
    if (record.last_battery && record.last_battery.unit === 'percent') {
        return (
            <ChipBaterryPercent value={record.last_battery.value} />
        )

    } else if (record.last_battery && record.last_battery.status) {
        if (record.last_battery.status === 'good') {
            return (
                <ChipBaterryGoodStatus />
            )

        } else if (record.last_battery.status === 'bad') {
            return (
                <ChipBaterryBadStatus />
            )
        }
    } else if (record.last_battery && record.last_battery.unit === 'millivolt') {
        return (
            <ChipBaterryMillivolt value={record.last_battery.value} />
        )
    }

    return null

}



/*
export const Battery = ({ record }) => {
    const translate = useTranslate();
    const [open, setOpen] = useState(false)
    const handleClick = () => {
        setOpen(!open)
    }
    if (record.last_battery && record.last_battery.unit === 'percent') {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}>
                <Typography variant='body2'>{`${record.last_battery.value}%`}</Typography>
                <Typography variant="caption" style={{ fontSize: '10px' }}  >{new Date(record.last_battery.timestamp).toLocaleDateString()}</Typography>
            </Box>
        )

    } else if (record.last_battery && record.last_battery.status) {
        if (record.last_battery.status === 'good') {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}>
                    <Chip
                        sx={{
                            textTransform: 'uppercase',
                            fontSize: 10,
                            height: 24,
                            backgroundColor: green[500], color: 'white'
                        }}
                        label={translate('resources.devices.battery_status.good')}
                    />
                    <Typography variant="caption" style={{ fontSize: '7px' }}>{new Date(record.last_battery.timestamp).toLocaleDateString()}</Typography>
                </Box>
            )
        } else if (record.last_battery.status === 'bad') {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}>
                    <Chip
                        sx={{
                            textTransform: 'uppercase',
                            fontSize: 10,
                            height: 24, backgroundColor: red[500], color: 'white'
                        }}
                        label={translate('resources.devices.battery_status.bad')}
                    />
                    <Typography variant="caption" style={{ fontSize: '7px' }}>{new Date(record.last_battery.timestamp).toLocaleDateString()}</Typography>
                </Box>
            )
        } else {
            return null
        }
    } else if (getNumberOfDaysSinceLastSeen(record) > -1 && getNumberOfDaysSinceLastSeen(record) <= 3) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}>
                <Chip

                    sx={{
                        textTransform: 'uppercase',
                        fontSize: 10,
                        height: 24,
                        backgroundColor: green[500], color: 'white'
                    }}
                    label={translate('resources.devices.battery_status.good')}
                    onClick={handleClick}
                />
                {open && record.last_battery && record.last_battery.unit === 'millivolt' &&
                    <Typography variant='caption'>{`${record.last_battery.value} mV`}</Typography>

                }
                {open && record.last_battery && record.last_battery.unit === 'millivolt' &&

                    <Typography variant="caption" style={{ fontSize: '7px' }}>{new Date(record.last_battery.timestamp).toLocaleDateString()}</Typography>

                }
            </Box>
        )
    } else if (getNumberOfDaysSinceLastSeen(record) > 3) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}>
                <Chip
                    sx={{
                        textTransform: 'uppercase',
                        fontSize: 10,
                        height: 24, backgroundColor: red[500], color: 'white'
                    }}
                    label={translate('resources.devices.battery_status.bad')}
                    onClick={handleClick}
                />
                {open && record.last_battery && record.last_battery.unit === 'millivolt' &&
                    <Typography variant='caption'>{`${record.last_battery.value} mV`}</Typography>

                }
                {open && record.last_battery && record.last_battery.unit === 'millivolt' &&

                    <Typography variant="caption" style={{ fontSize: '7px' }}>{new Date(record.last_battery.timestamp).toLocaleDateString()}</Typography>

                }
            </Box >
        )
    } else {
        return null
    }
}

*/