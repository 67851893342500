import React, { useRef, useState } from 'react'
import { List, Box, Dialog, DialogContent, Button } from '@mui/material'
import PerfectScrollbar from '@opuscapita/react-perfect-scrollbar'
import { useTranslate } from 'react-admin'
import { useTheme } from '@mui/material';



export const styles = theme => ({
    list: {
        display: 'flex',
        flexDirection: 'column',
        flex: '0 0 auto',
        height: '100%',
        width: 310,
        backgroundColor: theme.palette.background.rightbar,
        overflow: 'hidden',
        border: 'solid 1px #d9d9d9',
        borderBottom: 'none',
        marginRight: -4,
        padding: 0,
    },
    listItems: {
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: 6,
    },
    listHeader: {
        width: '100%',
    },
})


const MobileMenu = ({ locations, idSelected, handleSelect, handleUnSelect, MenuCustomListItem, scrollXContainerRef }) => {

    const theme = useTheme();
    const translate = useTranslate();
    const listRef = useRef(null);

    const listItems =
        locations.length > 0
            ? locations.map((location, idx) => {
                return (
                    <Box sx={{
                        display: "flex",
                        width: "270px !important",
                        minWidth: "270px !important",
                        marginRight: "40px",
                        background: "none",
                    }}>
                        <MenuCustomListItem
                            key={idx}
                            number={location.number || idx + 1}
                            location={location}
                            translate={translate}
                            handleSelect={handleSelect}
                            handleUnSelect={handleUnSelect}
                            selected={location.id === idSelected}
                            theme={theme}
                            isMobile={true}
                        />
                    </Box>

                )
            })
            : []



    return (<Box sx={{
        position: 'absolute',
        bottom: '10px',
        right: "0px",
        padding: "0px",
        height: 'auto',
        width: "100%",
        background: "none",
        zIndex: 1000
    }}>
        <Box sx={{
            display: 'flex',
            flexDirection: "row",
            alignItems: 'center',
            justifyContent: 'center',
            background: "none",
            zIndex: 1000
        }}>

            <PerfectScrollbar

                containerRef={(ref) => (scrollXContainerRef.current = ref)}
                option={{ wheelPropagation: true, suppressScrollY: true }}
            >

                <Box sx={{
                    display: 'flex',
                    flexDirection: "row",
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    padding: "10px",
                    minHeight: '100px',
                    maxHeight: '200px',
                    background: "none",
                }}>

                    {listItems}
                </Box>

            </PerfectScrollbar>
        </Box>



    </Box>)


}




const MobileDialogMenu = (
    { locations, idSelected, handleSelect, handleUnSelect, MenuCustomListItem, scrollYContainerRef, MenuCustomListSubheader }
) => {
    const theme = useTheme();
    const translate = useTranslate();
    const [open, setOpen] = useState(true)
    const listRef = useRef(null);

    const listItems =
        locations.length > 0
            ? locations.map((location, idx) => {
                return (
                    <Box sx={{
                        display: "flex",
                        width: "100% ",
                        margin: "0px",
                        padding: "0px 10px 0px 0px",
                        background: "none",
                    }}>
                        <MenuCustomListItem
                            key={idx}
                            number={location.number || idx + 1}
                            location={location}
                            translate={translate}
                            handleSelect={handleSelect}
                            handleUnSelect={handleUnSelect}
                            selected={location.id === idSelected}
                            theme={theme}
                            isMobile={true}
                            isListAsDialog={true}
                        />
                    </Box>

                )


            }).filter(x => x !== null)
            : []



    const handleDrag = (event) => {
        const listElement = listRef.current;
        if (!listElement) return;

        // Detect touch or mouse events
        const startY = event.type === 'mousedown'
            ? event.clientY
            : event.touches[0].clientY;

        const onMove = (moveEvent) => {
            const currentY = moveEvent.type === 'mousemove'
                ? moveEvent.clientY
                : moveEvent.touches[0].clientY;

            const deltaY = currentY - startY;

            if (deltaY > 40) {
                setOpen(false)
            }
            else if (deltaY < -40) {
                setOpen(true)
            } else {
                // Temporarily move the dialog down
                listElement.style.transform = `translateY(${Math.min(deltaY, 40)}px)`;
            }
        };

        const onEnd = () => {
            // Reset dialog position and remove listeners
            listElement.style.transform = '';
            window.removeEventListener('mousemove', onMove);
            window.removeEventListener('mouseup', onEnd);
            window.removeEventListener('touchmove', onMove);
            window.removeEventListener('touchend', onEnd);
        };

        // Add listeners for dragging and release
        window.addEventListener('mousemove', onMove);
        window.addEventListener('mouseup', onEnd);
        window.addEventListener('touchmove', onMove);
        window.addEventListener('touchend', onEnd);
    };
    return (
        <Box
            ref={listRef}
            sx={{
                height: open ? "280px" : "65px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                margin: "10px 0px 0px 0px",
                padding: "0px",
                backgroundColor: theme.palette.background.paper,
                borderRadius: "12px 12px 0px 0px",
                width: "100%",
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    height: "15px",
                    margin: "0px",
                    borderRadius: "0px",
                    backgroundColor: "none",
                    opacity: 0.45,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
                onMouseDown={handleDrag} // Handle mouse drag
                onTouchStart={handleDrag}

            >
                <Box
                    sx={{
                        width: "40px",
                        height: "4px",
                        margin: "1px",
                        borderRadius: "12px",
                        backgroundColor: theme.palette.text.secondary,
                        opacity: 0.45
                    }}


                >
                </Box>
            </Box>
            <List component="nav"
                dense={true}
                subheader={<MenuCustomListSubheader />}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '0 0 auto',
                    height: open ? "250px" : "40px",
                    width: "100%",
                    backgroundColor: theme.palette.background.paper,
                    overflow: 'hidden',
                    margin: "0px",
                    padding: "0px 5px 0px 5px"
                }}>

                {listItems.length > 0 && (
                    <PerfectScrollbar
                        containerRef={(ref) => (scrollYContainerRef.current = ref)}
                        sx={{
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            padding: 6
                        }}
                    >

                        {listItems}
                    </PerfectScrollbar>
                )}

            </List>


        </Box>
    )
}





const Menu = (props) => {

    const theme = useTheme();
    const translate = useTranslate();
    const { idSelected = '', locations, handleUnSelect, handleSelect, updateIcon, updateStatus, acknowledgeRollover, acknowledgeMotion, MenuCustomListSubheader, MenuCustomListItem, scrollYContainerRef, scrollXContainerRef, isMobile, isListAsDialog } = props

    const deviceName = ''

    const listItems =
        locations.length > 0
            ? locations.map((location, idx) => {
                return (
                    <MenuCustomListItem
                        key={idx}
                        number={location.number || idx + 1}
                        location={location}
                        translate={translate}
                        handleSelect={handleSelect}
                        handleUnSelect={handleUnSelect}
                        selected={location.id === idSelected}
                        theme={theme}
                    />
                )
            })
            : []



    if (isMobile && isListAsDialog) return <MobileDialogMenu
        listItems={listItems}
        locations={locations}
        idSelected={idSelected}
        handleSelect={handleSelect}
        handleUnSelect={handleUnSelect}
        MenuCustomListItem={MenuCustomListItem}
        MenuCustomListSubheader={MenuCustomListSubheader}
        scrollYContainerRef={scrollYContainerRef}
    />


    if (isMobile) return <MobileMenu
        listItems={listItems}
        locations={locations}
        idSelected={idSelected}
        handleSelect={handleSelect}
        handleUnSelect={handleUnSelect}
        MenuCustomListItem={MenuCustomListItem}
        scrollXContainerRef={scrollXContainerRef}
    />
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
        }}>

            <List component="nav"
                dense={true}
                subheader={<MenuCustomListSubheader />}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '0 0 auto',
                    height: '100%',
                    width: "300px",
                    borderRadius: "12px",
                    backgroundColor: theme.palette.background.paper,
                    overflow: 'hidden',
                    marginRight: "20px",
                    padding: "0px"
                }}>

                {locations.length > 0 && (
                    <PerfectScrollbar
                        containerRef={(ref) => (scrollYContainerRef.current = ref)}
                        sx={{
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            padding: 6
                        }}
                    //ref={s => (scrollbar = s)}
                    >

                        {listItems}
                    </PerfectScrollbar>
                )}
            </List>


        </Box>
    )
}



export default Menu
